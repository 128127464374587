import React from 'react';
import { stringifyBytesSize } from '../../../../helpers';
import { dateFormatter, nameFormatter } from '../../../Investigation/utils';
import { INVESTIGATION_DATE_FORMAT } from '../../../../constants';
import ResponsiveTable from '../../../Table/ResponsiveTable';

function LogbookStatsResponsiveTable(props) {
  const { statistics } = props;
  return (
    <>
      <br />
      <ResponsiveTable
        data={statistics}
        pageOptions={{
          showTotal: true,
          sizePerPageList: [
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
            { text: '500', value: 500 },
          ],
        }}
        columns={[
          {
            text: 'investigationId',
            dataField: 'investigationId',
            hidden: true,
          },
          {
            text: 'Instrument',
            dataField: 'instrument',
            hidden: false,
          },
          {
            text: 'Proposal',
            dataField: 'name',
            hidden: false,
            formatter: (_, record) =>
              nameFormatter(
                {
                  id: record.investigationId,
                  name: record.name,
                },
                true,
                'events'
              ),
          },
          {
            text: 'Title',
            dataField: 'title',
            hidden: false,
          },
          {
            text: 'Start',
            dataField: 'startDate',
            hidden: false,
            formatter: (_, record) =>
              dateFormatter(record.startDate, INVESTIGATION_DATE_FORMAT, false),
          },
          {
            text: 'End',
            dataField: 'endDate',
            hidden: false,
            formatter: (_, record) =>
              dateFormatter(record.endDate, INVESTIGATION_DATE_FORMAT, false),
          },
          {
            text: 'Annotations',
            dataField: 'annotations',
            hidden: false,
            sort: true,
          },
          {
            text: 'Notifications',
            dataField: 'notifications',
            hidden: false,
            sort: true,
          },
          {
            text: 'Total events',
            dataField: 'count',
            hidden: false,
            sort: true,
          },
          {
            text: 'Sample',
            dataField: '__sampleCount',
            hidden: false,
          },
          {
            text: 'Files',
            dataField: '__fileCount',
            hidden: false,
          },
          {
            text: 'Datasets',
            dataField: '__datasetCount',
            hidden: false,
          },
          {
            text: 'Volume',
            dataField: '__volume',
            hidden: false,
            formatter: (_, record) =>
              record.__volume
                ? stringifyBytesSize(record.__volume)
                : stringifyBytesSize(0),
          },
        ]}
      />
    </>
  );
}

export default LogbookStatsResponsiveTable;
