import moment from 'moment';

/**
 * Given two dates it will return an array with every day between the two dates
 * @param {*} startDate Date object
 * @param {*} endDate Date object
 */
export function enumerateDaysBetweenDates(startDate, endDate) {
  const dates = [];
  const currDate = moment(startDate).startOf('day');
  while (currDate.add(1, 'days').diff(moment(endDate).startOf('day')) < 0) {
    dates.push(currDate.clone().toDate());
  }
  return dates;
}

/**
 * Given two dates it returns true if both are the same day
 * @param {*} date1
 * @param {*} date2
 */
export function isSameDate(date1, date2) {
  return (
    moment(date1).isSame(moment(date2), 'day') &&
    moment(date1).isSame(moment(date2), 'month') &&
    moment(date1).isSame(moment(date2), 'year')
  );
}
