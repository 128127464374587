import React from 'react';
import {
  Col,
  Container,
  Row,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useQuery } from '../../helpers/hooks';
import { faFillDrip, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ViewSwitcher(props) {
  const { view } = props;
  const history = useHistory();
  const query = useQuery();

  const radios = [
    {
      name: 'Datasets',
      value: 'datasets',
      icon: <FontAwesomeIcon icon={faFillDrip} style={{ marginRight: 10 }} />,
    },
    {
      name: 'Files',
      value: 'files',
      icon: <FontAwesomeIcon icon={faFile} style={{ marginRight: 10 }} />,
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col>
          <ButtonGroup className="mb-2">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="info"
                name="radio"
                value={radio.value}
                checked={view === radio.value}
                onChange={(e) => {
                  query.set('view', e.currentTarget.value);
                  history.push({ search: query.toString() });
                }}
              >
                {radio.icon}
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewSwitcher;
