import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import DataStatisticsCSVResource from '../../../resources/dataStatisticsCSV';
import { useResource } from 'rest-hooks';
import HeatmapBeamlinePlot from './Plots/HeatmapBeamlinePlot';
import BarBeamlinePlot from './Plots/BarBeamlinePlot';
import LineBeamlinePlot from './Plots/LineBeamlinePlot';
import { parseCSV, parseTotalCSV } from './Plots/helpers';

function BeamlinePlot(props) {
  const {
    instrumentName,
    interval,
    startDate,
    endDate,
    metric,
    aggregation,
    plottype,
  } = props;

  const response = useResource(DataStatisticsCSVResource.listShape(), {
    instrumentName,
    interval,
    startDate,
    endDate,
    metric,
    aggregation,
    format: 'csv_json',
  });

  if (!response) {
    return (
      <Alert variant="danger">No data was retrieved from elastic search</Alert>
    );
  }

  return (
    <div className="app__inner">
      <Card style={{ margin: 10 }} border="primary">
        <Card.Header>
          <Card.Title>
            {metric} produced from {startDate} to {endDate}{' '}
            {metric === 'volume' ? ' in GB' : ''}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {plottype === 'heatmap' && (
            <HeatmapBeamlinePlot
              response={response}
              metric={metric}
            ></HeatmapBeamlinePlot>
          )}
          {plottype === 'bar' && (
            <BarBeamlinePlot
              response={response}
              metric={metric}
            ></BarBeamlinePlot>
          )}
          {plottype === 'line' && (
            <LineBeamlinePlot
              response={response}
              metric={metric}
              parse={parseCSV}
            ></LineBeamlinePlot>
          )}
          {plottype === 'linetotal' && (
            <LineBeamlinePlot
              response={response}
              metric={metric}
              parse={parseTotalCSV}
            ></LineBeamlinePlot>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default BeamlinePlot;
