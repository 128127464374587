import axios from 'axios';
import React, { useState } from 'react';
import {
  Button,
  Form,
  Dropdown,
  Card,
  SplitButton,
  Alert,
  Col,
  Row,
  Badge,
} from 'react-bootstrap';
import { getMintDOI } from '../../api/icat-plus/doi';
import BootstrapTable2 from 'react-bootstrap-table-next';
import { USER_ROLES } from '../../constants';
import { trackMintDOI } from '../../helpers/trackData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import Mandatory from '../Mandatory';

function DOIForm(props) {
  const { sessionId, datasets, investigationUsers } = props;
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [form, setForm] = useState({});
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [authorCount, setAuthorCount] = useState(0);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const addAuthor = (investigationUser) => {
    const authorsList = [...authors];
    if (!investigationUser.fullName) {
      authorsList.push({
        name: form.authorName,
        surname: form.authorSurname,
        id: `${form.authorName}_${form.authorSurname}_${authorCount}`,
      });
    } else {
      const name = investigationUser.fullName.split(' ')[0];
      const surname = investigationUser.fullName.replace(
        investigationUser.fullName.split(' ')[0],
        ''
      );
      authorsList.push({
        name,
        surname,
        id: `${name}_${surname}_${authorCount}`,
      });
    }
    setAuthorCount(authorCount + 1);
    setAuthors(authorsList);
  };

  const addAuthorByName = (name, investigationUsers) => {
    const usersByName = investigationUsers.filter((u) => u.name === name);
    return usersByName.length > 0 ? addAuthor(usersByName[0]) : undefined;
  };

  const handleOnSelectUser = (row, isSelected) => {
    if (isSelected) {
      setSelectedAuthors([...selectedAuthors, row.id]);
    } else {
      setSelectedAuthors(selectedAuthors.filter((x) => x !== row.id));
    }
  };

  const handleOnSelectAllUsers = (isSelected, rows) => {
    if (isSelected) {
      setSelectedAuthors({ selectedAuthors: rows.map((r) => r.id) });
    } else {
      setSelectedAuthors([]);
    }
  };

  const handleDeleteClick = () => {
    setAuthors(
      authors.filter((author) => !selectedAuthors.includes(author.id))
    );
    setSelectedAuthors([]);
  };

  const mint = () => {
    const { title, abstract } = form;
    const datasetIdList = [];

    for (let i = 0; i < datasets.length; i++) {
      datasetIdList.push(datasets[i].id);
    }
    const url = getMintDOI(sessionId);
    setFetching(true);
    trackMintDOI(datasetIdList.length);
    axios
      .post(url, {
        title,
        abstract,
        datasetIdList,
        authors,
      })
      .then((response) => {
        setFetching(false);
        setError(null);
        setSuccessMessage(`Your DOI has been created ${response.data.message}`);
      })
      .catch((error) => {
        setFetching(false);
        let errorMessage = `There was an error during the minting of the DOI. Contact administrators. ${error}`;
        if (error?.response?.status === 403) {
          errorMessage = `There was an error during the minting of the DOI. You can only mint a DOI for datasets which are linked to
          investigations where you are a user. Contact administrators otherwise. ${error}`;
        }
        setError(errorMessage);
        setSuccessMessage(null);
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    event.preventDefault();
    if (form.checkValidity() === true && authors.length !== 0) {
      mint();
    }
  };

  const renderButton = () => {
    return (
      <SplitButton
        id="add-author"
        variant="secondary"
        title="Add author"
        onClick={addAuthor}
        onSelect={(e) => addAuthorByName(e, investigationUsers)}
      >
        {investigationUsers.map((user) => {
          const variantRole =
            user.role === USER_ROLES.PrincipalInvestigator
              ? 'primary'
              : 'secondary';
          return (
            <Dropdown.Item
              key={`${user.fullName} ${user.investigationId} ${user.role}`}
              eventKey={user.name}
              style={{ width: 350 }}
            >
              <p>
                {user.fullName}
                <Badge bg="info" style={{ marginLeft: 10 }}>
                  {user.investigationName}
                </Badge>
                <Badge bg={variantRole} style={{ marginLeft: 10 }}>
                  {user.role}
                </Badge>
              </p>
            </Dropdown.Item>
          );
        }, this)}
      </SplitButton>
    );
  };

  return (
    <>
      {error && (
        <Alert variant="danger">
          <h4>Oh snap! You got an error!</h4>
          <p>{error}</p>
        </Alert>
      )}
      {successMessage && (
        <Alert variant="success">
          <h4>DOI has been minted successfully</h4>
          <p>{successMessage}</p>
        </Alert>
      )}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formControlsTextarea">
          <Form.Label>
            Title <Mandatory />
          </Form.Label>
          <Form.Control
            required
            name="title"
            as="textarea"
            placeholder=""
            onChange={(e) => setField('title', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a title.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formControlsTextarea">
          <Form.Label>
            Abstract <Mandatory />
          </Form.Label>
          <Form.Control
            required
            name="abstract"
            as="textarea"
            placeholder=""
            onChange={(e) => setField('abstract', e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Please provide an abstract.
          </Form.Control.Feedback>
        </Form.Group>
        <br />
        <Card border="primary">
          <Card.Header>
            <Card.Title>Authors</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Form.Group as={Row} controlId="formInlineName">
                  <Form.Label column sm="3">
                    Name
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      name="authorName"
                      type="text"
                      onChange={(e) => setField('authorName', e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  as={Row}
                  controlId="formInlineEmail"
                  style={{ marginLeft: 10 }}
                >
                  <Form.Label column sm="4">
                    Surname
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="authorSurname"
                      onChange={(e) =>
                        setField('authorSurname', e.target.value)
                      }
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col>{renderButton()}</Col>
            </Row>

            <br />
            <Button
              variant="warning"
              style={{ marginBottom: 10 }}
              disabled={selectedAuthors.length === 0}
              onClick={handleDeleteClick}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: 10 }} />
              Delete
            </Button>
            <BootstrapTable2
              keyField="id"
              data={authors}
              columns={[
                { dataField: 'id', text: '', hidden: true },
                { dataField: 'name', text: 'Name' },
                { dataField: 'surname', text: 'Surname' },
              ]}
              selectRow={{
                mode: 'checkbox',
                clickToSelect: true,
                selected: selectedAuthors,
                onSelect: handleOnSelectUser,
                onSelectAll: handleOnSelectAllUsers,
              }}
              striped
              hover
              condensed
            />
          </Card.Body>
        </Card>
        <br />
        {fetching && (
          <Button className="btn btn-primary pull-right">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        )}
        {!fetching && (
          <Button type="submit" className="btn btn-primary pull-right">
            Mint
          </Button>
        )}
      </Form>
    </>
  );
}
export default DOIForm;
