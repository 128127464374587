import { PropTypes } from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';

function FieldAlert(props) {
  const { fieldLabel, error } = props;

  return (
    <Alert variant="warning">
      {error.type === 'required' && `${fieldLabel} is required`}
      {error.type === 'pattern' && `${fieldLabel} is invalid`}
      {error.type === 'maxLength' && `${fieldLabel} exceeds the maximum length`}
    </Alert>
  );
}

FieldAlert.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  error: PropTypes.shape({ type: PropTypes.string }).isRequired,
};

export default FieldAlert;
