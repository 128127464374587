import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

function NewlyAvailableEventsDialogue(props) {
  const {
    autorefreshEventList,
    eventCountSinceLastRefresh,
    eventCountByPeriodicRefresher,
    onIconClicked,
  } = props;

  if (
    autorefreshEventList ||
    eventCountByPeriodicRefresher === 0 ||
    eventCountByPeriodicRefresher < eventCountSinceLastRefresh
  ) {
    return null;
  }

  return (
    <div className="btn btn-sm" style={{ color: '#888' }}>
      <span>
        {eventCountByPeriodicRefresher - eventCountSinceLastRefresh} new log(s)
        arrived.
      </span>
      <FontAwesomeIcon icon={faSyncAlt} onClick={onIconClicked} />
    </div>
  );
}

export default NewlyAvailableEventsDialogue;

NewlyAvailableEventsDialogue.proptype = {
  /** Whether the event list refreshes automatically when a new event has arrived */
  autorefreshEventList: PropTypes.bool,
  /* Total number of event in the logbook known by the client since the last refresh of the event list */
  eventCountSinceLastRefresh: PropTypes.number,
  /* Latest event count as found by the periodic calls to the server */
  eventCountByPeriodicRefresher: PropTypes.number,
  /* Callback function triggered which the user clicks the icon*/
  onIconClicked: PropTypes.func,
};
