import _ from 'lodash-es';
import moment from 'moment';

/**
 * Given a parcel and a date it returns the status of the parcel at that date
 * @param {*} parcel
 * @param {*} date
 */
export function getStatusByDate(parcel, date) {
  let parcelStatusAtDate;
  parcel.statuses.forEach(({ updatedAt, status }) => {
    if (moment(new Date(updatedAt)).isBefore(moment(new Date(date)))) {
      parcelStatusAtDate = status;
    }
  });
  return parcelStatusAtDate;
}
/**
 * Returns an object componsed by dates and statuses. Dates is an array with dates used as input and the statuses is an array of a dictionary that is has as key the name of the status and the value if the number of occurences
 * @param {*} parcels
 * @param {*} dates
 */
export function getStatuses(parcels, dates) {
  const statuses = [];
  dates.map((date) => {
    statuses.push({});
    return parcels.forEach((parcel) => {
      const status = getStatusByDate(parcel, date);
      statuses[statuses.length - 1][status]
        ? (statuses[statuses.length - 1][status] =
            statuses[statuses.length - 1][status] + 1)
        : (statuses[statuses.length - 1][status] = 1);
    });
  });
  return {
    dates,
    statuses,
  };
}
export function getItemsCount(parcels) {
  const count = parcels.map((p) => {
    return p.items.length;
  });
  return count.length === 0 ? 0 : count.reduce((total, num) => total + num);
}

/**
 * Get the count of parcel by status
 * @param {*} parcels
 * @param {*} status
 */
export function getParcelCountByStatus(parcels, status) {
  return parcels.filter((p) => p.status === status).length;
}

/**
 * Get the count of parcel by status
 * @param {*} parcels
 * @param {*} status
 */
export function getParcelInstrumentByStatus(parcels, status) {
  return parcels
    .filter((p) => p.status === status)
    .map((p) => (p.investigation ? p.investigation.instrument.name : null));
}

/**
 * Given an array of parcels it will return the count by type
 * @param {*} parcels
 * @param {*} type SAMPLESHEET | TOOL | OTHER
 */
function getItemCountByType(parcels, type) {
  const count = parcels.map((p) => {
    return p.items.filter((item) => item.type === type).length;
  });
  return count.length === 0 ? 0 : count.reduce((total, num) => total + num);
}

/**
 * Given a list of parcels it return some statistics
 * @param {*} parcels Array with the object parcel
 */
export function getStatisticsByParcels(parcels) {
  const groupedByInvestigationId = _.groupBy(parcels, 'investigationId');

  const sessions = [];
  for (const investigationId in groupedByInvestigationId) {
    const parcels = groupedByInvestigationId[investigationId];
    const itemCount = parcels
      .map((p) => p.items.length)
      .reduce((total, num) => total + num);
    const sampleCount = parcels
      .map((p) => p.items.filter((i) => i.type === 'SAMPLESHEET').length)
      .reduce((total, num) => total + num);

    sessions.push({
      beamline:
        groupedByInvestigationId[investigationId][0].investigation.instrument
          .name,
      investigationName:
        groupedByInvestigationId[investigationId][0].investigationName,
      parcels,
      investigation: groupedByInvestigationId[investigationId][0].investigation,
      itemCount,
      sampleCount,
    });
  }

  const beamlines = [...new Set(sessions.map((item) => item.beamline))].sort();
  const parcelsPerBeamline = beamlines.map((beamline) => {
    return parcels.filter((p) => {
      return p.investigation.instrument.name === beamline;
    }).length;
  });
  const itemsPerBeamline = beamlines.map((beamline) => {
    return parcels
      .filter((p) => {
        return p.investigation.instrument.name === beamline;
      })
      .map((p) => p.items.length)
      .reduce((total, num) => total + num);
  });
  return {
    sessions,
    parcelsCount: parcels.length,
    itemsCount: getItemsCount(parcels),
    samplesCount: getItemCountByType(parcels, 'SAMPLESHEET'),
    toolsCount: getItemCountByType(parcels, 'TOOL'),
    othersCount: getItemCountByType(parcels, 'OTHER'),
    beamlinesCount: beamlines.length,
    beamlines,
    parcelsPerBeamline,
    itemsPerBeamline,
  };
}
