import React from 'react';
import { getDatasetParameterValueByName } from '../../../helpers';
import PublicationSummary from './Publication/PublicationSummary';
import HumanAtlasDatasetWidget from './HumanAtlas/HumanAtlasDatasetWidget';
import GenericDatasetWidget from './Generic/GenericDatasetWidget';
import DATASET_WIDGET_FACTORY from '../../../config/datasetWidgetConfiguration';
import BioSaxsDatasetWidget from './BioSaxs/BioSaxsDatasetWidget';
import HTXRPDDatasetWidget from './HTXRPD/HTXRPDDatasetWidget';

const filterConfigurationByProjectAndDefinition = (
  parameter,
  value,
  dataset
) => {
  const datasetStartDate = new Date(dataset.startDate);

  return DATASET_WIDGET_FACTORY.filter((view) => {
    // This will take into account the project name and the dates to check which "view" should be used for the dataset table
    return (
      view[parameter] === value &&
      ((view.startDate <= datasetStartDate &&
        view.endDate > datasetStartDate &&
        view.endDate !== '') ||
        (view.endDate === '' && view.startDate <= datasetStartDate))
    );
  });
};

function Factory(properties) {
  const { projectName, definition, dataset } = properties;

  let component = <GenericDatasetWidget {...properties} />;

  /** Giving priorities to the projects  */
  if (projectName && projectName !== '') {
    const widgets = filterConfigurationByProjectAndDefinition(
      'projectName',
      projectName,
      dataset
    );
    if (widgets.length > 0) {
      switch (widgets[0].widget) {
        case 'HumanAtlasDatasetWidget':
          return <HumanAtlasDatasetWidget {...properties} />;
        default:
          component = <GenericDatasetWidget {...properties} />;
      }
    }
  }
  /** If no project then search by definition */
  if (definition) {
    const widgets = filterConfigurationByProjectAndDefinition(
      'definition',
      definition,
      dataset
    );

    if (widgets.length > 0) {
      switch (widgets[0].widget) {
        case 'PublicationSummary':
          return <PublicationSummary {...properties} />;
        case 'HTXRPDDatasetWidget':
          return <HTXRPDDatasetWidget {...properties} />;
        case 'BioSaxsDatasetWidget':
          return <BioSaxsDatasetWidget {...properties} />;
        default:
          component = <GenericDatasetWidget {...properties} />;
      }
    }
  }
  return component;
}
/**
 * This function is the visual representation of a dataset. The generic class is DatasetWidget that gets specialized in different classes depending on the definition or project
 * @param {*} props
 */
function DatasetWidgetFactory(props) {
  const { dataset } = props;

  const definition = getDatasetParameterValueByName(dataset, 'definition');
  const projectName = getDatasetParameterValueByName(dataset, 'Project_name');

  return (
    <Factory definition={definition} projectName={projectName} {...props} />
  );
}

export default DatasetWidgetFactory;
