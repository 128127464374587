import { Resource } from 'rest-hooks';
import UI from '../config/ui';

export default class DataciteDoiResource extends Resource {
  data = undefined;

  pk() {
    return this.data?.id?.toString();
  }

  static get key() {
    return 'DataciteDoiResource';
  }

  static listUrl(params) {
    const { doi } = params;
    return `${UI.doi.server}/${doi}`;
  }

  static url(params) {
    const { doi } = params;
    return `${UI.doi.server}/${doi}`;
  }

  // override the fetch method to handle errors when not existing doi
  static fetch(method, url, body) {
    return this.fetchResponse(method, url, body)
      .then((response) => {
        if (
          !response.headers.get('content-type')?.includes('json') ||
          response.status === 204
        )
          return response.text();
        return response.json().catch((error) => {
          error.status = 400;
          throw error;
        });
      })
      .catch((error) => {
        if (error.status === 404) {
          return { data: { id: -1 }, error: true };
        }
        throw error;
      });
  }
}
