import React, { useState } from 'react';
import { useFetcher } from 'rest-hooks';
import { useHistory } from 'react-router';
import { useQuery, useURLParams } from '../../helpers/hooks';
import Loader from '../Loader';
import {
  Button,
  OverlayTrigger,
  Card,
  Tooltip,
  Alert,
  Badge,
  Container,
  Row,
  Col,
  Stack,
} from 'react-bootstrap';
import TimeAgo from 'react-timeago';
import {
  ANNOTATION,
  EVENT_CATEGORY_COMMENT,
  LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT,
  LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
  LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT,
  LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
} from '../../constants/eventTypes';
import TagsSelectorContainer from '../../containers/Logbook/Tags/TagsSelectorContainer';
import { getText } from '../../helpers/eventHelpers';
import EditorWrapper from './Editor/EditorWrapper';
import EventFooter from './EventFooter';
import EventVersions from './EventVersions';
import EventResource from '../../resources/events';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** Render author and time */
const AuthorAndTime = (props) => {
  if (props.event) {
    return (
      <h5 style={{ marginTop: 0, marginBottom: 0 }}>
        <small style={{ fontSize: 'x-small', color: 'white' }}>
          {' '}
          <em>
            @{props.event.fullName} -{' '}
            <TimeAgo date={props.event.creationDate} />
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={EventVersions(props.event)}
              rootClose
            >
              <div style={{ display: 'inline-block' }}>
                <Button variant="link" size="sm">
                  {' '}
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{ color: 'white' }}
                  />{' '}
                </Button>
              </div>
            </OverlayTrigger>
          </em>{' '}
        </small>
      </h5>
    );
  }
  return null;
};

/**
 *
 * React component which renders a Panel for creating of editing an event
 */
function NewOrEditEventPanel(props) {
  const { user, investigationId, refetch, instrumentName, isReleased } = props;
  let { event } = props;

  const history = useHistory();
  const query = useQuery();

  /* URL to the TAG Manager page */
  const tagPageURL = `/tag?${useURLParams({
    investigationId,
    instrumentName,
  })}`;
  /** State */
  const [selectedTags, setSelectedTags] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  /* Fetchers */
  const createEvent = useFetcher(EventResource.createShape());
  const updateEvent = useFetcher(EventResource.updateShape());

  /* Not sure if we should kept this code, now it is useless but it might be needed **/
  const onEventContentChanged = () => {
    let isEditorContentValid = false;

    if (event) {
      //editing an event
      const currentTextInEditionMode = localStorage.getItem(
        LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT + event._id
      );
      isEditorContentValid =
        currentTextInEditionMode !== '' &&
        currentTextInEditionMode !== getText(event.content, 'plainText');
    } else {
      //creating an event
      const currentTextInCreationMode = localStorage.getItem(
        LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT
      );
      isEditorContentValid = currentTextInCreationMode !== '';
    }
    return isEditorContentValid;
  };

  const getContent = (plainTextKey, htmlKey) => {
    return [
      {
        format: 'plainText',
        text: localStorage.getItem(plainTextKey),
      },
      {
        format: 'html',
        text: localStorage.getItem(htmlKey),
      },
    ];
  };

  const saveEvent = async () => {
    setLoading(true);
    const currentTagIds = selectedTags.map((tag) => tag._id);
    if (event) {
      await updateEvent(
        { investigationId, instrumentName },
        {
          _id: event._id,
          category: event.category,
          content: getContent(
            LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT +
              event._id,
            LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT + event._id
          ),
          creationDate: Date(),
          type: event.type,
          tag: currentTagIds,
          title: null,
          previousVersionEvent: event._id,
        }
      );
    } else {
      // creation of an event
      event = await createEvent(
        { investigationId, instrumentName },
        {
          category: EVENT_CATEGORY_COMMENT,
          content: getContent(
            LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
            LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT
          ),
          creationDate: Date(),
          investigationId,
          title: null,
          tag: currentTagIds,
          type: ANNOTATION,
        }
      );
      await refetch();
      setLoading(false);
    }
  };

  const closeEditPanel = () => {
    query.delete('edit');
    history.push({ search: query.toString() });
  };

  /**
   * Callback function triggered when the user click the save button while an event is being created or updated.
   */
  const onSaveButtonClicked = async () => {
    try {
      await saveEvent();
      closeEditPanel();
      query.set('edit', event._id);
      history.push({ search: query.toString() });
    } catch (e) {
      console.log(e);
      setError(`${e.status}:${e.message}`);
    }
  };

  const onSaveAndCloseButtonClicked = async () => {
    try {
      await saveEvent();
      closeEditPanel();
    } catch (e) {
      console.log(e);
      setError(`${e.status}:${e.message}`);
    }
  };

  const onCancelButtonClicked = () => {
    localStorage.removeItem('plainText');
    localStorage.removeItem('HTMLText');
    closeEditPanel();
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Card bg="secondary">
          <Card.Header style={{ padding: '5px 15px' }}>
            <Stack direction="horizontal" gap={3}>
              <b>{event ? 'Edit' : 'New comment'}</b>
              <div className="ms-auto">
                <AuthorAndTime event={event} />
              </div>
            </Stack>
          </Card.Header>
        </Card>

        {error && (
          <div style={{ padding: 2 }}>
            <Alert variant="danger">
              <strong>Oops</strong> {error}
            </Alert>
          </div>
        )}

        <div style={{ flex: '2 2 70%' }}>
          {loading && (
            <>
              <br />
              <Loader message="Saving..."></Loader>
            </>
          )}
          {!loading && (
            <EditorWrapper
              event={event}
              investigationId={investigationId}
              instrumentName={instrumentName}
              onContentChanged={onEventContentChanged}
              user={user}
            />
          )}
        </div>

        <Container fluid style={{ marginTop: 10 }}>
          <Row>
            <Col>
              <h5 style={{ marginTop: 5, marginBottom: 5 }}>
                <Badge bg="secondary">Tags</Badge>
              </h5>
            </Col>
            <Col>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">
                    {' '}
                    <p> Manage tags </p>{' '}
                  </Tooltip>
                }
              >
                <a href={tagPageURL} target="_blank" rel="noopener noreferrer">
                  {' '}
                  <FontAwesomeIcon icon={faCog} />{' '}
                </a>
              </OverlayTrigger>
            </Col>
            <Col xs={10}>
              <TagsSelectorContainer
                investigationId={investigationId}
                instrumentName={instrumentName}
                user={user}
                onChange={(selectedTags) => setSelectedTags(selectedTags)}
                tags={event ? event.tag : []}
              />
            </Col>
          </Row>
        </Container>

        <div style={{ flex: '0 0 37px' }}>
          <EventFooter
            isSaveButtonEnabled={!isReleased}
            onCancelButtonClicked={() => onCancelButtonClicked()}
            onSaveButtonClicked={() => onSaveButtonClicked()}
            onSaveAndCloseButtonClicked={() => onSaveAndCloseButtonClicked()}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default NewOrEditEventPanel;
