import { faPencilAlt, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import styles from '../../Address/MyAddressesSummary.module.css';

const isEditionDisabled = (tag, isAdministrator, isInstrumentScientist) => {
  if (isAdministrator) return false;
  if (isInstrumentScientist && tag.instrumentName) return false;
  if (tag.investigationId) return false;
  return true;
};

function TagPanel(props) {
  const { tag, onEdit, isAdministrator, isInstrumentScientist } = props;
  return (
    <Container>
      <Row>
        <Col xs={3}>
          <div className={styles.row}>
            <FontAwesomeIcon
              icon={faTag}
              className={styles.glyph}
              style={{ color: tag.color, margin: '5px' }}
            />
            {tag.name.toUpperCase()}
          </div>
        </Col>
        <Col xs={1}></Col>
        <Col xs={3}>
          {!isEditionDisabled(tag, isAdministrator, isInstrumentScientist) ? (
            <>
              <Button
                size="sm"
                variant="primary"
                aria-label="Edit"
                style={{ marginBottom: 5 }}
                onClick={() => {
                  onEdit(tag);
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
                <span className={styles.actionLabel}> Edit</span>
              </Button>
            </>
          ) : (
            <span>PROTECTED</span>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default TagPanel;
