import React from 'react';
import { Alert } from 'react-bootstrap';
import Footer from '../Footer';
import LandingPageMenu from '../Menu/LandingPageMenu';
import { LandingPageWidget } from './LandingPageWidget';

/** Landing page: data can come either from icat or datacite */
export function LandingPage(props) {
  const { hasAcceptedPrefix, error, data } = props;
  return (
    <div>
      <div className="app">
        {!hasAcceptedPrefix || error || !data ? (
          <LandingPageMenu />
        ) : (
          <LandingPageMenu doi={data.doi} />
        )}
        <div className="app__inner" style={{ marginTop: 85, marginBottom: 25 }}>
          {(!hasAcceptedPrefix || error) && (
            <Alert variant="danger">This DOI is not recognized</Alert>
          )}
          {data && <LandingPageWidget doiData={data} />}
        </div>
      </div>
      <Footer />
    </div>
  );
}
