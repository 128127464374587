import moment from 'moment';
import {
  DC_RESOURCE_TYPE,
  ES_RESOURCE_TYPE,
  NO_LINK,
  OPEN_ACCESS,
  RESTRICTED_ACCESS,
} from '../../constants/doi';
import _ from 'lodash-es';

/**
 * Returns the type of the DOI, session or dataCollection
 * @param {*} types
 * @returns
 */
export function getResourceType(types) {
  if (types) {
    for (const key in types) {
      if (key === 'resourceType') {
        if (types[key] === ES_RESOURCE_TYPE) {
          return ES_RESOURCE_TYPE;
        }
        if (types[key] === DC_RESOURCE_TYPE) {
          return DC_RESOURCE_TYPE;
        }
      }
    }
  }
  return null;
}

/**
 * Returns the accessibility status depending on the type, the publication date
 * If type is dataCollection, it returns open access
 * If type is Session, it depends if it is the embargo time or not
 * @param {*} resourceType
 * @param {*} sessionDate
 * @param {*} publiclyAccessibleYear
 * @returns RESTRICTED_ACCESS or OPEN_ACCESS or undefined
 */
export function getAccessibilityStatus(
  resourceType,
  sessionDate,
  publiclyAccessibleYear
) {
  if (resourceType === ES_RESOURCE_TYPE) {
    if (publiclyAccessibleYear && sessionDate) {
      // take day and month of sessionDate and year of publiclyAccessibleYear
      const publiclyAvailableDate = moment(sessionDate).year(
        parseInt(publiclyAccessibleYear)
      );
      const now = moment();

      if (now > publiclyAvailableDate) {
        return OPEN_ACCESS;
      } else if (now <= publiclyAvailableDate) {
        return RESTRICTED_ACCESS;
      }
    }
  } else if (resourceType === DC_RESOURCE_TYPE) {
    return OPEN_ACCESS;
  }
  return undefined;
}

/**
 * Returns the date of the collected data
 * @param {*} dates
 * @returns
 */
export function getSessionDate(dates) {
  if (dates && dates instanceof Array) {
    const sessionDate = dates.find(
      (item) => item.dateType && item.dateType.toLowerCase() === 'collected'
    );

    if (sessionDate && sessionDate.date) {
      return moment(sessionDate.date).format('YYYY-MM-DD');
    }
  }
  return undefined;
}

/**
 * Returns the abstract field
 * @param {*} data
 * @returns
 */
export function getAbstract(data) {
  if (data && data.descriptions) {
    const abstractObject = data.descriptions.find((item) => {
      return item.descriptionType.toLowerCase() === 'abstract';
    });

    if (abstractObject) {
      return abstractObject.description;
    }
  }
  return undefined;
}

/**
 * Returns the value of a specific field in the fields section
 * @param {*} subjects
 * @param {*} field
 * @returns
 */
export function getFieldFromSubjects(subjects, field) {
  if (subjects) {
    const regExp = new RegExp(`^${field}$`, 'i');
    for (let i = 0; i < subjects.length; i++) {
      if (regExp.test(subjects[i].subjectScheme)) {
        if (subjects[i].subject) {
          return subjects[i].subject.split(',');
        }
      }
    }
  }
  return null;
}

/**
 * Returns the value if exists or Not available text
 * @param {*} value
 * @returns
 */
export function getValueOrNotAvailable(value) {
  return value ? value : 'Not available';
}

/**
 * Retrieve the proposals, beamlines and beamlinesUrls from the proposals and beamlines sections
 * @param {*} proposals
 * @param {*} beamlines
 * @param {*} beamlineUrls
 * @returns array of {proposal, beamline, beamlineUrl}
 */
export function getMergedProposalAndBeamline(
  proposals,
  beamlines,
  beamlineUrls
) {
  if (proposals && beamlines && beamlineUrls) {
    const result = [];
    let index = 0;
    if (
      proposals.length === beamlines.length &&
      proposals.length === beamlineUrls.length
    ) {
      for (index; index < proposals.length; index++) {
        result.push({
          proposal: proposals[index],
          beamline: beamlines[index],
          beamlineUrl: beamlineUrls[index],
        });
      }
      return _.uniqBy(result, (value) => {
        return JSON.stringify(value);
      });
    }

    if (
      proposals.length >= 1 &&
      beamlines.length === 1 &&
      beamlineUrls.length === 1
    ) {
      for (index = 0; index < proposals.length; index++) {
        result.push({
          proposal: proposals[index],
          beamline: beamlines[0],
          beamlineUrl: beamlineUrls[0],
        });
      }
      return _.uniqBy(result, (value) => {
        return JSON.stringify(value);
      });
    }
  }
  return null;
}

/**
 * Matches for each beamline of the given array the url from the instruments list
 * @param {*} beamlines
 * @param {*} instruments
 * @returns array of beamlineUrl, NO_LINK if does not exist
 */
export function getBeamlineUrlFromInstrumentList(beamlines, instruments) {
  const result = [];
  beamlines.forEach((beamline) => {
    const instrument = instruments.filter((i) => {
      return i.Instrument.name.toLowerCase() === beamline.toLowerCase();
    });
    result.push(
      instrument && instrument.length > 0 && instrument[0].Instrument.url
        ? instrument[0].Instrument.url
        : NO_LINK
    );
  });
  return result;
}
