import React from 'react';
import {
  getDatasetParameterByName,
  getDatasetParameterByPrefixName,
} from '../../helpers';
import InstrumentDetectorWidget from './InstrumentDetectorWidget';
import InstrumentSlitWidget from './InstrumentSlitWidget';
import ParameterTableWidget from './ParameterTableWidget';

function InstrumentWidget(props) {
  const { dataset } = props;

  const instrumentParameters = getDatasetParameterByPrefixName(
    dataset,
    'Instrument'
  );
  if (instrumentParameters.length === 0) {
    return null;
  }

  return (
    <div style={{ fontSize: 12 }}>
      <InstrumentWidgetSection
        header="Monochromator"
        parameters={[
          {
            name: 'Energy',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromator_energy'
            ),
          },
          {
            name: 'Wavelength',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromator_wavelength'
            ),
          },
          {
            name: 'd_spacing',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromatorCrystal_d_spacing'
            ),
          },
          {
            name: 'Reflection',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromatorCrystal_reflection'
            ),
          },
          {
            name: 'Type',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromatorCrystal_type'
            ),
          },
          {
            name: 'Usage',
            value: getDatasetParameterByName(
              dataset,
              'InstrumentMonochromatorCrystal_usage'
            ),
          },
        ]}
      />

      <InstrumentDetectorWidget dataset={dataset} />

      <InstrumentSlitWidget dataset={dataset} />

      <InstrumentWidgetSection
        header="Optics"
        instrumentNames={getDatasetParameterByPrefixName(
          dataset,
          'InstrumentOpticsPositioners_name'
        )}
        instrumentValues={getDatasetParameterByPrefixName(
          dataset,
          'InstrumentOpticsPositioners_value'
        )}
      />

      <InstrumentWidgetSection
        header="Positioners"
        instrumentNames={getDatasetParameterByPrefixName(
          dataset,
          'InstrumentPositioners_name'
        )}
        instrumentValues={getDatasetParameterByPrefixName(
          dataset,
          'InstrumentPositioners_value'
        )}
      />
    </div>
  );
}

export default InstrumentWidget;

function InstrumentWidgetSection(props) {
  const { instrumentValues, instrumentNames, parameters, header } = props;
  let names = null;
  let values = null;
  if (instrumentValues && instrumentNames) {
    /** This is a comma separeted pair of name-values
     * names = [{"name":"InstrumentOpticsPositioners_name","value":" m2angle m0tz m0tyrot mono enmono r2theta r2gamma "}]
     * values = [{"name":"InstrumentOpticsPositioners_value","value":" 26 -22 6.7 19.244 5.99885 -0.0829958 -0.456489 -0.820325 5.5 0.85 -18.5746 -4.49 5.96497 5.86713 6.06281 5.86713 7.5 100 0.141575 -0.511 NaN NaN NaN NaN NaN NaN 0"}]
     */

    if (instrumentValues.length > 0 && instrumentNames.length > 0) {
      names = instrumentNames[0].value;
      values = instrumentValues[0].value;
    }
  }

  if (
    !names &&
    !values &&
    (!parameters ||
      parameters === 0 ||
      !parameters.find((parameter) => !!parameter.value))
  ) {
    return null;
  }

  return (
    <div className="container-fluid">
      <br />
      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{header}</span>
      <div className="row">
        <div className="col-sm-2">
          <ParameterTableWidget
            names={names}
            values={values}
            parameters={parameters}
          />
        </div>
      </div>
    </div>
  );
}
