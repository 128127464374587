import { SELECT_DATASETS, DESELECT_DATASETS } from '../constants/actionTypes';

const INITIAL_STATE = [];

function selectedDatasets(state = INITIAL_STATE, action) {
  const { payload: ids } = action;

  switch (action.type) {
    case SELECT_DATASETS: {
      return [...state, ...ids];
    }
    case DESELECT_DATASETS: {
      const set = new Set(state);
      ids.forEach((id) => set.delete(id));
      return [...set];
    }
    default:
      return state;
  }
}

export default selectedDatasets;
