import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import TabContainerMenu from '../components/TabContainerMenu/TabContainerMenu';
import PageNotFound from './PageNotFound';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../resources/investigation';
import SamplesPanel from '../components/Investigation/SamplesPanel';
import { usePageTracking } from '../helpers/hooks';

function InvestigationSamplesPage() {
  usePageTracking();
  const { investigationId } = useParams();

  const investigations = useResource(InvestigationResource.listShape(), {
    ids: investigationId,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;

  if (!investigation) {
    return <PageNotFound />;
  }

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <TabContainerMenu investigation={investigation} />
          <div style={{ margin: 20 }}>
            <SamplesPanel investigationId={investigationId} />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default InvestigationSamplesPage;
