import {
  FETCH_DATACOLLECTIONS_FULFILLED,
  FETCH_DATACOLLECTIONS_PENDING,
  LOG_OUT,
} from '../constants/actionTypes';

const initialState = { fetching: false, fetched: false, data: [], error: null };

const datacollections = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATACOLLECTIONS_PENDING: {
      state = { ...state, fetched: false, fetching: true };
      break;
    }
    case FETCH_DATACOLLECTIONS_FULFILLED: {
      state = {
        ...state,
        data: action.payload.data,
        fetched: true,
        fetching: false,
      };
      break;
    }

    case LOG_OUT: {
      state = { ...state, fetching: false, fetched: false, data: [] };
      break;
    }
    default:
      break;
  }
  return state;
};

export default datacollections;
