import React from 'react';
import { getDatasetParameterValueByName } from '../../../../helpers';
import SampleChangerBioSaxsDatasetWidget from './SampleChangerBioSaxsDatasetWidget';
import HPLCBioSaxsDatasetWidget from './HPLCBioSaxsDatasetWidget';
import AlignmentBioSaxsDatasetWidget from './AlignmentBioSaxsDatasetWidget';

function BioSaxsDatasetWidget(props) {
  const { dataset } = props;

  const experimentType = getDatasetParameterValueByName(
    dataset,
    'SAXS_experiment_type'
  );

  if (!experimentType) {
    return (
      <AlignmentBioSaxsDatasetWidget
        dataset={dataset}
      ></AlignmentBioSaxsDatasetWidget>
    );
  }

  switch (experimentType) {
    case 'HPLC':
      return (
        <HPLCBioSaxsDatasetWidget dataset={dataset}></HPLCBioSaxsDatasetWidget>
      );

    default:
      return (
        <SampleChangerBioSaxsDatasetWidget
          dataset={dataset}
        ></SampleChangerBioSaxsDatasetWidget>
      );
  }
}
export default BioSaxsDatasetWidget;
