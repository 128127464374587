import React, { useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useFetcher, useResource } from 'rest-hooks';
import { isEditable } from '../../helpers/statusUtils';
import ParcelResource from '../../resources/parcel';
import ShipmentResource from '../../resources/shipment';
import ItemTable from '../Items/ItemTable';
import BasicPanel from './BasicPanel';
import ParcelFormModal from './ParcelFormModal';
import ParcelHeader from './ParcelHeader';
import ParcelStatusButtons from './ParcelStatusButtons';
import StatusDisplay from './StatusDisplay';
import StatusTable from './StatusTable';
import styles from './ParcelDetails.module.css';
import AddressPanel from '../Address/AddressPanel';
import AdminPanel from './AdminPanel';
import InvestigationUserResource from '../../resources/investigationUser';
import { USER_ROLES } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';

function ParcelDetails(props) {
  const { investigation, parcel } = props;

  const { id: investigationId } = investigation;

  // This is to render the component when the parcel is updated
  const [activeParcel, setActiveParcel] = useState(parcel);

  const editParcel = useFetcher(ParcelResource.updateShape());
  const getParcel = useFetcher(ParcelResource.listShape());
  const resetActiveParcel = async () => {
    const parcels = await getParcel({ investigationId, _id: activeParcel._id });
    setActiveParcel(parcels[0]);
  };
  const editParcelWithRefetch = async (params, body) => {
    await editParcel(params, body);
    await resetActiveParcel();
  };

  const {
    description,
    shippingAddress,
    returnAddress,
    comments,
    storageConditions,
  } = activeParcel;
  const [shipment] = useResource(ShipmentResource.listShape(), {
    investigationId,
  });
  const investigationUsers = useResource(
    InvestigationUserResource.listShape(),
    { investigationId }
  );

  const [isEditingParcel, setEditingParcel] = useState(false);
  const [alert, setAlert] = useState();

  const user = useSelector((state) => state.user);

  const isLocalContact = !!investigationUsers.find(
    (investigationUser) =>
      investigationUser.role === USER_ROLES.LocalContact &&
      investigationUser.name === user.name
  );
  const isInvestigationAdmin = isLocalContact || user.isAdministrator;

  function handleCloseModal() {
    setEditingParcel(false);
  }

  async function handleSubmit(values) {
    setAlert(undefined);
    try {
      const fetcherParams = {
        investigationId: activeParcel.investigationId,
        shipmentId: activeParcel.shipmentId,
      };

      await editParcelWithRefetch(fetcherParams, values);
      setAlert({ type: 'success', message: 'Parcel saved.' });
    } catch (error) {
      setAlert({ type: 'danger', message: 'An error occurred.' });
      console.error(error.response);
    }

    handleCloseModal();
  }

  function updateStatus(status, body) {
    return editParcelWithRefetch(
      {
        investigationId: activeParcel.investigationId,
        status,
        parcelId: activeParcel._id,
      },
      { _id: activeParcel._id, ...body }
    );
  }

  return (
    <div className={styles.panelWrapper}>
      {alert && <Alert variant={alert.type}>{alert.message}</Alert>}
      {isEditingParcel && (
        <ParcelFormModal
          shipment={shipment}
          parcel={activeParcel}
          onSubmitAsync={handleSubmit}
          onCloseModal={handleCloseModal}
        />
      )}
      <ParcelHeader
        parcel={activeParcel}
        investigation={investigation}
        setAlert={setAlert}
      />

      <div className={styles.statusRow}>
        <div className={styles.statusCol}>
          <span className={styles.label}>Status:</span>
          <div className={styles.currentStatus}>
            <StatusDisplay status={activeParcel.status} />
          </div>
        </div>
        <div className={styles.statusCol}>
          <ParcelStatusButtons
            parcel={activeParcel}
            investigation={investigation}
            updateStatus={updateStatus}
            showSafetyActions={isInvestigationAdmin}
          />
        </div>
      </div>

      <BasicPanel
        title={
          <div className={styles.panelHeader}>
            <div>Parcel info</div>
            <Button
              variant="primary"
              aria-label="Edit"
              onClick={() => setEditingParcel(true)}
              disabled={!isEditable(activeParcel)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
              <span className={styles.actionLabel}> Edit</span>
            </Button>
          </div>
        }
      >
        <Container fluid>
          <Row>
            <Col sm={12} md={2}>
              <span className={styles.label}>Description:</span>
            </Col>
            <Col sm={12} md={10}>
              {description || 'No description.'}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={2}>
              <span className={styles.label}>Storage conditions:</span>
            </Col>
            <Col sm={12} md={10}>
              {storageConditions}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={2}>
              <span className={styles.label}>Comments:</span>
            </Col>
            <Col sm={12} md={10}>
              {comments || 'No comments.'}
            </Col>
          </Row>

          <Row style={{ marginTop: '0.5rem' }}>
            <Col sm={12} md={6}>
              {activeParcel.containsDangerousGoods && (
                <Alert variant="danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} /> This parcel
                  contains dangerous goods.
                </Alert>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: '0.5rem' }}>
            {shippingAddress && (
              <Col sm={12} md={6}>
                <AddressPanel
                  address={shippingAddress}
                  header={<b>Address of Sender:</b>}
                />
              </Col>
            )}
            <Col sm={12} md={6}>
              {returnAddress ? (
                <AddressPanel
                  address={returnAddress}
                  header={<b>Return Address:</b>}
                />
              ) : (
                <Alert variant="danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  This parcel has no return address and will be destroyed at the
                  end of the investigation.
                </Alert>
              )}
            </Col>
          </Row>
        </Container>
      </BasicPanel>

      <BasicPanel title="Content">
        <ItemTable
          setAlert={setAlert}
          onItemChange={resetActiveParcel}
          parcel={activeParcel}
        />
      </BasicPanel>

      <BasicPanel title="History">
        <Container fluid>
          <Row>
            <StatusTable statuses={activeParcel.statuses} />
          </Row>
        </Container>
      </BasicPanel>

      {user.isAdministrator && (
        <AdminPanel
          currentStatus={activeParcel.status}
          updateStatus={updateStatus}
        />
      )}
    </div>
  );
}

export default ParcelDetails;
