import { Resource } from 'rest-hooks';
import { store } from '../store';
import {
  getDatasetsByInvestigationIdURL,
  getDatasets,
} from '../api/icat-plus/catalogue';
import { getDatasetByDOI } from '../api/icat-plus/doi';

export default class DatasetResource extends Resource {
  id = undefined;

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'DatasetResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const {
      investigationId,
      sampleId,
      doi,
      skip,
      limit,
      sortOrder,
      sortBy,
      search,
      datasetIds,
      datasetType,
      nested,
    } = params;

    if (doi) {
      return getDatasetByDOI(
        sessionId,
        doi,
        skip,
        limit,
        sortOrder,
        sortBy,
        search
      );
    }

    if (datasetIds) {
      return getDatasets(
        sessionId,
        datasetIds,
        skip,
        limit,
        sortOrder,
        sortBy,
        search,
        nested
      );
    }
    return getDatasetsByInvestigationIdURL(
      sessionId,
      investigationId,
      sampleId,
      skip,
      limit,
      sortOrder,
      sortBy,
      search,
      datasetType,
      nested
    );
  }

  static url(params) {
    return this.listUrl(params);
  }
}
