import React from 'react';
import { useParams } from 'react-router';
import { useResource } from 'rest-hooks';
import DoiResource from '../resources/doi';
import { LandingPage } from '../components/LandingPage/LandingPage';
import LoadingBoundary from '../components/LoadingBoundary';
import DataCiteLandingPage from './DataCiteLandingPage';

function DOILandingPage() {
  const { prefix, suffix } = useParams();
  const doi = `${prefix}/${suffix}`;

  const data = useResource(DoiResource.detailShape(), { doi });
  if (data.doi === -1) {
    return (
      <LoadingBoundary message="Loading doi..." spacedOut>
        <DataCiteLandingPage />
      </LoadingBoundary>
    );
  }
  return <LandingPage data={data} hasAcceptedPrefix={true} error={false} />;
}

export default DOILandingPage;
