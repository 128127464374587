import { Resource } from 'rest-hooks';
import { getInstrumentScientistsBySessionId } from '../api/icat-plus/catalogue';
import { store } from '../store';

export default class InstrumentScientistsResource extends Resource {
  id = undefined;

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'InstrumentScientistsResource';
  }

  static listUrl() {
    const { sessionId } = store.getState().user;
    return getInstrumentScientistsBySessionId(sessionId);
  }

  static url(params) {
    return this.listUrl(params);
  }
}
