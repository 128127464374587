import React from 'react';
import ResponsiveTable from '../../../Table/ResponsiveTable';
import ParameterDescriptionOverlay from '../../ParameterDescriptionOverlay';

function DatasetMetadataTab(props) {
  const { dataset } = props;

  const parameterDescriptionFormatter = (cell, row) => {
    return (
      <ParameterDescriptionOverlay
        name={row.name}
        id={row.id}
      ></ParameterDescriptionOverlay>
    );
  };

  const fieldFormatter = (cell, row) => {
    return `${row.name} : ${row.value}`;
  };

  const getColumns = () => {
    return [
      {
        text: 'Field',
        dataField: 'name',
        formatter: fieldFormatter,
        responsiveHeaderStyle: {
          xs: { hidden: false },
          sm: { hidden: false },
          md: { hidden: true },
          lg: { hidden: true },
        },
      },
      {
        text: 'Name',
        dataField: 'name',
        formatter: parameterDescriptionFormatter,
        responsiveHeaderStyle: {
          xs: { hidden: true },
          sm: { hidden: true },
          md: { hidden: false },
          lg: { hidden: false },
        },
      },
      {
        text: 'Value',
        dataField: 'value',
        responsiveHeaderStyle: {
          xs: { hidden: true },
          sm: { hidden: true },
          md: { hidden: false },
          lg: { hidden: false },
        },
      },
    ];
  };

  /** Data sorted by name */
  const data = dataset.parameters.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
  );

  return (
    <div style={{ margin: 10, fontSize: 12 }}>
      <ResponsiveTable
        keyField="name"
        data={[...data, { name: 'name', value: dataset.name }]}
        columns={getColumns()}
      />
    </div>
  );
}

export default DatasetMetadataTab;
