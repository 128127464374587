import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getEventURL } from '../api/icat-plus/logbook';

export default class EventResource extends Resource {
  _id = undefined;

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'EventResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const {
      investigationId,
      skip,
      limit,
      sortOrder,
      sortBy,
      types,
      search,
      format,
      instrumentName,
      filterInvestigation,
      date,
    } = params;
    return getEventURL(
      sessionId,
      investigationId,
      skip,
      limit,
      sortOrder,
      sortBy,
      types,
      format,
      search,
      instrumentName,
      filterInvestigation,
      date
    );
  }

  static url(params) {
    return this.listUrl(params);
  }
}
