import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { STATUS } from '../../constants/parcelStatuses';
import StatusDisplay from './StatusDisplay';

function StatusFilterDropdown(props) {
  const { rootClassName, value, onSelect } = props;
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => setSelectedStatus(value), [value]);

  return (
    <div className={rootClassName}>
      <DropdownButton
        id="filterStatus"
        variant="outline-primary"
        title={
          selectedStatus ? (
            <StatusDisplay status={selectedStatus} />
          ) : (
            <span>Filter by status</span>
          )
        }
      >
        {Object.keys(STATUS).map((status) => (
          <Dropdown.Item
            eventKey={status}
            key={status}
            onClick={() => {
              setSelectedStatus(status);
              onSelect(status);
            }}
          >
            <StatusDisplay status={status} />
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </div>
  );
}

export default StatusFilterDropdown;
