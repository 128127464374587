export const LOG_IN = 'LOG_IN';
export const LOGGED_IN = 'LOGGED_IN';
export const LOG_OUT = 'LOG_OUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_PAGE = 'SET_PAGE';
export const SESSION_REFRESHED = 'SESSION_REFRESHED';
/** data collections **/

export const FETCH_DATACOLLECTIONS = 'FETCH_DATACOLLECTIONS';
export const FETCH_DATACOLLECTIONS_FULFILLED =
  'FETCH_DATACOLLECTIONS_FULFILLED';
export const FETCH_DATACOLLECTIONS_PENDING = 'FETCH_DATACOLLECTIONS_PENDING';

/** investigations **/

/** datasets */
export const SET_DATASETS_COUNT = 'SET_DATASETS_COUNT';

/** logbook **/

export const SET_LOGBOOK_CONTEXT = 'SET_LOGBOOK_CONTEXT';
export const FILTER_EVENTS = 'FILTER_EVENTS';
export const SET_CATEGORY_TYPE = 'SET_CATEGORY_TYPE';

export const UNSET_CATEGORY_TYPE = 'UNSET_CATEGORY_TYPE';
export const SET_AUTOMATIC_COLLAPSING = 'SET_AUTOMATIC_COLLAPSING';
export const SET_AUTOMATIC_REFRESH = 'SET_AUTOMATIC_REFRESH';
export const SET_IS_SORTING_LATESTS_EVENTS_FIRST =
  'SET_IS_SORTING_LATESTS_EVENTS_FIRST';
export const SHOW_USER_LOGS_BEAMLINE_LOGBOOK =
  'SHOW_USER_LOGS_BEAMLINE_LOGBOOK';

/** selection */
export const SELECT_DATASETS = 'SELECT_DATASETS';
export const DESELECT_DATASETS = 'DESELECT_DATASETS';

/** breadcrumbs */
export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
export const APPEND_BREADCRUMB = 'APPEND_BREADCRUMB';
export const CLEAN_BREADCRUMBS = 'CLEAN_BREADCRUMBS';
export const REPLACE_BREADCRUMB = 'REPLACE_BREADCRUMB';

/** instrumentscientists */
export const FETCH_INSTRUMENTSCIENTISTS = 'FETCH_INSTRUMENTSCIENTISTS';
export const FETCH_INSTRUMENTSCIENTISTS_FULFILLED =
  'FETCH_INSTRUMENTSCIENTISTS_FULFILLED';
export const FETCH_INSTRUMENTSCIENTISTS_PENDING =
  'FETCH_INSTRUMENTSCIENTISTS_PENDING';
