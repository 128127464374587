import React, { useEffect, useState } from 'react';
import EventList from './List/EventList';
import EventListMenu from './Menu/EventListMenu';
import { useFetcher, useResource } from 'rest-hooks';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import Pager from '../Pager';
import { useSelector } from 'react-redux';
import UI from '../../config/ui';
import EventResource from '../../resources/events';
import NewOrEditEventPanel from './NewOrEditEventPanel';
import OverlayBox from './OverlayBox';
import { useQueryParams } from '../../helpers/hooks';
import axios from 'axios';
import { getCountEventsURL } from '../../api/icat-plus/logbook';
import { isReleasedInvestigation } from '../Investigation/utils';

function LogbookPanel(props) {
  const { investigation, investigationId, instrumentName } = props;
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const { sessionId } = user;
  const { search, edit, page = 1, date } = useQueryParams();
  const [totalEventCount, setTotalEventCount] = useState(undefined);

  const {
    categoryTypes,
    automaticCollapsing,
    automaticRefresh,
    isSortingLatestEventsFirst,
    showUserLogsOnBeamlineLogbook,
    pageSize,
  } = useSelector((state) => state.logbook);

  const types = categoryTypes?.map((cat) =>
    cat.category ? `${cat.type}-${cat.category}` : `${cat.type}`
  );

  const isBeamlineLogbook = !investigationId;
  const isGlobalLogbook = !investigationId && !instrumentName;
  const filterInvestigation =
    isBeamlineLogbook || isGlobalLogbook
      ? showUserLogsOnBeamlineLogbook
      : undefined;

  let isReleased = false;

  const fetchingParams = {
    investigationId,
    instrumentName,
    filterInvestigation,
    skip: pageSize * (page - 1),
    limit: pageSize,
    sortBy: UI.logbook.SORT_EVENTS_BY,
    sortOrder: isSortingLatestEventsFirst ? -1 : 1,
    search,
    types,
    date,
  };

  const events = useResource(EventResource.listShape(), fetchingParams);

  const refetch = useFetcher(EventResource.listShape());
  const refetchEvent = async () => {
    setLoading(true);
    await refetch(fetchingParams);
    setLoading(false);
  };

  const editEvent = events.find((event) => event._id === edit);

  const typesAsString = types ? types.toString() : '';

  useEffect(() => {
    const fetchCountEvents = async () => {
      setTotalEventCount(undefined);
      const results = await axios.get(
        getCountEventsURL(
          sessionId,
          investigationId,
          types,
          search,
          instrumentName,
          filterInvestigation,
          date
        )
      );
      setTotalEventCount(
        results?.data?.length > 0 ? results.data[0].totalNumber : undefined
      );
    };

    fetchCountEvents().catch(console.error);
    // depends on typesAsString instead of types, because types is an array, and the reference could change (even with the same values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    date,
    instrumentName,
    investigationId,
    search,
    sessionId,
    typesAsString,
    showUserLogsOnBeamlineLogbook,
  ]);

  isReleased = isReleasedInvestigation(investigation);

  const variant = investigationId ? 'primary' : 'success';
  return (
    <>
      {edit && (
        <OverlayBox
          open={true}
          classNames={{
            overlay: 'newOrEditOverlayClass',
            modal: 'newOrEditModalClass',
          }}
        >
          <NewOrEditEventPanel
            event={editEvent}
            isReleased={isReleased}
            isBeamlineLogbook={isBeamlineLogbook}
            user={user}
            refetch={refetchEvent}
            instrumentName={instrumentName}
            investigationId={investigationId}
          />
        </OverlayBox>
      )}

      <Container fluid>
        <Row>
          <Col sm={12}>
            <EventListMenu
              types={types}
              categoryTypes={categoryTypes}
              activePage={page}
              eventCountBySelectionFilter={totalEventCount}
              eventCountSinceLastRefresh={totalEventCount}
              investigationId={investigationId}
              isNewButtonEnabled={!isReleased}
              isReleased={isReleased}
              sessionId={sessionId}
              skip={fetchingParams.skip}
              limit={fetchingParams.limit}
              sortOrder={fetchingParams.sortOrder}
              sortBy={fetchingParams.sortBy}
              automaticCollapsing={automaticCollapsing}
              automaticRefresh={automaticRefresh}
              isSortingLatestEventsFirst={isSortingLatestEventsFirst}
              isBeamlineLogbook={isBeamlineLogbook}
              showUserLogsOnBeamlineLogbook={showUserLogsOnBeamlineLogbook}
              instrumentName={instrumentName}
              pageSize={pageSize}
              variant={variant}
              isGlobalLogbook={isGlobalLogbook}
            />
            {loading && <Alert variant={'info'}>Updating new events</Alert>}
            <EventList
              sessionId={sessionId}
              search={search}
              events={events}
              isReleased={isReleased}
              automaticCollapsing={automaticCollapsing}
              isBeamlineLogbook={isBeamlineLogbook}
              pageSize={pageSize}
              types={types}
              sortOrder={fetchingParams.sortOrder}
              instrumentName={instrumentName}
              isGlobalLogbook={isGlobalLogbook}
            />
          </Col>
        </Row>
      </Container>

      <Pager
        activePage={page}
        eventCount={totalEventCount}
        pageSize={pageSize}
        isCentered={true}
        variant={variant}
      />
    </>
  );
}

export default LogbookPanel;
