import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';

export function ErrorUserMessage(props) {
  const { text, isDismissible } = props;
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert
        variant="danger"
        onClose={() => setShow(false)}
        dismissible={isDismissible ? isDismissible : false}
      >
        <h4>{text}</h4>
      </Alert>
    );
  }
  return <></>;
}
