import {
  faPlayCircle,
  faCog,
  faCommentAlt,
  faList,
  faPlane,
  faThList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Badge, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import Alert from 'react-bootstrap/Alert';
import UI from '../../config/ui';
import { setInvestigationBreadCrumbs } from '../../containers/investigation-breadcrumbs';
import { isReleasedInvestigation } from '../Investigation/utils';

function TabContainerMenu(props) {
  const { doi, investigation } = props;
  const { isAnonymous, sessionId, fullName } = useSelector(
    (state) => state.user
  );

  const datasetCount = useSelector((state) => state.datasets.datasetsCount);
  const { investigationId } = useParams();

  const breadcrumbsList = useSelector((state) => state.breadcrumbsList);
  const currentBreadcrumbsList = useRef(); // work around stale breadcrumbsList reference
  currentBreadcrumbsList.current = breadcrumbsList;

  const dispatch = useDispatch();

  useEffect(() => {
    if (investigation) {
      dispatch(
        setInvestigationBreadCrumbs(
          investigation,
          currentBreadcrumbsList.current
        )
      );
    }
  }, [dispatch, investigation, currentBreadcrumbsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const routePrefix = `/investigation/${investigationId}`;
  const isReleased = isReleasedInvestigation(investigation);
  return (
    <>
      {UI.etherpad.enabled &&
        !isReleased &&
        UI.etherpad.beamlines.indexOf(investigation.instrument.name) !== -1 && (
          <Alert variant="danger">
            Due to a bug in Etherpad, the notebook will be made unavailable from
            23/06/2023 until it is fixed. Sorry for the inconvenience. <br />
            For any question, do not hesitate to contact
            dataportal-feedback@esrf.fr
          </Alert>
        )}
      <Nav variant="tabs">
        {UI.investigationContainer.isDatasetListVisible && (
          <Nav.Item>
            <LinkContainer
              to={doi ? `/public/${doi}` : `${routePrefix}/datasets`}
            >
              <Nav.Link>
                <FontAwesomeIcon icon={faList} />
                <span style={{ marginLeft: 2 }}> Dataset List &nbsp;</span>
                <Badge bsPrefix="ourBadges-m"> {datasetCount} </Badge>
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
        )}

        {!doi && (
          <>
            <Nav.Item>
              <LinkContainer to={`${routePrefix}/events`}>
                <Nav.Link>
                  <FontAwesomeIcon icon={faCommentAlt} />
                  <span style={{ marginLeft: 2 }}> Logbook </span>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {UI.etherpad.enabled &&
              !isReleased &&
              UI.etherpad.beamlines.indexOf(investigation.instrument.name) !==
                -1 && (
                <Nav.Item style={{ marginLeft: 10, marginTop: 10 }}>
                  <a
                    href={`${UI.etherpad.url}?sessionID=${sessionId}&padName=${investigationId}&userName=${fullName}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faPlayCircle} />
                    <span style={{ marginLeft: 2, marginTop: 45 }}>
                      Notebook
                    </span>
                  </a>
                </Nav.Item>
              )}

            {UI.sampleTracking.enabled && !isAnonymous && (
              <>
                <Nav.Item>
                  <LinkContainer to={`${routePrefix}/shipping`}>
                    <Nav.Link>
                      <FontAwesomeIcon icon={faPlane} />
                      <span style={{ marginLeft: 2 }}> Shipping </span>
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </>
            )}

            {!isAnonymous && (
              <>
                <Nav.Item>
                  <LinkContainer to={`${routePrefix}/samples`}>
                    <Nav.Link>
                      <FontAwesomeIcon icon={faThList} />
                      <span style={{ marginLeft: 2 }}> Samples </span>
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to={`${routePrefix}/proposal`}>
                    <Nav.Link>
                      <FontAwesomeIcon icon={faCog} />
                      <span style={{ marginLeft: 2 }}> Proposal </span>
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </>
            )}
          </>
        )}
      </Nav>
    </>
  );
}

export default TabContainerMenu;
