import React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  INVESTIGATION_DATE_FORMAT,
  INVESTIGATION_TIME_FORMAT,
} from '../../constants';
import { dateFormatter } from './utils';

/**
 * This will shows a popup with the experiment exchedule
 * @param {*} props
 */
function StartDateColumn(props) {
  const { investigation } = props;

  function getDateFormat(date, dateFormat, timeFormat, title) {
    return (
      <>
        <strong>{title}</strong> {moment(date).format(dateFormat)}
        <span style={{ marginLeft: '20px', color: 'grey', fontSize: '14px' }}>
          {moment(date).format(timeFormat)}
        </span>
      </>
    );
  }
  const calendarHoverFocus = (
    <Popover>
      <Popover.Header as="h3">
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
        Experiment Schedule
      </Popover.Header>
      <Popover.Body>
        {getDateFormat(
          investigation.startDate,
          INVESTIGATION_DATE_FORMAT,
          INVESTIGATION_TIME_FORMAT,
          'Start:'
        )}
        <br />
        {getDateFormat(
          investigation.endDate,
          INVESTIGATION_DATE_FORMAT,
          INVESTIGATION_TIME_FORMAT,
          'End:'
        )}
      </Popover.Body>
    </Popover>
  );

  return investigation.startDate ? (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={calendarHoverFocus}
      >
        <span className={'text-info'}>
          {dateFormatter(
            investigation.startDate,
            INVESTIGATION_DATE_FORMAT,
            false
          )}
        </span>
      </OverlayTrigger>
    </>
  ) : (
    ''
  );
}

export default StartDateColumn;
