import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';

function LogbookInvestigationStatisticsPanel(props) {
  const { statistics } = props;

  const instruments = new Set(statistics.map((i) => i.instrument));
  const reducer = (a, b) => {
    return {
      count: a.count + b.count,
    };
  };

  // Total events
  const total = statistics.length > 0 ? statistics.reduce(reducer).count : 0;

  // Count notifications and annotations
  const annotationsListCount = statistics.map((record) =>
    record.annotations ? record.annotations : 0
  );
  const notificationsListCount = statistics.map((record) =>
    record.notifications ? record.notifications : 0
  );

  // Calculate sum
  const annotationsCount =
    annotationsListCount.length > 0
      ? annotationsListCount.reduce((a, b) => a + b)
      : 0;
  const notificationsCount =
    notificationsListCount.length > 0
      ? notificationsListCount.reduce((a, b) => a + b)
      : 0;

  /** Get maximum and minimum */
  const maxAnnotations = Math.max(...annotationsListCount);
  const maxNotifications = Math.max(...notificationsListCount);

  return (
    <Container fluid style={{ margin: 20 }}>
      <Row>
        <Col xs={12}>
          <h4>General</h4>
          <ParameterTableWidget
            striped
            parameters={[
              {
                name: 'Instruments',
                value: instruments.size,
              },
              {
                name: 'Investigations',
                value: statistics.length,
              },
              {
                name: 'Average Events/Investigations',
                value: parseFloat(total / statistics.length).toFixed(0),
              },
              {
                name: 'Max. Annotation/investigation',
                value: maxAnnotations,
              },
              {
                name: 'Max. Notifications/investigation',
                value: maxNotifications,
              },
              {
                name: 'Total Events',
                value: total,
              },
            ]}
          />
          <h4>Event types</h4>
          <ParameterTableWidget
            striped
            parameters={[
              {
                name: 'Annotations',
                value: `${annotationsCount} (${parseFloat(
                  (annotationsCount / total) * 100
                ).toFixed(2)}%)`,
              },
              {
                name: 'Notifications',
                value: `${notificationsCount} (${parseFloat(
                  (notificationsCount / total) * 100
                ).toFixed(2)}%)`,
              },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LogbookInvestigationStatisticsPanel;
