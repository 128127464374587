import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';
import InstrumentResource from './instrument';

export default class InvestigationResource extends Resource {
  id = undefined;
  name = '';
  startDate = '';
  endDate = '';
  doi = '';
  title = '';
  visitId = '';
  releaseDate = '';
  summary = '';
  parameters = [];
  instrument = new InstrumentResource();

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'InvestigationResource';
  }

  static url(params) {
    return this.listUrl(params);
  }

  static listUrl(searchParams) {
    const { sessionId } = store.getState().user;
    const params = new URLSearchParams(searchParams);

    return `${
      ICATPLUS.server
    }/catalogue/${sessionId}/investigation?${params.toString()}`;
  }
}
