import {
  SET_CATEGORY_TYPE,
  UNSET_CATEGORY_TYPE,
  SET_LOGBOOK_CONTEXT,
  SET_AUTOMATIC_COLLAPSING,
  SET_AUTOMATIC_REFRESH,
  SET_IS_SORTING_LATESTS_EVENTS_FIRST,
  SHOW_USER_LOGS_BEAMLINE_LOGBOOK,
  SET_PAGE,
} from '../constants/actionTypes';
import {
  ANNOTATION,
  EVENT_CATEGORY_COMMENT,
  EVENT_CATEGORY_ERROR,
  NOTIFICATION,
} from '../constants/eventTypes';
import UI from '../config/ui';

// initialize the logbook redux state
const initialState = {
  context: {
    name: null,
    isReleased: null,
  },
  categoryTypes: [
    { type: ANNOTATION },
    { type: NOTIFICATION, category: EVENT_CATEGORY_COMMENT },
    { type: NOTIFICATION, category: EVENT_CATEGORY_ERROR },
  ],
  automaticCollapsing: true,
  automaticRefresh: false,
  isSortingLatestEventsFirst: true,
  showUserLogsOnBeamlineLogbook: true,
  pageSize: UI.logbook.EVENTS_PER_PAGE,
};

/**
 *  The logbook reducer. THe reducer takes the current state and the action and returns the new state
 */
const logbook = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_USER_LOGS_BEAMLINE_LOGBOOK: {
      state = {
        ...state,
        showUserLogsOnBeamlineLogbook: action.isVisible,
      };
      break;
    }
    case SET_LOGBOOK_CONTEXT: {
      state = {
        ...state,
        context: {
          name: action.context.name,
          isReleased: action.context.isReleased,
        },
      };
      break;
    }
    case SET_CATEGORY_TYPE: {
      action.categoryType.forEach((categoryType) => {
        // If categoryType does not exist then it is added to state
        if (
          state.categoryTypes.find(
            (ct) =>
              ct.type === categoryType.type &&
              ct.category === categoryType.category
          ) == null
        ) {
          state = {
            ...state,
            categoryTypes: [...state.categoryTypes, categoryType],
          };
        }
      });

      break;
    }
    case UNSET_CATEGORY_TYPE: {
      action.categoryType.forEach((categoryType) => {
        state = {
          ...state,
          categoryTypes: state.categoryTypes.filter(
            (ct) =>
              !(
                ct.type === categoryType.type &&
                ct.category === categoryType.category
              )
          ),
        };
      });

      break;
    }
    case SET_AUTOMATIC_COLLAPSING: {
      state = {
        ...state,
        automaticCollapsing: action.automaticCollapsing,
      };
      break;
    }
    case SET_AUTOMATIC_REFRESH: {
      state = {
        ...state,
        automaticRefresh: action.automaticRefresh,
      };
      break;
    }
    case SET_IS_SORTING_LATESTS_EVENTS_FIRST: {
      state = {
        ...state,
        isSortingLatestEventsFirst: action.isSortingLatestEventsFirst,
      };
      break;
    }
    case SET_PAGE: {
      state = {
        ...state,
        pageSize: action.pageSize,
      };
      break;
    }

    default:
      break; // leave the state unchanged when the action should have no impact on current logbook store state
  }
  return state;
};

export default logbook;
