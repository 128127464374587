// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelFormModal_info__1lbnn {\n  font-size: smaller;\n  margin-bottom: 0;\n}\n\n.ParcelFormModal_hint__3WgkM {\n  font-size: small;\n  color: #777;\n}\n\n.ParcelFormModal_returnCheckbox__2JEKi {\n  margin-bottom: 10px !important;\n}\n\n.ParcelFormModal_dropdown__2ptjp {\n  margin-top: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"info": "ParcelFormModal_info__1lbnn",
	"hint": "ParcelFormModal_hint__3WgkM",
	"returnCheckbox": "ParcelFormModal_returnCheckbox__2JEKi",
	"dropdown": "ParcelFormModal_dropdown__2ptjp"
};
module.exports = exports;
