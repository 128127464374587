import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useQuery } from '../../../helpers/hooks';
import React, { useState } from 'react';
import {
  Container,
  Nav,
  Navbar,
  InputGroup,
  Row,
  Form,
  Spinner,
} from 'react-bootstrap';
import { getEventURL } from '../../../api/icat-plus/logbook';
import { NEW_EVENT_VISIBLE } from '../../../constants/eventTypes';
import LogbookPager from '../../Pager';
import EventListMenuButton from './EventListMenuButton';
import NewlyAvailableEventsDialogue from './NewlyAvailableEventsDialogue';
import SettingLogbookMenuPanel from './SettingLogbookMenuPanel';
import { CAMERA_PATH } from '../../../constants/routePaths';
import JumpPage from '../LogbookJumpPage';
import EventSearchCalendar from '../EventSearchCalendar';

import LoadingBoundary from '../../LoadingBoundary';
import { faSearch, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * The menu displayed above the event list
 */
function EventListMenu(props) {
  const history = useHistory();
  const query = useQuery();

  const [isNavbarExpanded, setIsNavBarExpanded] = useState(true);
  const [isSettingsDisplayed, setIsSettingsDisplayed] = useState(false);

  const {
    eventCountBySelectionFilter,
    investigationId,
    instrumentName,
    isNewButtonEnabled,
    sessionId,
    getEvents,
    periodicdata,
    isReleased,
    eventCountSinceLastRefresh,
    activePage,
    categoryTypes,
    sortOrder,
    sortBy,
    types,
    automaticCollapsing,
    automaticRefresh,
    isSortingLatestEventsFirst,
    isBeamlineLogbook,
    variant = 'primary',
    showUserLogsOnBeamlineLogbook,
    pageSize,
    isGlobalLogbook = false,
  } = props;

  /**
   * Callback triggered when the user clicks the button to expand the navbar in mobile layout
   */
  const onToggleNavbar = () => {
    setIsNavBarExpanded(!isNavbarExpanded);
  };

  const getSettingsTooltip = () => {
    return isSettingsDisplayed ? 'Hide settings' : 'Show settings';
  };

  /**
   * Callback triggered when the user clicks an element of the navbar.
   * @param {*} eventKey key of the element
   */
  const onSelectNavbar = (eventKey) => {
    if (eventKey === '1') {
      if (isNewButtonEnabled) {
        query.set('edit', NEW_EVENT_VISIBLE);
        history.push({ search: query.toString() });
      }
    }
    if (eventKey === '3') {
      setIsSettingsDisplayed(!isSettingsDisplayed);
    }
  };

  const onSearch = (value) => {
    query.set('search', value);
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const onKeyUpValue = (event) => {
    if (event.keyCode === 13) {
      onSearch(event.target.value);
    }
  };

  const camera_param = isBeamlineLogbook
    ? `instrumentName=${instrumentName}`
    : `investigationId=${investigationId}`;

  const jumpToPage = (value) => {
    query.set('page', value);
    history.push({ search: query.toString() });
  };

  const onDateSearch = (date) => {
    if (date) {
      query.set('date', date);
    } else {
      query.delete('date');
    }
    query.delete('page');
    history.push({ search: query.toString() });
  };

  return (
    <Container fluid>
      <Row>
        <Navbar
          collapseOnSelect
          expand="lg"
          onSelect={onSelectNavbar}
          onToggle={onToggleNavbar}
          style={{
            background: 'none',
            border: 'none',
            WebkitBoxShadow: 'none',
            boxShadow: 'none',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            marginTop: 10,
          }}
        >
          <Nav style={{ marginLeft: 0 }} className="me-auto">
            {!isGlobalLogbook && (
              <>
                <Nav.Item className="logbookNavItem">
                  <Nav.Link eventKey="1">
                    <EventListMenuButton
                      variant={variant}
                      text="New"
                      iconName="faPlus"
                      tooltipText={
                        isBeamlineLogbook
                          ? 'Create a new log in the beamline logbook'
                          : 'Create a new log'
                      }
                      isEnabled={isNewButtonEnabled}
                      isVisible={!isReleased}
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="logbookNavItem">
                  <Nav.Link
                    href={`${CAMERA_PATH}?${camera_param}`}
                    target="_blank"
                  >
                    <EventListMenuButton
                      variant={variant}
                      text="Take a photo"
                      iconName="faCamera"
                      tooltipText="Take a photo"
                      isEnabled
                      isVisible={!isReleased}
                    />
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav>
              <Nav.Item className="logbookNavItem">
                <Nav.Link eventKey="3">
                  <EventListMenuButton
                    variant={variant}
                    text="Settings"
                    iconName="faCog"
                    tooltipText={getSettingsTooltip()}
                    isEnabled
                  />
                </Nav.Link>
              </Nav.Item>
              {!isBeamlineLogbook && (
                <Nav.Item className="logbookNavItem">
                  <Nav.Link
                    target="_blank"
                    href={
                      !isNewButtonEnabled || eventCountBySelectionFilter === 0
                        ? null
                        : getEventURL(
                            sessionId,
                            investigationId,
                            null,
                            null,
                            sortOrder,
                            sortBy,
                            types,
                            'pdf'
                          )
                    }
                  >
                    <EventListMenuButton
                      variant={variant}
                      text="PDF"
                      iconName="faDownload"
                      tooltipText="Download as PDF"
                      isEnabled={
                        !(
                          !isNewButtonEnabled ||
                          eventCountBySelectionFilter === 0
                        )
                      }
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item className="logbookNavItem">
                <Nav.Link
                  href={`https://confluence.esrf.fr/display/DATAPOLWK/Electronic+Logbook`}
                  target="_blank"
                >
                  <EventListMenuButton
                    text="Help"
                    iconName="faQuestionCircle"
                    tooltipText="Go to the documentation"
                    isEnabled
                    variant={variant}
                  />
                </Nav.Link>
              </Nav.Item>

              {false && (
                <Nav.Item className="logbookNavItem">
                  <div className="hidden-xs hidden-sm">
                    <NewlyAvailableEventsDialogue
                      autorefreshEventList={false} //{isAutorefreshEnabled}
                      eventCountByPeriodicRefresher={periodicdata}
                      eventCountSinceLastRefresh={eventCountSinceLastRefresh}
                      onIconClicked={() => getEvents(periodicdata)}
                    />
                  </div>
                </Nav.Item>
              )}

              <Nav.Item>
                <InputGroup style={{ marginTop: '5px' }}>
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    onKeyDown={(e) => e.stopPropagation()}
                    onKeyUp={onKeyUpValue}
                    placeholder="Search"
                  />
                  <InputGroup.Text>
                    {eventCountBySelectionFilter === undefined ? (
                      <Spinner animation="border" variant={variant} />
                    ) : (
                      <b>{eventCountBySelectionFilter} found</b>
                    )}
                  </InputGroup.Text>
                </InputGroup>
              </Nav.Item>
              <Nav.Item
                style={{
                  marginTop: '8px',
                  marginLeft: '10px',
                }}
                className="logbookNavItem"
              >
                <LoadingBoundary
                  withSilentError
                  customLoader={
                    <Nav.Item>
                      <FontAwesomeIcon icon={faSyncAlt} spin />
                    </Nav.Item>
                  }
                >
                  {!isGlobalLogbook && (
                    <EventSearchCalendar
                      investigationId={investigationId}
                      instrumentName={instrumentName}
                      types={types}
                      onDateSearch={onDateSearch}
                      variant={variant}
                    ></EventSearchCalendar>
                  )}
                </LoadingBoundary>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <Nav.Item className="logbookNavItem">
              <div className="hidden-xs hidden-sm hidden-md">
                <LogbookPager
                  activePage={activePage}
                  eventCount={eventCountBySelectionFilter}
                  pageSize={pageSize}
                />
              </div>
            </Nav.Item>
            <Nav.Item className="logbookNavItem">
              <JumpPage
                variant={variant}
                eventCount={eventCountBySelectionFilter}
                pageSize={pageSize}
                onPageClicked={jumpToPage}
              ></JumpPage>
            </Nav.Item>
          </Nav>
        </Navbar>
      </Row>
      <Row>
        {isSettingsDisplayed && (
          <SettingLogbookMenuPanel
            categoryTypes={categoryTypes}
            isReleased={isReleased}
            automaticCollapsing={automaticCollapsing}
            automaticRefresh={automaticRefresh}
            isSortingLatestEventsFirst={isSortingLatestEventsFirst}
            isBeamlineLogbook={isBeamlineLogbook}
            showUserLogsOnBeamlineLogbook={showUserLogsOnBeamlineLogbook}
            pageSize={pageSize}
          ></SettingLogbookMenuPanel>
        )}
      </Row>
    </Container>
  );
}

EventListMenu.propTypes = {
  /** Available tags for the proposal */
  availableTags: PropTypes.array,
  /** Event count by selection filter */
  eventCountBySelectionFilter: PropTypes.number,
  /** Number of event recieved since last refresh */
  eventCountSinceLastrefresh: PropTypes.number,
  /** Callback function used to trigger event list update */
  getEvents: PropTypes.func,
  /** Identifier of the current investigation */
  investigationId: PropTypes.number,
  /** Whether the New button is enabled or not */
  isNewButtonEnabled: PropTypes.bool,
  /** If investigation is relased */
  isReleased: PropTypes.bool,
  /** Selection filter for mongo request (used for PDF request) */
  selectionFilter: PropTypes.object,
  /** Session identifier */
  sessionId: PropTypes.string.isRequired,
  /** Callback function triggered when the user clicks on the new event button */
  setNewEventVisibility: PropTypes.func,
  /** Whether the events are currently sorted latest first. False if oldest first. */
  isSortingLatestEventsFirst: PropTypes.bool.isRequired,
  /** Callback function executed when the user clicks the filter panel buttons */
  filterEvents: PropTypes.func,
  /** Whether the events command lines are grouped together. */
  automaticCollapsing: PropTypes.bool.isRequired,
  /** Whether the events automatically refreshed */
  automaticRefresh: PropTypes.bool.isRequired,
  /** Instrument name in case of beamline logbook */
  instrumentName: PropTypes.string,
};

export default EventListMenu;
