import axios from 'axios';
import React from 'react';
import { usePageTracking, useQueryParams } from '../helpers/hooks';
import { useSelector } from 'react-redux';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { createEventFromBase64 } from '../api/icat-plus/logbook';

function CameraPage() {
  usePageTracking();
  const { instrumentName, investigationId } = useQueryParams();
  const user = useSelector((state) => state.user);
  const { sessionId } = user;
  const onTakePhoto = (dataUri) => {
    try {
      axios({
        method: 'post',
        url: createEventFromBase64(sessionId, investigationId, instrumentName),
        data: { base64: dataUri },
      }).then(
        () => {
          alert('Uploaded Successfully');
          window.close();
        },
        (error) => {
          alert(error);
        }
      );
    } catch (e) {
      alert(e);
    }
  };

  const onCameraError = (error) => {
    console.error('onCameraError', error);
  };

  const onCameraStart = () => {
    console.log('onCameraStart');
  };

  const onCameraStop = () => {
    console.log('onCameraStop');
  };

  return (
    <>
      <Camera
        onTakePhoto={(dataUri) => {
          onTakePhoto(dataUri);
        }}
        onCameraError={(error) => {
          onCameraError(error);
        }}
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        idealResolution={{
          width: 640,
          height: 480,
        }}
        imageCompression={0.97}
        isMaxResolution={false}
        isFullscreen={false}
        isImageMirror={false}
        isDisplayStartCameraError
        sizeFactor={1}
        onCameraStart={(stream) => {
          onCameraStart(stream);
        }}
        onCameraStop={() => {
          onCameraStop();
        }}
      />
    </>
  );
}

export default CameraPage;
