import React from 'react';
import { Button } from 'react-bootstrap';
import UI from '../../config/ui';

export function ExperimentalReportButton(props) {
  const { reportName } = props;

  return (
    <Button
      variant="primary"
      href={`${UI.doi.experimentalReport.ftp}/${reportName}`}
      target="_blank"
    >
      {reportName}
    </Button>
  );
}
