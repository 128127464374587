import React from 'react';
import DatasetTable from './DatasetTable';
import DATASET_TABLE_WIDGET_FACTORY from '../../config/datasetTableWidgetConfiguration';
import BioSaxsInlineDatasetTable from './BioSaxs/BioSaxsInlineDatasetTable';
import InlineDatasetTable from './InlineDatasetTable';
import GenericDatasetTable from './GenericDatasetTable';
/**
 * FactoryWidget that returns a component depending on the configuration file
 * This is following the react factory pattern explained here:
 * https://blog.bitsrc.io/react-js-with-factory-pattern-building-complex-ui-with-ease-fe6db29ab1c1
 *
 * @param {*} investigation
 */
function DatasetTableFactory(params) {
  const {
    investigation,
    pageOptions,
    onHandleTableChange,
    datasets,
    onSearch,
  } = params;
  // Default type of visual component
  let component = 'default';
  if (investigation?.instrument?.name) {
    const investigationDate = new Date(investigation.startDate);
    const widgets = DATASET_TABLE_WIDGET_FACTORY.filter((view) => {
      // This will take into account the instrument name and the dates to check which "view" should be used for the dataset table
      return (
        view.instrumentName === investigation.instrument.name &&
        ((view.startDate <= investigationDate &&
          view.endDate > investigationDate &&
          view.endDate !== '') ||
          (view.endDate === '' && view.startDate <= investigationDate))
      );
    });

    if (widgets.length > 0) {
      component = widgets[0].widget;
    }
  }

  switch (component) {
    case 'InlineDatasetTable':
      return (
        <InlineDatasetTable
          pageOptions={pageOptions}
          onHandleTableChange={onHandleTableChange}
          datasets={datasets}
        />
      );
    case 'GenericDatasetTable':
      return (
        <GenericDatasetTable
          pageOptions={pageOptions}
          onHandleTableChange={onHandleTableChange}
          datasets={datasets}
          onSearch={onSearch}
        />
      );
    case 'BioSaxsInlineDatasetTable':
      return (
        <BioSaxsInlineDatasetTable
          pageOptions={pageOptions}
          onHandleTableChange={onHandleTableChange}
          datasets={datasets}
        />
      );
    default:
      return <DatasetTable {...params} />;
  }
}

export default DatasetTableFactory;
