import React from 'react';
import { getDatasetParameterValueByName } from '../../../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';

function HTXRPDDatasetColumn(props) {
  const { dataset } = props;
  const datasetName = getDatasetParameterValueByName(dataset, 'datasetName');

  const getValue = () => {
    return (
      <LinkContainer
        style={{ padding: 0 }}
        to={`/dataset/${dataset.id}?view=datasets`}
      >
        <Nav.Link>{datasetName} </Nav.Link>
      </LinkContainer>
    );
  };
  return (
    <>
      <ParameterTableWidget
        header="Data acquisition"
        parameters={[
          {
            name: 'dataset',
            value: getValue(),
          },
          {
            name: 'sample',
            value: dataset.sampleName,
          },
        ]}
      ></ParameterTableWidget>
    </>
  );
}

export default HTXRPDDatasetColumn;
