import React from 'react';
import { Badge, Stack } from 'react-bootstrap';
import {
  DC_RESOURCE_TYPE,
  ES_RESOURCE_TYPE,
  OPEN_ACCESS,
  RESTRICTED_ACCESS,
} from '../../constants/doi';

/**
 * This component displays badges depending on the resource type of the DOI (session or data Collection) and depending on the accessibility status (open or restricted access)
 */
export function DOITypeBadgeWidget(props) {
  const { types, accessibilityStatus } = props;
  const { resourceType } = types;

  return (
    <>
      <Stack direction="horizontal" gap={3}>
        {resourceType === ES_RESOURCE_TYPE && (
          <h4>
            <Badge bg="secondary">Session</Badge>
          </h4>
        )}
        {resourceType === DC_RESOURCE_TYPE && (
          <h4>
            <Badge bg="secondary">Data Collection</Badge>
          </h4>
        )}
        {accessibilityStatus === OPEN_ACCESS && (
          <h4>
            <Badge bg="success">Open access</Badge>
          </h4>
        )}
        {accessibilityStatus === RESTRICTED_ACCESS && (
          <h4>
            <Badge bg="warning">Restricted access</Badge>
          </h4>
        )}
      </Stack>
    </>
  );
}
