export function trackH5Viewer(src) {
  window._paq.push(['trackEvent', 'H5Viewer', 'Display', src]);
}

export function trackBeamlineLogBook(src) {
  window._paq.push(['trackEvent', 'Logbook', 'Beamline', src]);
}

export function trackInvestigationLogBook(src) {
  window._paq.push(['trackEvent', 'Logbook', 'Investigation', src]);
}

export function trackAnonymousSignIn() {
  window._paq.push(['trackEvent', 'SignIn', 'Anonymous', 'anonymous']);
}

export function trackKeycloakSignIn() {
  window._paq.push(['trackEvent', 'SignIn', 'Keycloak', 'keycloak']);
}

export function trackSignIn(src) {
  window._paq.push(['trackEvent', 'SignIn', 'Plugin', src]);
}

export function trackParcel(src) {
  window._paq.push(['trackEvent', 'Parcel', 'Display', src]);
}

export function trackRestore(src) {
  window._paq.push(['trackEvent', 'Restore', 'Action', src]);
}

export function trackMintDOI(src) {
  window._paq.push(['trackEvent', 'MintDOI', 'Action', src]);
}

export function trackDownload(src, id) {
  window._paq.push(['trackEvent', 'Download', src, id]);
}

export function trackOpenDOI(src) {
  window._paq.push(['trackEvent', 'DOI', 'Display', src]);
}
