import React from 'react';

import { getDatasetUnitByName } from '../../../helpers';

/**
 * Returns a react component with the units as text muted
 * @param {*} props
 */
function UnitText(props) {
  const { dataset, parameterName } = props;
  if (dataset && parameterName && dataset.parameters) {
    const units = getDatasetUnitByName(dataset, parameterName);
    return (
      <span style={{ marginLeft: '3px' }} className="text-muted">
        {units}
      </span>
    );
  }

  return <></>;
}

export default UnitText;
