import React, { useEffect } from 'react';
import { Card, Container, Col, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../../actions/breadcrumbs';
import TagPanel from '../../../components/Logbook/Tag/TagPanel';
import styles from '../../../components/Address/MyAddressesSummary.module.css';
import { useQuery } from '../../../helpers/hooks';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTags } from '@fortawesome/free-solid-svg-icons';

function TagList(props) {
  const {
    title,
    tags,
    onEdit,
    isAdministrator,
    isInstrumentScientist,
    description,
    border,
  } = props;

  return (
    <Card border={border} style={{ marginBottom: 30 }}>
      <Card.Header>
        <Card.Title className={`text-${border}`}>
          <FontAwesomeIcon icon={faTags} style={{ marginRight: 10 }} />
          {title}
        </Card.Title>
        {description}
      </Card.Header>

      <Card.Body>
        {tags.map((tag) => (
          <TagPanel
            tag={tag}
            onEdit={onEdit}
            isAdministrator={isAdministrator}
            isInstrumentScientist={isInstrumentScientist}
          ></TagPanel>
        ))}
      </Card.Body>
    </Card>
  );
}
function TagsPanel(props) {
  const query = useQuery();
  const history = useHistory();
  const { tags, isInstrumentScientist, isAdministrator } = props;

  const globalTags = tags.filter(
    (tag) =>
      !tag.investigation && (tag.instrumentName === null || !tag.instrumentName)
  );
  const instrumentTags = tags.filter(
    (tag) =>
      (!tag.investigationId || tag.investigationId === null) &&
      tag.instrumentName !== null &&
      tag.instrumentName
  );
  const investigationTags = tags.filter(
    (tag) => tag.investigationId && tag.investigationId !== null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'Tags Manager' }]));
  }, [dispatch]);

  const onEdit = (tag) => {
    query.set('action', 'edit');
    query.set('tagId', tag._id);
    history.push({ search: query.toString() });
  };

  return (
    <div className="app__inner">
      <Container fluid>
        <Row>
          <Col sm={12} md={12}>
            <div className={styles.heading}>
              <Button
                variant="primary"
                aria-label="Create a tag"
                onClick={() => {
                  query.set('action', 'new');
                  history.push({ search: query.toString() });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add a new Tag
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4} md={4}>
            <TagList
              title="Investigation"
              description="Participants of a investigation can create their own tags"
              tags={investigationTags}
              onEdit={onEdit}
              isAdministrator={isAdministrator}
              isInstrumentScientist={isInstrumentScientist}
              border="success"
            ></TagList>
          </Col>
          <Col sm={4} md={4}>
            <TagList
              title="Beamline"
              description="These tags are only editable by beamline staff and are applicable to all experiments of a beamline"
              tags={instrumentTags}
              onEdit={onEdit}
              isAdministrator={isAdministrator}
              isInstrumentScientist={isInstrumentScientist}
              border="warning"
            ></TagList>
          </Col>
          <Col sm={4} md={4}>
            <TagList
              title="Global"
              description="These tags are only editable by administrator and applicable to all the experiments"
              tags={globalTags}
              onEdit={onEdit}
              isAdministrator={isAdministrator}
              isInstrumentScientist={isInstrumentScientist}
              border="danger"
            ></TagList>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TagsPanel;
