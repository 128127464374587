import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import LoadingBoundary from '../../components/LoadingBoundary';
import { MY_DATA_PATH } from '../../constants/routePaths';
import { usePageTracking } from '../../helpers/hooks';
import MyInvestigationsTable from './MyInvestigationsTable';
// import MyIndustryProposalsPanel from './MyIndustryProposalsPanel';

function MyDataPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'My Data', link: MY_DATA_PATH }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading investigations..." inPanel>
        <MyInvestigationsTable />
      </LoadingBoundary>

      {/* FIX Hiding for now, as users are mistakenly configuring shipping against proposals */}
      {/* Same API request as investigations above, so need for two loaders/errors */}
      {/* <LoadingBoundary withSilentError customLoader={null}>
        <MyIndustryProposalsPanel />
      </LoadingBoundary> */}
    </div>
  );
}

export default MyDataPage;
