import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { BeamlineField } from './BeamlineField';

export function ProposalBeamlineWidget(props) {
  const { proposalBeamlines, proposalTitle, beamlineTitle } = props;

  return (
    <Container fluid style={{ marginBottom: '1rem', padding: 0 }}>
      <Row>
        <Col>
          <Card.Title>{proposalTitle}</Card.Title>
        </Col>
        <Col>
          <Card.Title>{beamlineTitle}</Card.Title>
        </Col>
      </Row>
      {proposalBeamlines.map(({ proposal, beamline, beamlineUrl }, id) => (
        <Row key={`row-${id}`}>
          <Col>
            <Card.Text>{proposal}</Card.Text>
          </Col>
          <Col>
            <BeamlineField beamline={beamline} beamlineUrl={beamlineUrl} />
          </Col>
        </Row>
      ))}
    </Container>
  );
}
