// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SettingLogbookMenuPanel_returnCheckbox__3aorX {\n  margin-bottom: 12px !important;\n}\n\n.SettingLogbookMenuPanel_logbookSwitch__1qZty {\n  margin-top: 10px;\n  margin-left: -20px;\n}\n\n.SettingLogbookMenuPanel_logbookTextSwitch__2AalV {\n  margin-top: 14px;\n  margin-left: -20px;\n}\n\n.SettingLogbookMenuPanel_logbookRadio__3Oqn9 {\n  margin-left: -20px;\n}\n", ""]);
// Exports
exports.locals = {
	"returnCheckbox": "SettingLogbookMenuPanel_returnCheckbox__3aorX",
	"logbookSwitch": "SettingLogbookMenuPanel_logbookSwitch__1qZty",
	"logbookTextSwitch": "SettingLogbookMenuPanel_logbookTextSwitch__2AalV",
	"logbookRadio": "SettingLogbookMenuPanel_logbookRadio__3Oqn9"
};
module.exports = exports;
