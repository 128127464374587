import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

export default function EditSampleModal(props) {
  const {
    sampleId,
    handleClose,
    sample,
    sampleParameterDescription,
    setDescription,
    handleSubmit,
  } = props;

  return (
    <Modal show={sampleId !== undefined} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit sample</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control plaintext readOnly defaultValue={sample?.name} />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextDescription"
          >
            <Form.Label column sm="2">
              Description
            </Form.Label>
            <Col sm="10">
              <Form.Control
                required
                as="textarea"
                placeholder=""
                autoFocus
                defaultValue={sampleParameterDescription?.value}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
