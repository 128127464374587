import React from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '../helpers/hooks';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { getTotalPageNumber } from '../helpers/eventHelpers';

/* This class handles the display of pages for the logbook */
function Pager(props) {
  const { eventCount = 0, isCentered, activePage, pageSize, onClick } = props;
  const history = useHistory();
  const query = useQuery();

  if (getTotalPageNumber(eventCount, pageSize) <= 1 || eventCount === 0) {
    return null;
  }

  const styleForCentering = {
    position: 'absolute',
    left: '50%',
    marginLeft: '-125px',
    width: 250,
  };

  return (
    <div style={isCentered ? styleForCentering : { marginLeft: 70 }}>
      <ReactPaginate
        forcePage={activePage - 1}
        pageCount={getTotalPageNumber(eventCount, pageSize)}
        pageRangeDisplayed={1}
        previousLabel="<"
        nextLabel=">"
        marginPagesDisplayed={1}
        containerClassName={
          'reactpagination reactpagination-sm margin-top-bottom-0'
        }
        subContainerClassName={'pages reactpagination reactpagination-sm'}
        activeClassName={'active'}
        onPageChange={(data) => {
          if (onClick) {
            onClick(data.selected + 1);
          } else {
            query.set('page', data.selected + 1);
            history.push({ search: query.toString() });
          }
        }}
      />
    </div>
  );
}

Pager.propTypes = {
  /* Set the current page */
  activePage: PropTypes.number,
  /* the total number of events found by the server for a given search */
  eventCount: PropTypes.number.isRequired,
  /* Function triggered when the user clicks on a page */
  onPageClicked: PropTypes.func,
  /** Whether the rendered component is centered */
  isCentered: PropTypes.bool,
};

export default Pager;
