import React, { useState } from 'react';
import styles from './MyAddressesSummary.module.css';
import { LinkContainer } from 'react-router-bootstrap';
import { Alert, Button, Row, Col, Container } from 'react-bootstrap';
import { useResource, useFetcher } from 'rest-hooks';
import AddressResource from '../../resources/address';
import { useQuery } from '../../helpers/hooks';
import { useHistory } from 'react-router';
import AddressPanel from './AddressPanel';
import AddressFormModal from './AddressFormModal';
import ModalLoadingBoundary from '../ModalLoadingBoundary';
import AddressHeaderWithActions from './AddressHeaderWithActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

function MyAddressesSummary() {
  const [alert, setAlert] = useState();

  const history = useHistory();
  const query = useQuery();
  const isCreating = query.has('new');
  const editingId = query.get('edit');

  const addresses = useResource(AddressResource.listShape(), {});
  const editedAddress = addresses.find((a) => a._id === editingId);

  const createAddress = useFetcher(AddressResource.createShape());
  const editAddress = useFetcher(AddressResource.updateShape());
  const deleteAddress = useFetcher(AddressResource.deleteShape());
  const refetchAddresses = useFetcher(AddressResource.listShape());

  function handleCloseModal() {
    history.push(`/addresses`);
  }

  async function handleSubmit(values) {
    setAlert(undefined);
    try {
      if (!editedAddress) {
        await createAddress(values);
      } else {
        await editAddress(values);
      }

      await refetchAddresses();
      setAlert({
        type: 'success',
        message: `Address ${isCreating ? 'created' : 'saved'}.`,
      });
    } catch (error) {
      setAlert({ type: 'danger', message: `An error occurred.` });
      console.error(error.response);
    }

    handleCloseModal();
  }

  async function handleDelete(address) {
    try {
      await deleteAddress(address);
      setAlert({
        style: 'success',
        message: 'Address was removed !',
      });
    } catch (error) {
      setAlert({ type: 'danger', message: `An error occurred.` });
      console.log(error);
    }
  }

  return (
    <>
      {(isCreating || editingId) && (
        <ModalLoadingBoundary
          message="Loading investigations..."
          onCloseModal={handleCloseModal}
        >
          <AddressFormModal
            address={editedAddress}
            onSubmitAsync={handleSubmit}
            onCloseModal={handleCloseModal}
          />
        </ModalLoadingBoundary>
      )}
      {alert && <Alert variant={alert.style}>{alert.message}</Alert>}
      <Container fluid>
        <Row>
          <Col sm={24} md={12}>
            <div className={styles.heading}>
              <h3>Addresses</h3>
              <LinkContainer to={`/addresses?new`}>
                <Button variant="primary" aria-label="Create an address">
                  <FontAwesomeIcon icon={faPlus} /> Add an address
                </Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
        <Row>
          {addresses.length > 0 ? (
            addresses.map((address) => (
              <Col key={address._id} sm={6} md={3}>
                <AddressPanel
                  header={
                    <AddressHeaderWithActions
                      address={address}
                      onDelete={handleDelete}
                    />
                  }
                  address={address}
                  variant="primary"
                />
              </Col>
            ))
          ) : (
            <Alert>No address to display.</Alert>
          )}
        </Row>
      </Container>
    </>
  );
}

export default MyAddressesSummary;
