import moment from 'moment';
import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import LogbookStatisticsTabs from '../components/ManagerStats/GeneralStats/Logbook/LogbookStatisticsTabs';
import { usePageTracking, useQuery } from '../helpers/hooks';
import Loader from '../components/Loader';

function LogbookStatisticsPage() {
  usePageTracking();
  const dispatch = useDispatch();

  const query = useQuery();
  const startDate =
    query.get('startDate') ||
    moment().subtract(7, 'days').format(moment.HTML5_FMT.DATE);
  const endDate =
    query.get('endDate') ||
    moment().add(1, 'days').format(moment.HTML5_FMT.DATE);

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        {
          name: `Logbook Statistics `,
        },
      ])
    );
  }, [dispatch]);

  return (
    <div className="app__inner">
      <Suspense
        fallback={<Loader message="Loading Logbook Statistics..." spacedOut />}
      >
        <LogbookStatisticsTabs
          startDate={startDate}
          endDate={endDate}
        ></LogbookStatisticsTabs>
      </Suspense>
    </div>
  );
}

export default LogbookStatisticsPage;
