import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Form,
} from 'react-bootstrap';
import { getTotalPageNumber } from '../../helpers/eventHelpers';

function LogbookJumpPage(props) {
  const [jumpedPage, setJumpedPaged] = useState(1);
  const {
    eventCount = 0,
    onPageClicked,
    variant = 'primary',
    pageSize,
  } = props;
  const totalPageNumber = getTotalPageNumber(eventCount, pageSize);

  if (totalPageNumber <= 1 || eventCount === 0) {
    return null;
  }

  const callPageClicked = (value) => {
    if (value && value > 0 && value <= totalPageNumber) {
      onPageClicked(value);
    }
  };

  const onKeyUpValue = (event) => {
    if (event.keyCode === 13) {
      callPageClicked(event.target.value);
    }
  };

  const onButtonClicked = () => {
    callPageClicked(jumpedPage);
  };

  return (
    <div>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip">Go to page </Tooltip>}
      >
        <InputGroup>
          <Form.Control
            onKeyUp={onKeyUpValue}
            type="number"
            style={{ width: 70 }}
            min={1}
            max={totalPageNumber}
            onChange={(e) => setJumpedPaged(e.target.value)}
          />
          <Button onClick={onButtonClicked} variant={variant}>
            Go
          </Button>
        </InputGroup>
      </OverlayTrigger>
    </div>
  );
}

LogbookJumpPage.propTypes = {
  /* the total number of events found by the server for a given search */
  eventCount: PropTypes.number.isRequired,
  /* Function triggered when the user clicks on a page */
  onPageClicked: PropTypes.func,
  /**style of the Go button, primary by default */
  variant: PropTypes.string,
};
export default LogbookJumpPage;
