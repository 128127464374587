import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFetcher } from 'rest-hooks';
import AddressResource from '../../resources/address';
import AddressFormModal from '../Address/AddressFormModal';

function CreateAddress(props) {
  const { investigation, onCreate } = props;

  const [isCreating, setCreating] = useState(false);

  const createAddress = useFetcher(AddressResource.createShape());
  const refetchAddresses = useFetcher(AddressResource.listShape());

  async function handleSubmit(values) {
    const newAddress = await createAddress(values);
    await refetchAddresses();

    onCreate(newAddress._id); // select new address in form
    setCreating(false);
  }

  return (
    <>
      {isCreating && (
        <AddressFormModal
          investigation={investigation}
          onSubmitAsync={handleSubmit}
          onCloseModal={() => {
            setCreating(false);
          }}
        />
      )}

      <Button
        variant="link"
        style={{ padding: 0 }}
        onClick={() => {
          setCreating(true);
        }}
      >
        Create new address
      </Button>
    </>
  );
}

export default CreateAddress;
