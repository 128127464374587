import React, { useState } from 'react';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../../resources/investigation';
import Timeline, {
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
  DateHeader,
} from 'react-calendar-timeline/lib';
import 'react-calendar-timeline/lib/Timeline.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import InstrumentResource from '../../resources/instrument';
import { useQuery } from '../../helpers/hooks';

const COLOR_EMPTY_SESSION = 'white';
const COLOR_NON_SCHEDULED_SESSION = 'red';
const COLOR_NON_EMPTY_SESSION = 'LightBlue';

function getBackgroundColor(item) {
  if (item.investigation.visitId.trim().length < 15)
    return COLOR_NON_SCHEDULED_SESSION;
  if (item.investigation.parameters.__datasetCount) {
    return COLOR_NON_EMPTY_SESSION;
  }
  return COLOR_EMPTY_SESSION;
}
function itemRenderer(props) {
  const { item, itemContext, getItemProps, getResizeProps } = props;
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  return (
    <div
      {...getItemProps({
        style: {
          backgroundColor: getBackgroundColor(item),
          fontSize: '8px',
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

      <div>
        <Link
          to={`/investigation/${item.investigation.id}/datasets`}
          href="_blank"
        >
          <span style={{ marginLeft: 10 }}>{itemContext.title}</span>
        </Link>
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
    </div>
  );
}

function InvestigationsCalendarTimeLine() {
  const dateFormat = 'YYYY-MM-DD';
  const query = useQuery();

  const [startDate, setStartDate] = useState(
    query.get('startDate')
      ? moment(query.get('startDate'), dateFormat)
      : moment().startOf('month')
  );
  const [endDate, setEndDate] = useState(
    query.get('endDate')
      ? moment(query.get('endDate'), dateFormat)
      : moment().add(1, 'month').startOf('month')
  );
  // we load addionnal days, to have data while scrolling, half of the calendar time range
  const [loadAddDays, setLoadAddDays] = useState(
    query.get('startDate') && query.get('endDate')
      ? Math.ceil(
          moment(query.get('endDate')).diff(
            moment(query.get('startDate')),
            'days'
          ) / 2
        )
      : 15
  );
  const fetchingParams = {
    startDate: moment(startDate)
      .subtract(loadAddDays, 'day')
      .format(dateFormat),
    endDate: moment(endDate).add(loadAddDays, 'day').format(dateFormat),
  };

  const [investigations, instruments] = useResource(
    [InvestigationResource.listShape(), fetchingParams],
    [InstrumentResource.listShape(), {}]
  );

  let id = 1;
  const groups = [];
  instruments.forEach((instrument) => {
    groups.push({ id: id++, title: instrument.name });
  });
  groups.push({ id: id++, title: undefined });

  const items = investigations.map((investigation) => {
    return {
      id: investigation.id,
      investigation,
      scheduled: investigation.endData,
      group: groups.find(
        (group) => group.title === investigation.instrument.name
      ).id,
      title: investigation.name,
      start_time: moment(
        investigation.startDate,
        moment.HTML5_FMT.DATETIME_LOCAL_MS
      ),
      end_time: investigation.endDate
        ? moment(investigation.endDate, moment.HTML5_FMT.DATETIME_LOCAL_MS)
        : moment(
            investigation.startDate,
            moment.HTML5_FMT.DATETIME_LOCAL_MS
          ).add(1, 'day'),
    };
  });

  // When the timeline has scrolled enough (50% of the invisible surface on one side)
  const onBoundsChange = (canvasTimeStart, canvasTimeEnd) => {
    setStartDate(moment(canvasTimeStart));
    setEndDate(moment(canvasTimeEnd));
    setLoadAddDays(0);
  };

  return (
    <Timeline
      groups={groups}
      items={items}
      defaultTimeStart={startDate}
      defaultTimeEnd={endDate}
      stackItems
      itemHeightRatio={0.65}
      showCursorLine
      lineHeight={20}
      canMove={false}
      itemRenderer={itemRenderer}
      onBoundsChange={onBoundsChange}
    >
      <TimelineHeaders>
        <DateHeader unit="month" />
        <DateHeader unit="day" />
      </TimelineHeaders>
      <TimelineMarkers>
        <TodayMarker />
      </TimelineMarkers>
    </Timeline>
  );
}

export default InvestigationsCalendarTimeLine;
