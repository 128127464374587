// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelStatusButtons_wrapper__2HAIQ {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.ParcelStatusButtons_label__u1N82 {\n  font-weight: 600;\n  margin: 0.5rem;\n}\n\n@media (min-width: 720px) {\n  .ParcelStatusButtons_buttonGroup__vJoCn {\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    flex-wrap: wrap;\n  }\n}\n\n.ParcelStatusButtons_button__3FJHv {\n  margin: 0.5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "ParcelStatusButtons_wrapper__2HAIQ",
	"label": "ParcelStatusButtons_label__u1N82",
	"buttonGroup": "ParcelStatusButtons_buttonGroup__vJoCn",
	"button": "ParcelStatusButtons_button__3FJHv"
};
module.exports = exports;
