export const VOLUME = '__volume';
export const DATASET_COUNT = '__datasetCount';
export const FILE_COUNT = '__fileCount';
export const SAMPLE_COUNT = '__sampleCount';
export const TITLE = 'title';
export const INVESTIGATION_NAMES = 'investigationNames';
export const INSTRUMENT_NAMES = 'instrumentNames';

export const ACQUISITION_DATASET_COUNT = '__acquisitionDatasetCount';
export const PROCESSED_DATASET_COUNT = '__processedDatasetCount';

export const ACQUISITION_DATASET_VOLUME = '__acquisitionVolume';
export const PROCESSED_DATASET_VOLUME = '__processedVolume';
