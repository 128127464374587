import React from 'react';
import { Alert, Col, Container, Row, Spinner, Stack } from 'react-bootstrap';
import UI from '../../config/ui';
import { INVESTIGATION_DATE_FORMAT } from '../../constants';
import DOIBadge from '../doi/DOIBadge';
import LoadingBoundary from '../LoadingBoundary';
import { DMPButton } from './DMPButton';
import { dateFormatter } from './utils';

function ProposalPanel(props) {
  const { investigation } = props;

  return (
    <Stack gap={1}>
      <div>
        <h4>{investigation.title} </h4>
      </div>
      <Stack direction="horizontal" gap={2}>
        <Container style={{ padding: 0, margin: 0 }}>
          <Row xs={'auto'}>
            <Col>
              <p className="text-secondary">
                {dateFormatter(
                  investigation.startDate,
                  'DD/MM/YYYY hh:mm',
                  false
                )}
                {investigation.endDate && (
                  <>
                    {' '}
                    -{' '}
                    {dateFormatter(
                      investigation.endDate,
                      'DD/MM/YYYY hh:mm',
                      false
                    )}{' '}
                  </>
                )}
              </p>
            </Col>
            {investigation.instrument && (
              <Col>
                <p className="text-secondary">
                  on beamline: {investigation.instrument.name}{' '}
                </p>
              </Col>
            )}
            {investigation.releaseDate && (
              <Col>
                <p className="text-secondary">
                  release date:{' '}
                  {dateFormatter(
                    investigation.releaseDate,
                    INVESTIGATION_DATE_FORMAT,
                    false
                  )}
                </p>
              </Col>
            )}
            {investigation.doi && (
              <Col>
                <div style={{ marginBottom: '1rem' }}>
                  <DOIBadge doi={investigation.doi} />
                </div>
              </Col>
            )}
          </Row>
        </Container>
        {UI.dmp.enabled && (
          <div className="ms-auto" style={{ marginBottom: '1rem' }}>
            <LoadingBoundary
              message="Loading DMP information..."
              customLoader={<Spinner animation="border" />}
            >
              <DMPButton
                investigationName={investigation.name}
                investigationId={investigation.id}
              />
            </LoadingBoundary>
          </div>
        )}
      </Stack>
      <Alert variant="info">
        <Alert.Heading>Abstract</Alert.Heading>
        <p>{investigation.summary}</p>
      </Alert>
    </Stack>
  );
}

export default ProposalPanel;
