import React from 'react';
import LoadingBoundary from '../LoadingBoundary';
import SamplesTable from './SamplesTable';

function SamplesPanel(props) {
  const { investigationId } = props;
  return (
    <LoadingBoundary message="Loading samples">
      <SamplesTable investigationId={investigationId} withSampleStats />
    </LoadingBoundary>
  );
}

export default SamplesPanel;
