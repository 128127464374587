// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelHeader_parcelHeader__5j0iG {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 1rem 0;\n}\n\n.ParcelHeader_parcelAction__1Lj5F {\n  margin: 0 1rem;\n}\n\n.ParcelHeader_investigation__2edul {\n  color: #777;\n}\n", ""]);
// Exports
exports.locals = {
	"parcelHeader": "ParcelHeader_parcelHeader__5j0iG",
	"parcelAction": "ParcelHeader_parcelAction__1Lj5F",
	"investigation": "ParcelHeader_investigation__2edul"
};
module.exports = exports;
