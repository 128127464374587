/** Things that are in common between Sample Changer and HPLC */

export const DATASET_HEADER_WITH = 100;
export const TIME_HEADER_WITH = 50;

export const truncate = (value, toFixed) => {
  if (value && value !== '') {
    try {
      return parseFloat(value).toFixed(toFixed ? toFixed : 2);
    } catch {}
  }
};
