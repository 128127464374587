import ICATPLUS from '../../config/icatPlus';

export function getHistogramStats(
  sessionId,
  metric,
  interval,
  startDate,
  endDate,
  format,
  aggregation,
  instrumentName
) {
  return instrumentName == null
    ? `${ICATPLUS.server}/elasticsearch/${sessionId}/stats/histogram?metric=${metric}&interval=${interval}&startDate=${startDate}&endDate=${endDate}&format=${format}&aggregation=${aggregation}`
    : `${ICATPLUS.server}/elasticsearch/${sessionId}/stats/histogram?metric=${metric}&interval=${interval}&startDate=${startDate}&endDate=${endDate}&format=${format}&aggregation=${aggregation}&instrumentName=${instrumentName}`;
}

export function getInstrumentStatistics(sessionId, instrumentName, openData) {
  return instrumentName == null
    ? `${ICATPLUS.server}/elasticsearch/${sessionId}/stats/instrument/metrics`
    : `${ICATPLUS.server}/elasticsearch/${sessionId}/stats/instrument/metrics?instrumentName=${instrumentName}&openData=${openData}`;
}
