import UI from '../../config/ui';

export function isH5Viewer(datafile) {
  return UI.h5Viewer.fileExtensions.some((extension) =>
    datafile.name.endsWith(extension)
  );
}

export function isGalleryImageViewer(datafile) {
  return UI.imageViewer.fileExtensions.some(
    (extension) =>
      datafile.name.toLowerCase().endsWith(extension) &&
      datafile.location.toLowerCase().indexOf('gallery') !== -1
  );
}

export function isViewerAvailable(datafile) {
  return isH5Viewer(datafile) || isGalleryImageViewer(datafile);
}

export function isZeroBytesFile(datafile) {
  return datafile && datafile.fileSize === 0;
}
