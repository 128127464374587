import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Card, Row } from 'react-bootstrap';
import {
  EVENT_CATEGORY_COMMANDLINE,
  LATEST_EVENT_VERSION,
  MIDDLE_EVENT_VERSION,
  ORIGINAL_EVENT_VERSION,
  PLAINTEXT_CONTENT_FORMAT,
  HTML_CONTENT_FORMAT,
} from '../../constants/eventTypes';
import {
  getText,
  getContentAsHTML,
  getEventHistoryCreationDate,
} from '../../helpers/eventHelpers';
import TagListInLine from './Tag/TagListInLine';

/*
 * React component which renders a panel showing the provided event details using version context provided by the version property.
 */
function EventVersionPanel(props) {
  const { event, version } = props;

  const getEventHeaderTextByVersion = (event, version) => {
    if (event && version) {
      if (version === ORIGINAL_EVENT_VERSION) {
        return `On ${getEventHistoryCreationDate(
          event
        )}, the original message was: `;
      }
      if (version === LATEST_EVENT_VERSION) {
        return `Written on ${getEventHistoryCreationDate(
          event
        )}, the latest version of the comment is:`;
      }
      if (version === MIDDLE_EVENT_VERSION) {
        return `Written on ${getEventHistoryCreationDate(
          event
        )}, the comment was:`;
      }
    } else return '';
  };

  const getEventFooterTextByVersion = (event, version) => {
    if (event && version) {
      if (version === ORIGINAL_EVENT_VERSION) {
        return `Created by ${event.username}`;
      }
      if (version === LATEST_EVENT_VERSION || MIDDLE_EVENT_VERSION) {
        return `Commented by ${event.username}`;
      }
    } else {
      return '';
    }
  };

  return (
    <Card>
      <Card.Header>
        <b> {getEventHeaderTextByVersion(event, version)} </b>
      </Card.Header>
      <Card.Body>
        <EventText event={event} />
      </Card.Body>
      <Card.Footer>
        <Container fluid>
          <Row>
            <Col>
              <span className="padding-left-5" style={{ fontStyle: 'italic' }}>
                {getEventFooterTextByVersion(event, version)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <TagListInLine tags={event.tag} />
            </Col>
          </Row>
        </Container>
      </Card.Footer>
    </Card>
  );
}

EventVersionPanel.propTypes = {
  /** the event */
  event: PropTypes.object,
  /** event version */
  version: PropTypes.string,
};

export default EventVersionPanel;

function EventText(props) {
  const { event } = props;

  if (event) {
    if (event.category.toLowerCase() === EVENT_CATEGORY_COMMANDLINE) {
      const plainTextText = getText(event.content, PLAINTEXT_CONTENT_FORMAT);
      const htmlText = getText(event.content, HTML_CONTENT_FORMAT);

      if (plainTextText && !htmlText) {
        return (
          <pre>
            <div dangerouslySetInnerHTML={{ __html: plainTextText }} />
          </pre>
        );
      }
    }

    const htmlText = getContentAsHTML(event.content);
    if (htmlText) {
      return <div dangerouslySetInnerHTML={{ __html: htmlText }} />;
    }
    return null;
  }
}

EventText.propTypes = {
  /** Logbook event */
  event: PropTypes.object,
};
