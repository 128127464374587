import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { getFileByEventId } from '../../../../api/icat-plus/resource';

function PublicationSummary(props) {
  const { dataset, sessionId } = props;

  const getImageFromGalleyByIndex = (index) => {
    /** Checking gallery **/
    let gallery = dataset.parameters.filter(
      (o) => o.name === 'ResourcesGallery'
    );

    if (gallery != null) {
      if (gallery.length > 0) {
        gallery = gallery[0].value.split(' ');
        if (gallery.length > index) {
          const url = getFileByEventId(sessionId, gallery[index]);
          return (
            <div>
              <br />
              <Image src={url} height={200} />
            </div>
          );
        }
      }
    }
    return null;
  };

  const getParameterValue = (name) => {
    const sampleNameParameter = dataset.parameters.filter(
      (o) => o.name === name
    );
    if (sampleNameParameter) {
      if (sampleNameParameter[0]) {
        return sampleNameParameter[0].value;
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={2}>
          {getImageFromGalleyByIndex(0)}
        </Col>
        <Col xs={12} md={8}>
          <div style={{ fontSize: 10 }}>
            {getParameterValue('Sample_description')}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PublicationSummary;
