import React from 'react';
import Footer from '../components/Footer';
import { LandingPageWelcomeWidget } from '../components/LandingPage/LandingPageWelcomeWidget';
import LandingPageMenu from '../components/Menu/LandingPageMenu';

function WelcomeLandingPage() {
  return (
    <div>
      <div className="app">
        <LandingPageMenu />
        <div className="app__inner" style={{ marginTop: 85, marginBottom: 25 }}>
          <LandingPageWelcomeWidget />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WelcomeLandingPage;
