import React, { useState } from 'react';
import moment from 'moment';
import DOIBadge from '../doi/DOIBadge';
import ResponsiveTable from '../Table/ResponsiveTable';
import {
  beamlineFormatter,
  nameFormatter,
  experimentFormatter,
  fileCountFormatter,
  dateFormatter,
  userPortalLinkFormatter,
} from './utils';
import DatasetVolumeStatisticsColumn from './DatasetVolumeStatisticsColumn';
import InvestigationDateFilter from './InvestigationDateFilter';
import styles from './InvestigationTable.module.css';
import { useSelector } from 'react-redux';
import { INVESTIGATION_DATE_FORMAT } from '../../constants';
import UI from '../../config/ui';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../../resources/investigation';
import { useHistory } from 'react-router';
import { useQuery, useQueryParams } from '../../helpers/hooks';
import StartDateColumn from './StartDateColumn';

const INVESTIGATION_NAME_ICON_WIDTH = 62;
const INVESTIGATION_NAME_MARGIN = 8;

function getLgHeaderStyle(width, hidden) {
  return {
    xs: { hidden: true },
    sm: { hidden: true },
    md: { hidden: true },
    lg: { hidden, width, textAlign: 'center' },
  };
}

function getColumns({
  showProposalLinks,
  showInvestigationStats,
  showFiles,
  withUserPortalLink,
  proposalColWidth,
}) {
  return [
    {
      text: 'id',
      dataField: 'id',
      hidden: true,
    },
    {
      text: 'Experiment',
      dataField: 'name',
      formatter: (_, investigation) =>
        experimentFormatter(investigation, showProposalLinks),
      sort: true,
      hidden: false,
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { width: '100%' },
        sm: { width: '100%' },
        md: { hidden: true },
        lg: { hidden: true },
      },
    },
    {
      text: 'Proposal',
      dataField: 'name',
      formatter: (_, investigation) =>
        nameFormatter(investigation, showProposalLinks),
      sort: true,
      hidden: false,
      headerStyle: () => ({ width: 'fit-content', textAlign: 'center' }),
      responsiveHeaderStyle: getLgHeaderStyle(proposalColWidth, false),
    },

    {
      text: 'Beamline',
      dataField: 'instrument',
      formatter: (_, investigation) => (
        <span style={{ fontWeight: 'bold' }}>
          {beamlineFormatter(investigation)}
        </span>
      ),
      sort: true,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 110, textAlign: 'center' },
        lg: { width: 110, textAlign: 'center' },
      },
    },
    {
      text: 'Start',
      dataField: 'startDate',
      formatter: (_, investigation) => (
        <StartDateColumn
          investigation={investigation}
          showProposalLinks={showProposalLinks}
        ></StartDateColumn>
      ),
      sort: true,
      responsiveHeaderStyle: getLgHeaderStyle(110, false),
    },
    // See https://gitlab.esrf.fr/icat/E-DataPortal/-/issues/439
    {
      text: 'Title',
      sort: true,
      dataField: 'title',
      formatter: (_, investigation) =>
        investigation.title.length < 10
          ? investigation.summary
          : investigation.title,
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
      },
    },
    {
      text: 'A-Form',
      dataField: 'userPortal',
      formatter: (_, investigation) => {
        return userPortalLinkFormatter(investigation, 120);
      },
      sort: false,
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: !withUserPortalLink },
        md: { width: 140, textAlign: 'center', hidden: !withUserPortalLink },
        lg: { width: 140, textAlign: 'center', hidden: !withUserPortalLink },
      },
    },
    {
      text: 'Datasets',
      dataField: 'datasets',
      formatter: (_, { parameters }) => (
        <DatasetVolumeStatisticsColumn
          parameters={parameters}
        ></DatasetVolumeStatisticsColumn>
      ),
      responsiveHeaderStyle: getLgHeaderStyle(130, !showInvestigationStats),
    },
    {
      text: 'Files',
      hidden: !showFiles,
      dataField: 'dummy-1',
      isDummyField: true,
      formatter: (_, { parameters }) => fileCountFormatter(parameters),
      responsiveHeaderStyle: getLgHeaderStyle(80, !showInvestigationStats),
    },
    {
      text: 'Release',
      dataField: 'releaseDate',
      formatter: (_, investigation) =>
        dateFormatter(
          investigation.releaseDate,
          INVESTIGATION_DATE_FORMAT,
          false
        ),
      sort: true,
      responsiveHeaderStyle: getLgHeaderStyle(110, false),
    },
    {
      text: 'DOI',
      dataField: 'doi',
      formatter: (doi) => <DOIBadge doi={doi} />,
      sort: true,
      responsiveHeaderStyle: getLgHeaderStyle(260, false),
    },
  ];
}

function InvestigationTable(props) {
  const {
    withProposalLinks = false,
    withInvestigationStats = false,
    showStatisticsMenu = false,
    showLogbookMenu = false,
    instrumentName,
    withUserPortalLink = false,
    withJupyter = false,
    filter,
  } = props;
  const history = useHistory();
  const query = useQuery();

  function useQueryParam(key, defaultValue) {
    const value = useQueryParams()[key] || defaultValue;
    const [queryParam, setQueryParam] = useState(value);
    const updateValue = (newVal) => {
      setQueryParam(newVal);
      if (newVal) {
        query.set(key, newVal);
      } else {
        query.delete(key);
      }
      history.push({ search: query.toString() });
    };
    return [queryParam, updateValue];
  }

  const [search, setSearch] = useQueryParam('search', '');
  const [sizePerPage, setSizePerPage] = useState(25);
  const [page, setPage] = useQueryParam('page', 1);
  const [sortField, setSortField] = useQueryParam('sortField', 'startDate');
  const [sortOrder, setSortOrder] = useQueryParam('sortOrder', -1);
  const [startDate, setStartDate] = useQueryParam('startDate', undefined);
  const [endDate, setEndDate] = useQueryParam('endDate', undefined);

  let fetchingParams = {
    limit: sizePerPage,
    skip: sizePerPage * (page - 1),
    sortBy: sortField ? sortField : 'startDate',
    sortOrder: sortOrder ? sortOrder : -1,
  };

  if (filter) {
    fetchingParams = { ...fetchingParams, filter };
  }

  if (startDate) {
    fetchingParams = { ...fetchingParams, startDate };
  }

  if (endDate) {
    fetchingParams = { ...fetchingParams, endDate };
  }

  if (instrumentName) {
    fetchingParams = { ...fetchingParams, instrumentName };
  }

  if (search) {
    fetchingParams = { ...fetchingParams, search };
  }

  const data = useResource(InvestigationResource.listShape(), fetchingParams);
  const totalSize = data && data.length > 0 ? data[0].meta?.page?.total : 0;

  const user = useSelector((state) => state.user);
  const { isAdministrator } = user;

  function handleStartDateChange(date) {
    setStartDate(moment(date).format('YYYY-MM-DD'));
    setPage(1);
  }

  function handleEndDateChange(date) {
    setEndDate(moment(date).format('YYYY-MM-DD'));
    setPage(1);
  }

  function handleStartDateClear() {
    setStartDate(undefined);
    setPage(1);
  }

  function handleEndDateClear() {
    setEndDate(undefined);
    setPage(1);
  }

  function handleTableChange(page, sizePerPage, sortField, sortOrder) {
    setPage(parseInt(page || 1));
    setSizePerPage(parseInt(sizePerPage || 25));
    setSortField(sortField);
    const order = sortOrder ? (sortOrder === 'asc' ? 1 : -1) : undefined;
    setSortOrder(order);
  }

  function onSearch(s) {
    setSearch(s);
    setPage(1);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <InvestigationDateFilter
          rootClassName={styles.filter}
          startValue={startDate}
          onStartDayChange={handleStartDateChange}
          onStartClear={handleStartDateClear}
          startPlaceHolder={'Filter between dates'}
          endValue={endDate}
          onEndDayChange={handleEndDateChange}
          onEndClear={handleEndDateClear}
          endPlaceHolder={'Filter between dates'}
          showStatisticsMenu={showStatisticsMenu}
          showLogbookMenu={showLogbookMenu}
          instrumentName={instrumentName}
          withJupyter={withJupyter}
        />
      </div>
      <ResponsiveTable
        remote={true}
        data={data}
        defaultSearchText={search}
        pageOptions={{
          page: parseInt(page),
          sizePerPage,
          totalSize,
          showTotal: true,
          sizePerPageList: [
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '100', value: 100 },
          ],
        }}
        columns={getColumns({
          showProposalLinks: withProposalLinks || isAdministrator,
          showInvestigationStats: withInvestigationStats || isAdministrator,
          showFiles: isAdministrator,
          withUserPortalLink:
            withUserPortalLink ||
            (UI.userPortal.isLinkEnabled && isAdministrator),
          proposalColWidth:
            Math.max(...data.map((item) => item.name.length)) *
              INVESTIGATION_NAME_MARGIN +
            INVESTIGATION_NAME_ICON_WIDTH,
        })}
        handleTableChange={handleTableChange}
        onSearch={onSearch}
      />
    </>
  );
}

export default InvestigationTable;
