import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function DatasetRestoreModal(props) {
  const { show, onClose, onSubmit } = props;
  const user = useSelector((state) => state.user);
  const isAnonymous = user.isAnonymous;
  const [email, setEmail] = useState();
  const handleRestore = () => {
    if (isAnonymous && !email) {
      return;
    }
    onSubmit(user.name, email);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Request to restore dataset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The dataset restoration can take time. An email will be sent once the
          dataset is restored.
        </p>
        {isAnonymous && (
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
              hasValidation
            >
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="name@example.com"
                autoFocus
                defaultValue={email}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRestore}>
          Restore
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
