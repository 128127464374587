import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Card, Tab, Tabs, Accordion } from 'react-bootstrap';
import TECHNIQUES from '../../../../config/techniques';
import { FILE_COUNT } from '../../../../constants/parameterTypes';
import { getDatasetParameterByPrefixName } from '../../../../helpers';
import DatasetFileTree from '../../../File/DatasetFileTree';
import InstrumentWidget from '../../../Instrument/InstrumentWidget';
import DatasetFooter from './Footer';
import DatasetMetadataTab from './DatasetMetadataTab';
import Summary from './Summary';
import TechniqueTab from './TechniqueTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const getTabCountBadge = (count) => {
  return <Badge style={{ backgroundColor: '#bfbfbf' }}>{count}</Badge>;
};
const getFilesTabTitle = (dataset) => {
  const fileCount = dataset.parameters.find((o) => o.name === FILE_COUNT);
  if (fileCount && fileCount.value) {
    return <div>Files {getTabCountBadge(fileCount.value)}</div>;
  }
  return 'Files';
};

function GenericDatasetWidget(props) {
  const { dataset } = props;

  const user = useSelector((state) => state.user);
  const { sessionId } = user;
  return (
    <Card>
      <Card.Body>
        <Tabs id="tabs">
          <Tab eventKey={1} title="Summary">
            <Summary dataset={dataset} sessionId={sessionId} />
          </Tab>
          {TECHNIQUES.map((technique, i) => {
            const params = dataset.parameters.filter((parameter) =>
              parameter.name.startsWith(technique.shortname)
            );
            if (params.length > 0) {
              return (
                <Tab
                  key={technique.shortname}
                  eventKey={i + 2}
                  title={technique.name}
                >
                  <TechniqueTab dataset={dataset} params={params} />
                </Tab>
              );
            }
            return null;
          })}
          {getDatasetParameterByPrefixName(dataset, 'Instrument').length >
            0 && (
            <Tab eventKey={9} title="Instrument">
              <InstrumentWidget dataset={dataset} />
            </Tab>
          )}

          <Tab eventKey={11} title={getFilesTabTitle(dataset)} mountOnEnter>
            <DatasetFileTree dataset={dataset} sessionId={sessionId}>
              {' '}
              &gt;
            </DatasetFileTree>
          </Tab>
          <Tab eventKey={10} title="Metadata List" className="pull-right">
            <DatasetMetadataTab dataset={dataset} />
          </Tab>
        </Tabs>
        <Accordion>
          {dataset.outputDatasets &&
            dataset.outputDatasets
              .sort((a, b) => a.id - b.id)
              .map((ds, i) => (
                <Accordion.Item eventKey={i}>
                  <Accordion.Header bg="info">
                    <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />{' '}
                    {ds.name}{' '}
                    <Badge bg={'success'} style={{ marginLeft: 10 }}>
                      Processed
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Body>
                    <GenericDatasetWidget sessionId={sessionId} dataset={ds} />
                  </Accordion.Body>
                </Accordion.Item>
              ))}
        </Accordion>
      </Card.Body>
      <Card.Footer>
        <DatasetFooter
          location={dataset.location}
          sessionId={sessionId}
          dataset={dataset}
        />
      </Card.Footer>
    </Card>
  );
}

export default GenericDatasetWidget;
