import React from 'react';
import UnitText from '../UnitText';
import { dateFormatter } from '../../../Investigation/utils';
import { DATASET_TIME_FORMAT } from '../../../../constants/index';
import { getDatasetParameterValueByName } from '../../../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import DatasetDownloadButton from '../../DatasetDownloadButton';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AlignmentBioSaxsDatasetWidget(props) {
  const { dataset } = props;

  const datasetName = getDatasetParameterValueByName(dataset, 'datasetName');

  return (
    <>
      <tr>
        <td style={{ borderRight: '1px solid gray', borderTop: '0px' }}>
          {dateFormatter(dataset.startDate, DATASET_TIME_FORMAT, false)}
        </td>
        <td>
          <>
            <LinkContainer
              style={{ padding: 0 }}
              to={`/dataset/${dataset.id}?view=datasets`}
            >
              <Nav.Link>{datasetName} </Nav.Link>
            </LinkContainer>

            {dataset.sampleName}
            <br />
            <>
              {getDatasetParameterValueByName(
                dataset,
                'SAXS_exposure_temperature'
              )}
              <UnitText
                dataset={dataset}
                parameterName={'SAXS_exposure_temperature'}
              ></UnitText>
            </>
          </>
        </td>

        <td>
          {getDatasetParameterValueByName(dataset, 'InstrumentSource_mode')}
        </td>
        <td>
          {getDatasetParameterValueByName(dataset, 'InstrumentSource_current')}
        </td>

        <td>
          <DatasetDownloadButton
            id={dataset.id}
            dataset={dataset}
          ></DatasetDownloadButton>
        </td>
      </tr>
      <tr style={{ backgroundColor: '#F5F5F5' }}>
        <td
          style={{
            borderRight: '0px solid gray',
            borderTop: '0px',
            backgroundColor: 'white',
          }}
        ></td>
        <td
          colSpan="100%"
          style={{
            textAlign: 'right',
          }}
        >
          <LinkContainer to={`/dataset/${dataset.id}?view=files`}>
            <Nav.Link>
              <FontAwesomeIcon icon={faFolder} style={{ marginRight: 10 }} />
              {dataset.location}
            </Nav.Link>
          </LinkContainer>
        </td>
      </tr>
    </>
  );
}

export default AlignmentBioSaxsDatasetWidget;
