import React from 'react';
import { dateFormatter } from '../../Investigation/utils';
import { DATASET_TIME_FORMAT } from '../../../constants/index';
import {
  getDatasetParameterValueByName,
  stringifyBytesSize,
} from '../../../helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function StartTimeDatasetPanel(props) {
  const { dataset } = props;

  const startDate = dateFormatter(
    getDatasetParameterValueByName(dataset, 'startDate'),
    DATASET_TIME_FORMAT
  );
  const registrationTime = dateFormatter(
    dataset.startDate,
    DATASET_TIME_FORMAT
  );

  const volume = stringifyBytesSize(
    getDatasetParameterValueByName(dataset, '__volume')
  );

  const calendarHoverFocus = (
    <Popover>
      <Popover.Header as="h3">Dataset Information</Popover.Header>
      <Popover.Body>
        <div>
          <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
          Collected at: {startDate}
        </div>
        <div>
          <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
          Registered at: {registrationTime}
        </div>
        <div>Raw Volume: {volume}</div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={calendarHoverFocus}
    >
      <span className={'text-info'} style={{ cursor: 'pointer' }}>
        {startDate}
      </span>
    </OverlayTrigger>
  );
}
export default StartTimeDatasetPanel;
