// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loader_loader__TLVE4 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 auto;\n  padding: 6rem 1.5rem;\n  background-color: #f6f6f6;\n  text-align: center;\n}\n\n.Loader_loader__TLVE4[data-in-panel] {\n  margin: -1.5rem;\n}\n\n.Loader_loader__TLVE4:not([data-in-panel]) {\n  max-width: 35rem;\n  border: 1px #ddd solid;\n  border-radius: 3px;\n}\n\n.Loader_loader__TLVE4[data-spaced-out] {\n  margin-top: 3rem;\n  margin-bottom: 3rem;\n}\n\n.Loader_spinner__BUZSx {\n  width: 4rem;\n  height: 4rem;\n  margin: 0 auto;\n}\n\n.Loader_text__1SjYW {\n  margin: 1rem 0 0;\n  color: #333;\n  font-style: italic;\n}\n", ""]);
// Exports
exports.locals = {
	"loader": "Loader_loader__TLVE4",
	"spinner": "Loader_spinner__BUZSx",
	"text": "Loader_text__1SjYW"
};
module.exports = exports;
