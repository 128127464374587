/**
 * This method returns an well-formed URL with the URL send as url params and the query parameters contained in the props.
 * The null values of the props will be ignored and not added to the query params
 * @param {*} props
 */
export function getURLParamsByDictionary(props) {
  const urlParams = new URLSearchParams();
  if (!props) props = {};
  Object.keys(props).forEach((key) => {
    if (props[key]) urlParams.set(key, props[key]);
  });
  return urlParams;
}
