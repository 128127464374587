import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import LoadingBoundary from '../../components/LoadingBoundary';
import { CLOSED_DATA_PATH } from '../../constants/routePaths';
import { usePageTracking } from '../../helpers/hooks';
import EmbargoedInvestigationsTable from './EmbargoedInvestigationsTable';

function ClosedDataPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'Closed Data', link: CLOSED_DATA_PATH }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary inPanel message="Loading investigations...">
        <EmbargoedInvestigationsTable />
      </LoadingBoundary>
    </div>
  );
}

export default ClosedDataPage;
