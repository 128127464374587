/** List of actions sorted chronologically */
export const STATUS = Object.freeze({
  CREATED: 'CREATED',
  SCHEDULED: 'SCHEDULED',
  READY: 'READY',
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
  INPUT: 'INPUT',
  SENT: 'SENT',
  STORES: 'STORES',
  BEAMLINE: 'BEAMLINE',
  BACK_STORES: 'BACK_STORES',
  BACK_USER: 'BACK_USER',
  DESTROYED: 'DESTROYED',
});

export const STATUS_DEFS = {
  [STATUS.CREATED]: {
    desc: 'Parcel has been created',
    label: 'CREATED',
    icon: 'faExternalLinkAlt',
    editable: true,
    inactiveParcel: false,
    sendable: false,
    allowedActions: () => [STATUS.SCHEDULED],
  },
  [STATUS.SCHEDULED]: {
    desc: 'Parcel has been associated with an experimental session',
    label: 'SCHEDULED',
    icon: 'faCalendar',
    editable: true,
    inactiveParcel: false,
    sendable: false,
    action: {
      label: 'SCHEDULED',
      desc: 'Associate the parcel with an experimental session',
    },
    allowedActions: (parcel) => (parcel.items.length > 0 ? [STATUS.READY] : []),
  },
  [STATUS.READY]: {
    desc: 'Samples have been declared and waiting for safety validation',
    label: 'READY FOR SAFETY CHECK',
    icon: 'faCheck',
    editable: false,
    inactiveParcel: false,
    sendable: false,
    action: {
      label: 'REQUEST SAFETY CHECK',
      desc: 'Safety approval must be requested for the parcel to be sent.',
      requiresInput: true,
    },
    allowedActions: () => [STATUS.APPROVED, STATUS.REFUSED, STATUS.INPUT],
  },
  [STATUS.APPROVED]: {
    desc: 'Approved by safety group',
    label: 'APPROVED',
    icon: 'faCheckCircle',
    editable: false,
    inactiveParcel: false,
    sendable: true,
    action: {
      label: 'APPROVE',
      desc: 'Approve the parcel',
      safety: true,
      requiresInput: true,
    },
    allowedActions: () => [STATUS.SENT],
  },
  [STATUS.REFUSED]: {
    desc: 'Refused by safety group',
    label: 'REFUSED',
    icon: 'faBan',
    editable: true,
    inactiveParcel: false,
    sendable: false,
    action: {
      label: 'REFUSE',
      desc: 'Refuse the parcel',
      safety: true,
      requiresInput: true,
    },
    allowedActions: (parcel) => (parcel.items.length > 0 ? [STATUS.READY] : []),
  },
  [STATUS.INPUT]: {
    desc: 'Safety group needs more information',
    label: 'NEEDS MORE INFO',
    icon: 'faCommentAlt',
    editable: true,
    inactiveParcel: false,
    sendable: false,
    action: {
      label: 'ASK FOR INFORMATION',
      desc: 'Ask user for more information',
      safety: true,
      requiresInput: true,
    },
    allowedActions: (parcel) => (parcel.items.length > 0 ? [STATUS.READY] : []),
  },
  [STATUS.SENT]: {
    desc: 'Sent to facility',
    label: 'SENT',
    icon: 'faEnvelope',
    editable: false,
    inactiveParcel: false,
    sendable: true,
    action: {
      label: 'Mark as SENT',
      desc: 'Notify that the parcel has been shipped to the facility',
    },
    allowedActions: () => [STATUS.STORES],
  },
  [STATUS.STORES]: {
    desc: 'Arrived at facility stores',
    label: 'STORES',
    icon: 'faHome',
    editable: false,
    inactiveParcel: false,
    sendable: true,
    action: {
      label: 'Mark as RECEIVED IN STORES',
      desc: 'Notify that the parcel has arrived at the stores',
      safety: true,
    },
    allowedActions: () => [STATUS.BEAMLINE],
  },
  [STATUS.BEAMLINE]: {
    desc: 'Arrived at the beamline',
    label: 'BEAMLINE',
    icon: 'faThumbsUp',
    editable: false,
    inactiveParcel: false,
    sendable: true,
    action: {
      label: 'Mark as RECEIVED AT BEAMLINE',
      desc: 'Notify that the parcel has arrived at the beamline',
    },
    allowedActions: (parcel) => {
      return parcel.returnAddress
        ? [STATUS.BACK_STORES]
        : [STATUS.BACK_STORES, STATUS.DESTROYED];
    },
  },
  [STATUS.BACK_STORES]: {
    desc: 'Back to facility stores',
    label: 'BACK IN STORES',
    icon: 'faHome',
    editable: false,
    inactiveParcel: true,
    sendable: true,
    action: {
      label: 'Mark as RETURNED TO STORES',
      desc: 'Notify that the parcel has been returned to the stores',
      safety: true,
    },
    allowedActions: (parcel) => [
      parcel.returnAddress ? STATUS.BACK_USER : STATUS.DESTROYED,
    ],
  },
  [STATUS.BACK_USER]: {
    desc: 'Sent back to return address',
    label: 'SENT BACK TO USER',
    icon: 'faUser',
    editable: false,
    inactiveParcel: true,
    sendable: true,
    action: {
      label: 'Mark as SENT BACK TO THE USER',
      desc: 'Notify that the parcel has been shipped back to the user',
    },
    allowedActions: () => [],
  },
  [STATUS.DESTROYED]: {
    desc: 'Parcel destroyed',
    label: 'DESTROYED',
    icon: 'faTimesCircle',
    editable: false,
    inactiveParcel: true,
    sendable: true,
    action: {
      label: 'Mark as DESTROYED',
      desc: 'Notify that the parcel has been destroyed',
      safety: true,
    },
    allowedActions: () => [],
  },
};
