import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import BasicPanel from './BasicPanel';
import StatusButton from './StatusButton';
import { STATUS } from '../../constants/parcelStatuses';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AdminPanel(props) {
  const { currentStatus, updateStatus } = props;
  const [isProcessing, setProcessing] = useState(false);

  return (
    <BasicPanel title="Admin section" variant="danger">
      <Container fluid>
        <Row>
          <Col sm={2}>
            <b>Change the status to: </b>
          </Col>
          <Col sm={10}>
            <StatusButton
              disabled={currentStatus === STATUS.SCHEDULED}
              onClick={async () => {
                setProcessing(true);
                await updateStatus(STATUS.SCHEDULED, {
                  comments: 'Set by admin',
                });
                setProcessing(false);
              }}
              status="SCHEDULED"
              variant="danger"
              isProcessing={isProcessing}
            />
          </Col>
        </Row>
        <Row>
          <Alert variant="light">
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />{' '}
            This will set the parcel back in editable mode but will require
            safety validation once again.
            <hr />
            <p className="mb-0">
              To be used when the user has made mistakes during the parcel
              preparation and cannot correct them.
            </p>
          </Alert>
        </Row>
      </Container>
    </BasicPanel>
  );
}

export default AdminPanel;
