import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  Container,
  Modal,
  Card,
  Row,
} from 'react-bootstrap';
import TextFieldGroup from '../../Form/TextFieldGroup';
import { useForm, FormProvider } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

function TagFormModal(props) {
  const { tag, instrumentName, investigationId } = props;
  const { onSubmitAsync, onCloseModal } = props;

  const methods = useForm({ defaultValues: tag });
  const { handleSubmit, register, formState } = methods;

  const { isSubmitting } = formState;
  const [submitError, setSubmitError] = useState();

  async function handleFormSubmit(values) {
    try {
      setSubmitError(undefined);
      await onSubmitAsync(values);
    } catch (error) {
      setSubmitError(
        `An error occurred while ${
          tag ? 'saving' : 'creating'
        } the address: ${error}`
      );
    }
  }

  const getvariantByTagType = (tag) => {
    if (!tag && investigationId) return 'success';
    if (!tag && instrumentName) return 'warning';
    if (tag.investigationId) return 'success';
    if (tag.instrumentName) return 'warning';
    return 'danger';
  };
  return (
    <Modal
      show
      backdrop="static"
      size="lg"
      aria-labelledby="address-modal-title"
      onHide={onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="address-modal-title">
          {tag ? 'Edit' : 'Create'} tag
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {submitError && <Alert variant="danger">{submitError}</Alert>}

        <Form>
          <FormProvider {...methods}>
            {tag && <input name="_id" type="hidden" ref={register} />}

            <Card variant={getvariantByTagType(tag)}>
              <Card.Header>
                <FontAwesomeIcon icon={faTag} style={{ marginRight: 8 }} />
                Tag
              </Card.Header>
              <Card.Body>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="name"
                        label="Label"
                        type="text"
                        //initialValue={tag ? tag.name : ''}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </FormProvider>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="link" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={isSubmitting}
          onClick={handleSubmit(handleFormSubmit)}
        >
          {`${tag ? 'Sav' : 'Creat'}${isSubmitting ? 'ing...' : 'e'}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TagFormModal;
