import React from 'react';
import { Alert, Card, Tab, Tabs } from 'react-bootstrap';
import ICAT from '../../config/icat';
import SSO from './SSO';
import LoginForm from './LoginForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function LoginTabs() {
  return (
    <Tabs id="login-tabs">
      {ICAT.authentication.sso.enabled && (
        <Tab eventKey="sso" title="SSO">
          <Card className="tab-panel-fix">
            <SSO />
          </Card>
        </Tab>
      )}
      {ICAT.authentication.authenticators
        .filter((a) => a.enabled)
        .map((authenticator) => (
          <Tab
            key={authenticator.plugin}
            eventKey={authenticator.plugin}
            title={authenticator.title}
          >
            {authenticator.message && (
              <Alert variant="info">
                {' '}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ marginRight: 10 }}
                />
                {authenticator.message}
              </Alert>
            )}

            <LoginForm plugin={authenticator.plugin} />
          </Tab>
        ))}
    </Tabs>
  );
}

export default LoginTabs;
