import React from 'react';
import InvestigationTable from '../../components/Investigation/InvestigationTable';
import { useParams } from 'react-router';
import UI from '../../config/ui';

function BeamlineDataTable(props) {
  const { name } = useParams();
  const { showStatisticsMenu = false, showLogbookMenu = false } = props;

  return (
    <InvestigationTable
      withInvestigationStats
      withProposalLinks
      showStatisticsMenu={showStatisticsMenu}
      showLogbookMenu={showLogbookMenu}
      instrumentName={name}
      withUserPortalLink={true}
      withJupyter={UI.analysis.jupyter.isLinkEnabled}
    />
  );
}

export default BeamlineDataTable;
