import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useResource } from 'rest-hooks';
import UI from '../../config/ui';
import DmpQuestionnaireResource from '../../resources/questionnaires';

export function DMPButton(props) {
  const { investigationId, investigationName, token } = props;

  DmpQuestionnaireResource.dmpToken = token;
  const questionnaires = useResource(DmpQuestionnaireResource.listShape(), {
    investigationName,
    investigationId,
  });

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip-dmp" {...props}>
      Open the corresponding data management plan
    </Tooltip>
  );

  if (questionnaires.length === 0) {
    return <></>;
  }

  const questionnaire = questionnaires[0];

  return (
    <>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <Button
          variant="outline-primary"
          href={`${UI.dmp.server}/projects/${questionnaire.uuid}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faListCheck} style={{ marginRight: 5 }} />
          Open DMP
        </Button>
      </OverlayTrigger>
    </>
  );
}
