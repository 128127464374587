import React, { useEffect, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import axios from 'axios';
import { ExperimentalReportButton } from './ExperimentalReportButton';
import { getFieldFromSubjects } from './utilsDoiExtractor';
import { getURLParamsByDictionary } from '../../helpers/url';
import UI from '../../config/ui';

export function ESRFExperimentalReportWidget(props) {
  const { subjects } = props;
  const [state, setState] = useState({
    loading: true,
    experimentalReports: [],
    error: false,
  });

  /**
   * Extracts the proposalCode and the proposalNumber of a specific proposal name (separated by a dash)
   * @param {*} proposalName
   * @returns {proposalCode, proposalNumber}
   */
  function extractProposalCodeAndNumber(proposalName) {
    let aroundHyphenRegExp;
    try {
      // extract the proposal LetterCode (before the hyphen) and the digits (after the hyphen)
      aroundHyphenRegExp = new RegExp(/^(.*)-(.*)/);
      return {
        proposalCode: aroundHyphenRegExp.exec(proposalName)[1],
        proposalNumber: aroundHyphenRegExp.exec(proposalName)[2],
      };
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      /** It is most likely that symbol '-' is not present in the proposal name then we use /\d+$/ */
      aroundHyphenRegExp = new RegExp(/\d+$/);
      /** This will get all numbers at the end of the string
       * Example: input=mx3030 then output[0]=3030
       */

      try {
        return {
          proposalCode: proposalName.substring(
            0,
            proposalName.length -
              aroundHyphenRegExp.exec(proposalName)[0].length
          ),
          proposalNumber: aroundHyphenRegExp.exec(proposalName)[0],
        };
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }

  const proposalNames = getFieldFromSubjects(subjects, 'Proposal');
  const { proposalCode, proposalNumber } = extractProposalCodeAndNumber(
    proposalNames
  );

  /**
   * Returns the URL to retrieve the experimental report from the User Portal
   * @param {*} proposalCode
   * @param {*} proposalNumber
   * @returns
   */
  function getExperimentalReportURL(proposalCode, proposalNumber) {
    const params = getURLParamsByDictionary({
      categoryCode: proposalCode,
      categoryCounter: proposalNumber,
    });
    return `${UI.doi.experimentalReport.server}?${params.toString()}`;
  }

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(getExperimentalReportURL(proposalCode, proposalNumber))
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            experimentalReports: response.data,
          }));
        })
        .catch((error) => {
          console.log(error);
          setState((prevState) => ({
            ...prevState,
            error: true,
          }));
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    };
    getData();
  }, [proposalCode, proposalNumber]);

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <Card.Header>Experimental Report</Card.Header>
        <Card.Body>
          {state.loading && <Card.Text>Loading...</Card.Text>}
          {!state.loading && state.experimentalReports.length === 0 && (
            <Card.Text>There is currently no experimental report.</Card.Text>
          )}
          {!state.loading && state.experimentalReports.length === 1 && (
            <>
              <Card.Text>
                <b>One</b> report has been found for this proposal.{' '}
              </Card.Text>
              <ExperimentalReportButton
                reportName={state.experimentalReports[0]}
              />
            </>
          )}
          {!state.loading && state.experimentalReports.length > 1 && (
            <>
              <Card.Text>
                In total, <b>{state.experimentalReports.length}</b> reports have
                been found for this proposal.{' '}
              </Card.Text>
              <Stack className="col-md-2" gap={3}>
                {state.experimentalReports.map((report, idR) => (
                  <ExperimentalReportButton
                    key={`report-${idR}`}
                    reportName={report}
                  />
                ))}
              </Stack>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
