import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import MyParcelsSummary from '../components/Parcels/MyParcelsSummary';
import LoadingBoundary from '../components/LoadingBoundary';
import { usePageTracking } from '../helpers/hooks';

function MyParcelsPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'My Parcels' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading parcels...">
        <MyParcelsSummary />
      </LoadingBoundary>
    </div>
  );
}

export default MyParcelsPage;
