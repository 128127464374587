import React, { useState } from 'react';
import { useResource } from 'rest-hooks';
import UserResource from '../../resources/user';
import { Typeahead } from 'react-bootstrap-typeahead';

export default function UserComboBoxList(props) {
  const { onUserSelected, multiple, investigationId } = props;

  const [state] = useState({});
  const { usersSelected } = state;

  /** Get all users and orders by fullName */
  const users = useResource(UserResource.listShape(), {
    investigationId,
  })
    .filter((u) => u.fullName !== '')
    .sort((a, b) => (a.fullName < b.fullName ? -1 : 1));

  const _renderMenuItemChildren = (option) => [
    <p key={option.name}>{option.fullName}</p>,
  ];

  const handleChange = (value) => {
    onUserSelected(value.usersSelected);
    return true;
  };
  return (
    <>
      <Typeahead
        id="typeAheadUsers"
        labelKey="fullName"
        renderMenuItemChildren={_renderMenuItemChildren}
        multiple={multiple}
        onChange={(usersSelected) => {
          handleChange({ usersSelected });
        }}
        selected={usersSelected}
        options={users}
        placeholder="Invite a user..."
      />
    </>
  );
}
