// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelTable_wrapper__2O4gn {\n  position: relative;\n}\n\n@media (min-width: 50em) {\n  .ParcelTable_filters__2o6xr {\n    display: flex;\n    align-items: center;\n    position: absolute;\n    left: 0;\n    top: 0;\n  }\n}\n\n@media (max-width: 50em) {\n  .ParcelTable_actionLabel__2A-l2 {\n    display: none;\n  }\n}\n\n.ParcelTable_filter__3Q-1n {\n  display: flex;\n}\n\n.ParcelTable_clearButton__3ql-D {\n  color: inherit;\n  padding-left: 6px;\n}\n\n.ParcelTable_clearButton__3ql-D:hover {\n  color: darkslategray;\n}\n\n.ParcelTable_clickable__3zhUT {\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "ParcelTable_wrapper__2O4gn",
	"filters": "ParcelTable_filters__2o6xr",
	"actionLabel": "ParcelTable_actionLabel__2A-l2",
	"filter": "ParcelTable_filter__3Q-1n",
	"clearButton": "ParcelTable_clearButton__3ql-D",
	"clickable": "ParcelTable_clickable__3zhUT"
};
module.exports = exports;
