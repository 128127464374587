import { faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Card, Row, Alert } from 'react-bootstrap';
import AddressPanel from '../Address/AddressPanel';

function ShipmentSummary(props) {
  const { shipment, defaultShippingAddress, defaultReturnAddress } = props;

  return (
    <Row>
      <Col md={4}>
        {defaultShippingAddress !== undefined ? (
          <AddressPanel
            header={<b>Default sender's address</b>}
            address={defaultShippingAddress}
          />
        ) : (
          <Alert variant="danger">
            Default sender's address no longer exists.
          </Alert>
        )}
      </Col>
      <Col md={4}>
        {defaultReturnAddress !== undefined ? (
          <AddressPanel
            header={<b>Default return address</b>}
            address={defaultReturnAddress}
          />
        ) : (
          <Alert variant="danger">
            Default return address no longer exists.
          </Alert>
        )}
      </Col>
      <Col md={4}>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon={faCommentAlt} style={{ marginRight: 5 }} />
            Comments
          </Card.Header>
          <Card.Body>{shipment.comments || 'No comments provided'}</Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ShipmentSummary;
