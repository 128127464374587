import React from 'react';
import { useResource } from 'rest-hooks';
import { Card } from 'react-bootstrap';
import CountlogbookStatisticsResource from '../../../../resources/countLogbookStatistics';
import PlotWidget from '../../../../containers/Stats/PlotWidget';

function LogbookStatisticsEvents(props) {
  const { startDate, endDate, type, footer } = props;
  /** Window with to resize the plots */
  const { innerWidth: width } = window;
  const statisticsCount = useResource(
    CountlogbookStatisticsResource.listShape(),
    {
      startDate,
      endDate,
      type,
    }
  );

  const trace = {
    x: statisticsCount.map((s) => s._id),
    y: statisticsCount.map((s) => s.count),
    type: 'bar',
  };

  return (
    <div className="app__inner">
      <Card style={{ margin: 10 }} variant="primary">
        <Card.Header>
          <Card.Title>{type} created by date</Card.Title>
        </Card.Header>
        <Card.Body>
          <PlotWidget
            data={[trace]}
            layout={{ width: width * 0.8, height: 350 }}
          ></PlotWidget>
        </Card.Body>
        <Card.Footer>{footer}</Card.Footer>
      </Card>
    </div>
  );
}

export default LogbookStatisticsEvents;
