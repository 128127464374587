/* Constants mapping  the corresponding concepts as they are stored in the DB */
export const ANNOTATION = 'annotation';
export const NOTIFICATION = 'notification';
export const BROADCAST = 'broadcast';

export const EVENT_CATEGORY_COMMENT = 'comment';
export const EVENT_CATEGORY_COMMANDLINE = 'commandline';
export const EVENT_CATEGORY_INFO = 'info';
export const EVENT_CATEGORY_DEBUG = 'debug';
export const EVENT_CATEGORY_ERROR = 'error';

export const NEW_EVENT_VISIBLE = 'newEventExpanded';
export const NEW_EVENT_INVISIBLE = 'newEventCollapsed';

export const NEW_EVENT_CONTEXT = 'newEventContext';
export const EDIT_EVENT_CONTEXT = 'editEventContext';

export const ORIGINAL_EVENT_VERSION = 'eventHistoryOriginalVersionContext';
export const LATEST_EVENT_VERSION = 'eventHistoryLatestVersionContext';
export const MIDDLE_EVENT_VERSION =
  'eventHistoryNotOriginalAndNotLatestVersionContext';

export const LOGBOOK_CONTAINER_CONTEXT = 'logbookContainerContext';
export const LOGBOOK_CONTEXT_NAME_PROPOSAL = 'logbookContextNameProposal';

export const LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT =
  'localstorageKeyNewEventContentInPlaintextFormat';
export const LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT =
  'localstorageKeyNewEventContentInHtmlFormat';
export const LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT =
  'localstorageKeyEditedEventContentInPlaintextFormat';
export const LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT =
  'localstorageKeyEditedEventContentInHtmlFormat';

export const PLAINTEXT_CONTENT_FORMAT = 'plainText';
export const HTML_CONTENT_FORMAT = 'html';
