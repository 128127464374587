import React, { useRef, useState, useEffect } from 'react';
import { useResource } from 'rest-hooks';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '../../../helpers/hooks';
import { getStatisticsByParcels, getStatuses } from './ParcelStatsUtils';
import { Card } from 'react-bootstrap';
import ParcelResource from '../../../resources/parcel';
import ResponsiveTable from '../../../components/Table/ResponsiveTable';
import moment from 'moment';
import SampleTrackingStatsDateFilter from './SampleTrackingStatsDateFilter';
import ParcelStatsColumns from './ParcelStatsColumns';
import SummaryParcelStats from './SummaryParcelStats';
import { INVESTIGATION_DATE_FORMAT } from '../../../constants';
import { enumerateDaysBetweenDates } from '../../../helpers/dateUtils';
import PlotWidget from '../PlotWidget';

const beamlineTest = 'ID00';

function ParcelStats() {
  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const { state } = location;
  const latestQuery = useRef(); // work around stale query reference when changing search term
  latestQuery.current = query;

  const [start, setStart] = useState(
    query.get('start')
      ? moment(query.get('start'), INVESTIGATION_DATE_FORMAT)
      : moment(new Date()).subtract(1, 'month')
  );
  const [end, setEnd] = useState(moment(new Date()));

  useEffect(() => {
    if (state && state.scrollPosition) {
      window.scrollTo(...state.scrollPosition);
    }
  });

  function handleDateChange(date, queryParam) {
    const newQuery = new URLSearchParams(query, queryParam);
    date
      ? newQuery.set(queryParam, moment(date).format(INVESTIGATION_DATE_FORMAT))
      : newQuery.delete(queryParam);
    history.replace(`${location.pathname}?${newQuery.toString()}`);
  }

  const parcels = useResource(ParcelResource.listShape(), {}).filter(
    (parcel) => {
      return (
        parcel.investigation &&
        parcel.investigation.instrument &&
        parcel.investigation.instrument.name.toUpperCase() !== beamlineTest &&
        parcel.investigation.startDate &&
        parcel.investigation.endDate &&
        moment(parcel.investigation.startDate) >= moment(start) &&
        moment(parcel.investigation.endDate) <= moment(end)
      );
    }
  );

  const stats = getStatisticsByParcels(parcels);

  const { dates, statuses } = getStatuses(
    parcels,
    enumerateDaysBetweenDates(start, end)
  );

  function getXYStatsByStatus(status) {
    return dates.map((date, index) => {
      return statuses[index][status] ? statuses[index][status] : 0;
    });
  }

  return (
    <>
      <h3>Parcel Statistics</h3>
      <SampleTrackingStatsDateFilter
        start={start}
        setStart={setStart}
        end={end}
        setEnd={setEnd}
        onStartDayChange={(date) => handleDateChange(date, 'start')}
        onEndDayChange={(date) => handleDateChange(date, 'end')}
      ></SampleTrackingStatsDateFilter>
      <br />
      <Card border="primary">
        <Card.Header>
          <Card.Title>Summary</Card.Title>
        </Card.Header>
        <Card.Body>
          <SummaryParcelStats
            parcels={parcels}
            beamlineTest={beamlineTest}
            stats={stats}
          ></SummaryParcelStats>
        </Card.Body>
      </Card>

      <Card border="primary" style={{ marginTop: 10 }}>
        <Card.Header>
          <Card.Title>Status of the parcels</Card.Title>
        </Card.Header>
        <Card.Body>
          <PlotWidget
            data={[
              'READY',
              'APPROVED',
              'SENT',
              'BEAMLINE',
              'BACK_STORES',
              'BACK_USER',
              'DESTROYED',
            ].map((status) => {
              return {
                name: status,
                type: 'scatter',
                x: dates,
                y: getXYStatsByStatus(status),
              };
            })}
            layout={{
              autosize: true,
              width: window.innerWidth - window.innerWidth * 0.2,
              title: 'Statuses',
              xaxis: { title: 'Dates' },
              yaxis: { title: 'Parcels' },
              showlegend: true,
              legend: {
                y: 80,
                orientation: 'h',
              },
              yaxis2: {
                title: 'Items',
                overlaying: 'y',
                side: 'right',
                showgrid: false,
              },
            }}
          />
        </Card.Body>
      </Card>
      <Card border="primary" style={{ marginTop: 10 }}>
        <Card.Header>
          <Card.Title>Sessions Summary</Card.Title>
        </Card.Header>
        <Card.Body>
          {' '}
          <ResponsiveTable
            data={stats.sessions}
            columns={ParcelStatsColumns}
            pageOptions={{
              sizePerPageList: [
                { text: '25', value: 25 },
                { text: '50', value: 50 },
                { text: '100', value: 100 },
                { text: '500', value: 500 },
              ],
            }}
          />
        </Card.Body>
      </Card>
    </>
  );
}

export default ParcelStats;
