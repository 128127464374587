import React from 'react';
import { orcidFormatter } from '../Investigation/utils';
import { Card, Stack } from 'react-bootstrap';

export function UsersWidget(props) {
  const { users, title } = props;

  function getOrcidIdentifier(nameIdentifiers) {
    if (nameIdentifiers) {
      const orcidObject = nameIdentifiers.find((item) => {
        return item.nameIdentifierScheme.toLowerCase() === 'orcid';
      });
      if (orcidObject) {
        return orcidFormatter(orcidObject.nameIdentifier, false);
      }
    }
    return '';
  }

  const nbUsers = users.length;

  return (
    <>
      <Card style={{ borderStyle: 'none' }}>
        <Card.Body style={{ padding: 0 }}>
          <Stack direction="horizontal" gap={2}>
            {title && <p className="text-primary">{title}: </p>}
            <p>
              {users.map(
                ({ name, givenName, familyName, nameIdentifiers }, idU) => {
                  const orcidIdentifier = getOrcidIdentifier(
                    nameIdentifiers,
                    false
                  );
                  const userName = givenName
                    ? `${givenName} ${familyName}`
                    : name;
                  const separator = idU === nbUsers - 1 ? '' : '; ';
                  return (
                    <>
                      {orcidIdentifier}
                      <span class="text-muted">{userName}</span>
                      {separator}
                    </>
                  );
                }
              )}
            </p>
          </Stack>
        </Card.Body>
      </Card>
    </>
  );
}
