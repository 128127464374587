import { SET_DATASETS_COUNT } from '../constants/actionTypes';

/**
 * Set the totol number of datasets
 * @param {*} datasetsCount datasetsCount value
 */
export function setDatasetsCount(datasetsCount) {
  return {
    type: SET_DATASETS_COUNT,
    datasetsCount,
  };
}
