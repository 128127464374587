import { Resource } from 'rest-hooks';
import {
  getCreateSampleParameterURL,
  getSampleParameterURL,
} from '../api/icat-plus/catalogue';
import { store } from '../store';

export default class SampleParameterResource extends Resource {
  id = undefined;
  value = undefined;

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'SampleParameterResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId, parameterId } = params;
    return getSampleParameterURL(sessionId, investigationId, parameterId);
  }

  static url(params) {
    return this.listUrl(params);
  }

  static sampleUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId, sampleId } = params;
    return getCreateSampleParameterURL(sessionId, investigationId, sampleId);
  }

  static createShape() {
    return {
      ...super.createShape(),
      getFetchKey: (params) => `POST ${this.sampleUrl(params)}`,
      fetch: (parameter) =>
        this.fetch('post', this.sampleUrl(parameter), parameter),
    };
  }

  static updateShape() {
    return {
      ...super.updateShape(),
      fetch: (parameter) => this.fetch('put', this.url(parameter), parameter),
    };
  }
}
