import React from 'react';
import UI from '../../config/ui';
import Loader from '../Loader';
import { ErrorUserMessage } from '../UserMessages/ErrorUserMessage';
import AnonymousSignIn from './AnonymousSignIn';
import keycloak from '../../keycloak';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Alert } from 'react-bootstrap';
import { trackKeycloakSignIn } from '../../helpers/trackData';
import ICAT from '../../config/icat';
import { doSignIn, doLogOut } from '../../actions/login';

function SSO() {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();

  if (keycloak) {
    keycloak.onAuthSuccess = () => {
      dispatch(
        doSignIn(ICAT.authentication.sso.plugin, null, keycloak.idToken)
      );
    };

    keycloak.onAuthLogout = () => {
      if (ICAT.authentication.sso.handleSSOlogout) {
        dispatch(doLogOut(user.sessionId));
      }
    };

    keycloak.onTokenExpired = () => {
      // When SSO access token expires, try to refresh it
      // https://www.keycloak.org/docs/latest/securing_apps/#callback-events

      if (ICAT.authentication.sso.refreshToken) {
        console.log('update token');
        keycloak.updateToken().catch(() => {
          // If session has fully expired or an error occurred, clear expired token completely
          keycloak.clearToken(); // this invokes `onAuthLogout`
        });
      }
    };
  }

  function handleClick() {
    const fromLocation = location.state?.from;
    trackKeycloakSignIn();
    if (fromLocation) {
      const { pathname, search, hash } = fromLocation;
      const redirectPath = `${pathname}${search}${hash}`;
      const redirectUri = `${window.location.origin}/login${
        redirectPath === '/' ? '' : `?to=${encodeURIComponent(redirectPath)}`
      }`;

      keycloak.login({ redirectUri });
    } else {
      keycloak.login();
    }
  }

  if (user.isAuthenticating) {
    return (
      <div style={{ margin: '1.5rem' }}>
        <Loader message="Authenticating..." inPanel />
      </div>
    );
  }

  return (
    <div style={{ padding: '30px 15px', textAlign: 'center' }}>
      {user.error && <ErrorUserMessage text={user.error} />}
      {user.isSessionExpired && (
        <Alert variant="warning">Session expired</Alert>
      )}

      <Button type="submit" variant="primary" size="lg" onClick={handleClick}>
        {UI.loginForm.ssoBtnLabel}
      </Button>

      <AnonymousSignIn />
    </div>
  );
}

export default SSO;
