import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { STATUS_DEFS } from '../../constants/parcelStatuses';

function StatusTooltipOverlay(props) {
  const { status, placement = 'top', children } = props;

  return (
    <OverlayTrigger
      trigger={['hover']}
      placement={placement}
      overlay={
        <Popover
          id="popover-trigger-hover-focus"
          title={STATUS_DEFS[status].action.label}
        >
          {STATUS_DEFS[status].action.desc}
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  );
}

export default StatusTooltipOverlay;
