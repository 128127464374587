import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import LoadingBoundary from '../../components/LoadingBoundary';
import { usePageTracking } from '../../helpers/hooks';
import InvestigationsCalendarTimeLine from './InvestigationsCalendarTimeLine';

function CalendarPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'Calendar' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <p>
        <span style={{ backgroundColor: 'red', color: 'black' }}>
          Session not scheduled
        </span>
        <span
          style={{
            backgroundColor: 'lightBlue',
            color: '#337ab7',
            marginLeft: 5,
          }}
        >
          Session contains data
        </span>
        <span
          style={{
            backgroundColor: 'white',
            color: '#337ab7',
            marginLeft: 5,
          }}
        >
          Empty session
        </span>
      </p>
      <LoadingBoundary inPanel message="Loading investigations...">
        <InvestigationsCalendarTimeLine></InvestigationsCalendarTimeLine>
      </LoadingBoundary>
    </div>
  );
}

export default CalendarPage;
