import { faTag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

/** React component which renders a tag label */
function TagLabel(props) {
  const { tag, showDeleteButton, onDeleteTagClicked } = props;
  return (
    <div style={{ display: 'inline-block', marginRight: 4 }}>
      <div
        style={{
          fontSize: '75%',
          backgroundColor: tag.color ? tag.color : '#777',
          display: 'inline',
          whiteSpace: 'nowrap',
          color: '#fff',
          borderTopRightRadius: '0.25em',
          lineHeight: 1,
          fontWeight: 700,
          textAlign: 'center',
          padding: '0.2em 0.6em 0.3em 0.6em',
        }}
      >
        {' '}
        <FontAwesomeIcon icon={faTag} />
        {tag.name.toUpperCase()}
      </div>

      {showDeleteButton && (
        <div
          style={{
            backgroundColor: '#777',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 0,
            display: 'inline-block',
            marginBottom: 2,
            paddingLeft: 0,
            paddingRight: 1,
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            size="sm"
            style={{
              backgroundColor: '#777',
              color: 'white',
              verticalAlign: 'bottom',
            }}
            onClick={() => onDeleteTagClicked(tag)}
          />
        </div>
      )}
    </div>
  );
}

TagLabel.propTypes = {
  /* Callback function used to remove a tag from the selection. Used in EVENT context only */
  onDeleteTagClicked: PropTypes.func,
  /* Whether the delete button is displayed or not */
  showDeleteButton: PropTypes.bool,
  /* the tag to render */
  tag: PropTypes.object.isRequired,
};

TagLabel.defaultProps = {
  showDeleteButton: false,
};

export default TagLabel;
