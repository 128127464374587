import React from 'react';
import Pager from '../Pager';

function InlineDatasetTable(props) {
  const { pageOptions, onHandleTableChange, children } = props;
  const { page, sizePerPage, totalSize } = pageOptions;

  return (
    <>
      <Pager
        eventCount={totalSize}
        activePage={page}
        pageSize={sizePerPage}
        onClick={onHandleTableChange}
      ></Pager>
      {children}
      <Pager
        eventCount={totalSize}
        activePage={page}
        pageSize={sizePerPage}
        onClick={onHandleTableChange}
      ></Pager>
    </>
  );
}

export default InlineDatasetTable;
