import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Toast,
} from 'react-bootstrap';
import UI from '../../config/ui';

export function LandingPageWelcomeWidget() {
  const [searchedDOI, setSearchedDOI] = useState('');
  const [error, setError] = useState('');

  const landingPage = UI.landingPage;

  if (landingPage.length === 0) {
    return <></>;
  }

  function searchDOI(doiPrefix, errorMessage) {
    if (!searchedDOI || !searchedDOI.startsWith(doiPrefix)) {
      setError(errorMessage);
      return;
    }
    window.open(`/doi/${searchedDOI}`, '_blank', 'noopener,noreferrer');
    setSearchedDOI('');
  }

  return (
    <Container fluid>
      <Row style={{ textAlign: 'center' }}>
        <Col>
          <h1 className="text-primary">ESRF DOI Portal</h1>
        </Col>
      </Row>
      <Row>
        {landingPage.map(({ title, texts, footer, search }, idC) => (
          <Col xs={12} md={4} key={`col-${idC}`}>
            <Card key={`card-${idC}`}>
              <Card.Header style={{ textAlign: 'center' }}>
                <h3 className="text-primary">{title}</h3>
              </Card.Header>
              <Card.Body>
                {texts.map(({ text }, idT) => (
                  <Card.Text key={`text-${idT}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    />
                  </Card.Text>
                ))}
                {footer && (
                  <Card.Footer>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: footer,
                      }}
                    />
                  </Card.Footer>
                )}
                {search && (
                  <Card.Footer>
                    <Stack gap={2}>
                      <Stack direction="horizontal">
                        <Form.Control
                          type="text"
                          value={searchedDOI}
                          onChange={(e) => setSearchedDOI(e.target.value)}
                          placeholder={search.placeHolder}
                        />

                        <Button
                          variant="primary"
                          onClick={() => searchDOI(UI.doi.prefix, search.error)}
                        >
                          Search
                        </Button>
                      </Stack>

                      {error && (
                        <Toast
                          onClose={() => setError('')}
                          tdelay={3000}
                          autohide
                          bg="danger"
                        >
                          <Toast.Header>
                            <strong className="me-auto">Search DOI</strong>
                          </Toast.Header>
                          <Toast.Body>{error}</Toast.Body>
                        </Toast>
                      )}
                    </Stack>
                  </Card.Footer>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
