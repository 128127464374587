// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* It is the border top outside the page: icon, date, tags and edit button **/\n.EventList_borderTopSeparatorBetweenEvents__1mPxz {\n  border: 0;\n  border-top: 0px solid #c6c6c6 !important;\n}\n\n/* It is the border top within the page **/\n.EventList_borderTopSeparatorBetweenEventsInline__3M4Gu {\n  border: 0;\n  border-top: 0px dotted #e8e8e8 !important;\n}\n\n.EventList_editButton__nINV_ {\n  width: 25px;\n  position: 'static';\n  padding: 0;\n}\n\n.EventList_link__3agX9 {\n  font-weight: bold;\n  text-decoration: underline;\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"borderTopSeparatorBetweenEvents": "EventList_borderTopSeparatorBetweenEvents__1mPxz",
	"borderTopSeparatorBetweenEventsInline": "EventList_borderTopSeparatorBetweenEventsInline__3M4Gu",
	"editButton": "EventList_editButton__nINV_",
	"link": "EventList_link__3agX9"
};
module.exports = exports;
