import ICATPLUS from '../../config/icatPlus';
import { getURLParamsByDictionary } from '../../helpers/url';
/**
 * Get URL used to update an event on a given investigation on ICAT+
 * @param {String} sessionId the session identifier
 * @param {String} investigationId the investigation indentifier
 * @return {String} the URL to get the requested events
 */
export function getEventURL(
  sessionId,
  investigationId,
  skip,
  limit,
  sortOrder,
  sortBy,
  types,
  format,
  search,
  instrumentName,
  filterInvestigation,
  date
) {
  const params = getURLParamsByDictionary({
    investigationId,
    limit,
    sortBy,
    sortOrder,
    types,
    skip,
    format,
    search,
    instrumentName,
    date,
    filterInvestigation,
  });
  return `${ICATPLUS.server}/logbook/${sessionId}/event?${params.toString()}`;
}

export function getLogbookStatisticsURL(sessionId, startDate, endDate) {
  const params = new URLSearchParams();
  params.set('startDate', startDate);
  params.set('endDate', endDate);
  return `${
    ICATPLUS.server
  }/logbook/${sessionId}/stats/investigation?${params.toString()}`;
}

export function getCountLogbookStatisticsURL(
  sessionId,
  startDate,
  endDate,
  type
) {
  const params = new URLSearchParams();
  params.set('startDate', startDate);
  params.set('endDate', endDate);
  params.set('type', type);
  return `${
    ICATPLUS.server
  }/logbook/${sessionId}/stats/count?${params.toString()}`;
}

/** Get the tags associated to a given investigation
 * @param {string} sessionId session identifier
 * @param {*} investigationId investigation identifier
 */
export function getTagURL(sessionId, investigationId, instrumentName, id) {
  const params = getURLParamsByDictionary({
    investigationId,
    instrumentName,
    id,
  });

  return `${ICATPLUS.server}/logbook/${sessionId}/tag?${params.toString()}`;
}

export function createEventFromBase64(
  sessionId,
  investigationId,
  instrumentName
) {
  const params = new URLSearchParams();

  investigationId
    ? params.set('investigationId', investigationId)
    : params.set('instrumentName', instrumentName);

  return `${
    ICATPLUS.server
  }/logbook/${sessionId}/event/createfrombase64?${params.toString()}`;
}

/** Get the statistics of the logbook usage
 * @param {string} sessionId session identifier
 * @param {string} startDate the startDate of the periodr
 * @param {string} endDate endDate of the period
 */
export function getStats(sessionId, startDate, endDate) {
  return `${ICATPLUS.server}/logbook/${sessionId}/stats?startDate=${startDate}&endDate=${endDate}`;
}

export function getEventPage(
  sessionId,
  _id,
  investigationId,
  pageSize,
  sortOrder,
  types,
  instrumentName
) {
  const params = new URLSearchParams();
  params.set('limit', pageSize);
  if (investigationId) {
    params.set('investigationId', investigationId);
  }
  params.set('_id', _id);
  params.set('sortOrder', sortOrder);
  params.set('types', types);
  params.set('instrumentName', instrumentName);

  return `${
    ICATPLUS.server
  }/logbook/${sessionId}/event/page?${params.toString()}`;
}

/**
 * Get URL used to retrieve the number of events in a logbook
 * @param {String} sessionId the session identifier
 * @param {String} investigationId the investigation indentifier
 * @param {String} types the types/category filter
 * @param {String} search the search query
 * @param {String} instrumentName the  instrumentName
 * @param {String} filterInvestigation boolean to filter on investigation events (beamline and esrf logbook)
 * @param {String} date the date filter
 * @return {String} the URL to get the requested events
 */
export function getCountEventsURL(
  sessionId,
  investigationId,
  types,
  search,
  instrumentName,
  filterInvestigation,
  date
) {
  const params = getURLParamsByDictionary({
    investigationId,
    types,
    search,
    instrumentName,
    date,
    filterInvestigation,
  });
  //params.set('filterInvestigation', filterInvestigation);
  return `${
    ICATPLUS.server
  }/logbook/${sessionId}/event/count?${params.toString()}`;
}
