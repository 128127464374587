import React from 'react';
import { Badge, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import UI from '../../config/ui';
import BreadCrumbs from '../Breadcrumbs/BreadCrumbs';
import ManagerMenu from './ManagerMenu';
import BeamlinesMenu from './BeamlinesMenu';
import keycloak from '../../keycloak';
import { useSelector, useDispatch } from 'react-redux';
import { doLogOut } from '../../actions/login';
import styles from './Menu.module.css';
import LoadingBoundary from '../LoadingBoundary';
import AffiliationMenu from './AffiliationMenu';
import {
  faComment,
  faSearch,
  faSignOutAlt,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';

function Menu() {
  const user = useSelector((state) => state.user);
  const { sessionId, fullName, isSSO, isAnonymous } = user;

  const selectedDatasets = useSelector((state) => state.selectedDatasets);
  const breadcrumbsList = useSelector((state) => state.breadcrumbsList);

  const dispatch = useDispatch();

  function handleLogout(sessionId) {
    if (keycloak && isSSO) {
      keycloak.logout();
    }
    dispatch(doLogOut(sessionId));
  }

  const getFeedbackBody = () => {
    const defaultBody = `Hi,

       << Please provide your feedback here. >>
       << To report an issue, please include screenshots, reproduction steps, proposal number, beamline, etc. >>
       << To suggest a new feature, please describe the needs this feature would fulfill. >>

       Here is some additional context:

          - User agent: ${navigator.userAgent}
          - Location: ${window.location.href}

       Thanks,
       << Name >>`;
    return UI.feedback.body ? UI.feedback.body : defaultBody;
  };

  const getFeedbackURL = () => {
    return `mailto:${UI.feedback.email}?subject=${
      UI.feedback.subject
    }&body=${encodeURIComponent(getFeedbackBody())}`;
  };

  if (!sessionId) {
    return (
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="primary"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand className={styles.logo} href="/">
            {UI.menu.applicationTitle}
          </Navbar.Brand>
        </Container>
      </Navbar>
    );
  }

  return (
    <div>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="primary"
        variant="dark"
      >
        <Container fluid>
          <Navbar.Brand className={styles.logo} href="/welcome">
            {UI.menu.applicationTitle}
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="me-auto">
              {UI.menu.isSearchVisible && (
                <LinkContainer to="/search">
                  <Nav.Link>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ fontSize: 20, verticalAlign: 'top' }}
                    />
                  </Nav.Link>
                </LinkContainer>
              )}

              <LinkContainer
                to="/investigations"
                isActive={(match, location) =>
                  match || /^\/(|home)$/.test(location.pathname)
                }
              >
                <Nav.Link eventKey="my">My Data</Nav.Link>
              </LinkContainer>

              {UI.menu.isMySelectionVisible && selectedDatasets.length > 0 && (
                <LinkContainer to="/selection">
                  <Nav.Link>
                    My Selection
                    <Badge pill style={{ marginLeft: 10 }} bg="secondary">
                      {selectedDatasets.length}
                    </Badge>
                  </Nav.Link>
                </LinkContainer>
              )}

              {UI.menu.isOpenDataVisible && (
                <LinkContainer to="/public">
                  <Nav.Link>Open Data</Nav.Link>
                </LinkContainer>
              )}

              {UI.menu.isClosedDataVisible && (
                <LinkContainer to="/closed">
                  <Nav.Link>Closed Data</Nav.Link>
                </LinkContainer>
              )}

              {UI.sampleTracking.enabled && !isAnonymous && (
                <NavDropdown title="Shipping" id="basic-nav-dropdown">
                  <LinkContainer to="/parcels">
                    <NavDropdown.Item>My Parcels</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/addresses">
                    <NavDropdown.Item>My Addresses</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

              <LoadingBoundary
                withSilentError
                customLoader={
                  <Nav.Link className={styles.managerMenuLoader}>
                    <FontAwesomeIcon icon={faSyncAlt} spin />
                  </Nav.Link>
                }
              >
                <BeamlinesMenu />
              </LoadingBoundary>

              <LoadingBoundary
                withSilentError
                customLoader={
                  <Nav.Item className={styles.managerMenuLoader}>
                    <FontAwesomeIcon icon={faSyncAlt} spin />
                  </Nav.Item>
                }
              >
                <ManagerMenu />
              </LoadingBoundary>
            </Nav>

            <Nav>
              <Nav.Link href={getFeedbackURL()}>
                <FontAwesomeIcon icon={faComment} style={{ marginRight: 4 }} />
                Feedback
              </Nav.Link>
              <AffiliationMenu></AffiliationMenu>
              <Nav.Link onClick={() => handleLogout(sessionId)}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ marginRight: 4 }}
                />
                Log out <strong>{fullName}</strong>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <BreadCrumbs breadcrumbs={breadcrumbsList.items} />
    </div>
  );
}

export default Menu;
