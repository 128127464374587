import { parse } from 'date-fns';

const DATASET_TABLE_WIDGET_FACTORY = [
  {
    instrumentName: 'BM29',
    startDate: parse('25-09-2022', 'dd-MM-yyyy', new Date()),
    endDate: '',
    widget: 'BioSaxsInlineDatasetTable',
  },
  {
    instrumentName: 'ID00',
    startDate: parse('02-11-2022', 'dd-MM-yyyy', new Date()),
    endDate: parse('14-11-2022', 'dd-MM-yyyy', new Date()),
    widget: 'GenericDatasetTable',
  },
  {
    instrumentName: 'ID31',
    startDate: parse('15-11-2022', 'dd-MM-yyyy', new Date()),
    endDate: parse('16-11-2022', 'dd-MM-yyyy', new Date()),
    widget: 'GenericDatasetTable',
  },
];

export default DATASET_TABLE_WIDGET_FACTORY;
