import React from 'react';
import { Button, Card } from 'react-bootstrap';
import {
  ES_RESOURCE_TYPE,
  OPEN_ACCESS,
  RESTRICTED_ACCESS,
} from '../../constants/doi';

export function ExperimentalDataWidget(props) {
  const { doi, accessibilityStatus, publicationYear, resourceType } = props;

  function getInvestigationId(doi) {
    const regExp = RegExp(/\/\w*-*[Ee][Ss][Rr][Ff]-[Ee][Ss]-(\d+)$/);
    if (regExp.exec(doi)) {
      return regExp.exec(doi)[1];
    }
    return null;
  }
  const investigationId = getInvestigationId(doi);

  const accessDataButtonText =
    accessibilityStatus === RESTRICTED_ACCESS
      ? 'Access data for experimental team'
      : 'Access data';

  const dataUrl =
    resourceType === ES_RESOURCE_TYPE
      ? `/investigation/${investigationId}/datasets`
      : `/public/${doi}`;

  return (
    <Card style={{ marginBottom: 12 }}>
      <Card.Header>Experimental Data</Card.Header>
      <Card.Body>
        {accessibilityStatus === OPEN_ACCESS && (
          <Card.Text>
            The data can be accessed by clicking on the link below
          </Card.Text>
        )}
        {accessibilityStatus === RESTRICTED_ACCESS && (
          <Card.Text>
            The data are under embargo until <b>{publicationYear}</b> but could
            be released earlier. Currently, they are only accessible to proposal
            team members.
          </Card.Text>
        )}
        <Button variant="primary" href={dataUrl} target="_blank">
          {accessDataButtonText}{' '}
        </Button>
      </Card.Body>
    </Card>
  );
}
