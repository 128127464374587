import React, { useEffect, useState } from 'react';
import PlotWidget from '../../../../containers/Stats/PlotWidget';
import Loader from '../../../Loader';

function LineBeamlinePlot(props) {
  const { response, metric, parse } = props;

  const { innerWidth: width } = window;
  const [loading, setLoading] = useState(true);
  const [plot, setPlot] = useState(true);

  useEffect(() => {
    if (response[0].data) {
      const { data, dates, instruments } = parse(response[0].data, metric);
      let transposed = data[0].map((_, colIndex) =>
        data.map((row) => row[colIndex])
      );

      transposed = transposed.map((i, index) => {
        return {
          name: instruments[index],
          x: dates,
          y: i,
          mode: 'lines+markers',
          connectgaps: false,
          fill: 'tozeroy',
        };
      });

      setPlot(transposed);
    }
    setLoading(false);
  }, [metric, parse, response]);

  if (!plot || !plot[0]) return null;

  if (loading) {
    return <Loader message="Rendering plots..."></Loader>;
  }
  return (
    <PlotWidget
      data={plot}
      layout={{
        width: width - 0.1 * width,
        height: plot[0].y.length * 30 > 300 ? plot[0].y.length * 30 : 300,
        xaxis: {
          ticks: '',
          side: 'top',
        },
      }}
      responsive={true}
    ></PlotWidget>
  );
}

export default LineBeamlinePlot;
