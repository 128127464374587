import React from 'react';
import ResponsiveTable from '../../../Table/ResponsiveTable';

function TechniqueTab(props) {
  const { params } = props;

  return (
    <div style={{ padding: 10 }}>
      <ResponsiveTable
        keyField="name"
        data={params
          .slice(0)
          .sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          )}
        columns={[
          { dataField: 'name', text: 'Name', headerStyle: { width: '30%' } },
          { dataField: 'value', text: 'Value' },
        ]}
        pageOptions={{
          paginationSize: 10,
          sizePerPage: 20,
          showTotal: true,
          hidePageListOnlyOnePage: true,
        }}
      />
    </div>
  );
}

export default TechniqueTab;
