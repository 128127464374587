import { Resource } from 'rest-hooks';
import { store } from '../store';
import ICATPLUS from '../config/icatPlus';
import { getURLParamsByDictionary } from '../helpers/url';

export default class EventDatesResource extends Resource {
  _id = undefined;

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'EventDatesResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const {
      investigationId,
      instrumentName,
      types,
      search,
      filterInvestigation,
    } = params;
    const urlParams = getURLParamsByDictionary({
      investigationId,
      instrumentName,
      types,
      search,
      filterInvestigation,
    });
    return `${
      ICATPLUS.server
    }/logbook/${sessionId}/event/dates?${urlParams.toString()}`;
  }

  static url(params) {
    return this.listUrl(params);
  }
}
