import ICATPLUS from '../../config/icatPlus';
import { getURLParamsByDictionary } from '../../helpers/url';
import UI from '../../config/ui';

/**
 * Get the URL used to mint a DOI
 * @param {*} sessionId session identifier
 * @return {string} URL used to mint a DOI
 */
export function getMintDOI(sessionId) {
  return `${ICATPLUS.server}/doi/${sessionId}/mint`;
}

export function getDatasetByDOI(
  sessionId,
  doi,
  skip,
  limit,
  sortOrder,
  sortBy,
  search
) {
  const params = getURLParamsByDictionary({
    limit,
    sortBy,
    sortOrder,
    skip,
    search,
  });
  return `${
    ICATPLUS.server
  }/doi/${doi}/datasets?sessionId=${sessionId}?${params.toString()}`;
}

export function getDoiDataURL(doi) {
  return `${ICATPLUS.server}/doi/${doi}/json-datacite`;
}

export function getDoiDataDataCiteURL(doi) {
  return `${UI.doi.server}/${doi}`;
}

export function getDoiCitationURL(doi) {
  const params = getURLParamsByDictionary({
    doi,
  });
  return encodeURI(`${UI.doi.formatCitation.server}&${params.toString()}`);
}

export function getOpenCitationURL(doi) {
  return encodeURI(`${UI.doi.openCitation.server}/${doi}`);
}
