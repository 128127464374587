import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import { useParams } from 'react-router';
import LoadingBoundary from '../../components/LoadingBoundary';
import BeamlineDataTable from './BeamlineDataTable';
import { BEAMLINE_PATH } from '../../constants/routePaths';
import { usePageTracking } from '../../helpers/hooks';

function BeamlineDataPage() {
  usePageTracking();
  const { name } = useParams();

  const beamline = name.toUpperCase();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: `${beamline} Data`, link: `${BEAMLINE_PATH}${beamline}` },
      ])
    );
  }, [beamline, dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading investigations..." inPanel>
        <BeamlineDataTable showStatisticsMenu={true} showLogbookMenu={true} />
      </LoadingBoundary>
    </div>
  );
}

export default BeamlineDataPage;
