import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { EVENT_CATEGORY_COMMANDLINE } from '../../../constants/eventTypes';
import { getOriginalEvent } from '../../../helpers/eventHelpers';
import { makeFullScreen } from '../../../helpers';
import Event from './Event';
import UserMessage from '../../UserMessage';

// Expose on window to use as `onClick` handler on images
window.makeFullScreen = makeFullScreen;

function collapse(items) {
  const collapsed = [];
  for (let i = 0; i < items.length; i++) {
    const event = items[i];

    if (event.category && !isEventSearchedResult(event)) {
      if (
        event.category.toLowerCase() === EVENT_CATEGORY_COMMANDLINE &&
        !event.previousVersionEvent
      ) {
        const lastEvent = collapsed[collapsed.length - 1];
        if (lastEvent && lastEvent.category) {
          if (
            lastEvent.category.toLowerCase() === EVENT_CATEGORY_COMMANDLINE &&
            !isEventSearchedResult(lastEvent)
          ) {
            if (!lastEvent.events) {
              lastEvent.events = [event];
            } else {
              lastEvent.events.push(event);
            }
            continue;
          }
        } else {
          lastEvent.events = [event];
        }
      }
    }
    collapsed.push(event);
  }
  return collapsed;
}

function isEventSearchedResult(event) {
  const { hash } = window.location;
  const id = hash ? hash.replace('#', '') : undefined;
  return id && id === event._id;
}

/** Returns the list of items to be displayed in the table: events + days */
function getItems(events, automaticCollapsing) {
  const eventsCopy = cloneDeep(events);
  const items = [];
  let lastDate = null; // format DDMMYYYY
  for (let i = 0; i < eventsCopy.length; i++) {
    const date = moment(getOriginalEvent(eventsCopy[i]).creationDate).format(
      'MMMM Do YYYY'
    );
    if (date !== lastDate) {
      lastDate = date;
      items.push({ text: date, type: 'date', anchor: date });
    }

    items.push(eventsCopy[i]);
  }
  /** Add shadow to events that are before a date */
  for (let i = 0; i < items.length; i++) {
    if (items[i].type === 'date') {
      if (items[i - 1]) {
        items[i - 1].shadowBottomBorder = true;
      }
    }
  }
  /** Add shadow to the last one */
  if (items.length > 0) {
    items[items.length - 1].shadowBottomBorder = true;
  }
  return automaticCollapsing ? collapse(items) : items;
}

/**
 * The list of the all events
 */
function EventList(props) {
  const {
    events,
    isReleased,
    search,
    automaticCollapsing,
    isBeamlineLogbook = false,
    pageSize,
    sortOrder,
    types,
    instrumentName,
    isGlobalLogbook,
  } = props;

  if (!events) {
    return null;
  }

  if (events.length === 0) {
    return <UserMessage type="info" message="No log found." />;
  }

  const collapseEvents =
    search && search.length > 0 ? false : automaticCollapsing;

  return (
    <>
      <Table responsive style={{ border: 0 }}>
        <tbody>
          {getItems(events, collapseEvents).map((event, index) => {
            if (event.type === 'date') {
              return (
                <tr
                  key={index}
                  style={{
                    backgroundColor: '#f0f0f6',
                    color: 'gray',
                    height: 50,
                  }}
                >
                  <td
                    id={event.anchor}
                    style={{
                      textAlign: 'center',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                    colSpan={6}
                  >
                    {event.text}
                    <br />
                  </td>
                </tr>
              );
            }

            return (
              <Event
                search={search}
                key={index}
                event={event}
                isReleased={isReleased}
                onEventClicked={props.onEventClicked}
                isBeamlineLogbook={isBeamlineLogbook}
                pageSize={pageSize}
                sortOrder={sortOrder}
                types={types}
                instrumentName={instrumentName}
                isGlobalLogbook={isGlobalLogbook}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

EventList.propTypes = {
  /** the array of unsorted events as provided by the ICAT+ server */
  events: PropTypes.array,
  /** the logbook context */
  logbookContext: PropTypes.object,
  /** Callback function triggered when the user clicks the edit icon */
  onEventCliked: PropTypes.func,
};

export default EventList;
