import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getHistogramStats } from '../api/icat-plus/elasticsearch';

export default class DataStatisticsCSVResource extends Resource {
  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'DataStatisticsCSVResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const {
      metric,
      interval,
      startDate,
      endDate,
      format,
      aggregation,
      instrumentName,
    } = params;
    return getHistogramStats(
      sessionId,
      metric,
      interval,
      startDate,
      endDate,
      format,
      aggregation,
      instrumentName
    );
  }

  static url(params) {
    return this.listUrl(params);
  }
}
