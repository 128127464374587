import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class DmpQuestionnaireResource extends Resource {
  uuid = undefined;

  pk() {
    return this.uuid?.toString();
  }

  static get key() {
    return 'DmpQuestionnaireResource';
  }

  static url(params) {
    return this.listUrl(params);
  }

  static listUrl(searchParams) {
    const { sessionId } = store.getState().user;
    const params = new URLSearchParams(searchParams);
    return `${
      ICATPLUS.server
    }/dmp/${sessionId}/questionnaires?${params.toString()}`;
  }
}
