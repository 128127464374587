import React from 'react';
import { Card, Alert } from 'react-bootstrap';
import { isGalleryImageViewer, isH5Viewer } from './helper';
import GalleryDatasetThumbnail from '../../components/Dataset/GalleryDatasetThumbnail';
import {
  RESOURCES_GALLERY_PARAMETER,
  RESOURCES_GALLERY_FILE_PATHS_PARAMETER,
} from '../../helpers';
import H5Viewer from '../../components/File/H5Viewer';

const NoViewer = () => {
  return <Alert variant="danger">No file or viewer available</Alert>;
};

const GalleryViewer = (props) => {
  const { dataset, datafile } = props;

  const galleryParameterPaths = dataset.parameters.find(
    (p) => p.name === RESOURCES_GALLERY_FILE_PATHS_PARAMETER
  );

  const galleryParameter = dataset.parameters.find(
    (p) => p.name === RESOURCES_GALLERY_PARAMETER
  );

  if (galleryParameterPaths) {
    const index = galleryParameterPaths.value
      .split(',')
      .indexOf(
        galleryParameterPaths.value
          .split(',')
          .find((name) => name.indexOf(datafile.name) !== -1)
      );
    const imageId = galleryParameter.value.split(' ')[index];
    return <GalleryDatasetThumbnail imageId={imageId} />;
  }
  return <NoViewer></NoViewer>;
};

function ViewerFactory(props) {
  const { datafile, sessionId, dataset } = props;

  let component = <Alert variant="warning">No file selected</Alert>;
  if (datafile) {
    if (isH5Viewer(datafile))
      component = (
        <H5Viewer
          datafile={datafile}
          sessionId={sessionId}
          iframeStyle={{
            width: '100%',
            height: '500px',
          }}
        />
      );
    if (isGalleryImageViewer(datafile)) {
      component = (
        <GalleryViewer dataset={dataset} datafile={datafile}></GalleryViewer>
      );
    }
  }

  return (
    <Card>
      <Card.Header>Viewer</Card.Header>
      <Card.Body>{component}</Card.Body>
    </Card>
  );
}

export default ViewerFactory;
