import { capitalize } from 'lodash-es';
import React, { Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import FieldAlert from './FieldAlert';
import { useFormContext } from 'react-hook-form';
import RequiredStar from '../RequiredStar';

function TextFieldGroup(props) {
  const { register, errors } = useFormContext();
  const {
    type,
    name,
    label,
    layout,
    registerOptions = {},
    ...controlProps
  } = props;
  const controlId = `form${name}`;

  const unpackedName = name.split('.');
  const error = unpackedName.reduce(
    (acc, key) => (acc ? acc[key] : undefined),
    errors
  );
  const fieldLabel = label || capitalize(unpackedName.pop());

  const Wrapper = layout ? Col : Fragment;
  const as = layout ? Row : Col;

  return (
    <Form.Group as={as} controlId={controlId}>
      <Wrapper {...(layout && layout.labelCol)}>
        <Form.Label>
          {fieldLabel}
          {registerOptions.required && <RequiredStar />}
        </Form.Label>
      </Wrapper>
      <Wrapper {...(layout && layout.fieldCol)}>
        <Form.Control
          type={type}
          name={name}
          as={type === 'textarea' ? 'textarea' : 'input'}
          ref={register(registerOptions)}
          {...controlProps}
        />
        {error && <FieldAlert fieldLabel={fieldLabel} error={error} />}
      </Wrapper>
    </Form.Group>
  );
}

export default TextFieldGroup;
