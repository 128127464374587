import React, { Suspense } from 'react';
import { Alert } from 'react-bootstrap';
import Loader from './Loader';
import { NetworkErrorBoundary } from 'rest-hooks';

function LoadingBoundary(props) {
  const {
    customLoader,
    withSilentError = false,
    children,
    ...loaderProps
  } = props;

  const loader =
    customLoader !== undefined ? customLoader : <Loader {...loaderProps} />;

  const errorFallback = withSilentError
    ? () => null
    : ({ error }) => (
        <Alert variant="warning" style={{ marginTop: 16 }}>
          An error occured: {error.message}
        </Alert>
      );

  return (
    <Suspense fallback={loader}>
      <NetworkErrorBoundary fallbackComponent={errorFallback}>
        {children}
      </NetworkErrorBoundary>
    </Suspense>
  );
}

export default LoadingBoundary;
