import { faUser, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useResource } from 'rest-hooks';
import { createInstrumentScientists } from '../../api/icat-plus/catalogue';
import { ErrorUserMessage } from '../UserMessages/ErrorUserMessage';
import { SuccessUserMessage } from '../UserMessages/SuccessUserMessage';
import UserResource from '../../resources/user';
import InstrumentResource from '../../resources/instrument';

export default function BeamlineManagerForm(props) {
  const { user, refetch } = props;

  const instruments = useResource(InstrumentResource.listShape(), {
    filter: 'instrumentscientist',
  });
  const instrumentsNames = instruments.map((i) => i.name);

  const [fetching, setFetching] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [instrumentsSelected, setInstrumentsSelected] = useState([]);
  const [message, setMessage] = useState({
    isError: false,
    text: null,
  });

  const _renderMenuItemChildren = (option) => [
    <span>{option.fullName}</span>,
    <br />,
  ];

  const renderBeamlineItem = (option) => [<span>{option}</span>, <br />];

  const users = useResource(UserResource.listShape(), {}).filter(
    (u) => !!u.fullName
  );

  const handleClick = async () => {
    setFetching(true);
    axios
      .put(createInstrumentScientists(user.sessionId), {
        usernames: usersSelected.map((u) => u.name),
        instrumentnames: instrumentsSelected,
      })
      .then((response) => {
        setFetching(false);
        setUsersSelected([]);
        setInstrumentsSelected([]);
        setMessage({
          isError: false,
          text:
            response.status === 200
              ? 'User(s) have been added correctly as beamline managers'
              : 'Oopps, something might be wrong',
        });
      })
      .catch((error) => {
        setFetching(false);
        setMessage({
          isError: true,
          text: error.message,
        });
      });
    await refetch();
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} md={3}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />
              Users
            </InputGroup.Text>
            <Typeahead
              id="typeAheadUsers"
              labelKey="fullName"
              renderMenuItemChildren={_renderMenuItemChildren}
              multiple
              onChange={(usersSelected) => {
                setUsersSelected(usersSelected);
              }}
              selected={usersSelected}
              options={users}
              placeholder="Choose a user..."
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={3}>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faVideo} style={{ marginRight: 10 }} />
              Beamlines
            </InputGroup.Text>
            <Typeahead
              id="typeAheadBeamlines"
              renderMenuItemChildren={renderBeamlineItem}
              multiple
              onChange={(instrumentsSelected) => {
                setInstrumentsSelected(instrumentsSelected);
              }}
              selected={instrumentsSelected}
              options={instrumentsNames}
              placeholder="Choose a beamline..."
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={1}>
          <Button
            variant="primary"
            type="submit"
            disabled={
              fetching ||
              usersSelected.length === 0 ||
              instrumentsSelected.length === 0
            }
            onClick={handleClick}
          >
            {fetching ? 'Adding' : 'Add'}
          </Button>
        </Col>
        <Col xs={12} md={5}>
          {message.text && message.isError && (
            <ErrorUserMessage text={message.text} />
          )}
          {message.text && !message.isError && (
            <SuccessUserMessage text={message.text} />
          )}
        </Col>
      </Row>
    </Container>
  );
}
