import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import { useResource } from 'rest-hooks';
import SampleResource from '../../resources/sample';

export default function SampleSelection(props) {
  const { investigationId, selectedSampleId, updateSelectedSample } = props;
  const samples = useResource(SampleResource.listShape(), { investigationId });
  const handleSelect = (e) => {
    updateSelectedSample(e);
  };
  const sampleName = selectedSampleId
    ? samples.find((s) => s.id === parseInt(selectedSampleId)).name
    : undefined;

  return (
    <Stack direction="horizontal" gap={1}>
      <DropdownButton
        style={{ marginLeft: '20px' }}
        id="dropdown--samples-button"
        variant="secondary"
        title={
          selectedSampleId ? (
            <span>Sample: {sampleName}</span>
          ) : (
            <span>Filter by samples</span>
          )
        }
        onSelect={handleSelect}
      >
        <Dropdown.Menu
          style={{
            overflowY: 'scroll',
            maxHeight: '150px',
          }}
        >
          {samples.map((sample) => (
            <Dropdown.Item
              eventKey={sample.id}
              key={sample.id}
              active={sample.id === parseInt(selectedSampleId)}
            >
              {sample.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </DropdownButton>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip-clear">Clear sample filter</Tooltip>
        }
      >
        <Button
          variant="outline-secondary"
          onClick={() => handleSelect(undefined)}
          disabled={!selectedSampleId}
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </OverlayTrigger>
    </Stack>
  );
}
