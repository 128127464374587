import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

/**
 * This class represents the event footer of an event panel.
 */
function EventFooter(props) {
  const {
    cancelButtonLabel,
    isSaveButtonEnabled,
    loading = false,
    onSaveButtonClicked,
    onSaveAndCloseButtonClicked,
    onCancelButtonClicked,
  } = props;

  return (
    <div style={{ position: 'relative', height: 37 }}>
      &nbsp;
      <div
        className="noUnderlinedLink"
        style={{ position: 'absolute', right: 10, top: 5 }}
      >
        <ButtonToolbar>
          {isSaveButtonEnabled && (
            <>
              <ButtonGroup style={{ height: 30 }}>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="tooltip">Save and close the event.</Tooltip>
                  }
                >
                  <Button
                    className="btn btn-primary save-close"
                    size="sm"
                    style={{ marginRight: 5 }}
                    disabled={loading}
                    onClick={() => onSaveAndCloseButtonClicked()}
                  >
                    Save and close
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
              <ButtonGroup style={{ height: 30 }}>
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="tooltip">Save the event.</Tooltip>}
                >
                  <Button
                    className="btn btn-primary"
                    size="sm"
                    style={{ marginRight: 5 }}
                    disabled={loading}
                    onClick={() => onSaveButtonClicked()}
                  >
                    Save
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </>
          )}
          <ButtonGroup>
            <Button
              className="btn btn-primary cancel"
              size="sm"
              onClick={(e) => onCancelButtonClicked(e)}
            >
              {' '}
              {cancelButtonLabel ? cancelButtonLabel : 'Cancel'}{' '}
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </div>
  );
}

EventFooter.PropType = {
  /** a custom text displayed in the button. 'Cancel' when not provided */
  cancelButtonLabel: PropTypes.string,
  /** the callback function executed when the cancel button is clicked */
  onCancelButtonClicked: PropTypes.func.isRequired,
  /** the callback function executed when the save button is clicked */
  onSaveButtonClicked: PropTypes.func.isRequired,
  /** the callback function executed when the save and close button is clicked */
  onSaveAndCloseButtonClicked: PropTypes.func.isRequired,
  /** whether the save button is enabled or not */
  isSaveButtonEnabled: PropTypes.bool,
};

EventFooter.defaultProps = {
  /** Default text displayed in the button */
  cancelButtonLabel: 'Cancel',
};

export default EventFooter;
