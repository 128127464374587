import React from 'react';
import { Carousel } from 'react-bootstrap';
import UI from '../../config/ui';

export default function WelcomeAnnoucements() {
  const annoucements = UI.welcomeAnnoucements;

  if (annoucements.length === 0) {
    return <></>;
  }

  return (
    <Carousel fade variant="dark">
      {annoucements.map(({ img, alt, captionTitle, captionText }, index) => (
        <Carousel.Item
          key={`announcement-${index}`}
          style={{ textAlign: 'center' }}
        >
          <img className="w-50" src={img} alt={alt} />
          <Carousel.Caption>
            <h3>{captionTitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: captionText,
              }}
            />
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
