import React from 'react';
import { Table } from 'react-bootstrap';
import ParameterRowWidget from './ParameterRowWidget';

function ParameterTableWidget(props) {
  const {
    names,
    values,
    parameters, //  {key, value, id, name, unit }

    header,
    striped = true,
    nameColumnWidth = '50%',
    editable = false,
    datasetId,
  } = props;

  const parameterNames = names?.trim().split(' ');
  const parameterValues = values?.trim().split(' ');

  const allParams = [
    ...(parameterNames && parameterValues
      ? parameterNames.map((name, i) => ({ name, value: parameterValues[i] }))
      : []),
    ...(parameters || []),
  ];

  return (
    <Table striped={striped} size="sm">
      {header && (
        <thead>
          <tr>
            <th colSpan="2">{header}</th>
          </tr>
        </thead>
      )}
      <tbody>
        {allParams.map(({ name, value, id, unit }) => {
          return (
            <ParameterRowWidget
              key={Math.random()}
              value={value}
              parameterId={id}
              name={name}
              unit={unit}
              nameColumnWidth={nameColumnWidth}
              editable={editable}
              datasetId={datasetId}
            ></ParameterRowWidget>
          );
        })}
      </tbody>
    </Table>
  );
}

export default ParameterTableWidget;
