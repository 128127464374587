import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import TabContainerMenu from '../components/TabContainerMenu/TabContainerMenu';
import { OPEN_DATA_PATH } from '../constants/routePaths';
import LoadingBoundary from '../components/LoadingBoundary';
import RemoteDatasetTable from '../components/DatasetTable/RemoteDatasetTable';
import { usePageTracking } from '../helpers/hooks';

function DOIPage() {
  usePageTracking();
  const { prefix, suffix } = useParams();
  const doi = `${prefix}/${suffix}`;

  const sessionId = useSelector((state) => state.user.sessionId);
  const dispatch = useDispatch();
  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '30', value: 30 },
    { text: '50', value: 50 },
  ];

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: 'Open Data', link: OPEN_DATA_PATH },
        { name: '', badges: [doi] },
      ])
    );
  }, [dispatch, doi, sessionId]);

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <TabContainerMenu doi={doi} />
          <LoadingBoundary inPanel message="Loading datasets...">
            <RemoteDatasetTable
              doi={doi}
              defaultSizePerPage={10}
              sizePerPageList={sizePerPageList}
            />
          </LoadingBoundary>
        </Col>
      </Row>
    </Container>
  );
}

export default DOIPage;
