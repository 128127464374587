import React from 'react';
import { Alert, Card, Container, Row, Col } from 'react-bootstrap';
import PlotWidget from '../../../../containers/Stats/PlotWidget';
import LogbookInvestigationStatisticsPanel from './LogbookInvestigationStatisticsPanel';
import AnnotationsStatisticsPanel from './AnnotationsStatisticsPanel';

const getPlot = (data, width) => {
  return (
    <PlotWidget
      data={data}
      layout={{
        titlefont: {
          size: 16,
        },
        width,
        height: 300,
        barmode: 'group',
        font: {
          family: 'Raleway, sans-serif',
          size: 12,
        },
        xaxis: {
          tickangle: -45,
        },
        yaxis: {
          title: 'Logbook Entries',
          tickangle: -45,
        },
      }}
      responsive={true}
    ></PlotWidget>
  );
};

function LogbookUsagePanel(props) {
  const { startDate, endDate, statistics } = props;

  /** Window with to resize the plots */
  const { innerWidth: width } = window;

  /** Gets an array with the counts */
  const annotationsListCount = statistics.map((record) =>
    record.annotations ? record.annotations : 0
  );
  const notificationsListCount = statistics.map((record) =>
    record.notifications ? record.notifications : 0
  );

  /** labels */
  const x = statistics.map((record) => `${record.name} ${record.instrument}`);
  const annotationTrace = {
    x,
    y: annotationsListCount,
    text: statistics.map((record) => `${record.instrument}`),
    textposition: 'auto',
    type: 'bar',
    name: 'Annotations',
  };

  const notificationsTrace = {
    x,
    y: notificationsListCount,
    type: 'bar',
    name: 'Notifications',
  };

  return (
    <div className="app__inner">
      <>
        <Alert variant="info">
          Logbook Usage for period <strong>{startDate}</strong> and{' '}
          <strong>{endDate}</strong>.<br /> Change url parameters, startDate and
          endDate to change manually the range of dates. Example:
          /manager/starts/logbook?startDate=2020-08-01&endDate=2020-09-01
        </Alert>
        <h1> </h1>
        <Card style={{ margin: 10 }} variant="primary">
          <Card.Header>
            <Card.Title>
              Number of entries in the logbook per investigation
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col xs={12} md={8}>
                  {getPlot(
                    [annotationTrace, notificationsTrace],
                    width * 0.6,
                    `Number of entries in the logbook per investigation.`
                  )}
                </Col>
                <Col xs={12} md={4}>
                  <LogbookInvestigationStatisticsPanel
                    statistics={statistics}
                    startDate={startDate}
                    endDate={endDate}
                  ></LogbookInvestigationStatisticsPanel>
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer>
            This plot shows the number of entries in the logbook for each
            investigation.It can give a hint about the verbosity (number of logs
            automatically sent) of each beamline.
          </Card.Footer>
        </Card>
        <Card style={{ margin: 10 }} variant="primary">
          <Card.Header>
            <Card.Title>
              Annotations (hand made entries) per investigation
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col xs={12} md={8}>
                  {getPlot([annotationTrace], width * 0.6)}
                </Col>
                <Col xs={12} md={4}>
                  <AnnotationsStatisticsPanel
                    statistics={statistics}
                    startDate={startDate}
                    endDate={endDate}
                  ></AnnotationsStatisticsPanel>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </>
      )
    </div>
  );
}

export default LogbookUsagePanel;
