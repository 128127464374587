// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Menu_menu__1wQ3F {\n  margin-bottom: 0;\n}\n\n.Menu_logo__2yFIt {\n  display: flex;\n  align-items: center;\n}\n\n.Menu_managerMenuLoader__muM4W {\n  pointer-events: none;\n}\n\n.Menu_managerMenu__1v26g > .dropdown-menu {\n  max-height: 40rem;\n  overflow: hidden scroll;\n  scrollbar-width: thin;\n}\n", ""]);
// Exports
exports.locals = {
	"menu": "Menu_menu__1wQ3F",
	"logo": "Menu_logo__2yFIt",
	"managerMenuLoader": "Menu_managerMenuLoader__muM4W",
	"managerMenu": "Menu_managerMenu__1v26g"
};
module.exports = exports;
