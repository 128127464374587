import {
  CategorySearch,
  DateRange,
  MultiList,
  ReactiveBase,
  ReactiveList,
  ResultCard,
} from '@appbaseio/reactivesearch';

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import { getFileByEventId } from '../api/icat-plus/resource';
import ICATPLUS from '../config/icatPlus';

import SearchDataset from './Search/SearchDataset';

const { ResultCardsWrapper } = ReactiveList;

function SearchPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        {
          name: `Search`,
        },
      ])
    );
  }, [dispatch]);

  const sessionId = useSelector((state) => state.user.sessionId);

  const getDatasetImageURL = (data) => {
    /**Getting the image */
    let url = null;
    if (data.ResourcesGallery) {
      const images = data.ResourcesGallery.split(' ');
      if (images) {
        if (images.length > 0) {
          url = getFileByEventId(sessionId, images[0]);
        }
      }
    }
    return url;
  };

  const getFullTitle = (data) => {
    return <SearchDataset data={data} />;
  };

  const getDescription = (data) => {
    const image = getDatasetImageURL(data);
    const redirect = `/investigation/${data.investigationId}/datasets`;
    return (
      <div
        style={{ marginTop: 15 }}
        className="flex column justify-space-between text-center"
      >
        <div>
          <div>
            <span className="authors-list">
              <a href={redirect}>{data.investigationSummary}</a>
            </span>
            <img alt="dataset" src={image} />
          </div>
          <br />
        </div>
      </div>
    );
  };

  const renderDataset = (data) => {
    const image = getDatasetImageURL(data);

    if (image) {
      return {
        title: getFullTitle(data),
        image,
        description: getDescription(data),
      };
    }
    return {
      title: getFullTitle(data),
      description: getDescription(data),
    };
  };

  const url = `${ICATPLUS.server}/elasticsearch/${sessionId}/`;
  return (
    <div className="app__inner">
      <ReactiveBase app="datasets" url={url} themePreset="light">
        <Container fluid>
          <Row>
            <Col sm={2} />
            <Col xs={10}>
              <CategorySearch
                componentId="searchbox"
                dataField={[
                  'investigationName',
                  'investigationSummary',
                  'name',
                  'definition',
                  'sampleName',
                  'scanType',
                ]}
                categoryField="investigationName.keyword"
                queryFormat="or"
                placeholder="Search by dataset name, sample name or investigation title"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <br />
              <DateRange
                componentId="DateSensor"
                dataField="startDate"
                title="Filter by date"
              />
              <br />

              <MultiList
                componentId="BeamlineSensor"
                dataField="instrumentName.keyword"
                title="Beamlines"
                react={{
                  and: ['DateSensor', 'DefinitionSensor'],
                }}
              />
              <br />
              <MultiList
                componentId="DefinitionSensor"
                dataField="definition.keyword"
                title="Technique"
                react={{
                  and: ['DateSensor', 'BeamlineSensor'],
                }}
              />
            </Col>
            <Col sm={10}>
              <ReactiveList
                componentId="result"
                dataField="name"
                title="Results"
                from={0}
                size={25}
                paginationAt="top"
                pagination
                react={{
                  and: [
                    'searchbox',
                    'DateSensor',
                    'BeamlineSensor',
                    'DefinitionSensor',
                  ],
                }}
                style={{
                  width: '60%',
                  textAlign: 'center',
                }}
                render={({ data }) => (
                  <ResultCardsWrapper>
                    {data.map((item) => (
                      <ResultCard key={item._id}>
                        <ResultCard.Image src={renderDataset(item).image} />

                        <ResultCard.Title>
                          <SearchDataset data={item}></SearchDataset>
                        </ResultCard.Title>
                      </ResultCard>
                    ))}
                  </ResultCardsWrapper>
                )}
              />
            </Col>
          </Row>
        </Container>
      </ReactiveBase>
    </div>
  );
}

export default SearchPage;
