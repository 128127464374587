import React from 'react';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
import { dateFormatter } from '../../../Investigation/utils';
import { DATASET_TIME_FORMAT } from '../../../../constants/index';
import {
  getDatasetParameterValueByName,
  getOutputGalleryImageIdByKey,
} from '../../../../helpers';
import DatasetDownloadButton from '../../DatasetDownloadButton';
import { truncate } from './helper';
import BiosaxsDatasetColumn from './BiosaxsDatasetColumn';

function HPLCBioSaxsDatasetWidget(props) {
  const { dataset } = props;

  return (
    <tr style={{ backgroundColor: '#F5F5F5' }}>
      <td style={{ borderRight: '1px solid gray', borderTop: '0px' }}>
        {dateFormatter(dataset.startDate, DATASET_TIME_FORMAT, false)}
      </td>
      <td>
        <BiosaxsDatasetColumn dataset={dataset}></BiosaxsDatasetColumn>
      </td>

      <td>{getDatasetParameterValueByName(dataset, 'SAXS_numberFrames')}</td>
      <td>
        {truncate(getDatasetParameterValueByName(dataset, 'SAXS_waveLength'))}
      </td>
      <td>{getDatasetParameterValueByName(dataset, 'SAXS_transmission')}</td>
      <td>{getDatasetParameterValueByName(dataset, 'SAXS_flow_rate')}</td>

      <td>
        {getDatasetParameterValueByName(dataset, 'InstrumentSource_mode')}
      </td>
      <td>
        {getDatasetParameterValueByName(dataset, 'InstrumentSource_current')}
      </td>
      <td style={{ width: '180px' }}>
        <GalleryDatasetThumbnail
          imageId={getOutputGalleryImageIdByKey(dataset, 'hplc', 'chroma')}
        ></GalleryDatasetThumbnail>
      </td>
      <td>
        <DatasetDownloadButton
          id={dataset.id}
          dataset={dataset}
        ></DatasetDownloadButton>
      </td>
    </tr>
  );
}

export default HPLCBioSaxsDatasetWidget;
