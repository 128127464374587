import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getParameterTypeURL } from '../../api/icat-plus/catalogue';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function DescriptionToolTip(props) {
  const { name } = props;
  const [parameter, setParameter] = useState([]);
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    const { data } = await axios.get(getParameterTypeURL(name));
    setParameter(data[0]);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  });

  if (loading) return 'Loading...';

  return <div>{parameter.description}</div>;
}

export default function ParameterDescriptionOverlay(props) {
  const { name } = props;

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-desc-${name}`}>
          <DescriptionToolTip name={name}></DescriptionToolTip>
        </Tooltip>
      }
    >
      <div style={{ cursor: 'pointer' }}>{name}</div>
    </OverlayTrigger>
  );
}
