import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getLogbookStatisticsURL } from '../api/icat-plus/logbook';

export default class LogbookStatistics extends Resource {
  _id = undefined;

  pk() {
    return this.investigationId + this.count;
  }

  static get key() {
    return 'LogbookStatisticsResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { startDate, endDate } = params;
    return getLogbookStatisticsURL(sessionId, startDate, endDate);
  }
}
