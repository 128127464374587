import React, { useState, useEffect } from 'react';
import { useFetcher } from 'rest-hooks';
import { Alert, Card, Button } from 'react-bootstrap';
import { groupBy } from 'lodash-es';
import ResponsiveTable from '../Table/ResponsiveTable';
import { useSelector } from 'react-redux';
import { USER_ROLES } from '../../constants';
import InvestigationUserResource from '../../resources/investigationUser';
import InvestigationSharingPanel from './InvestigationSharingPanel';
import LoadingBoundary from '../LoadingBoundary';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { orcidFormatter } from './utils';

/**
 * It basically merges InvestigationUsers in an array by grouping by roles and it sorts the array alphabetically
 * @param {*} investigationUsers
 */
const parseInvestigationUsers = (investigationUsers) => {
  const participantUsers = groupBy(investigationUsers, (user) => user.name);
  return Object.entries(participantUsers)
    .map(([id, users]) => {
      const role = users.map((user) => user.role).join(', ');
      return {
        id,
        fullName: users[0].fullName,
        name: users[0].name,
        role,
        orcidId: users[0].orcidId,
      };
    })
    .sort((a, b) => (a.role < b.role ? -1 : 1));
};

/**
 * Returns a boolean if the user has permissions to revoke an user of the investigation. A user has permissions if it is principal administrator or local contact
 * @param {*} investigationUsers
 * @param {*} name
 */
const isAllowed = (investigationUsers, name) => {
  let isAllowed = false;
  const participantUsers = groupBy(investigationUsers, (user) => user.name);
  Object.entries(participantUsers).map(([id, users]) => {
    const role = users.map((user) => user.role).join(', ');
    isAllowed =
      (id === name && role.indexOf(USER_ROLES.PrincipalInvestigator) !== -1) ||
      isAllowed;

    /** To be commented out if we move to LocalContact
     isAllowed =
      (id === name &&
        (role.indexOf(USER_ROLES.PrincipalInvestigator) !== -1 ||
          role.indexOf(USER_ROLES.LocalContact) !== -1)) ||
      isAllowed;
      **/

    return isAllowed;
  });
  return isAllowed;
};

function ParticipantsPanel(props) {
  const { investigationId } = props;
  const { name } = useSelector((state) => state.user);

  const [submitError, setSubmitError] = useState();
  const [loading, setLoading] = useState();
  const [investigationUsers, setInvestigationUsers] = useState([]);
  const [isPrincipalInvestigator, setIsPrincipalInvestigator] = useState(false);

  const deleteInvestigationUser = useFetcher(
    InvestigationUserResource.deleteShape()
  );

  const getInvestigationUserList = useFetcher(
    InvestigationUserResource.listShape()
  );

  const updateData = (investigationUsers, name) => {
    const participants = parseInvestigationUsers(investigationUsers, name);
    const isPrincipalInvestigator = isAllowed(investigationUsers, name);
    setInvestigationUsers(participants);
    setIsPrincipalInvestigator(isPrincipalInvestigator);
  };

  const revokeHandler = async (user) => {
    try {
      setSubmitError(undefined);
      setLoading(`Removing ${user.fullName}`);
      const investigationUsers = await deleteInvestigationUser(
        { investigationId },
        user
      );
      updateData(investigationUsers, name);
    } catch (error) {
      setSubmitError(
        `There was an error revoking access to user ${user.name}. ${error}`
      );
    } finally {
      setLoading();
    }
  };

  const userAddedHandler = async () => {
    const investigationUsers = await getInvestigationUserList({
      investigationId,
    });
    updateData(investigationUsers, name);
  };

  useEffect(() => {
    async function fetchData() {
      const investigationUsers = await getInvestigationUserList({
        investigationId,
      });
      updateData(investigationUsers, name);
    }
    fetchData();
  }, [getInvestigationUserList, investigationId, name]);

  return (
    <Card variant="primary">
      <Card.Header>
        <Card.Title>
          {' '}
          <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />
          Participants
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {submitError && <Alert variant="danger">{submitError}</Alert>}
        {loading && <Loader message={loading}></Loader>}
        {!loading && (
          <ResponsiveTable
            keyField="id"
            data={investigationUsers}
            columns={[
              {
                text: 'id',
                dataField: 'id',
                hidden: true,
                searchable: false,
              },
              {
                text: 'Name',
                dataField: 'fullName',
                formatter: (_, user) => {
                  if (name === user.id) {
                    return (
                      <span variant="primary">
                        {' '}
                        {user.fullName.toUpperCase()}
                      </span>
                    );
                  }
                  return user.fullName;
                },
              },
              {
                text: 'Role',
                dataField: 'role',
              },
              {
                text: 'ORCID',
                dataField: 'orcidId',
                responsiveHeaderStyle: {
                  xs: { hidden: true },
                  sm: { hidden: true },
                },
                formatter: orcidFormatter,
              },
              {
                text: 'Permissions',
                dataField: 'permissions',
                hidden: !isPrincipalInvestigator,
                formatter: (_, investigationUser) =>
                  investigationUser.role
                    .toUpperCase()
                    .indexOf(USER_ROLES.Collaborator.toUpperCase()) !== -1 ? (
                    <Button
                      variant="danger"
                      onClick={() => revokeHandler(investigationUser)}
                    >
                      Revoke
                    </Button>
                  ) : null,
                headerStyle: () => ({ width: '50%', textAlign: 'center' }),
              },
            ]}
          />
        )}
        {isPrincipalInvestigator ? (
          <LoadingBoundary message="Loading users..." inPanel>
            <InvestigationSharingPanel
              investigationId={investigationId}
              userAddedHandler={userAddedHandler}
            ></InvestigationSharingPanel>
          </LoadingBoundary>
        ) : null}
      </Card.Body>
    </Card>
  );
}

export default ParticipantsPanel;
