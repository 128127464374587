// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelDetails_wrapper__XakNX {\n  display: flex;\n  flex-direction: column;\n}\n\n.ParcelDetails_statusRow__33WeF {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.ParcelDetails_statusCol__3CyAE {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  margin: 0.5rem 1rem;\n}\n\n.ParcelDetails_panelHeader__3C9zl {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.ParcelDetails_currentStatus__19x_o {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  font-size: 1.3rem;\n  font-weight: 600;\n}\n\n.ParcelDetails_label__19tZk {\n  font-weight: 600;\n  margin: 0.5rem;\n}\n\n@media (max-width: 50em) {\n  .ParcelDetails_actionLabel__1_ChI {\n    display: none;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "ParcelDetails_wrapper__XakNX",
	"statusRow": "ParcelDetails_statusRow__33WeF",
	"statusCol": "ParcelDetails_statusCol__3CyAE",
	"panelHeader": "ParcelDetails_panelHeader__3C9zl",
	"currentStatus": "ParcelDetails_currentStatus__19x_o",
	"label": "ParcelDetails_label__19tZk",
	"actionLabel": "ParcelDetails_actionLabel__1_ChI"
};
module.exports = exports;
