import { useCache } from 'rest-hooks';
import AddressResource from '../../resources/address';

export function addressFormatter(addr) {
  return `${addr.name} ${addr.surname} – ${addr.address} – ${addr.postalCode} ${addr.city}`;
}

export function useReturnAddress(shipment) {
  const retrievedReturnAddress = useCache(AddressResource.detailShape(), {
    _id: shipment?.defaultReturnAddress,
  });
  return shipment?.defaultReturnAddress === null
    ? null
    : retrievedReturnAddress;
}
