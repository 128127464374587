import { combineReducers } from 'redux';
import breadcrumbsList from './breadcrumbs';
import datacollections from './datacollections';
import datasets from './datasets';
import instrumentscientists from './instrumentscientists';
import logbook from './logbook';
import user from './login';
import selectedDatasets from './selection';

export default combineReducers({
  user,
  datasets,
  logbook,
  selectedDatasets,
  breadcrumbsList,
  datacollections,
  instrumentscientists,
});
