import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TextFieldGroup from '../Form/TextFieldGroup';

function AddressTextFieldsForm(props) {
  const { layout, addressObjName, defaultValues } = props;

  const fieldName = addressObjName
    ? (field) => `${addressObjName}.${field}`
    : (field) => field;

  return (
    <div className="horiz-form-fix">
      <Container fluid style={{ padding: 12 }}>
        <Row>
          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true }}
              name={fieldName('name')}
              type="text"
              defaultValue={defaultValues?.name}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true }}
              name={fieldName('surname')}
              type="text"
              defaultValue={defaultValues?.surname}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true }}
              name={fieldName('email')}
              type="email"
              defaultValue={defaultValues?.email}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{
                required: true,
                pattern: /\+?\d+/,
                maxLength: 20,
              }}
              name={fieldName('phoneNumber')}
              label="Phone"
              type="tel"
              layout={layout}
              defaultValue={defaultValues?.phoneNumber}
            />
          </Col>
        </Row>

        <TextFieldGroup
          registerOptions={{ required: true }}
          name={fieldName('address')}
          type="textarea"
          layout={layout}
          defaultValue={defaultValues?.address}
        />

        <Row>
          <Col sm={12} md={3}>
            <TextFieldGroup
              name={fieldName('companyName')}
              label="Institution"
              type="text"
              layout={layout}
              defaultValue={defaultValues?.companyName}
            />
          </Col>
          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true, pattern: /\d+/, maxLength: 7 }}
              name={fieldName('postalCode')}
              label="Postal Code"
              type="text"
              layout={layout}
              defaultValue={defaultValues?.postalCode}
            />
          </Col>

          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true }}
              name={fieldName('city')}
              type="text"
              layout={layout}
              defaultValue={defaultValues?.city}
            />
          </Col>

          <Col sm={12} md={3}>
            <TextFieldGroup
              registerOptions={{ required: true }}
              name={fieldName('country')}
              type="text"
              layout={layout}
              defaultValue={defaultValues?.country}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AddressTextFieldsForm;
