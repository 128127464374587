import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';
import AddressResource from './address';

export default class ParcelResource extends Resource {
  _id = undefined;
  investigationId = undefined;
  investigationName = '';
  shipmentId = undefined;
  name = '';
  type = 'DEFAULT';
  status = 'PREPARATION';
  name = '';
  description = '';
  returnAddress = new AddressResource();
  shippingAddress = new AddressResource();
  comments = '';

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'ParcelResource';
  }

  static url(params) {
    const { investigationId, shipmentId, status, parcelId } = params;
    const { sessionId } = store.getState().user;

    if (status) {
      // For PUT status
      return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${parcelId}/status/${status}`;
    }

    if (shipmentId) {
      return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/shipment/id/${shipmentId}/parcel`;
    }

    // For GET
    return `${
      ICATPLUS.server
    }/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${this.pk(
      params
    )}`;
  }

  static listUrl(params) {
    const { investigationId, shipmentId, status, _id } = params;
    const { sessionId } = store.getState().user;

    // For listing a shipment's parcels
    if (investigationId && shipmentId) {
      return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/shipment/id/${shipmentId}/parcel`;
    }

    // For listing a shipment's parcels
    if (investigationId && shipmentId) {
      return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/shipment/id/${shipmentId}/parcel`;
    }

    if (investigationId && _id) {
      return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${_id}`;
    }

    // For listing parcels of a given status
    if (status) {
      return `${ICATPLUS.server}/tracking/${sessionId}/parcel/status/${status}`;
    }

    // For listing a user's parcels
    return `${ICATPLUS.server}/tracking/${sessionId}/parcel`;
  }

  static labelUrl(params) {
    const { investigationId, parcelId } = params;
    const { sessionId } = store.getState().user;

    return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${parcelId}/labels`;
  }

  static deleteShape() {
    return {
      ...super.deleteShape(),
      fetch: (params, body) => this.fetch('delete', this.url(params), body),
    };
  }
}
