import 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/charmap';

// Configuration file for tinyMCE editor

/** the general configuration set used for all instances of the editor */
class GeneralConfig {
  constructor() {
    this.plugins =
      'link, image, textcolor, fullscreen, media, paste, table, lists, importcss, charmap';
    this.skin_url = '/tinymce/skins/customLightgray';
    this.statusbar = false;
    this.branding = false; // hides powered by tinymce
    this.paste_data_images = true;
    this.content_css = '/tinymce/customStyles.css';
    this.formats = {
      alignleft: { selector: 'img', styles: { float: 'left', margin: 10 } },
      alignright: { selector: 'img', styles: { float: 'right', margin: 10 } },
      bullist: { selector: 'ul', styles: { display: 'inline-block' } },
    };
  }
}

/** the configuration set when the editor is used for creation or edition */
export class EditionModeConfig extends GeneralConfig {
  constructor(width) {
    super();
    this.readonly = false;
    this.menubar = false;
    this.toolbar =
      'undo redo | fontsizeselect bold italic | charmap | subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | link image table | fullscreen';

    if (width) {
      if (width <= 1050) {
        this.toolbar = 'undo redo paste | bold italic | bullist | image';
      }
    }
  }
}
