// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddressPanel_row__2Dlq8 {\n  display: flex;\n  margin: 0.5rem;\n}\n.AddressPanel_glyph__1LNeb {\n  margin-right: 0.75rem;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "AddressPanel_row__2Dlq8",
	"glyph": "AddressPanel_glyph__1LNeb"
};
module.exports = exports;
