import {
  APPEND_BREADCRUMB,
  CLEAN_BREADCRUMBS,
  REPLACE_BREADCRUMB,
  SET_BREADCRUMBS,
} from '../constants/actionTypes';

export function setBreadCrumbs(breadcrumbs) {
  return {
    type: SET_BREADCRUMBS,
    breadcrumbs,
  };
}

export function appendBreadCrumb(breadcrumb) {
  return {
    type: APPEND_BREADCRUMB,
    breadcrumb,
  };
}

export function cleanBreadcrumbs() {
  return {
    type: CLEAN_BREADCRUMBS,
  };
}

export function replaceOrAppendBreadCrumb(breadcrumb, level) {
  return {
    type: REPLACE_BREADCRUMB,
    breadcrumb,
    level,
  };
}
