import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoginPanel from '../components/Login/LoginPanel';
import WelcomeAnnoucements from '../components/Login/WelcomeAnnoucements';
import { usePageTracking } from '../helpers/hooks';

function LoginPage() {
  usePageTracking();

  return (
    <div className="app__inner" style={{ marginTop: 85, marginBottom: 25 }}>
      <Container fluid>
        <Row>
          <Col
            xs={12}
            className="d-block d-md-none"
            style={{ marginBottom: 20 }}
          >
            <LoginPanel />
          </Col>
          <Col md={12} lg={8}>
            <WelcomeAnnoucements />
          </Col>
          <Col md={12} lg={4} className="d-none d-md-block">
            <LoginPanel />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginPage;
