import { LOG_OUT, SET_DATASETS_COUNT } from '../constants/actionTypes';

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  datasetsCount: 0,
};

const datasets = (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT: {
      state = {
        ...state,
        data: [],
        fetching: false,
        fetched: false,
        datasetsCount: 0,
      };
      break;
    }
    case SET_DATASETS_COUNT: {
      state = {
        ...state,
        datasetsCount: action.datasetsCount,
      };
      break;
    }

    default:
      break;
  }
  return state;
};

export default datasets;
