import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tinymce from 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';

import { EditionModeConfig } from './tinymceConfig';
import { getText } from '../../../helpers/eventHelpers';
import {
  HTML_CONTENT_FORMAT,
  EDIT_EVENT_CONTEXT,
  NEW_EVENT_CONTEXT,
  PLAINTEXT_CONTENT_FORMAT,
  LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
  LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT,
  LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
  LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT,
  NOTIFICATION,
} from '../../../constants/eventTypes';
import UI from '../../../config/ui';
import { getFileByEventId, uploadFile } from '../../../api/icat-plus/resource';

const EDITOR_ID_FOR_CREATION = 'myEditorForCreation';
const EDITOR_ID_FOR_EDITION = 'myEditorForEdition';

export default function EditorWrapper(props) {
  const {
    event,
    onContentChanged,
    user,
    investigationId,
    instrumentName,
    onImageLoaded,
  } = props;

  /** Get the text to edit depending on the event type and the current action (edit, create)
   * @param {object} event event to get text from
   * @returns {string} text to edit when event is an annotation or commented notification. Null when
   * event is null or event is a notification with no comment.
   */
  const getTextToEdit = (event) => {
    if (
      !event ||
      (event.type.toLowerCase() === NOTIFICATION && !event.previousVersionEvent)
    ) {
      return null;
    }

    return (
      getText(event.content, HTML_CONTENT_FORMAT) ||
      getText(event.content, PLAINTEXT_CONTENT_FORMAT)
    );
  };

  /**
   * Store data to the localstorage
   * @param {string} context
   * @param {string} content content data to store
   * @param {string} contentFormat data format
   */
  const storeToLocalStorage = useCallback(
    (context, content, contentFormat) => {
      if (context && contentFormat) {
        if (context === NEW_EVENT_CONTEXT) {
          if (contentFormat === PLAINTEXT_CONTENT_FORMAT) {
            localStorage.setItem(
              LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT,
              content
            );
          } else if (contentFormat === HTML_CONTENT_FORMAT) {
            localStorage.setItem(
              LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT,
              content
            );
          }
        }
        if (context === EDIT_EVENT_CONTEXT) {
          if (contentFormat === PLAINTEXT_CONTENT_FORMAT) {
            localStorage.setItem(
              LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT +
                event._id,
              content
            );
          } else if (contentFormat === HTML_CONTENT_FORMAT) {
            localStorage.setItem(
              LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT + event._id,
              content
            );
          }
        }
      }
    },
    [event]
  );

  const [text, setText] = useState(getTextToEdit(event));
  const config = new EditionModeConfig(window.innerWidth);

  useEffect(() => {
    // Make sure localstorage is populated in case the editor is not clicked on at all
    const context = event ? EDIT_EVENT_CONTEXT : NEW_EVENT_CONTEXT;
    storeToLocalStorage(context, text, HTML_CONTENT_FORMAT);
  }, [event, storeToLocalStorage, text]);

  useEffect(() => {
    return () => {
      const context = event ? EDIT_EVENT_CONTEXT : NEW_EVENT_CONTEXT;
      if (context === NEW_EVENT_CONTEXT) {
        localStorage.removeItem(
          LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_PLAINTEXT_FORMAT
        );
        localStorage.removeItem(
          LOCALSTORAGE_KEY_NEW_EVENT_CONTENT_IN_HTML_FORMAT
        );
      }

      if (context === EDIT_EVENT_CONTEXT) {
        localStorage.removeItem(
          LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_PLAINTEXT_FORMAT + event._id
        );
        localStorage.removeItem(
          LOCALSTORAGE_KEY_EDITED_EVENT_CONTENT_IN_HTML_FORMAT + event._id
        );
      }
    };
  }, [event]);

  /**
   * Sets a default image height using a CSS rule. This is a trick to make sure the image height
   * is applied in the editor (for scrollabar propose) especially because the image is not necessary
   * yet downloaded at this step. The height is changed to auto after the image is fully downloaded
   * such that image ratio is kept.
   */
  const setImageHeightByCSSRule = () => {
    if (tinymce && tinymce.activeEditor) {
      const selectedNode = tinymce.activeEditor.selection.getNode();
      if (
        selectedNode.nodeName === 'IMG' &&
        selectedNode.style.height !== 'auto'
      ) {
        const nxElement = selectedNode;
        nxElement.style.height = UI.logbook.NEW_EVENT_MAX_HEIGHT;
        nxElement.style.width = 'auto'; // a css trick for some browsers IE8 and old iceweasel
        nxElement.onload = onImageLoaded;
        tinymce.activeEditor.dom.replace(nxElement, selectedNode);
      }
    }
  };

  /**
   * Function triggered when the editor state changes (mouse click, key press for example )
   * @param {String} text current editor content in HTML format
   */
  const onEditorStateChanged = (text) => {
    const context = event ? EDIT_EVENT_CONTEXT : NEW_EVENT_CONTEXT;
    if (text) {
      // Editor content has been modified by the user. Save the update to localStorage
      storeToLocalStorage(
        context,
        tinymce.activeEditor.getContent({ format: 'text' }),
        PLAINTEXT_CONTENT_FORMAT
      );
      storeToLocalStorage(
        context,
        tinymce.activeEditor.getContent(),
        HTML_CONTENT_FORMAT
      );
    }

    setImageHeightByCSSRule();
    onContentChanged();
    setText(text);
  };

  /**
   * Defines what to do when the user drag an image onto the dropzone of the editor image plugin. This function must return a promise.
   * The value of a fullfilled promise must be an array of the form { data: { link: url } } where url value is the link to the image which
   * has just been uoloaded to the ICAT+ server.
   * @param {*} file : the image which has just been dropped on the drop zone.
   */

  const imagesUploadHandler = (blobInfo, success, failure) => {
    const sessionId = user.sessionId;

    const data = new FormData();
    data.append('file', blobInfo.blob(), blobInfo.filename());
    data.append('investigationId', investigationId);
    data.append('instrumentName', instrumentName);
    data.append('creationDate', Date());
    data.append('type', 'attachment');
    data.append('category', 'file');

    axios({
      method: 'post',
      url: uploadFile(sessionId, investigationId, instrumentName),
      data,
    }).then(
      (value) => {
        const eventId = value.data._id;
        success(getFileByEventId(sessionId, eventId));
      },
      (error) => {
        console.log(
          '[ERROR] Retrieval of the image you have just upladed into the editor failed ! '
        );
        failure(error);
      }
    );
  };

  return (
    <Editor
      id={event ? EDITOR_ID_FOR_EDITION : EDITOR_ID_FOR_CREATION}
      init={{
        plugins: config.plugins,
        skin_url: config.skin_url,
        branding: config.branding,
        readonly: config.readonly,
        toolbar: config.toolbar,
        menubar: config.menubar,
        statusbar: config.statusbar,
        images_upload_handler: imagesUploadHandler,
        paste_data_images: config.paste_data_images,
        formats: config.formats,
        //content_css: config.content_css,
      }}
      value={text}
      onEditorChange={onEditorStateChanged}
    />
  );
}

EditorWrapper.propTypes = {
  /** Event being edited. When event is undefined, editor is used to create content of a new event */
  event: PropTypes.object,
  /** the investigationId of the event being edited. */
  investigationId: PropTypes.number,
  /** Function triggered when editor content changed */
  onContentChanged: PropTypes.func,
  /** the user who is currently logged in */
  user: PropTypes.object.isRequired,
  /** the instrumentName of the event being edited in case of beamline logbook */
  instrumentName: PropTypes.string,
};
