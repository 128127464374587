import React from 'react';
import { useSelector } from 'react-redux';
import ResponsiveTable from '../Table/ResponsiveTable';
import StatusDisplay from './StatusDisplay';

function StatusTable(props) {
  const { statuses } = props;
  const user = useSelector((state) => state.user);

  const columns = [
    {
      text: 'ID',
      dataField: '_id',
      sort: true,
      hidden: true,
      searchable: false,
    },
    {
      text: 'Status',
      dataField: 'status',
      formatter: (status) => <StatusDisplay status={status} />,
    },
    {
      text: 'Updated at',
      dataField: 'updatedAt',
      sort: true,
      formatter: (dateString) =>
        `${dateString.slice(0, 10)} ${dateString.slice(11, 16)} GMT`,
    },
    {
      text: 'Updated by',
      dataField: 'createdByFullName',
      hidden: !user.isAdministrator,
    },
    { text: 'Comments', dataField: 'comments' },
  ];

  return (
    <ResponsiveTable
      keyField="_id"
      data={statuses}
      columns={columns}
      defaultSorted={[{ dataField: '_id', order: 'desc' }]}
    />
  );
}

export default StatusTable;
