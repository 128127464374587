import React from 'react';
import { Card } from 'react-bootstrap';
import { NO_LINK } from '../../constants/doi';

export function BeamlineField(props) {
  const { beamline, beamlineUrl } = props;

  return (
    <>
      {!beamlineUrl || beamlineUrl === NO_LINK ? (
        <Card.Text>{beamline}</Card.Text>
      ) : (
        <Card.Text>
          <a href={beamlineUrl} target="_blank" rel="noopener noreferrer">
            {beamline}
          </a>
        </Card.Text>
      )}
    </>
  );
}
