import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card } from 'react-bootstrap';

/**
 * React component which renders a message to the user
 */
function UserMessage(props) {
  const { type, message, onRetryClicked } = props;

  if (!type || !message || message === '') {
    return null;
  }

  const retryButton = onRetryClicked && (
    <Button variant="link" onClick={onRetryClicked}>
      Retry
    </Button>
  );

  return (
    <Card variant={getVariant(type)} style={{ marginBottom: 0 }}>
      <Card.Header> {getHeaderText(type)} </Card.Header>
      <Card.Body>
        {message}
        {retryButton}
      </Card.Body>
    </Card>
  );
}

UserMessage.propTypes = {
  /** Message itself */
  message: PropTypes.string.isRequired,
  /** Function triggered when the use clicks the retry link. No link displayed when not provided */
  onRetryClicked: PropTypes.func,
  /* Type of the message */
  type: PropTypes.oneOf(['error', 'info', 'success']).isRequired,
};

export default UserMessage;

/**
 * Get bootstrap style
 * @param {('error'|'info'|'success')} type type of the user Message
 */
function getVariant(type) {
  if (type === 'info' || type === 'success') {
    return type;
  }

  if (type === 'error') {
    return 'danger';
  }

  return 'primary';
}

function getHeaderText(type) {
  if (type === 'error') {
    return 'Error message';
  }
  return 'Information message';
}
