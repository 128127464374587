import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';

function AnnotationsStatisticsPanel(props) {
  const { statistics } = props;

  const investigations =
    statistics !== null
      ? statistics.filter((stats) => stats.annotations > 0)
      : 0;
  // Count notifications and annotations
  const annotationsListCount = statistics.map((record) =>
    record.annotations ? record.annotations : 0
  );

  // Calculate sum
  const annotationsCount =
    annotationsListCount.length > 0
      ? annotationsListCount.reduce((a, b) => a + b)
      : 0;

  /** Get maximum and minimum */
  const maxAnnotations = Math.max(...annotationsListCount);

  return (
    <Container fluid style={{ margin: 20 }}>
      <Row>
        <Col xs={12}>
          <h4>Annotations</h4>
          <ParameterTableWidget
            striped
            parameters={[
              {
                name: 'Investigation with Annotations',
                value: investigations.length,
              },
              {
                name: 'Total Annotations',
                value: annotationsCount,
              },
              {
                name: 'Max. Annotations/investigation',
                value: maxAnnotations,
              },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AnnotationsStatisticsPanel;
