// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParcelSection_dangerous__3aEz3 {\n  color: darkred;\n  font-weight: 600;\n}\n\n.ParcelSection_heading__3JKVg {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.ParcelSection_newButton__2NOLa {\n  margin: 0.75rem;\n}\n", ""]);
// Exports
exports.locals = {
	"dangerous": "ParcelSection_dangerous__3aEz3",
	"heading": "ParcelSection_heading__3JKVg",
	"newButton": "ParcelSection_newButton__2NOLa"
};
module.exports = exports;
