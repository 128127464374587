const PARAMETERS = {
  InstrumentMonochromator_energy: 'Energy',
  InstrumentMonochromator_wavelength: 'Wavelength',
  InstrumentMonochromatorCrystal_d_spacing: 'd_spacing',
  InstrumentMonochromatorCrystal_reflection: 'Reflection',
  InstrumentMonochromatorCrystal_type: 'Type',
  InstrumentMonochromatorCrystal_usage: 'Usage',
};

export default PARAMETERS;
