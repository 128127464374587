import { faSyncAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFetcher, useResource } from 'rest-hooks';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import { fetchInstrumentScientistsBySessionId } from '../actions/instrumentscientists';
import LoadingBoundary from '../components/LoadingBoundary';
import BeamlineManagerForm from '../components/UserManagement/BeamlineManagerForm';
import InstrumentScientistTable from '../components/UserManagement/InstrumentScientistTable';
import InstrumentScientistsResource from '../resources/instrumentScientists';

function UserManagementPage() {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const instrumentScientists = useResource(
    InstrumentScientistsResource.listShape(),
    {}
  );

  const refetch = useFetcher(InstrumentScientistsResource.listShape());
  const refetchInstrumentScientists = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'User Management' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <p className="text-secondary">
        Beamline managers have access to all data, metadata and the electronic
        logbooks produced in a beamline
      </p>
      <LoadingBoundary withSilentError>
        <BeamlineManagerForm
          fetchInstrumentScientistsBySessionId={
            fetchInstrumentScientistsBySessionId
          }
          user={user}
          isFetching={loading}
          refetch={refetchInstrumentScientists}
        />
      </LoadingBoundary>

      <Card border="primary" style={{ marginTop: 30, marginRight: 30 }}>
        <Card.Header>
          <Card.Title className="text-primary">
            <FontAwesomeIcon icon={faUser} /> Beamline Managers{' '}
            <Badge>
              {' '}
              {!instrumentScientists ? (
                <FontAwesomeIcon icon={faSyncAlt} spin />
              ) : (
                instrumentScientists.length
              )}
            </Badge>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <InstrumentScientistTable
            fetchInstrumentScientistsBySessionId={
              fetchInstrumentScientistsBySessionId
            }
            user={user}
            isFetching={loading}
            instrumentScientists={instrumentScientists}
            refetch={refetchInstrumentScientists}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default UserManagementPage;
