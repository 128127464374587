import React from 'react';
import moment from 'moment';
import { stringifyBytesSize, getDatasetParameterByName } from '../../helpers';
import { VOLUME, FILE_COUNT } from '../../constants/parameterTypes';
import DatasetDownloadButton from './DatasetDownloadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

export function dateFormatter(date) {
  if (!date) {
    return '';
  }

  const parsedDate = moment(date);
  return (
    <>
      <FontAwesomeIcon icon={faClock} style={{ marginRight: 5, top: 2 }} />
      {parsedDate.format(moment.HTML5_FMT.TIME)}
      <span style={{ marginLeft: 5, color: '#777', fontSize: 11 }}>
        {parsedDate.format('D MMM YYYY')}
      </span>
    </>
  );
}

export function volumeFormatter(dataset) {
  return stringifyBytesSize(getDatasetParameterByName(dataset, VOLUME));
}

export function fileCountFormatter(dataset) {
  return getDatasetParameterByName(dataset, FILE_COUNT);
}

export function techniqueFormatter(dataset) {
  const definition = getDatasetParameterByName(dataset, 'definition');
  return definition || '';
}

export function downloadFormatter(dataset) {
  const dataArchived = getDatasetParameterByName(dataset, '__dataArchived');

  if (dataArchived === 'False') {
    return 'N/A';
  }

  return <DatasetDownloadButton id={dataset.id} dataset={dataset} />;
}
