// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ShippingForm_text__16aNu {\n  margin-bottom: 2rem;\n}\n\n.ShippingForm_info__1s3p4 {\n  font-size: smaller;\n}\n\n.ShippingForm_hint__1bB4z {\n  font-size: small;\n  color: #777;\n}\n", ""]);
// Exports
exports.locals = {
	"text": "ShippingForm_text__16aNu",
	"info": "ShippingForm_info__1s3p4",
	"hint": "ShippingForm_hint__1bB4z"
};
module.exports = exports;
