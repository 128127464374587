import React from 'react';
import styles from './MyAddressesSummary.module.css';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

function AddressHeaderWithActions(props) {
  const { address, onDelete } = props;

  return (
    <div className={styles.header}>
      <span className={styles.investigation}>{address.investigationName}</span>
      <div className={styles.actions}>
        <LinkContainer to={`/addresses?edit=${address._id}`}>
          <Button size="sm" variant="link" aria-label="Edit">
            <FontAwesomeIcon icon={faPencilAlt} />
            <span className={styles.actionLabel}> Edit</span>
          </Button>
        </LinkContainer>
        <Button
          size="sm"
          variant="link"
          aria-label="Remove"
          onClick={() => onDelete(address)}
        >
          <FontAwesomeIcon icon={faTrash} />
          <span className={styles.actionLabel}> Remove</span>
        </Button>
      </div>
    </div>
  );
}

export default AddressHeaderWithActions;
