import React, { useEffect } from 'react';
import MyAddressesSummary from '../components/Address/MyAddressesSummary';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import LoadingBoundary from '../components/LoadingBoundary';
import { usePageTracking } from '../helpers/hooks';

function AddressesPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'My Addresses' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading addresses...">
        <MyAddressesSummary />
      </LoadingBoundary>
    </div>
  );
}

export default AddressesPage;
