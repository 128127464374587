import React from 'react';
import { Col, Alert, Row, Container } from 'react-bootstrap';
import maintenanceImage from '../images/maintenance.jpg';
import maintenanceImage2 from '../images/maintenance2.jpg';
import maintenanceImage3 from '../images/maintenance3.jpg';
import maintenanceImage4 from '../images/maintenance4.jpg';
import UI from '../config/ui';
import Menu from '../components/Menu/Menu';
import Footer from '../components/Footer';

function OfflinePage() {
  return (
    <>
      <div className="app">
        <Menu />
        <Container style={{ marginTop: 100 }}>
          <Row>
            <Col xs={3}>
              <img
                alt=""
                src={maintenanceImage}
                style={{ height: 200, width: 200 }}
              />
            </Col>
            <Col xs={3} />
            <Col xs={3}>
              <img
                alt=""
                src={maintenanceImage3}
                style={{ height: 200, width: 200 }}
              />
            </Col>
            <Col xs={3} />
          </Row>

          <Row>
            <Col />
            <Col>
              <Alert style={{ textAlign: 'center' }} variant="warning">
                <strong>Offline Message!</strong>
                <br /> <h1>{UI.status.offline.message}</h1>
              </Alert>
            </Col>
            <Col>
              <Col xs={3} />
              <Col xs={3}>
                <img
                  alt=""
                  src={maintenanceImage2}
                  style={{ height: 200, width: 200 }}
                />
              </Col>
              <Col xs={3} />
              <Col xs={3}>
                <img
                  alt=""
                  src={maintenanceImage4}
                  style={{ height: 200, width: 200 }}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default OfflinePage;
