import React from 'react';
import { useResource } from 'rest-hooks';
import { Tabs, Tab } from 'react-bootstrap';
import LogbookStatistics from '../../../../resources/logbookStatistics';
import LogbookUsagePanel from './LogbookUsagePanel';
import LogbookStatsResponsiveTable from './LogbookStatsResponsiveTable';
import LogbookStatisticsEvents from './LogbookStatisticsEvents';

function LogbookStatisticsTabs(props) {
  const { startDate, endDate } = props;

  const statistics = useResource(LogbookStatistics.listShape(), {
    startDate,
    endDate,
  });

  return (
    <Tabs id="uncontrolled-tab-example" defaultActiveKey={1}>
      <Tab eventKey={1} title="Logbook Usage">
        <LogbookUsagePanel
          startDate={startDate}
          endDate={endDate}
          statistics={statistics}
        ></LogbookUsagePanel>
      </Tab>
      <Tab eventKey={2} title="Raw Statistics">
        <LogbookStatsResponsiveTable
          statistics={statistics}
        ></LogbookStatsResponsiveTable>
      </Tab>
      <Tab eventKey={3} title="Events produced">
        <LogbookStatisticsEvents
          startDate={startDate}
          endDate={endDate}
          type="annotation"
          footer="Annotations are hand-made entries in the logbook done by users"
        ></LogbookStatisticsEvents>
        <LogbookStatisticsEvents
          startDate={startDate}
          endDate={endDate}
          type="notification"
          footer="Notifications are entries produced and sent automatically by beamline software"
        ></LogbookStatisticsEvents>
      </Tab>
    </Tabs>
  );
}

export default LogbookStatisticsTabs;
