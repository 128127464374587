import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Container, Badge, Breadcrumb, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import styles from './BreadCrumbs.module.css';

function BreadCrumbs(props) {
  const { breadcrumbs } = props;
  const { state } = useLocation();

  if (state && state.back) {
    const { title, pathname, search, scrollPosition } = state.back;
    return (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={{ pathname, search, state: { scrollPosition } }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 10 }} />
            {title}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  }

  if (!breadcrumbs || breadcrumbs.length === 0) {
    return null;
  }

  function getFullName(item) {
    let badges = '';
    if (item.badges) {
      badges = item.badges.map((badge) => (
        <span key={badge}>
          {badge && <Badge bg="secondary">{badge}</Badge>}{' '}
        </span>
      ));
    }

    return (
      <>
        {badges}
        {item.name}
      </>
    );
  }

  function buildBreadCrumbsItem(item, i, classNames) {
    if (item.link) {
      return (
        <Breadcrumb.Item
          key={i}
          className={classNames.join(' ')}
          href={item.link}
        >
          {getFullName(item)}
        </Breadcrumb.Item>
      );
    }
    return (
      <Breadcrumb.Item active key={i} className={classNames.join(' ')}>
        {getFullName(item)}
      </Breadcrumb.Item>
    );
  }

  return (
    <Container fluid>
      <Row xs={12}>
        <Breadcrumb className={styles.list}>
          {breadcrumbs.map((item, i) => {
            const classNames = [];
            if (i === breadcrumbs.length - 1) {
              classNames.push('active');
            }
            return buildBreadCrumbsItem(item, i, classNames);
          })}
        </Breadcrumb>
      </Row>
    </Container>
  );
}

export default BreadCrumbs;
