import React from 'react';
import styles from './Spinner.module.css';

function Spinner(props) {
  const { className, dotColor } = props;
  return (
    <div className={className}>
      <div className={styles.skChaseSpinner}>
        {Array(6)
          .fill()
          .map((v, i) => (
            <div key={i} className={styles.skChaseDotContainer}>
              <div
                className={styles.skChaseDot}
                style={{ backgroundColor: dotColor }}
              />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Spinner;
