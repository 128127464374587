// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ResponsiveTable_bar__3f5SL {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.ResponsiveTable_actions__L9dfs,\n.ResponsiveTable_search__1hR9H {\n  flex: 1 1;\n}\n\n.ResponsiveTable_search__1hR9H {\n  max-width: 500px;\n}\n\n.ResponsiveTable_actionIcon__10us0 {\n  margin-right: 5px;\n  font-size: 0.9em;\n}\n", ""]);
// Exports
exports.locals = {
	"bar": "ResponsiveTable_bar__3f5SL",
	"actions": "ResponsiveTable_actions__L9dfs",
	"search": "ResponsiveTable_search__1hR9H",
	"actionIcon": "ResponsiveTable_actionIcon__10us0"
};
module.exports = exports;
