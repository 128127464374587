import React from 'react';
import { Col, Container, Row, Badge } from 'react-bootstrap';
import {
  getDatasetParameterValueByName,
  getDatasetParameterIdByName,
  getDatasetUnitByName,
} from '../../../../helpers';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
import { useSelector } from 'react-redux';
import UI from '../../../../config/ui';

function getParametersByName(dataset, parametersDictionary) {
  return parametersDictionary.map((parameter) => {
    return {
      key: parameter.key,
      id: getDatasetParameterIdByName(dataset, parameter.key),
      name: parameter.name,
      unit: getDatasetUnitByName(dataset, parameter.key),
      value: parameter.value
        ? parameter.value
        : getDatasetParameterValueByName(dataset, parameter.key),
    };
  });
}

function HumanAtlasDatasetSummary(props) {
  const { dataset } = props;
  const user = useSelector((state) => state.user);
  /** Wwhen administrator the metadata will be editable */
  const { isAdministrator } = user;
  const projectName = getDatasetParameterValueByName(dataset, 'Project_name');
  return (
    <>
      <Container fluid style={{ margin: 20 }}>
        <Row>
          <Col xs={12} sm={12} md={3}>
            <GalleryDatasetThumbnail
              dataset={dataset}
              nameColumnWidth="25%"
              index={0}
            />
            <h2>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={UI.projects.humanOrgans.frontEndURL}
              >
                <Badge bg="primary">{projectName}</Badge>
              </a>
            </h2>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              header="DOI"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'Abstract', key: 'DOI_abstract' },
                { name: 'Title', key: 'DOI_title' },
                { name: 'Users', key: 'DOI_users' },
              ])}
            />
          </Col>
          <Col xs={12} md={3}>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              header="Patient"
              nameColumnWidth="25%"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'definition', key: 'definition' },
                { name: 'Identifier', key: 'SamplePatient_number' },
                { name: 'Age', key: 'SamplePatient_age' },
                { name: 'Sex', key: 'SamplePatient_sex' },
                { name: 'Organ', key: 'SamplePatient_organ_name' },
                { name: 'Institute', key: 'SamplePatient_institute' },
                { name: 'Info', key: 'SamplePatient_info' },
              ])}
            />
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              header="Sample"
              nameColumnWidth="25%"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'Sample', key: 'Sample_name' },
                { name: 'Info', key: 'Sample_description' },
                { name: 'Preparation', key: 'Sample_preparation_description' },
              ])}
            />
          </Col>
          <Col xs={12} md={3}>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              nameColumnWidth="25%"
              striped={false}
              header="Scan Parameters"
              parameters={getParametersByName(dataset, [
                { name: 'Instrument', key: 'TOMO_idNames' },
                { name: 'SR Current', key: 'InstrumentSource_current' },
                { name: 'Exposure Time', key: 'TOMO_exposureTime' },
                { name: 'Pixel Size', key: 'TOMO_pixelSize' },
                { name: 'Mode', key: 'scanType' },
                { name: 'ScanRadix', key: 'TOMO_scanRadix' },
                {
                  name: 'Step (x,y,z)',
                  value: `${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_xStep'
                  )},${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_yStep'
                  )},${getDatasetParameterValueByName(dataset, 'TOMO_zStep')}`,
                },
                {
                  name: 'Stages (x,y,z)',
                  value: `${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_xStages'
                  )},${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_yStages'
                  )},${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_zStages'
                  )}`,
                },

                { name: 'Projections', key: 'TOMO_projN' },
                { name: 'refn', key: 'TOMO_refN' },
                { name: 'darkn', key: 'TOMO_darkN' },
                { name: 'refon', key: 'TOMO_refOn' },

                { name: 'Acc. Frames Count', key: 'TOMO_accFramesCount' },

                { name: 'Detector Distance', key: 'TOMO_detectorDistance' },
                { name: 'Energy (avg)', key: 'TOMO_energy' },
                { name: 'Scan Geometry', key: 'TOMO_halfAcquisition' },
                { name: 'Scan Range', key: 'TOMO_scanRange' },
                {
                  name: 'Pixel (x,y)',
                  value: `${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_x_pixel_n'
                  )},${getDatasetParameterValueByName(
                    dataset,
                    'TOMO_y_pixel_n'
                  )}`,
                },

                { name: 'Magnification', key: 'TOMO_magnification' },

                { name: 'Scintillator', key: 'TOMO_scintillator' },
                { name: 'Sur. Dose Rate', key: 'TOMO_surface_dose' },
                { name: 'Dose Rate', key: 'TOMO_voi_dose' },
                { name: 'VOI Integ. Dose', key: 'TOMO_total_voi_dose' },
                { name: 'Scan time', key: 'TOMO_scanTime' },
                { name: 'Series time', key: 'TOMO_seriesTime' },
              ])}
            />
          </Col>

          <Col xs={12} md={3}>
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              nameColumnWidth="25%"
              striped={false}
              header="Sensor"
              parameters={getParametersByName(dataset, [
                {
                  name: 'Name',
                  key: 'InstrumentDetector01_description',
                },
                {
                  name: 'Mode',
                  key: 'InstrumentDetector01_acquisition_mode',
                },
                { name: 'Size', key: 'TOMO_ccdPixelSize' },
                { name: 'Optics Type', key: 'TOMO_opticsType' },
              ])}
            />
            <ParameterTableWidget
              datasetId={dataset.id}
              editable={isAdministrator}
              header="Processing"
              striped={false}
              parameters={getParametersByName(dataset, [
                { name: 'refapproach', key: 'TOMO_reference_description' },
                { name: 'Volume X', key: 'TOMO_x_volume' },
                { name: 'Volume Y', key: 'TOMO_y_volume' },
                { name: 'Volume Z', key: 'TOMO_z_volume' },
                { name: '32to16bitsmin', key: 'TOMO_min32to16bits' },
                { name: '32to16bitsmax', key: 'TOMO_max32to16bits' },
                { name: 'jp2comprratio', key: 'TOMO_jp2CompressRatio' },
                { name: 'filters', key: 'InstrumentAttenuator01_description' },
                { name: 'technique', key: 'TOMO_technique' },
                { name: 'experimentType', key: 'TOMO_experimentType' },
              ])}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HumanAtlasDatasetSummary;
