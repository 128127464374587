import React, { Suspense } from 'react';
import { Modal, Alert } from 'react-bootstrap';
import { NetworkErrorBoundary } from 'rest-hooks';
import Loader from './Loader';

function ModalLoadingBoundary(props) {
  const { message, onCloseModal, children } = props;

  return (
    <Suspense
      fallback={
        <Modal show>
          <Loader message={message} spacedOut />
        </Modal>
      }
    >
      <NetworkErrorBoundary
        fallbackComponent={({ error }) => (
          <Modal show onHide={onCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Alert variant="warning" style={{ margin: 16 }}>
              An error occured: {error.message}
            </Alert>
          </Modal>
        )}
      >
        {children}
      </NetworkErrorBoundary>
    </Suspense>
  );
}

export default ModalLoadingBoundary;
