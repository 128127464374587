import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { getEventPage } from '../../../api/icat-plus/logbook';
import { useLocation } from 'react-router';
import { Button } from 'react-bootstrap';
import { getOriginalEvent } from '../../../helpers/eventHelpers';

export default function EventButton(props) {
  const user = useSelector((state) => state.user);
  const { sessionId } = user;

  const {
    event,
    pageSize,
    sortOrder,
    types,
    search,
    instrumentName,
    isBeamlineLogbook,
  } = props;
  const eventCreationDate = moment(getOriginalEvent(event).creationDate);
  const location = useLocation();

  function jumpToEvent(event, pageSize, sortOrder, types, instrumentName) {
    axios
      .get(
        getEventPage(
          sessionId,
          event._id,
          isBeamlineLogbook ? null : event.investigationId,
          pageSize,
          sortOrder,
          types,
          instrumentName
        ),
        {}
      )
      .then(({ data }) => {
        const newQuery = new URLSearchParams();
        newQuery.set('page', `${data.page.index}`);
        /*history.push(
          `${location.pathname}?${newQuery.toString()}#${event._id}`
        );*/

        /** Ugly trick to make the component to jump to the selected event **/
        window.location.href = `${location.pathname}?${newQuery.toString()}#${
          event._id
        }`;
      });
  }

  if (search) {
    return (
      <Button
        variant="outline-secondary"
        size="sm"
        id={event._id}
        onClick={() =>
          jumpToEvent(event, pageSize, sortOrder, types, instrumentName)
        }
      >
        {' '}
        {eventCreationDate.format(moment.HTML5_FMT.TIME_SECONDS)}
      </Button>
    );
  }
  return (
    <span className="text-info">
      {eventCreationDate.format(moment.HTML5_FMT.TIME_SECONDS)}
    </span>
  );
}
