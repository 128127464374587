import React from 'react';
import { trackOpenDOI } from '../../helpers/trackData';
import styles from './DOIBadge.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

function DOIBadge(props) {
  const { doi } = props;

  if (!doi) {
    return <FontAwesomeIcon icon={faLock} />;
  }

  if (doi.trim() === '') {
    return null;
  }

  function openDOI() {
    trackOpenDOI(doi);
  }

  const doiUrl = `https://doi.esrf.fr/${doi}`;
  return (
    <a
      className={styles.badge}
      target="_blank"
      rel="noopener noreferrer"
      href={doiUrl}
      onClick={(e) => openDOI(e)}
    >
      DOI
      <span className={styles.doi}>{doi}</span>
    </a>
  );
}

export default DOIBadge;
