import React, { useState } from 'react';
import FileTable from './FileTable';
import { Row, Col, Card, Container } from 'react-bootstrap';
import H5Viewer from './H5Viewer';

function DatasetFileTree(props) {
  const { sessionId, dataset } = props;

  const [datafileSelected, setDatafileSelected] = useState(null);

  const selectDatafile = (dataFile) => {
    setDatafileSelected(dataFile);
  };

  return (
    <Container fluid>
      <Row style={{ margin: 10 }}>
        <Col xs={12} md={6}>
          <FileTable
            dataset={dataset}
            sessionId={sessionId}
            selectDatafile={selectDatafile}
          ></FileTable>
        </Col>
        <Col xs={12} md={6}>
          {datafileSelected && (
            <Card variant="primary">
              <Card.Header>
                <Card.Title>{datafileSelected.name}</Card.Title>
              </Card.Header>
              <Card.Body>
                <H5Viewer
                  sessionId={sessionId}
                  iframeStyle={{
                    marginTop: '50px',
                    width: '100%',
                    height: '600px',
                  }}
                  datafile={datafileSelected}
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default DatasetFileTree;
