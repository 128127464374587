// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_footer__2NNgL {\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.75rem;\n  background-color: #101010;\n  color: #ccc;\n  text-align: center;\n}\n\n.Footer_logo__3I7YG {\n  width: auto;\n  height: 50px;\n  margin-right: 1.5rem;\n}\n", ""]);
// Exports
exports.locals = {
	"footer": "Footer_footer__2NNgL",
	"logo": "Footer_logo__3I7YG"
};
module.exports = exports;
