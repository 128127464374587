import moment from 'moment';
import React, { useEffect } from 'react';
import { Tab, Tabs, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import GeneralStatsPanel from '../../components/ManagerStats/GeneralStats/GeneralStatsPanel';
import BeamlinePlot from '../../components/ManagerStats/GeneralStats/BeamlinePlot';
import { usePageTracking, useQuery } from '../../helpers/hooks';
import LoadingBoundary from '../../components/LoadingBoundary';
import { useHistory } from 'react-router';
import DataStatisticsMenu from './DataStatisticsMenu';
/* Friendly name to map the event key of the tabs with the URL */
const tabAlias = ['overall', 'beamlines'];

function DataStatisticsPage() {
  usePageTracking();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const instrumentName = query.get('instrumentName') || '';
  const interval = query.get('interval') || '1d';
  const aggregation = query.get('aggregation') || 'instrumentName';
  const metric = query.get('metric') || 'volume';
  const startDate =
    query.get('startDate') || moment().format(moment.HTML5_FMT.DATE);
  const endDate =
    query.get('endDate') ||
    moment().add(1, 'days').format(moment.HTML5_FMT.DATE);
  const plottype = query.get('plottype') || 'heatmap';

  const openData = query.get('openData') || false;

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        {
          name: `Data Statistics ${instrumentName}`,
        },
      ])
    );
  }, [dispatch, instrumentName]);

  const handleSelect = (key) => {
    query.set('tab', tabAlias[key - 1]);
    history.push({ search: query.toString() });
  };

  return (
    <div className="app__inner">
      <DataStatisticsMenu
        startDate={startDate}
        endDate={endDate}
        metric={metric}
        aggregation={aggregation}
        interval={interval}
        tab={query.get('tab') ? query.get('tab') : 'overall'}
        plottype={plottype}
        openData={openData}
      ></DataStatisticsMenu>

      <Tabs
        id="controled-statisticstab"
        onSelect={handleSelect}
        defaultActiveKey={
          query.get('tab') ? tabAlias.indexOf(query.get('tab')) + 1 : 1
        }
        style={{ marginTop: 10 }}
      >
        <Tab eventKey={1} title="Overall">
          <Card style={{ margin: 10 }} border="primary">
            <Card.Header>
              <Card.Title>
                From {startDate} to {endDate}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <GeneralStatsPanel
                key="Today"
                instrumentName={instrumentName}
                user={user}
                start={startDate}
                end={endDate}
                openData={openData}
                name=""
              />
            </Card.Body>
            <Card.Footer>
              Statistics from data produced {startDate} until {endDate}. The pie
              plots represent the volume of data 1) per definition (technique)
              and 2) per beamline
            </Card.Footer>
          </Card>

          <Card style={{ margin: 10 }} border="info">
            <Card.Header>
              <Card.Title>Since last 7 days</Card.Title>
            </Card.Header>
            <Card.Body>
              <GeneralStatsPanel
                key="Week"
                instrumentName={instrumentName}
                user={user}
                start={moment()
                  .subtract(7, 'days')
                  .format(moment.HTML5_FMT.DATE)}
                end={moment().add(1, 'days').format(moment.HTML5_FMT.DATE)}
                name=""
                openData={openData}
              />
            </Card.Body>
          </Card>

          <Card style={{ margin: 10 }} border="primary">
            <Card.Header>
              <Card.Title>All</Card.Title>
            </Card.Header>
            <Card.Body>
              <GeneralStatsPanel
                key="all"
                instrumentName={instrumentName}
                user={user}
                start="2014-01-01"
                end="2024-01-01"
                name="From 2024-01-01"
                openData={openData}
              />
            </Card.Body>
            <Card.Footer>Data produced since the beginning of time</Card.Footer>
          </Card>
        </Tab>
        <Tab eventKey={2} title="Beamlines">
          <LoadingBoundary message="Retrieving data...">
            <BeamlinePlot
              instrumentName={instrumentName}
              user={user}
              aggregation={aggregation}
              interval={interval}
              startDate={startDate}
              endDate={endDate}
              metric={metric}
              plottype={plottype}
            ></BeamlinePlot>
          </LoadingBoundary>
        </Tab>
      </Tabs>
    </div>
  );
}

export default DataStatisticsPage;
/**


 */
