import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getCountLogbookStatisticsURL } from '../api/icat-plus/logbook';

export default class CountlogbookStatisticsResource extends Resource {
  _id = undefined;

  pk() {
    return this._id + this.count;
  }

  static get key() {
    return 'CountlogbookStatisticsResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { startDate, endDate, type } = params;
    return getCountLogbookStatisticsURL(sessionId, startDate, endDate, type);
  }
}
