import React from 'react';
import { Card } from 'react-bootstrap';
import UI from '../../config/ui';
import { ES_RESOURCE_TYPE } from '../../constants/doi';
import DOIBadge from '../doi/DOIBadge';
import {
  getBeamlineUrlFromInstrumentList,
  getFieldFromSubjects,
  getMergedProposalAndBeamline,
  getValueOrNotAvailable,
} from './utilsDoiExtractor';
import { ProposalBeamlineWidget } from './ProposalBeamlineWidget';
import InstrumentsResource from '../../resources/instruments';
import { useResource } from 'rest-hooks';

export function MetadataWidget(props) {
  const { doi, resourceType, subjects, sessionDate, publicationYear } = props;
  const publisher = UI.doi.publisher.name;
  const publisherUrl = UI.doi.publisher.url;

  const instruments = useResource(InstrumentsResource.listShape(), {});

  const isSessionResource = resourceType === ES_RESOURCE_TYPE;
  const proposalType = getFieldFromSubjects(
    subjects,
    'Proposal Type Description'
  );

  const proposalNames = getFieldFromSubjects(subjects, 'Proposal');
  const beamlines = getFieldFromSubjects(subjects, 'Instrument');

  const beamlineUrls = getBeamlineUrlFromInstrumentList(beamlines, instruments);
  const proposalBeamlines = getMergedProposalAndBeamline(
    proposalNames,
    beamlines,
    beamlineUrls
  );

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <Card.Header>Metadata</Card.Header>
        <Card.Body>
          <Card.Title>Identifier</Card.Title>
          <Card.Text>
            <DOIBadge doi={doi} />
          </Card.Text>
          {isSessionResource ? (
            <ProposalBeamlineWidget
              proposalBeamlines={proposalBeamlines}
              proposalTitle="Proposal"
              beamlineTitle="Beamline"
            />
          ) : (
            <ProposalBeamlineWidget
              proposalBeamlines={proposalBeamlines}
              proposalTitle="Proposals"
              beamlineTitle="Beamlines"
            />
          )}
          <Card.Title>Public release year</Card.Title>
          <Card.Text>{getValueOrNotAvailable(publicationYear)}</Card.Text>
          {isSessionResource && (
            <>
              <Card.Title>Session date</Card.Title>
              <Card.Text>{getValueOrNotAvailable(sessionDate)}</Card.Text>
              <Card.Title>Category</Card.Title>
              <Card.Text>{getValueOrNotAvailable(proposalType)}</Card.Text>
            </>
          )}
          <Card.Title>Publisher</Card.Title>
          <Card.Text>
            <a href={publisherUrl} target="_blank" rel="noopener noreferrer">
              {publisher}
            </a>
          </Card.Text>
          <Card.Title>Licence (for files)</Card.Title>
          <Card.Text>
            <a
              href="https://creativecommons.org/licenses/by/4.0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Creative Commons Attribution 4.0{' '}
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
