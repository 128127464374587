import React from 'react';
import { Button } from 'react-bootstrap';
import ICAT from '../../config/icat';
import { doSignIn } from '../../actions/login';
import { useDispatch } from 'react-redux';
import { trackAnonymousSignIn } from '../../helpers/trackData';

function AnonymousSignIn() {
  const dispatch = useDispatch();

  function handleClick() {
    trackAnonymousSignIn();
    dispatch(
      doSignIn(
        ICAT.authentication.anonymous.plugin,
        ICAT.authentication.anonymous.username,
        ICAT.authentication.anonymous.password
      )
    );
  }

  if (!ICAT.authentication.anonymous.enabled) {
    return null;
  }

  return (
    <p style={{ marginTop: 5, marginBottom: -10, textAlign: 'center' }}>
      <Button variant="link" onClick={handleClick}>
        or sign in as anonymous
      </Button>
    </p>
  );
}

export default AnonymousSignIn;
