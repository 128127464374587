import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import logo from '../../images/loader.gif';

/**
 * React component used to render a box overlayed on top of the page
 */
function OverlayBox(props) {
  const { classNames, onClose, open, children } = props;
  const [isChildComponentVisible, setIsChildComponentVisible] = useState(false);

  /** Callback triggered when the overlay box is opened. */
  const onEntered = () => {
    /** this is a trick to circumvent a bug with tinymce in modals. the modal needs to be displayed first and tinymce shown afterwards otherwise a bug occurs in tinymce. */
    setTimeout(() => {
      setIsChildComponentVisible(true);
    }, 100);
  };

  /**
   * Callback triggered when the modal is close. This happends when props.open becomes false
   */
  const onExited = () => {
    setIsChildComponentVisible(false);
  };

  return (
    <Modal
      animationDuration={0}
      classNames={classNames}
      onClose={onClose ? onClose : () => null}
      onEntered={onEntered}
      onExited={onExited}
      open={open}
      blockScroll={false}
      showCloseIcon={onClose ? true : false}
      focusTrapped={false}
      center
    >
      {isChildComponentVisible ? (
        children
      ) : (
        <img
          src={logo}
          alt="logo"
          style={{
            position: 'absolute',
            left: '50%',
            marginLeft: '-25px',
            top: '50%',
            marginTop: '-25px',
            height: 50,
            width: 50,
          }}
        />
      )}
    </Modal>
  );
}

OverlayBox.propTypes = {
  /** css classes used by the overlay box */
  classNames: PropTypes.object,
  /** when set to true the OverlayBox is opened. */
  open: PropTypes.bool,
};

export default OverlayBox;
