import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class ItemResource extends Resource {
  _id = undefined;
  name = '';
  type = '';
  description = '';
  comments = '';
  sampleId = undefined;

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'ItemResource';
  }

  static url(params) {
    const { sessionId } = store.getState().user;
    const { investigationId, parcelId } = params;

    return `${
      ICATPLUS.server
    }/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${parcelId}/item/${this.pk(
      params
    )}`;
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId, parcelId } = params;
    return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/parcel/id/${parcelId}/item`;
  }
}
