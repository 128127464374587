import React from 'react';
import moment from 'moment';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { stringifyBytesSize } from '../../helpers';
import { INVESTIGATION_DATE_FORMAT } from '../../constants';
import TECHNIQUES from '../../config/techniques';

function getTechniqueColor(definition) {
  if (definition && TECHNIQUES) {
    const technique = TECHNIQUES.find(
      (technique) => technique.shortname === definition
    );
    if (technique) {
      return technique.color;
    }
  }
  return 'white';
}
export default function SearchDataset(props) {
  const { data } = props;

  const styleTechnique = {
    backgroundColor: getTechniqueColor(data.definition),
    fontSize: 10,
  };

  const redirect = `/investigation/${data.investigationId}/datasets`;

  return (
    <div>
      <Container fluid>
        <Row>
          <Col sm={4}>
            <Badge style={styleTechnique}> {data.definition} </Badge>
          </Col>
          <Col sm={4} />
          <Col sm={4} style={{ fontSize: 12 }}>
            <Badge bg="primary"> DATASET </Badge>
          </Col>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Name
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            <a href={redirect}>{data.name}</a>
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Sample
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.sampleName}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Proposal
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.investigationName}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Date
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {moment(data.startDate).format(INVESTIGATION_DATE_FORMAT)}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Beamline
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.investigationVisitId}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Files
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.fileCount}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Volume
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {stringifyBytesSize(data.volume)}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Mode
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentSource_mode}
          </Col>
        </Row>

        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Type
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentMonochromatorCrystal_type}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Reflection
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentMonochromatorCrystal_reflection}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Usage
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentMonochromatorCrystal_usage}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Energy
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentMonochromator_energy}
          </Col>
        </Row>
        <Row>
          <Col
            sm={3}
            style={{ textAlign: 'left', color: 'gray', fontSize: 11 }}
          >
            Wavelength
          </Col>
          <Col sm={9} style={{ textAlign: 'left', fontSize: 11 }}>
            {data.InstrumentMonochromator_wavelength}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
