import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Button, Row, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function PageNotFound() {
  return (
    <Container style={{ marginTop: 100 }}>
      <Row>
        <Col xs={12}>
          <h1>Page Not Found</h1>
          <LinkContainer exact to="/" style={{ marginTop: 10 }}>
            <Button variant="primary">
              <FontAwesomeIcon icon={faHome} style={{ marginRight: 10 }} />
              Back to home
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;
