export const BREAKPOINTS = {
  xs: 768,
  sm: 992,
  md: 1200,
  lg: 10000,
};

export const PERSPECTIVE = {
  Datasets: 1,
  Files: 2,
  Events: 3,
};

export const ITEM_TYPES = Object.freeze({
  SampleSheet: 'SAMPLESHEET',
  Tool: 'TOOL',
  Other: 'OTHER',
});

export const ITEM_TYPE_DEFS = {
  [ITEM_TYPES.SampleSheet]: {
    optionLabel: 'Sample Sheet',
    countLabel: 'sample',
  },
  [ITEM_TYPES.Tool]: { optionLabel: 'Tool', countLabel: 'tool' },
  [ITEM_TYPES.Other]: { optionLabel: 'Other', countLabel: 'other' },
};

export const DATASET_TIME_FORMAT = 'HH:mm:ss';

export const INVESTIGATION_DATE_FORMAT = 'DD/MM/YYYY';
export const INVESTIGATION_TIME_FORMAT = 'hh:mm';

export const BYTE_UNITS = ['PB', 'TB', 'GB', 'MB', 'KB'];

export const USER_ROLES = Object.freeze({
  PrincipalInvestigator: 'Principal investigator',
  LocalContact: 'Local contact',
  Collaborator: 'Collaborator',
});
