import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Card, Row, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDatasetsById,
  getUsersByInvestigationIds,
} from '../../api/icat-plus/catalogue';
import DOIForm from '../../components/doi/DOIForm';
import Loader from '../../components/Loader';
import { setBreadCrumbs } from '../../actions/breadcrumbs';
import DatasetSummaryList from './DatasetSummaryList';
import { usePageTracking } from '../../helpers/hooks';

function MintSelectionPage() {
  usePageTracking();
  const sessionId = useSelector((state) => state.user.sessionId);
  const selectedDatasets = useSelector((state) => state.selectedDatasets);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    datasets: [],
    users: [],
    isFetching: false,
  });

  const { datasets, users, isFetching: fetching } = state;

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { name: 'My Selection', link: '/selection' },
        { name: 'Mint DOI' },
      ])
    );

    async function fetchDatasetsAndUsers() {
      const datasets = await axios.get(
        getDatasetsById(sessionId, selectedDatasets)
      );

      const investigationIds = datasets.data.map((d) => d.investigation.id);
      const users = await axios.get(
        getUsersByInvestigationIds(sessionId, [...new Set(investigationIds)])
      );

      setState({
        datasets: datasets.data,
        users: users.data,
        isFetching: false,
      });
    }

    if (selectedDatasets.length > 0) {
      setState({ datasets: [], users: [], isFetching: true });
      fetchDatasetsAndUsers();
    }
  }, [selectedDatasets, dispatch, sessionId]);

  return (
    <div className="app__inner">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Alert variant="info">
              <p>
                You can only mint a DOI for datasets which are linked to
                investigations where you are a user.
              </p>
              <hr />
              <p className="mb-0">
                {' '}
                Minting the DOI makes the datasets public immediately.
              </p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <Card variant="primary">
              <Card.Header>
                <strong>Please fill in this form</strong>
              </Card.Header>
              <Card.Body>
                {fetching ? (
                  <Loader message="Loading authors..." inPanel />
                ) : (
                  <DOIForm
                    sessionId={sessionId}
                    datasets={datasets}
                    investigationUsers={users}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={5}>
            <DatasetSummaryList
              fetching={fetching}
              datasets={datasets}
            ></DatasetSummaryList>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MintSelectionPage;
