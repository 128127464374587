import axios from 'axios';
import { getInstrumentScientistsBySessionId } from '../api/icat-plus/catalogue';
import { FETCH_INSTRUMENTSCIENTISTS } from '../constants/actionTypes';

export function fetchInstrumentScientistsBySessionId(sessionId) {
  return function (dispatch) {
    dispatch({
      type: FETCH_INSTRUMENTSCIENTISTS,
      payload: axios.get(getInstrumentScientistsBySessionId(sessionId)),
    });
  };
}
