import React from 'react';
import { Card } from 'react-bootstrap';
import BootstrapTable2 from 'react-bootstrap-table-next';
import Loader from '../../components/Loader';

function DatasetSummaryList(props) {
  const { datasets, fetching } = props;

  return (
    <Card variant="info">
      <Card.Header>
        <strong>Dataset List</strong>
      </Card.Header>
      <Card.Body>
        {fetching ? (
          <Loader message="Loading datasets..." inPanel />
        ) : (
          <BootstrapTable2
            keyField="id"
            data={datasets}
            columns={[
              { dataField: 'id', text: '', hidden: true },
              { dataField: 'name', text: 'Name' },
              {
                dataField: 'investigation.name',
                text: 'Proposal',
                headerStyle: () => ({ width: 120 }),
              },
            ]}
            pageOptions={{
              paginationSize: 10,
              sizePerPage: 25,
              showTotal: true,
              hidePageListOnlyOnePage: true,
            }}
            striped
            condensed
            noDataIndication="This is no data to display"
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default DatasetSummaryList;
