import React from 'react';
import { Card } from 'react-bootstrap';
import { ESRFExperimentalReportWidget } from './ESRFExperimentalReportWidget';

export function ExperimentalReportFactory(props) {
  const { component, data } = props;
  switch (component) {
    case 'ESRFExperimentalReportWidget':
      return <ESRFExperimentalReportWidget subjects={data.subjects} />;
    default:
      return (
        <Card style={{ marginBottom: 12 }}>
          <Card.Header>Experimental Report</Card.Header>
          <Card.Body>No experimental report widget defined!</Card.Body>
        </Card>
      );
  }
}
