import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getDatasetParameterURL } from '../api/icat-plus/catalogue';

export default class DatasetParameter extends Resource {
  parameterId = undefined;
  value = undefined;

  pk() {
    return this.parameterId?.toString();
  }

  static get key() {
    return 'DatasetParameterResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { parameterId } = params;
    return getDatasetParameterURL(sessionId, parameterId);
  }

  static url(params) {
    return this.listUrl(params);
  }

  static createShape() {
    return {
      ...super.createShape(),
      getFetchKey: (params) => `POST ${this.url(params)}`,
      fetch: (tag) => this.fetch('post', this.url(tag), tag),
    };
  }

  static updateShape() {
    return {
      ...super.updateShape(),
      fetch: (tag) => this.fetch('put', this.url(tag), tag),
    };
  }

  static deleteShape() {
    return {
      ...super.deleteShape(),
      fetch: (tag) => this.fetch('delete', this.url(tag), tag),
    };
  }
}
