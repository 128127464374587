import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { useFetcher } from 'rest-hooks';
import { useQuery } from '../../helpers/hooks';
import ShipmentResource from '../../resources/shipment';
import ShipmentSummary from './ShipmentSummary';
import ShippingForm from './ShippingForm';
import LoadingBoundary from '../LoadingBoundary';

function ShipmentSection(props) {
  const {
    investigation,
    shipment,
    defaultShippingAddress,
    defaultReturnAddress,
  } = props;
  const apiParams = { investigationId: investigation.id };

  const history = useHistory();
  const query = useQuery();
  const isConfiguring = !shipment || query.has('configure');

  const createShipment = useFetcher(ShipmentResource.createShape());
  const editShipment = useFetcher(ShipmentResource.updateShape());
  const refetchShipments = useFetcher(ShipmentResource.listShape());

  async function handleSubmit(values) {
    if (!shipment) {
      await createShipment(apiParams, values);
    } else {
      await editShipment(apiParams, values);
    }

    await refetchShipments(apiParams);
    history.push(`/investigation/${investigation.id}/shipping`);
  }

  return (
    <Container style={{ marginLeft: 0 }} fluid>
      <div className="actions-heading">
        <h3>Shipping configuration</h3>
        {!isConfiguring && (
          <LinkContainer
            to={`/investigation/${investigation.id}/shipping?configure`}
          >
            <Button variant="primary">Edit</Button>
          </LinkContainer>
        )}
      </div>

      {isConfiguring ? (
        <LoadingBoundary message="Loading your addresses..." spacedOut>
          <ShippingForm
            investigation={investigation}
            shipment={shipment}
            defaultShippingAddress={defaultShippingAddress}
            defaultReturnAddress={defaultReturnAddress}
            onSubmitAsync={handleSubmit}
          />
        </LoadingBoundary>
      ) : (
        <ShipmentSummary
          shipment={shipment}
          defaultShippingAddress={defaultShippingAddress}
          defaultReturnAddress={defaultReturnAddress}
        />
      )}
    </Container>
  );
}

export default ShipmentSection;
