// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BreadCrumbs_bar__Q1eyP {\n  min-height: 0;\n  border-radius: 0;\n  border-left: 0;\n  border-right: 0;\n  font-size: 15px;\n}\n\n.BreadCrumbs_backBar__1Ez7J {\n  padding: 8px 0;\n}\n\n.BreadCrumbs_list__3mB_N {\n  margin-bottom: 0;\n  padding-left: 0;\n  padding-right: 0;\n  border-radius: 0;\n}\n\n.BreadCrumbs_list__3mB_N .active {\n  font-weight: bold;\n}\n\n.BreadCrumbs_badge__aNMJZ {\n  margin-right: 5px;\n  font-size: 15px;\n}\n", ""]);
// Exports
exports.locals = {
	"bar": "BreadCrumbs_bar__Q1eyP",
	"backBar": "BreadCrumbs_backBar__1Ez7J BreadCrumbs_bar__Q1eyP",
	"list": "BreadCrumbs_list__3mB_N",
	"badge": "BreadCrumbs_badge__aNMJZ"
};
module.exports = exports;
