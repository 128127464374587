import {
  APPEND_BREADCRUMB,
  CLEAN_BREADCRUMBS,
  LOG_OUT,
  SET_BREADCRUMBS,
  REPLACE_BREADCRUMB,
} from '../constants/actionTypes';

/**
 * Example: "items": [{name:'test', link:"test"}]
 */
const initialState = { items: [] };

const breadcrumbsList = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREADCRUMBS: {
      state = { ...state, items: action.breadcrumbs };
      break;
    }
    case APPEND_BREADCRUMB: {
      state = { ...state, items: state.items.concat(action.breadcrumb) };
      break;
    }
    case REPLACE_BREADCRUMB: {
      let breadcrumbsItems = state.items;
      if (action.level < breadcrumbsItems.length) {
        breadcrumbsItems[action.level] = action.breadcrumb;
      } else {
        breadcrumbsItems = breadcrumbsItems.concat(action.breadcrumb);
      }
      state = { ...state, items: breadcrumbsItems };
      break;
    }
    case CLEAN_BREADCRUMBS: {
      state = { items: [] };
      break;
    }
    case LOG_OUT: {
      state = { items: [] };
      break;
    }
    default:
      break;
  }
  return state;
};

export default breadcrumbsList;
