import React from 'react';
import { Card } from 'react-bootstrap';
import { DOIReferenceItem } from './DOIReferenceItem';

export function ReferenceWidget(props) {
  const { doi } = props;

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <Card.Header>Reference</Card.Header>
        <Card.Body>
          <Card.Text>
            Researchers must acknowledge the source of the data and cite its
            unique identifier as well as any publications linked to the same raw
            data.
            <br />
            Below is the recommended format for citing this work in a research
            publication.
          </Card.Text>
          <DOIReferenceItem doi={doi} canCopyToClipboard={true} />
        </Card.Body>
      </Card>
    </>
  );
}
