import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../../../actions/breadcrumbs';
import ParcelStats from './ParcelStats';
import LoadingBoundary from '../../../components/LoadingBoundary';
import { usePageTracking } from '../../../helpers/hooks';

function SampleTrackingStatsPage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'My Parcels' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading parcels...">
        <ParcelStats />
      </LoadingBoundary>
    </div>
  );
}

export default SampleTrackingStatsPage;
