import { parse } from 'date-fns';

const DATASET_WIDGET_FACTORY = [
  {
    projectName: 'The Human Organ Atlas',
    widget: 'HumanAtlasDatasetWidget',
    startDate: parse('01-01-2019', 'dd-MM-yyyy', new Date()),
    endDate: '',
  },

  {
    definition: 'Publication',
    widget: 'PublicationSummary',
    startDate: parse('01-01-2019', 'dd-MM-yyyy', new Date()),
    endDate: '',
  },
  {
    definition: 'SAXS',
    beamline: 'BM29',
    widget: 'BioSaxsDatasetWidget',
    startDate: parse('25-09-2022', 'dd-MM-yyyy', new Date()),
    endDate: '',
  },
  {
    definition: 'SAXS',
    beamline: 'BM29',
    widget: 'BioSaxsDatasetWidget',
    startDate: parse('25-09-2022', 'dd-MM-yyyy', new Date()),
    endDate: '',
  },
  {
    definition: 'HTXRPD',
    beamline: 'ID00',
    widget: 'HTXRPDDatasetWidget',
    startDate: parse('03-11-2022', 'dd-MM-yyyy', new Date()),
    endDate: parse('16-11-2022', 'dd-MM-yyyy', new Date()),
  },
  {
    definition: 'HTXRPD',
    beamline: 'ID31',
    widget: 'HTXRPDDatasetWidget',
    startDate: parse('13-09-2022', 'dd-MM-yyyy', new Date()),
    endDate: parse('16-09-2022', 'dd-MM-yyyy', new Date()),
  },
];

export default DATASET_WIDGET_FACTORY;
