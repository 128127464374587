import React from 'react';
import { useParams } from 'react-router';
import { useResource } from 'rest-hooks';
import { LandingPage } from '../components/LandingPage/LandingPage';
import UI from '../config/ui';
import DataciteDoiResource from '../resources/dataciteDoi';

function DataCiteLandingPage() {
  const { prefix, suffix } = useParams();
  const doi = `${prefix}/${suffix}`;
  const acceptedPrefix = UI.doi.prefix;

  const hasAcceptedPrefix = doi && doi.startsWith(acceptedPrefix);

  let error = false;
  const data = useResource(DataciteDoiResource.detailShape(), { doi });
  if (data.data.id === -1) {
    error = true;
  }

  return (
    <LandingPage
      data={data.data.attributes}
      hasAcceptedPrefix={hasAcceptedPrefix}
      error={error}
    />
  );
}

export default DataCiteLandingPage;
