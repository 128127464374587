import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { LinkContainer } from 'react-router-bootstrap';
import { useResource } from 'rest-hooks';
import AddressResource from '../../resources/address';
import { addressFormatter } from '../Address/utils';
import FieldAlert from '../Form/FieldAlert';
import CreateAddress from './CreateAddress';
import styles from './ShippingForm.module.css';
import { addAddressIfAbsent } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import TextFieldGroup from '../Form/TextFieldGroup';

const FORM_LAYOUT = { labelCol: { sm: 4 }, fieldCol: { sm: 8 } };

function ShippingForm(props) {
  const {
    investigation,
    shipment,
    defaultShippingAddress,
    defaultReturnAddress,
    onSubmitAsync,
  } = props;
  const { id: investigationId, name: investigationName } = investigation;

  const methods = useForm({
    defaultValues: {
      name: 'shipment',
      ...shipment,
      defaultShippingAddress: defaultShippingAddress?._id,
      defaultReturnAddress: defaultReturnAddress && defaultReturnAddress._id,
    },
  });

  const { handleSubmit, errors, register, setValue, watch } = methods;
  const { isSubmitting } = methods.formState;

  const watchedShippingAddressId = watch('defaultShippingAddress');
  const watchedReturnAddressId = watch('defaultReturnAddress') || null;

  const userAddresses = useResource(AddressResource.listShape(), {});
  const shippingAddressOptions = addAddressIfAbsent(
    userAddresses,
    defaultShippingAddress
  );
  const returnAddressOptions = addAddressIfAbsent(
    userAddresses,
    defaultReturnAddress
  );

  const [alert, setAlert] = useState();

  async function handleFormSubmit(values) {
    try {
      setAlert(undefined);
      await onSubmitAsync({
        ...values,
        defaultReturnAddress: values.defaultReturnAddress || null,
      });
    } catch (error) {
      setAlert({ type: 'danger', message: `An error occurred while saving.` });
      console.error(error.response);
    }
  }

  return (
    <>
      {alert && (
        <Alert variant={alert.type} onDismiss={() => setAlert(undefined)}>
          {alert.message}
        </Alert>
      )}
      <Form>
        {shipment && <input name="_id" type="hidden" ref={register} />}
        <input name="name" type="hidden" ref={register} />
        <input
          name="investigationId"
          type="hidden"
          defaultValue={investigationId}
          ref={register}
        />
        <input
          name="investigationName"
          type="hidden"
          defaultValue={investigationName}
          ref={register}
        />
        <p className={styles.text}>
          The addresses you provide below will be used as defaults for new
          parcels.
        </p>

        <Form.Group
          as={Row}
          className="mb-3"
          controlId="defaultShippingAddress"
        >
          <Form.Label column sm={4}>
            Default sender's address{' '}
            <span className={styles.hint}>– required</span>
          </Form.Label>

          <Col sm={8}>
            <Form.Select
              name="defaultShippingAddress"
              ref={register({ required: true })}
            >
              <option value="">Select default sender's address...</option>
              {shippingAddressOptions.map((addr) => (
                <option key={addr._id} value={addr._id}>
                  {addressFormatter(addr)}
                </option>
              ))}
              )
            </Form.Select>
            {shipment &&
              watchedShippingAddressId &&
              shipment.defaultShippingAddress !== watchedShippingAddressId && (
                <Alert>
                  <FontAwesomeIcon icon={faExclamationTriangle} /> The new
                  default address will not be applied to existing parcels
                  automatically
                </Alert>
              )}
            {errors.defaultShippingAddress && (
              <FieldAlert
                fieldLabel="Default sender's address"
                error={errors.defaultShippingAddress}
              />
            )}
            {shippingAddressOptions.length === 0 && 'No addresses found! '}
            <CreateAddress
              investigation={investigation}
              onCreate={(id) => {
                setValue('defaultShippingAddress', id);
              }}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="defaultReturnAddress">
          <Col sm={4}>
            <Container style={{ padding: 0 }} fluid>
              <Row>
                <Form.Label column>
                  Default return address{' '}
                  <span className={styles.hint}>– optional</span>
                </Form.Label>
              </Row>
              <Row>
                <Form.Text id="defaultReturnAddressHelp" muted>
                  The <em>return address</em> is the address to which you would
                  like a parcel to be returned at the end of the investigation.
                  Parcels without a return address are discarded at the end of
                  the investigation.
                </Form.Text>
              </Row>
            </Container>
          </Col>
          <Col sm={8}>
            <Form.Select name="defaultReturnAddress" ref={register}>
              <option value="">No default return address</option>
              {returnAddressOptions.map((addr) => (
                <option key={addr._id} value={addr._id}>
                  {addressFormatter(addr)}
                </option>
              ))}
            </Form.Select>
            {shipment &&
              shipment.defaultReturnAddress !== watchedReturnAddressId && (
                <Alert>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  The new default address will not be applied to existing
                  parcels automatically
                </Alert>
              )}
            {errors.defaultReturnAddress && (
              <FieldAlert
                fieldLabel="Default return address"
                error={errors.defaultReturnAddress}
              />
            )}
            {returnAddressOptions.length === 0 && 'No addresses found! '}
            <CreateAddress
              investigation={investigation}
              onCreate={(id) => {
                setValue('defaultReturnAddress', id);
              }}
            />
          </Col>
        </Form.Group>
        <FormProvider {...methods}>
          <TextFieldGroup
            name="comments"
            type="textarea"
            layout={FORM_LAYOUT}
          />
        </FormProvider>
        <Row>
          <Col {...FORM_LAYOUT.fieldCol} offset={FORM_LAYOUT.labelCol.sm}>
            <Button
              variant="primary"
              disabled={isSubmitting}
              onClick={handleSubmit(handleFormSubmit)}
              style={{ marginTop: 10 }}
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            {shipment && (
              <LinkContainer to={`/investigation/${investigation.id}/shipping`}>
                <Button variant="link">Cancel</Button>
              </LinkContainer>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ShippingForm;
