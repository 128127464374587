import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getInstrumentStatistics } from '../../../api/icat-plus/elasticsearch';
import ParameterTableWidget from '../../Instrument/ParameterTableWidget';
import Loader from '../../Loader';
import { ErrorUserMessage } from '../../UserMessages/ErrorUserMessage';
import { stringifyBytesSize } from '../../../helpers';
import PlotWidget from '../../../containers/Stats/PlotWidget';

// GB
const GB = 1024 * 1024 * 1024;
function GeneralStatsPanel(props) {
  const { user, instrumentName, start, end, name, openData } = props;

  const [message, setMessage] = useState(true);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    setFetching(true);
    axios
      .post(getInstrumentStatistics(user.sessionId, instrumentName, openData), {
        ranges: [
          {
            name,
            start,
            end,
          },
        ],
      })
      .then((response) => {
        const statistics = Object.keys(response.data.startDate.buckets).map(
          (key) => ({
            date: key,
            data: response.data.startDate.buckets[key],
          })
        );
        setData(statistics);
        setFetching(false);
      })
      .catch((e) => {
        setMessage({
          isError: true,
          text: e.message,
        });
        setFetching(false);
      });
  }, [end, instrumentName, name, openData, start, user.sessionId]);

  const getParameters = (record) => {
    return [
      { name: 'Datasets', value: record.data.doc_count },
      { name: 'Beamlines', value: record.data.instrument.buckets.length },
      {
        name: 'Total Volume',
        value: stringifyBytesSize(record.data.volume_stats.sum),
      },
      { name: 'Total Number of files', value: record.data.fileCount_stats.sum },
    ];
  };

  const getStatsParameters = (record) => {
    if (record.data.doc_count === 0) {
      return;
    }
    return [
      {
        name: 'Average file count',
        value: record.data.fileCount_stats.avg.toFixed(0),
      },
      { name: 'Max files', value: record.data.fileCount_stats.max },
      {
        name: 'Average volume',
        value: stringifyBytesSize(record.data.volume_stats.avg),
      },
      {
        name: 'Max volume',
        value: stringifyBytesSize(record.data.volume_stats.max),
      },
      {
        name: 'Average metadata',
        value: record.data.parametersCount_stats.avg.toFixed(1),
      },
    ];
  };

  if (message.isError) {
    return <ErrorUserMessage text={message.text} />;
  }

  if (fetching) {
    return <Loader message="Loading statistics..." spacedOut />;
  }

  if (data.doc_count === 0) {
    if (data[0].doc_count === 0) {
      return <ErrorUserMessage text="No data found" />;
    }
  }

  return (
    <Suspense fallback={<Loader message="Loading plots..." spacedOut />}>
      {data.map((record) => {
        const data = record.data;
        return (
          <Container fluid style={{ margin: 20 }} key="gsGrid">
            <Row>
              <Col xs={12} md={2}>
                <h4>Summary</h4>
                <ParameterTableWidget
                  striped
                  parameters={getParameters(record)}
                />
              </Col>
              <Col xs={12} md={2}>
                <h4>Dataset</h4>
                <ParameterTableWidget
                  striped
                  parameters={getStatsParameters(record)}
                />
              </Col>
              <Col xs={3}>
                <PlotWidget
                  data={[
                    {
                      type: 'pie',
                      hole: 0.5,
                      textposition: 'inside',
                      textinfo: 'text',
                      texttemplate: '%{label}<br>%{value} GB',
                      text: data.definition.buckets.map((i) => {
                        return `${i.key.toUpperCase()} ${parseFloat(
                          i.volume_stats.sum
                        )}`;
                      }),
                      labels: data.definition.buckets.map((i) =>
                        i.key.toUpperCase()
                      ),
                      values: data.definition.buckets.map((i) =>
                        (i.volume_stats.sum / GB).toFixed(4)
                      ),
                    },
                  ]}
                  layout={{
                    margin: { t: 0, b: 0, l: 0, r: 0 },
                    height: 175,
                    width: 350,
                    showlegend: false,
                    annotations: [
                      {
                        font: {
                          size: 8,
                        },
                        showarrow: false,
                        text: 'Volume/technique',
                      },
                    ],
                  }}
                />
              </Col>
              <Col xs={3}>
                <PlotWidget
                  data={[
                    {
                      type: 'pie',
                      hole: 0.5,
                      textposition: 'inside',
                      textinfo: 'text',
                      texttemplate: '%{label}<br>%{value} GB',
                      text: data.instrument.buckets.map(
                        (i) =>
                          `${i.key.toUpperCase()} ${i.volume_stats.sum / GB}`
                      ),
                      labels: data.instrument.buckets.map((i) =>
                        i.key.toUpperCase()
                      ),
                      values: data.instrument.buckets.map((i) =>
                        (i.volume_stats.sum / GB).toFixed(4)
                      ),
                    },
                  ]}
                  layout={{
                    margin: { t: 0, b: 0, l: 0, r: 0 },
                    height: 175,
                    width: 350,
                    showlegend: false,
                    annotations: [
                      {
                        font: {
                          size: 8,
                        },
                        showarrow: false,
                        text: 'Volume/Beamline',
                      },
                    ],
                  }}
                />
              </Col>
            </Row>
          </Container>
        );
      })}
    </Suspense>
  );
}

export default GeneralStatsPanel;
