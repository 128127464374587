import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import styles from './Menu.module.css';
import { useSelector } from 'react-redux';
import { useResource } from 'rest-hooks';
import InstrumentResource from '../../resources/instrument';
import { BEAMLINE_PATH } from '../../constants/routePaths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

function BeamlinesMenu() {
  const { isAdministrator, isInstrumentScientist } = useSelector(
    (state) => state.user
  );

  const instruments = useResource(InstrumentResource.listShape(), {
    filter: 'instrumentscientist',
  });

  if (!isAdministrator && !isInstrumentScientist) {
    return null;
  }

  const sortedInstruments = instruments
    .slice(0)
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

  return (
    <NavDropdown
      title="My Beamlines"
      id="basic-nav-dropdown"
      className={styles.managerMenu}
    >
      <LinkContainer to="/usermanagement">
        <NavDropdown.Item>
          {' '}
          <FontAwesomeIcon icon={faUser} />
          <span style={{ marginLeft: 10 }}>Beamline Permissions</span>
        </NavDropdown.Item>
      </LinkContainer>

      <NavDropdown.Divider />

      {sortedInstruments.map(({ id, name }) => (
        <LinkContainer key={id} to={`${BEAMLINE_PATH}${name.toLowerCase()}`}>
          <NavDropdown.Item>{name}</NavDropdown.Item>
        </LinkContainer>
      ))}
    </NavDropdown>
  );
}

export default BeamlinesMenu;
