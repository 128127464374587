import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

/** React component which renders a lazyloaded text when necessary. At the moment it is lazyloaded when the text has at
 * least an `img` tag. When there are no images, the text is rendered as such.
 */
function LazyLoadedText(props) {
  const { search } = props;
  let { text } = props;

  const withImages = text.indexOf('img') !== -1;

  /* If search is enabled then the search is highlighted but not in the images as the base64 code can get corrupted */
  if (search && !withImages) {
    text = text.replace(
      new RegExp(search, 'g'),
      `<span style="background-color:yellow;">${search}</span>`
    );
  }

  if (!text) {
    return <div />;
  }

  if (!withImages) {
    return (
      <LazyLoad once>
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </LazyLoad>
    );
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
}

LazyLoadedText.propTypes = {
  /** Text to be rendered. It can be a strig containing HTML code or simply a text */
  text: PropTypes.string,
};

export default LazyLoadedText;
