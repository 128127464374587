import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import FieldAlert from '../Form/FieldAlert';
import TextFieldGroup from '../Form/TextFieldGroup';
import { ITEM_TYPE_DEFS } from '../../constants';

function ItemForm(props) {
  const { item, samples, variant, onSubmit, onCancel } = props;
  const methods = useForm({ defaultValues: item });
  const { handleSubmit, errors, register, formState, setValue } = methods;
  const { isSubmitting } = formState;

  const sampleDescriptions = Object.fromEntries(
    samples.map((s) => [
      s.id,
      (s.parameters.desc && s.parameters.desc.value) || '',
    ])
  );

  const [type, setType] = useState(undefined);
  const [sampleId, setSampleId] = useState(undefined);
  const watchedItemIsSample = type === 'SAMPLESHEET';
  const watchedSampleId = sampleId;

  useEffect(() => {
    // Remove stale description if no sample and sampleId (occurs when changing to Tool or Other from a selected Sample)
    if (!watchedItemIsSample && watchedSampleId) {
      setValue('description', '');
    }
  });

  return (
    <Card variant={variant} style={{ marginBottom: 10 }}>
      <Card.Body>
        <FormProvider {...methods}>
          {item && item._id && (
            <input name="_id" type="hidden" ref={register} />
          )}
          <TextFieldGroup
            registerOptions={{ required: true }}
            name="name"
            type="text"
          />

          <Form.Select
            name="type"
            value={type}
            ref={register({ required: true })}
            style={{ marginTop: 10, marginBottom: 10 }}
            onChange={(event) => {
              setType(event.target.value);
            }}
          >
            <option value="">Select item type...</option>
            {Object.entries(ITEM_TYPE_DEFS).map(([type, { optionLabel }]) => (
              <option key={type} value={type}>
                {optionLabel}
              </option>
            ))}
          </Form.Select>
          {errors?.type && <FieldAlert fieldLabel="type" error={errors.type} />}

          {watchedItemIsSample && (
            <>
              <Form.Select
                name="sampleId"
                value={sampleId}
                ref={register({ required: true })}
                style={{ marginTop: 10, marginBottom: 10 }}
                onChange={(event) => {
                  setSampleId(event.target.value);
                  if (watchedSampleId) {
                    setValue(
                      'description',
                      sampleDescriptions[watchedSampleId]
                    );
                  }
                }}
              >
                <option value="">Select sample...</option>
                {samples.map((sample) => (
                  <option key={sample.id} value={sample.id}>
                    {sample.name}
                  </option>
                ))}
              </Form.Select>
              {errors?.sampleId && (
                <FieldAlert fieldLabel="Sample" error={errors.sampleId} />
              )}
            </>
          )}
          <TextFieldGroup
            name="description"
            label={`Description${watchedItemIsSample ? ' (from sample)' : ''}`}
            type="text"
            disabled={watchedItemIsSample}
          />
          <TextFieldGroup name="comments" type="textarea" />
        </FormProvider>
      </Card.Body>
      <Card.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </Button>
        <Button variant="link" onClick={onCancel} disabled={isSubmitting}>
          Cancel
        </Button>
      </Card.Footer>
    </Card>
  );
}

export default ItemForm;
