import React from 'react';
import { getDatasetParameterByName } from '../../helpers';
import ParameterTableWidget from './ParameterTableWidget';

function InstrumentSlitWidget(props) {
  const { dataset } = props;

  const getParameter = (parameterName) => {
    return getDatasetParameterByName(dataset, parameterName);
  };

  const getJSONKeyValuePair = (name, value) => {
    return { name, value };
  };

  const getSlitValues = (prefix) => {
    return [
      getJSONKeyValuePair('Blade Front', getParameter(`${prefix}_blade_front`)),
      getJSONKeyValuePair('Blade Back', getParameter(`${prefix}_blade_back`)),
      getJSONKeyValuePair('Blade Up', getParameter(`${prefix}_blade_up`)),
      getJSONKeyValuePair('Blade Down', getParameter(`${prefix}_blade_down`)),
      getJSONKeyValuePair(
        'Horizontal Gap',
        getParameter(`${prefix}_horizontal_gap`)
      ),
      getJSONKeyValuePair(
        'Horizontal Offset',
        getParameter(`${prefix}_horizontal_offset`)
      ),
      getJSONKeyValuePair(
        'Vertical Gap',
        getParameter(`${prefix}_vertical_gap`)
      ),
      getJSONKeyValuePair(
        'Vertical Offset',
        getParameter(`${prefix}_vertical_offset`)
      ),
    ];
  };

  return (
    <div className="container-fluid">
      <br />
      <span style={{ fontSize: 16, fontWeight: 'bold' }}>Slits</span>
      <div className="row">
        <div className="col-sm-2">
          <ParameterTableWidget
            header="Primary slit"
            parameters={getSlitValues('InstrumentSlitPrimary')}
          />
        </div>
        <div className="col-sm-2">
          <ParameterTableWidget
            header="Secondary slit"
            parameters={getSlitValues('InstrumentSlitSecondary')}
          />
        </div>
        <div className="col-sm-8">
          <ParameterTableWidget
            header="Slits"
            parameters={getSlitValues('InstrumentSlits')}
          />
        </div>
      </div>
    </div>
  );
}

export default InstrumentSlitWidget;
