import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { VOLUME } from '../../constants/parameterTypes';
import ResponsiveTable from '../Table/ResponsiveTable';
import DatasetWidgetFactory from '../Dataset/Widgets/DatasetWidgetFactory';
import { getDatasetParameterValueByName } from '../../helpers';
import {
  dateFormatter,
  volumeFormatter,
  fileCountFormatter,
  techniqueFormatter,
  downloadFormatter,
} from '../Dataset/utils';
import { useSelector, useDispatch } from 'react-redux';
import { selectDatasets, deselectDatasets } from '../../actions/selection';
import { useQueryParams } from '../../helpers/hooks';
import {
  faSearchMinus,
  faSearchPlus,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DatasetTable(props) {
  const {
    remote = false,
    datasets,
    pageOptions,
    expanded = [],
    search,
    defaultSizePerPage,
    onHandleTableChange,
    onSearch,
  } = props;

  const sessionId = useSelector((state) => state.user.sessionId);
  const selectedDatasets = useSelector((state) => state.selectedDatasets);

  const { expands } = useQueryParams();
  if (expands) {
    expanded.push(parseInt(expands));
  }

  const dispatch = useDispatch();

  const columns = [
    { text: 'id', dataField: 'id', hidden: true },
    {
      text: 'Date',
      dataField: 'startDate',
      sort: true,
      formatter: dateFormatter,
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 150 },
        lg: { width: 150 },
      },
    },
    {
      text: 'Sample',
      dataField: 'sampleName',
      sort: true,
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { hidden: false },
        lg: { hidden: false },
      },
    },
    {
      text: 'Dataset',
      dataField: 'name',
      formatter: (_, dataset) => {
        let datasetName = getDatasetParameterValueByName(
          dataset,
          'datasetName'
        );
        if (!datasetName) {
          datasetName = dataset.name;
        }
        return (
          <>
            <span id={dataset.id}>{datasetName}</span>
          </>
        );
      },
      sort: true,
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: false },
        sm: { hidden: false },
        md: { hidden: false },
        lg: { hidden: false },
      },
    },
    {
      text: 'Definition',
      dataField: 'definition',
      sort: false,
      formatter: (_, dataset) => techniqueFormatter(dataset),
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 130 },
        lg: { width: 130 },
      },
    },
    {
      text: 'Location',
      dataField: 'location',
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { hidden: true },
        lg: { hidden: true },
      },
    },
    {
      text: 'Files',
      dataField: '__fileCount',
      sort: false,
      formatter: (_, dataset) => fileCountFormatter(dataset),
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 80 },
        lg: { width: 80 },
      },
    },
    {
      text: 'Size',
      dataField: VOLUME,
      sort: false,
      formatter: (_, dataset) => volumeFormatter(dataset),
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 80 },
        lg: { width: 80 },
      },
    },
    {
      text: 'Processed',
      dataField: 'processed',
      sort: false,
      formatter: (_, dataset) => {
        return dataset.outputDatasets && dataset.outputDatasets.length > 0 ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <></>
        );
      },
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 80 },
        lg: { width: 80 },
      },
    },

    {
      text: 'Download',
      dataField: 'download',
      sort: false,
      formatter: (_, dataset) => downloadFormatter(dataset, sessionId),
      headerStyle: () => ({ width: '50%', textAlign: 'center' }),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { hidden: true },
        md: { width: 110 },
        lg: { width: 110 },
      },
    },
  ];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedDatasets,
    onSelectAll: handleSelect,
    onSelect: (row, isSelecting) => handleSelect(isSelecting, [row]),
    classes: 'selection-row',
  };

  const expandRow = {
    showExpandColumn: true,
    expanded,
    onExpand: () => {},
    expandHeaderColumnRenderer: ({ isAnyExpands }) => (
      <span style={{ fontSize: 18 }}>
        <FontAwesomeIcon icon={isAnyExpands ? faSearchMinus : faSearchPlus} />
      </span>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="tooltip">View dataset details </Tooltip>}
      >
        {() => (
          <FontAwesomeIcon icon={expanded ? faSearchMinus : faSearchPlus} />
        )}
      </OverlayTrigger>
    ),
    renderer: (dataset) => {
      return <DatasetWidgetFactory dataset={dataset} sessionId={sessionId} />;
    },
  };

  function handleSelect(isSelecting, rows) {
    const ids = rows.map((r) => r.id);
    dispatch(isSelecting ? selectDatasets(ids) : deselectDatasets(ids));
    return true;
  }

  return (
    <div>
      <ResponsiveTable
        remote={remote}
        data={datasets}
        defaultSearchText={search}
        defaultSizePerPage={defaultSizePerPage}
        pageOptions={pageOptions}
        columns={columns}
        handleTableChange={onHandleTableChange}
        onSearch={onSearch}
        expandRow={expandRow}
        selectRow={selectRow}
      />
    </div>
  );
}

DatasetTable.propTypes = {
  datasets: PropTypes.array.isRequired,
  expanded: PropTypes.array,
  defaultSizePerPage: PropTypes.number,
  pageOptions: PropTypes.object,
  search: PropTypes.string,
  /** if true, can be used through RemoteDatasetTable */
  remote: PropTypes.bool.isRequired,
  /* Callback function onSearch in remote mode */
  onSearch: PropTypes.func,
  /* Callback function handleTableChange in remote mode */
  onHandleTableChange: PropTypes.func,
};

export default DatasetTable;
