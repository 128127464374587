import {
  startDateFormatter,
  nameFormatter,
} from '../../../components/Investigation/utils';

const ParcelStatsColumns = [
  {
    text: 'id',
    dataField: 'investigationId',
    hidden: true,
  },
  {
    text: 'investigationName',
    dataField: 'investigationName',
    hidden: true,
  },
  {
    text: 'Proposal',
    dataField: 'investigation',
    sort: true,
    formatter: (investigation) =>
      investigation ? nameFormatter(investigation, true) : '',
  },
  {
    text: 'Beamline',
    dataField: 'investigation',
    formatter: (investigation) => {
      return investigation ? investigation.instrument.name : 'Not available';
    },
    sort: true,
  },

  {
    text: 'Start',
    dataField: 'investigation',
    sort: true,
    formatter: (investigation) => {
      return investigation
        ? startDateFormatter(investigation)
        : 'Not available';
    },
  },
  {
    text: '# Parcels',
    dataField: 'parcels',
    formatter: (parcels) => {
      return parcels.length;
    },
    sort: true,
  },
  {
    text: '# Items',
    dataField: 'itemCount',
    sort: true,
  },
  {
    text: '# Samples',
    dataField: 'sampleCount',
    sort: true,
  },
];

export default ParcelStatsColumns;
