import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatter } from '../Investigation/utils';
import { switchAffilication } from '../../actions/login';

function AffiliationMenu() {
  const { usersByPrefix } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  function getAffiliationName(createTime) {
    return dateFormatter(createTime, 'DD/MM/YYYY', false);
  }

  function handleAffiliation(name) {
    dispatch(switchAffilication(name.split('/')[1]));
  }

  return (
    <>
      {usersByPrefix && usersByPrefix.length > 0 && (
        <NavDropdown
          name="switchAffiliation"
          title="Switch Affiliation"
          id="affiliation-dropdown"
        >
          {usersByPrefix.map(({ name, createTime }) => (
            <NavDropdown.Item
              name={name}
              onClick={() => handleAffiliation(name)}
              key={name}
            >
              {getAffiliationName(createTime)}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      )}
    </>
  );
}

export default AffiliationMenu;
