import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import WelcomeAnnoucements from '../components/Login/WelcomeAnnoucements';
import { usePageTracking } from '../helpers/hooks';

function WelcomePage() {
  usePageTracking();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ name: 'Home' }]));
  }, [dispatch]);

  return (
    <div className="app__inner">
      <WelcomeAnnoucements />
    </div>
  );
}

export default WelcomePage;
