import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import PageNotFound from './PageNotFound';
import { useResource } from 'rest-hooks';
import DatasetResource from '../resources/dataset';
import InvestigationResource from '../resources/investigation';
import { usePageTracking, useQuery } from '../helpers/hooks';
import { store } from '../store';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import DetailView from './DatasetPage/DetailView';
import FileView from './DatasetPage/FileView';
import ViewSwitcher from './DatasetPage/ViewSwitcher';

/**
 * This component shows the information of a single dataset which includes the processed datasets attached to it
 * @returns
 */
function DatasetPage() {
  usePageTracking();
  const { datasetId } = useParams();
  const datasets = useResource(DatasetResource.listShape(), {
    datasetIds: datasetId,
  });

  const { sessionId } = store.getState().user;
  const investigations = useResource(InvestigationResource.listShape(), {
    ids: datasets[0].investigation.id,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;

  const query = useQuery();
  const view = query.get('view') || 'datasets';
  const breadcrumbsList = useSelector((state) => state.breadcrumbsList);
  const currentBreadcrumbsList = useRef(); // work around stale breadcrumbsList reference
  currentBreadcrumbsList.current = breadcrumbsList;

  const dispatch = useDispatch();

  useEffect(() => {
    if (investigation) {
      dispatch(
        setBreadCrumbs([
          {
            name: investigation.title,
            link: `/investigation/${investigation.id}/datasets`,
          },

          { name: datasets[0].name },
        ])
      );
    }
  }, [dispatch, investigation, currentBreadcrumbsList]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!datasets || datasets.length === 0) {
    return <PageNotFound />;
  }

  return (
    <>
      <ViewSwitcher view={view}></ViewSwitcher>
      {view === 'datasets' && <DetailView dataset={datasets[0]}></DetailView>}
      {view === 'files' && (
        <FileView dataset={datasets[0]} sessionId={sessionId}></FileView>
      )}
    </>
  );
}

export default DatasetPage;
