import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, ListGroup, Spinner } from 'react-bootstrap';
import { getOpenCitationURL } from '../../api/icat-plus/doi';
import UI from '../../config/ui';
import { DOIReferenceItem } from './DOIReferenceItem';

/** Component which displays the list of articles citing the given DOI.
 * It calls a webservice from opencitation (only articles whose references contain the DOI, not full-text search)
 */
export function CitationWidget(props) {
  const { doi } = props;
  const [state, setState] = useState({
    loading: true,
    data: null,
    citationCount: 0,
    citations: [],
    error: false,
  });

  useEffect(() => {
    const loadCitation = async () => {
      if (state.loading) {
        await axios
          .get(getOpenCitationURL(doi), {
            headers: {
              authorization: `${UI.doi.openCitation.accessToken}`,
            },
          })
          .then((response) => {
            setState((prevState) => ({
              ...prevState,
              data: response.data,
              citationCount:
                response.data && response.data.length > 0
                  ? parseInt(response.data[0].citation_count)
                  : 0,
              citations:
                response.data && response.data.length > 0
                  ? response.data[0].citation.split(';')
                  : [],
            }));
          })
          .catch((error) => {
            console.log(error);
            setState((prevState) => ({
              ...prevState,
              error: true,
            }));
          })
          .finally(() => {
            setState((prevState) => ({
              ...prevState,
              loading: false,
            }));
          });
      }
    };

    loadCitation();
  }, [doi, state.data, state.loading]);

  return (
    <>
      <Card style={{ marginBottom: 12 }}>
        <Card.Header>Cited by</Card.Header>
        <Card.Body>
          <Card.Text></Card.Text>
          {state.loading ? (
            <>
              <Spinner animation="border" variant="primary" />
              <span>Loading Citations...</span>
            </>
          ) : (
            <>
              {state.citationCount === 0 ? (
                <Card.Text>No citations were found.</Card.Text>
              ) : (
                <ListGroup variant="flush">
                  {state.citations.map((citationDoi, id) => (
                    <ListGroup.Item key={`row-${id}`}>
                      <DOIReferenceItem
                        doi={citationDoi.trim()}
                        canCopyToClipboard={false}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
