import React from 'react';

import UI from '../../config/ui';
import { ExperimentalReportFactory } from './ExperimentalReportFactory';

export function ExperimentalReportWidget(props) {
  return (
    <ExperimentalReportFactory
      component={UI.doi.experimentalReport.widget}
      data={props.data}
    />
  );
}
