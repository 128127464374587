import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { STATUS_DEFS } from '../../constants/parcelStatuses';
import { fas as icons } from '@fortawesome/free-solid-svg-icons';

function StatusDisplay(props) {
  const { status, ref, action, ...triggerHandler } = props;
  return (
    <span ref={ref} {...triggerHandler}>
      <FontAwesomeIcon
        icon={icons[STATUS_DEFS[status].icon]}
        style={{ marginRight: 10 }}
      />{' '}
      {action ? STATUS_DEFS[status].action.label : STATUS_DEFS[status].label}
    </span>
  );
}

export default StatusDisplay;
