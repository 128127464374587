import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '../../../helpers/hooks';
import { Button } from 'react-bootstrap';
import TagListInLine from '../Tag/TagListInLine';
import styles from './EventList.module.css';
import EventTextBox from './EventTextBox';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventButton from './EventButton';
import { BROADCAST } from '../../../constants/eventTypes';

/** React component which renders an event. Here 'event can be the classical event as found in the logbook but
 * could also be a list of event corresponding to a collapsed line containing several events
 */
function Event(props) {
  let events = [props.event];
  const {
    isReleased,
    search,
    isBeamlineLogbook,
    pageSize,
    sortOrder,
    types,
    instrumentName,
    isGlobalLogbook,
  } = props;
  const history = useHistory();
  const query = useQuery();

  const [collapsed, setCollapsed] = useState(true);

  if (props.event.events && !collapsed) {
    events = events.concat(props.event.events);
  }

  const getButtonIcon = () => {
    if (isReleased) {
      return <FontAwesomeIcon icon={faEye} />;
    }
    return <FontAwesomeIcon icon={faPencilAlt} style={{ width: 10 }} />;
  };

  const isBeamlineEvent = (event) => {
    return !event.investigationId;
  };

  const isBroadcastEvent = (event) => {
    return event.type === BROADCAST;
  };

  const getAdditionalfield = (event) => {
    if (!isBeamlineLogbook && !isBeamlineEvent(event)) {
      return;
    }
    return (
      <td className={`${styles.borderTopSeparatorBetweenEvents}`}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/beamline/${event.instrumentName}/events`}
        >
          {event.instrumentName}
        </a>
        {['software', 'source', 'machine', 'datasetName'].map((column) => {
          return event[column] ? (
            <>
              <br />
              {column}: {event[column]}
            </>
          ) : null;
        })}
      </td>
    );
  };
  const getInvestigationNameColumn = (event) => {
    if (!isBeamlineLogbook && !isBeamlineEvent(event)) {
      return;
    }
    return (
      <td className={`${styles.borderTopSeparatorBetweenEvents}`}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/investigation/${event.investigationId}/events`}
        >
          {event.investigationName}
        </a>
      </td>
    );
  };

  return events.map((myEvent, index) => (
    <tr id={myEvent._id} key={index} style={{ backgroundColor: '#f0f0f6' }}>
      <td
        style={{ width: 16 }}
        className={styles.borderTopSeparatorBetweenEvents}
      >
        {!isBroadcastEvent(myEvent) &&
          (!isBeamlineLogbook || isBeamlineEvent(myEvent)) && (
            <Button
              variant="default"
              size="sm"
              className={styles.editButton}
              onClick={() => {
                query.set('edit', myEvent._id);
                history.push({ search: query.toString() });
              }}
            >
              {getButtonIcon(myEvent)}
            </Button>
          )}
      </td>

      <td
        className={styles.borderTopSeparatorBetweenEvents}
        style={{ width: 16, backgroundColor: 'white' }}
      >
        <EventButton
          event={myEvent}
          pageSize={pageSize}
          sortOrder={sortOrder}
          types={types}
          search={search}
          instrumentName={instrumentName}
          isBeamlineLogbook={isBeamlineLogbook}
        ></EventButton>
      </td>

      <td
        className={styles.borderTopSeparatorBetweenEvents}
        style={{
          paddingBottom: 0,
          backgroundColor: 'white',
        }}
      >
        <div style={{ marginLeft: 5, backgroundColor: 'white' }}>
          <EventTextBox event={myEvent} search={search} />

          {myEvent.events && collapsed && (
            <Button
              variant="light"
              size="sm"
              onClick={setCollapsed(!collapsed)}
            >
              .... {myEvent.events.length} command lines more
            </Button>
          )}
        </div>
      </td>
      {!isBroadcastEvent(myEvent) && getInvestigationNameColumn(myEvent)}
      {isGlobalLogbook && getAdditionalfield(myEvent)}
      <td
        className={styles.borderTopSeparatorBetweenEvents}
        style={{ width: 50 }}
      >
        <TagListInLine tags={myEvent.tag} />
      </td>
    </tr>
  ));
}

export default Event;
