import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import EventListMenuButton from './Menu/EventListMenuButton';
import { useResource } from 'rest-hooks';
import EventDatesResource from '../../resources/eventDates';

function EventSearchCalendar(props) {
  const {
    investigationId,
    instrumentName,
    types,
    onDateSearch,
    variant = 'primary',
  } = props;
  const [focused, setFocused] = useState(false);

  const params = { investigationId, instrumentName, types };
  const uniqueDateListByEvents = useResource(
    EventDatesResource.listShape(),
    params
  );

  const availableDates = uniqueDateListByEvents.map((d) =>
    moment(d._id, 'YYYY-MM-DD')
  );

  const [selectedDate, setSelectedDate] = useState(null);
  const onDateChange = (date) => {
    setSelectedDate(date);
    onDateSearch(date ? date.format('YYYY-MM-DD') : null);
  };

  const onFocusChange = (f) => {
    setFocused(f.focused);
  };

  const isDayContainsEvents = (day) => {
    return availableDates.some((date) => day.isSame(date, 'day'));
  };

  return (
    <div>
      <SingleDatePicker
        date={selectedDate}
        onDateChange={(date) => onDateChange(date)}
        onFocusChange={(e) => onFocusChange(e)}
        focused={focused}
        displayFormat={() => 'DD/MM/YYYY'}
        numberOfMonths={1}
        id="date"
        customInputIcon={
          <EventListMenuButton
            text=""
            isEnabled={true}
            iconName="faCalendar"
            tooltipText="Filter by date"
            variant={variant}
          />
        }
        small={true}
        isOutsideRange={() => false}
        isDayHighlighted={(day) => isDayContainsEvents(day)}
        showClearDate={true}
      ></SingleDatePicker>
    </div>
  );
}

EventSearchCalendar.propTypes = {
  /* Function triggered when the user selects a date */
  onDateSearch: PropTypes.func,
  /** style of the calendar button, primary by default */
  variant: PropTypes.string,
  /** InvestigationId in case of investigation logbook */
  investigationId: PropTypes.number,
  /** Instrument name in case of beamline logbook */
  instrumentName: PropTypes.string,
  /** Types of events to be displayed */
  types: PropTypes.array,
};
export default EventSearchCalendar;
