import React, { useState } from 'react';
import TagsPanel from './Logbook/Tags/TagsPanel';
import { useSelector } from 'react-redux';
import { useQueryParams, useQuery, usePageTracking } from '../helpers/hooks';
import ModalLoadingBoundary from '../components/ModalLoadingBoundary';
import TagFormModal from '../components/Logbook/Tag/TagFormModal';
import { useHistory } from 'react-router';
import { useResource, useFetcher } from 'rest-hooks';
import TagResource from '../resources/tag';
import { Alert } from 'react-bootstrap';

function TagPage() {
  usePageTracking();
  const query = useQuery();
  const history = useHistory();
  const [alert, setAlert] = useState();

  const { instrumentName, investigationId, action, tagId } = useQueryParams();
  const isCreating = action === 'new';
  const isEditing = action === 'edit';
  const user = useSelector((state) => state.user);
  const { sessionId, isAdministrator, isInstrumentScientist } = user;

  const tags = useResource(TagResource.listShape(), {
    sessionId,
    investigationId,
    instrumentName,
  });

  let editedTag = null;
  if (tagId) {
    editedTag = tags.filter((tag) => tag._id === tagId)[0];
  }

  const createTag = useFetcher(TagResource.createShape());
  const editTag = useFetcher(TagResource.updateShape());
  const refetchTags = useFetcher(TagResource.listShape());

  const handleCloseModal = () => {
    query.delete('action');
    history.push({ search: query.toString() });
  };

  async function handleSubmit(values) {
    setAlert(undefined);

    values.instrumentName = instrumentName;
    values.investigationId = investigationId;
    try {
      if (isCreating) {
        await createTag(values);
      } else {
        await editTag(values);
      }

      await refetchTags(values);
      setAlert({
        type: 'success',
        message: `Tag ${isCreating ? 'created' : 'saved'}.`,
      });
    } catch (error) {
      setAlert({ type: 'danger', message: `An error occurred.` });
      console.error(error.response);
    }

    handleCloseModal();
  }

  return (
    <>
      {(isCreating || isEditing) && (
        <ModalLoadingBoundary
          message="Loading investigations..."
          onCloseModal={handleCloseModal}
        >
          <TagFormModal
            tag={isCreating ? {} : editedTag}
            instrumentName={instrumentName}
            investigationId={investigationId}
            onSubmitAsync={handleSubmit}
            onCloseModal={handleCloseModal}
          />
        </ModalLoadingBoundary>
      )}
      {alert && <Alert variant={alert.style}>{alert.message}</Alert>}
      <TagsPanel
        tags={tags}
        investigationId={investigationId}
        instrumentName={instrumentName}
        isAdministrator={isAdministrator}
        isInstrumentScientist={isInstrumentScientist}
      />
    </>
  );
}

export default TagPage;
