import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { fas as icons } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* A React component which renders a button in the event list menu. It does not handle event associated to the button.*/

function EventListMenuButton(props) {
  const {
    isEnabled,
    isVisible,
    text,
    iconName,
    tooltipText,
    variant = 'danger',
  } = props;

  if (!isVisible) {
    return null;
  }

  if (!isEnabled) {
    return (
      <div className={`btn btn-sm btn-${variant}`} disabled>
        {' '}
        <FontAwesomeIcon icon={icons[iconName]} />
        {text}{' '}
      </div>
    );
  }

  // default behavior
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip"> {tooltipText} </Tooltip>}
    >
      {/* <Button size="sm" variant="primary" disabled={false} > <<FontAwesomeIcon icon={glyph} /> {text} </Button> */}
      <div className={`btn btn-sm btn-${variant}`}>
        {' '}
        <FontAwesomeIcon icon={icons[iconName]} /> {text}{' '}
      </div>
    </OverlayTrigger>
  );
}

export default EventListMenuButton;

EventListMenuButton.propTypes = {
  /** icon added on the left side of the displayed text */
  iconName: PropTypes.string.isRequired,
  /** Whether the button is enabled or disabled */
  isEnabled: PropTypes.bool.isRequired,
  /** Whether the button is visible or not */
  isVisible: PropTypes.bool,
  /** the text displayed in the button */
  text: PropTypes.string.isRequired,
  /** text of the tooltip when the user hover the mouse over the button */
  tooltipText: PropTypes.string,
};

EventListMenuButton.defaultProps = {
  isVisible: true,
};
