import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getTagURL } from '../api/icat-plus/logbook';

export default class TagResource extends Resource {
  _id = undefined;

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'TagResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId, instrumentName, _id } = params;
    return getTagURL(sessionId, investigationId, instrumentName, _id);
  }

  static url(params) {
    return this.listUrl(params);
  }

  static createShape() {
    return {
      ...super.createShape(),
      getFetchKey: (params) => `POST ${this.url(params)}`,
      fetch: (tag) => this.fetch('post', this.url(tag), tag),
    };
  }

  static updateShape() {
    return {
      ...super.updateShape(),
      fetch: (tag) => this.fetch('put', this.url(tag), tag),
    };
  }

  static deleteShape() {
    return {
      ...super.deleteShape(),
      fetch: (tag) => this.fetch('delete', this.url(tag), tag),
    };
  }
}
