import React, { useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFetcher, useResource } from 'rest-hooks';
import { useQuery } from '../../helpers/hooks';
import ParcelResource from '../../resources/parcel';
import ParcelFormModal from '../Parcels/ParcelFormModal';
import ParcelTable from '../Parcels/ParcelTable';
import styles from './ParcelSection.module.css';
import ModalLoadingBoundary from '../ModalLoadingBoundary';
import ParcelColumns from './ParcelColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

function ParcelsSection(props) {
  const {
    investigation,
    shipment,
    defaultShippingAddress,
    defaultReturnAddress,
  } = props;

  const history = useHistory();
  const investigationUrl = `/investigation/${investigation.id}`;

  const query = useQuery();
  const isCreating = query.has('new-parcel');
  const editingId = query.get('edit-parcel');

  const apiParams = { investigationId: investigation.id };

  const parcels = useResource(ParcelResource.listShape(), {
    investigationId: investigation.id,
    shipmentId: shipment._id,
  });
  const parcel = useResource(
    ParcelResource.detailShape(),
    editingId ? { _id: editingId, ...apiParams } : null
  );

  const [alert, setAlert] = useState();

  const createParcel = useFetcher(ParcelResource.createShape());
  const editParcel = useFetcher(ParcelResource.updateShape());
  const refetchParcels = useFetcher(ParcelResource.listShape());

  function handleCloseModal() {
    history.push(`${investigationUrl}/shipping`);
  }

  async function handleSubmit(values) {
    setAlert(undefined);
    try {
      const fetcherParams = { ...apiParams, shipmentId: shipment._id };

      if (!parcel) {
        await createParcel(fetcherParams, values);
      } else {
        await editParcel(fetcherParams, values);
      }

      await refetchParcels(fetcherParams);
      setAlert({
        type: 'success',
        message: `Parcel ${isCreating ? 'created' : 'saved'}.`,
      });
    } catch (error) {
      setAlert({ type: 'danger', message: `An error occurred.` });
      console.error(error.response);
    }

    handleCloseModal();
  }

  return (
    <Container style={{ marginLeft: 0 }} fluid>
      {(isCreating || editingId) && (
        <ModalLoadingBoundary
          message="Loading form..."
          onCloseModal={handleCloseModal}
        >
          <ParcelFormModal
            investigation={investigation}
            shipment={shipment}
            parcel={parcel}
            investigationUrl={investigationUrl}
            onSubmitAsync={handleSubmit}
            onCloseModal={handleCloseModal}
          />
        </ModalLoadingBoundary>
      )}
      <div className="actions-heading">
        <div className={styles.heading}>
          <h3>Parcels</h3>
          {investigation &&
            (defaultShippingAddress !== undefined &&
            defaultReturnAddress !== undefined ? (
              <LinkContainer
                className={styles.newButton}
                to={`/investigation/${investigation.id}/shipping?new-parcel`}
              >
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPlus} /> New parcel
                </Button>
              </LinkContainer>
            ) : (
              <div className={styles.heading}>
                <Alert>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  Your default shipping addresses are not valid ! Please correct
                  them before adding parcels.
                </Alert>
                <Button className={styles.newButton} variant="primary" disabled>
                  <FontAwesomeIcon icon={faPlus} />
                  New parcel
                </Button>
              </div>
            ))}
        </div>
      </div>

      {alert && (
        <Alert variant={alert.type} onDismiss={() => setAlert(undefined)}>
          {alert.message}
        </Alert>
      )}

      <ParcelTable columns={ParcelColumns(true)} parcels={parcels} />
    </Container>
  );
}

export default ParcelsSection;
