import React from 'react';
import { Button } from 'react-bootstrap';
import BootstrapTable2 from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { LinkContainer } from 'react-router-bootstrap';
import styles from './ResponsiveTable.module.css';
import { getCurrentBreakpoint } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas as icons } from '@fortawesome/free-solid-svg-icons';
import UI from '../../config/ui';

/**
 * This method will calculate the headerStyle based on the ResponsiveHeaderStyle and the size of the window
 * @param {array} columns BootstrapTable2 columns
 */
function configure(columns) {
  const size = getCurrentBreakpoint();

  return columns.map((column) =>
    column.responsiveHeaderStyle && column.responsiveHeaderStyle[size]
      ? {
          ...column,
          headerStyle: column.responsiveHeaderStyle[size],
          hidden: column.responsiveHeaderStyle[size].hidden,
        }
      : column
  );
}

function renderActionButton(btnProps) {
  const { label, icon, ...otherProps } = btnProps;
  return (
    <Button key={label} {...otherProps}>
      {icon && (
        <FontAwesomeIcon
          icon={icons[icon]}
          className={styles.actionIcon}
          style={{ marginRight: 5 }}
        />
      )}
      {label}
    </Button>
  );
}

export default function ResponsiveTable(props) {
  const { SearchBar } = Search;

  const {
    keyField,
    data,
    columns,
    search,
    expandRow,
    actions,
    onSearch,
    defaultSorted,
    selectRow,
    pageOptions,
    rowEvents,
    rowClasses,
    delay,
    remote = false,
    defaultSearchText = '',
    striped = true,
  } = props;

  function onColumnMatch({ searchText, value, column, row }) {
    if (column.onMatch) {
      return column.onMatch(searchText, value, row, column.formatExtraData);
    }

    return (
      value !== null &&
      value !== undefined &&
      value.toString().toLowerCase().includes(searchText)
    );
  }

  function handleTableChange(
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) {
    if (props.handleTableChange) {
      props.handleTableChange(page, sizePerPage, sortField, sortOrder);
    }
  }

  const remoteProps = remote
    ? {
        remote: {
          filter: true,
          pagination: true,
          sort: true,
          cellEdit: false,
        },
      }
    : {};

  return (
    <ToolkitProvider
      keyField={keyField || 'id'}
      data={data}
      columns={configure(columns)}
      search={{
        onColumnMatch,
        defaultSearch: defaultSearchText,
        ...search,
      }}
      expandRow={expandRow}
    >
      {({ baseProps, searchProps }) => (
        <>
          <div className={styles.bar}>
            <div className={styles.actions}>
              {(actions || []).map((action) => {
                const { href, ...btnProps } = action;
                if (!href) {
                  return renderActionButton(btnProps);
                }
                return (
                  <LinkContainer key={btnProps.label} to={action.href}>
                    {renderActionButton(btnProps)}
                  </LinkContainer>
                );
              })}
            </div>
            <div className={styles.search}>
              <SearchBar
                searchText={searchProps.searchText}
                delay={delay ? delay : UI.searchDelay}
                onSearch={(query) => {
                  searchProps.onSearch(query);
                  if (onSearch) {
                    onSearch(query);
                  }
                }}
              />
            </div>
          </div>
          <BootstrapTable2
            {...remoteProps}
            pagination={paginationFactory(pageOptions)}
            onTableChange={handleTableChange}
            striped={striped}
            hover
            condensed
            defaultSorted={defaultSorted}
            selectRow={selectRow}
            expandRow={expandRow}
            rowEvents={rowEvents}
            rowClasses={rowClasses}
            noDataIndication="No data to display"
            {...baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  );
}
