import React, { useState } from 'react';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import TabContainerMenu from '../components/TabContainerMenu/TabContainerMenu';
import PageNotFound from './PageNotFound';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../resources/investigation';
import LoadingBoundary from '../components/LoadingBoundary';
import RemoteDatasetTable from '../components/DatasetTable/RemoteDatasetTable';
import {
  usePageTracking,
  useQuery,
  useQueryParams,
  useScrollToHash,
} from '../helpers/hooks';
import SampleSelection from '../components/Dataset/SampleSelection';

/**
 * This components shows the list of datasets attached to an investigation
 * @returns
 */
function DatasetsPage() {
  usePageTracking();
  const { investigationId } = useParams();

  const history = useHistory();
  const query = useQuery();
  function useQueryParam(key, defaultValue) {
    const value = useQueryParams()[key] || defaultValue;
    const [queryParam, setQueryParam] = useState(value);
    const updateValue = (newVal) => {
      setQueryParam(newVal);
      if (newVal) {
        query.set(key, newVal);
      } else {
        query.delete(key);
      }
      history.push({ search: query.toString() });
    };
    return [queryParam, updateValue];
  }

  const [sampleId, setSampleId] = useQueryParam('sampleId', '');
  const investigations = useResource(InvestigationResource.listShape(), {
    ids: investigationId,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;

  const datasets = useSelector((state) => state.datasets);

  useScrollToHash({ milliseconds: 350, attempts: 50 });

  if (!investigation) {
    return <PageNotFound />;
  }

  const expanded =
    investigation.visitId === 'publisher' ? datasets.data.map((d) => d.id) : [];

  const sizePerPageList = [
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '1000', value: 1000 },
  ];
  const updateSelectedSample = (sampleId) => {
    setSampleId(sampleId);
  };

  return (
    <Container fluid>
      <Row>
        <Col sm={12}>
          <TabContainerMenu investigation={investigation} />
          <Alert style={{ margin: 10 }} variant="warning">
            For users that want to download large volume of experimental data{' '}
            <strong>(&gt;2GB)</strong>, ESRF users can access the Globus
            service, please read the documentation for proceeding:{' '}
            <strong>
              <Alert.Link
                href="https://confluence.esrf.fr/display/SCKB/Globus"
                target="_blank"
              >
                https://confluence.esrf.fr/display/SCKB/Globus
              </Alert.Link>
            </strong>
          </Alert>
          <LoadingBoundary inPanel message="Loading samples...">
            <SampleSelection
              investigationId={investigationId}
              selectedSampleId={sampleId}
              updateSelectedSample={updateSelectedSample}
            />
          </LoadingBoundary>
          <div style={{ padding: 20 }}>
            <LoadingBoundary inPanel message="Loading datasets...">
              <RemoteDatasetTable
                investigationId={investigationId}
                sampleId={sampleId}
                expanded={expanded}
                defaultSizePerPage={25}
                sizePerPageList={sizePerPageList}
                investigation={investigation}
              />
            </LoadingBoundary>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default DatasetsPage;
