import React from 'react';
import { Col, Container, Card, Row, Form } from 'react-bootstrap';
import {
  EVENT_CATEGORY_COMMANDLINE,
  EVENT_CATEGORY_ERROR,
  EVENT_CATEGORY_INFO,
  ANNOTATION,
  NOTIFICATION,
  EVENT_CATEGORY_COMMENT,
  EVENT_CATEGORY_DEBUG,
  BROADCAST,
} from '../../../constants/eventTypes';
import { useDispatch } from 'react-redux';
import styles from './SettingLogbookMenuPanel.module.css';
import {
  setAutomaticCollapsing,
  setAutomaticRefresh,
  setCategoryTypes,
  setIsSortingLatestEventsFirst,
  unsetCategoryTypes,
  setBeamlineLogbookShowUserLogs,
  setPageSize,
} from '../../../actions/logbook';
import UI from '../../../config/ui';

const commandLineValue = [
  { type: NOTIFICATION, category: EVENT_CATEGORY_COMMANDLINE },
];
const logTypeCheckBoxes = [
  {
    name: 'showComments',
    label: 'Comments',
    value: [
      { type: ANNOTATION },
      { type: NOTIFICATION, category: EVENT_CATEGORY_COMMENT },
    ],
  },
  {
    name: 'showInformation',
    label: 'Information',
    value: [
      { type: NOTIFICATION, category: EVENT_CATEGORY_INFO },
      { type: NOTIFICATION, category: EVENT_CATEGORY_DEBUG },
    ],
  },
  {
    name: 'showError',
    label: 'Errors',
    value: [{ type: NOTIFICATION, category: EVENT_CATEGORY_ERROR }],
  },
  {
    name: 'showCommandLine',
    label: 'Command Lines',
    value: commandLineValue,
  },
  {
    name: 'showBroadcast',
    label: 'Machine',
    value: [{ type: BROADCAST }],
  },
];

/*
 * React component which renders a panel showing the filter that can be applied on events
 */
export default function SettingLogbookMenuPanel(props) {
  const dispatch = useDispatch();

  const {
    isReleased,
    automaticCollapsing,
    automaticRefresh,
    isSortingLatestEventsFirst,
    isBeamlineLogbook = false,
    showUserLogsOnBeamlineLogbook = true,
    pageSize = UI.logbook.EVENTS_PER_PAGE,
  } = props;

  const getValueByName = (name) =>
    logTypeCheckBoxes.find((cb) => cb.name === name).value;

  const toggleColor = '#428bca';

  /** Checkbox will be checked if and only if its values are in the categoryTypes  */
  const isChecked = (values) => {
    let found = true;
    values.forEach((value) => {
      if (
        props.categoryTypes &&
        props.categoryTypes.find(
          (ct) => ct.type === value.type && ct.category === value.category
        ) == null
      ) {
        found = false;
      }
    });

    return found;
  };

  const isCommandLineDisplayed = () => {
    return isChecked(commandLineValue);
  };

  const switchWidth = 40;
  const switchHeight = 20;

  return (
    <Card style={{ marginBottom: 10, padding: 0 }}>
      <Card.Header>
        <Card.Title>Settings</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col md={2} sm={6} xs={6}>
              <h4>Log types</h4>
              <Container fluid>
                <Form>
                  {logTypeCheckBoxes.map((checkbox, index) => (
                    <Row key={checkbox.name}>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          name={checkbox.name}
                          label={checkbox.label}
                          id={`checkbox-logType-${index}`}
                          checked={isChecked(checkbox.value)}
                          value={checkbox.value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              dispatch(
                                setCategoryTypes(getValueByName(e.target.name))
                              );
                            } else {
                              dispatch(
                                unsetCategoryTypes(
                                  getValueByName(e.target.name)
                                )
                              );
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  ))}
                </Form>
              </Container>
            </Col>
            {isCommandLineDisplayed() && (
              <Col md={2} sm={6} xs={12}>
                <h4>Display</h4>
                <Container>
                  {!isReleased && false && (
                    <Form>
                      <Row>
                        <Col md={12} sm={12} xs={12}>
                          <Form.Check
                            type="switch"
                            label="Auto refresh logs"
                            onColor={toggleColor}
                            onChange={() => {
                              dispatch(setAutomaticRefresh(!automaticRefresh));
                            }}
                            checked={automaticRefresh}
                            width={switchWidth}
                            height={switchHeight}
                            className={styles.logbookSwitch}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                  {isCommandLineDisplayed() && (
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        <Form>
                          <Form.Check
                            type="switch"
                            label="Group command lines"
                            onColor={toggleColor}
                            onChange={() => {
                              dispatch(
                                setAutomaticCollapsing(!automaticCollapsing)
                              );
                            }}
                            checked={automaticCollapsing}
                            width={switchWidth}
                            height={switchHeight}
                            className={styles.logbookSwitch}
                          />
                        </Form>
                      </Col>
                    </Row>
                  )}
                </Container>
              </Col>
            )}
            <Col md={2} sm={6} xs={12}>
              <h4>Sorting</h4>
              <Container>
                <Row>
                  <Col md={12} sm={12} xs={12}>
                    <Form.Check
                      type="radio"
                      checked={!isSortingLatestEventsFirst}
                      label="Oldest log on top"
                      onChange={() => {
                        dispatch(setIsSortingLatestEventsFirst(false));
                      }}
                      className={styles.logbookRadio}
                    />
                  </Col>
                  <Col md={12} sm={12} xs={12}>
                    <Form.Check
                      type="radio"
                      label="Latest log on top"
                      checked={isSortingLatestEventsFirst}
                      onChange={() => {
                        dispatch(setIsSortingLatestEventsFirst(true));
                      }}
                      className={styles.logbookRadio}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
            {isBeamlineLogbook && (
              <Col md={2} sm={6} xs={12}>
                <h4>Beamline Logbook</h4>
                <Container>
                  <Row>
                    <Col>
                      <Form>
                        <Form.Check
                          type="switch"
                          label="Hide user's logs"
                          onColor={toggleColor}
                          onChange={() => {
                            dispatch(
                              setBeamlineLogbookShowUserLogs(
                                !showUserLogsOnBeamlineLogbook
                              )
                            );
                          }}
                          checked={showUserLogsOnBeamlineLogbook}
                          width={switchWidth}
                          height={switchHeight}
                          className={styles.logbookSwitch}
                        />
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            )}
            <Col md={2} sm={4} xs={6}>
              <h4>Page Size</h4>
              <Container>
                <Row key={'radioPageSize'}>
                  <Form>
                    {[100, 200, 500, 1000].map((value) => {
                      return (
                        <Col md={12} sm={12} xs={12}>
                          <Form.Check
                            type="radio"
                            label={value}
                            checked={value === pageSize}
                            onChange={() => {
                              dispatch(setPageSize(value));
                            }}
                            className={styles.logbookRadio}
                          />
                        </Col>
                      );
                    })}
                  </Form>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
