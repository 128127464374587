import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Spinner, Toast } from 'react-bootstrap';
import { getDoiCitationURL } from '../../api/icat-plus/doi';
import Autolinker from 'autolinker';

/** Component which formats a DOi as a citation or a reference (APA formatting style) */
export function DOIReferenceItem(props) {
  const { doi, canCopyToClipboard } = props;
  const [state, setState] = useState({
    loading: true,
    data: null,
    error: false,
  });
  const [copied, setCopied] = useState(false);

  function copyCitation(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  useEffect(() => {
    const loadCitation = async () => {
      await axios
        .get(getDoiCitationURL(doi))
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            data: response.data,
          }));
        })
        .catch((error) => {
          console.log(error);
          setState((prevState) => ({
            ...prevState,
            error: true,
          }));
        })
        .finally(() => {
          setState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    };
    loadCitation();
  }, [doi]);

  return (
    <>
      {(() => {
        if (state.loading) {
          return (
            <>
              <Spinner animation="border" variant="primary" />
              <span>Loading Citation...</span>
            </>
          );
        } else if (state.error) {
          return <Alert variant="danger">DOI {doi} not found</Alert>;
        }
        return (
          <>
            <cite>
              <div
                dangerouslySetInnerHTML={{
                  __html: Autolinker.link(state.data),
                }}
              />
            </cite>
            {canCopyToClipboard && (
              <>
                <br />
                <Button variant="info" onClick={() => copyCitation(state.data)}>
                  Copy to clipboard
                </Button>
                {copied && (
                  <Toast
                    onClose={() => setCopied(false)}
                    tdelay={1000}
                    autohide
                    bg="info"
                  >
                    <Toast.Body>Reference copied!</Toast.Body>
                  </Toast>
                )}
              </>
            )}
          </>
        );
      })()}
    </>
  );
}
