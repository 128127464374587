import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class InstrumentResource extends Resource {
  id = undefined;
  name = '';

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'InstrumentResource';
  }

  static listUrl(searchParams) {
    const { sessionId } = store.getState().user;
    const params = new URLSearchParams(searchParams);

    return `${
      ICATPLUS.server
    }/catalogue/${sessionId}/instruments?${params.toString()}`;
  }
}
