import React, { useState } from 'react';
import styles from './ParameterTableWidget.module.css';
import { InlineInputEdit } from 'react-inline-input-edit';
import DatasetParameterResource from '../../resources/datasetParameter';
import { useFetcher } from 'rest-hooks';
import { useQuery } from '../../helpers/hooks';
import { useHistory } from 'react-router';
import { faSyncAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ParameterRowWidget(props) {
  const {
    datasetId,
    parameterId,
    name,
    nameColumnWidth,
    editable,
    unit,
  } = props;

  const [spinning, setSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(props.value);

  const query = useQuery();
  const history = useHistory();
  const updateDatasetParameter = useFetcher(
    DatasetParameterResource.updateShape()
  );

  async function handleSubmit(text, id) {
    setSpinning(true);
    setError(false);
    try {
      const { value } = await updateDatasetParameter({
        value: text,
        parameterId: id,
      });

      /** Trick to do no collapse the row */
      const newQuery = new URLSearchParams(query);
      newQuery.set('expands', datasetId);
      history.push({ search: newQuery.toString() });
      setValue(value);
    } catch {
      setError(true);
    }
    setSpinning(false);
  }

  return (
    <>
      <tr
        key={Math.random()}
        className={styles.row}
        data-empty={!value || undefined}
      >
        <td width={nameColumnWidth}>
          {name}
          {unit && unit !== 'NA' && unit !== 'N.A.' && (
            <span style={{ width: '20px', color: 'gray' }}> ({unit})</span>
          )}
        </td>
        {editable && (
          <td>
            <InlineInputEdit
              text={value}
              labelPlaceHolder=""
              inputWidth="200px"
              inputHeight="50px"
              labelFontWeight="bold"
              inputFontWeight="bold"
              onFocusOut={(text) => handleSubmit(text, parameterId)}
            ></InlineInputEdit>

            {spinning && (
              <FontAwesomeIcon
                icon={faSyncAlt}
                style={{ margin: 10 }}
                size="sm"
                spin
              />
            )}

            {error && (
              <>
                <FontAwesomeIcon icon={faTrash} style={{ color: 'red' }} />{' '}
                Error
              </>
            )}
          </td>
        )}
        {!editable && <td>{value}</td>}
      </tr>
    </>
  );
}

export default ParameterRowWidget;
