import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { getURLParamsByDictionary } from './url';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useQueryParams() {
  const dict = {};
  for (const [key, value] of new URLSearchParams(
    useLocation().search
  ).entries()) {
    dict[key] = value;
  }
  return dict;
}

/**
 * This hook returns an well-formed URL with the URL send as url params and the query parameters contained in the props.
 * The null values of the props will be ignored and not added to the query params
 * @param {*} props
 */
export function useURLParams(props) {
  return getURLParamsByDictionary(props);
}

/**
 * Checks every milisseconds if there is an element which id is the hash location until a defined number of attempts
 * and if it is found then it makes a beter and scrolls to the element
 * @param {*} props
 */
export function useScrollToHash(props) {
  const { milliseconds = 100, offsetY = -70 } = props;
  let { attempts = 10 } = props;

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const { hash } = window.location;
        attempts--;

        if (attempts < 0 || !hash) {
          clearInterval(intervalId);
        }
        if (hash) {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.setAttribute('style', 'border:1px solid blue');
          }
          if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'instant' });
            window.scrollBy(0, offsetY);
            clearInterval(intervalId);
          }
        }
      },

      milliseconds
    );
  }, [attempts, milliseconds, offsetY]);
}

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    window._paq.push(['trackPageView', document.title]);
  }, [location.pathname]);
}
