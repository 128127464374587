import 'react-app-polyfill/stable';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-datetime/css/react-datetime.css'; // dependency of `react-combo-search`
import 'react-combo-select/style.css'; // dependency of `react-combo-search`
import 'react-combo-search/css/style.css';
import 'react-day-picker/lib/style.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-vis/dist/style.css';
import './index.scss';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CacheProvider } from 'rest-hooks';
import App from './App';
import { unregister } from './registerServiceWorker';
import { persistor, store } from './store';
import { BrowserRouter } from 'react-router-dom';

function renderApp() {
  render(
    <CacheProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </CacheProvider>,
    document.getElementById('root')
  );
}

renderApp();

// Remove any installed service worker
unregister();
