import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useQuery, useQueryParams } from '../../helpers/hooks';
import { useResource } from 'rest-hooks';
import DatasetResource from '../../resources/dataset';
import { setDatasetsCount } from '../../actions/datasets';
import DatasetTableFactory from './DatasetTableFactory';

function RemoteDatasetTable(props) {
  const {
    investigationId,
    doi,
    loadSelectedDatasets,
    defaultSizePerPage,
    sizePerPageList,
    expanded = [],
    investigation,
    sampleId,
  } = props;
  const history = useHistory();
  const query = useQuery();

  const dispatch = useDispatch();

  function useQueryParam(key, defaultValue) {
    const value = useQueryParams()[key] || defaultValue;
    const [queryParam, setQueryParam] = useState(value);
    const updateValue = (newVal) => {
      setQueryParam(newVal);
      if (newVal) {
        query.set(key, newVal);
      } else {
        query.delete(key);
      }
      history.push({ search: query.toString() });
    };
    return [queryParam, updateValue];
  }

  const [search, setSearch] = useQueryParam('search', '');
  const [sizePerPage, setSizePerPage] = useState(defaultSizePerPage);
  const [page, setPage] = useQueryParam('page', 1);
  const [sortField, setSortField] = useQueryParam('sortField', 'startDate');
  const [sortOrder, setSortOrder] = useQueryParam('sortOrder', -1);

  let fetchingParams = {
    investigationId,
    sampleId,
    doi,
    datasets: loadSelectedDatasets,
    limit: sizePerPage,
    skip: sizePerPage * (page - 1),
    sortBy: sortField ? sortField : 'startDate',
    sortOrder: sortOrder ? sortOrder : -1,
    datasetType: 'other',
    nested: true,
  };

  if (search) {
    fetchingParams = { ...fetchingParams, search };
  }

  const data = useResource(DatasetResource.listShape(), fetchingParams);

  if (data) {
    data.forEach((d) => {
      if (d.investigation.visitId.toLowerCase() === 'publisher') {
        expanded.push(d.id);
      }
    });
  }

  const totalSize = data && data.length > 0 ? data[0].meta?.page?.total : 0;
  const totalWithoutFilters =
    data && data.length > 0 ? data[0].meta?.page?.totalWithoutFilters : 0;

  useEffect(() => {
    dispatch(setDatasetsCount(totalWithoutFilters));

    return () => {
      dispatch(setDatasetsCount(0));
    };
  }, [dispatch, totalWithoutFilters]);

  function handleTableChange(page, sizePerPage, sortField, sortOrder) {
    setPage(parseInt(page || 1));
    setSizePerPage(parseInt(sizePerPage || defaultSizePerPage));
    setSortField(sortField);
    const order = sortOrder ? (sortOrder === 'asc' ? 1 : -1) : undefined;
    setSortOrder(order);
  }

  function onSearch(s) {
    setSearch(s);
    setPage(1);
  }

  return (
    <DatasetTableFactory
      remote={true}
      datasets={data}
      expanded={expanded}
      defaultSizePerPage={defaultSizePerPage}
      defaultSearchText={search}
      pageOptions={{
        page: parseInt(page),
        sizePerPage,
        totalSize,
        showTotal: true,
        sizePerPageList,
      }}
      onHandleTableChange={handleTableChange}
      onSearch={onSearch}
      investigation={investigation}
    />
  );
}

RemoteDatasetTable.propTypes = {
  investigationId: PropTypes.string,
  doi: PropTypes.string,
  loadSelectedDatasets: PropTypes.array,
  expanded: PropTypes.array,
  defaultSizePerPage: PropTypes.number,
  sizePerPageList: PropTypes.array,
};

export default RemoteDatasetTable;
