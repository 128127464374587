import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import logo from '../../images/ESRF-Logo-WHITE.png';

function LandingPageMenu(props) {
  const { doi } = props;

  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      bg="primary"
      variant="dark"
      style={{ paddingBottom: '0', paddingTop: '0' }}
    >
      <Container fluid>
        <Navbar.Brand>
          <img
            src={logo}
            height="56px"
            width="56px"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        {doi && (
          <>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>DOI &gt; {doi}</Navbar.Text>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}
export default LandingPageMenu;
