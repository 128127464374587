import axios from 'axios';
import moment from 'moment';
import ICATPLUS from '../config/icatPlus';
import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOG_IN,
  LOG_OUT,
  SESSION_REFRESHED,
} from '../constants/actionTypes';
import keycloak from '../keycloak';
import ICAT from '../config/icat';
import { trackSignIn } from '../helpers/trackData';

/**
 * This method returns:
 *  true: if there is a session and there is still remaining time
 *  false: if session expired or no session
 */
export function isValidSession(sessionId, expirationTime) {
  return sessionId && getRemainingSessionTime(expirationTime) > 0;
}

/**
 * Returns the difference in milliseconds between the given date and now
 * @param {*} expirationTime
 */
export function getRemainingSessionTime(expirationTime) {
  return moment(expirationTime).diff(moment());
}

/**
 * Remove the icat token and change the redux status
 * @param {*} sessionId
 * @param {*} params
 */
export function doLogOut(sessionId, params = { expired: false }) {
  if (sessionId) {
    axios
      .delete(`${ICATPLUS.server}/session/${sessionId}`)
      .then()
      .catch((error) => {
        console.log(error.response.data);
      });
  }
  return { type: LOG_OUT, ...params };
}

/**
 * This action logs a user in ICAT based in the ICAT authentication plugins
 * @param {*} plugin this is the icat plugin mnemonic
 * @param {*} username
 * @param {*} password
 */
export function doSignIn(plugin, username, password, usernameSuffix) {
  return async (dispatch) => {
    /** Dispatch action as we are being logging in */
    dispatch({ type: LOG_IN, username });
    try {
      const response = await axios.post(`${ICATPLUS.server}/session`, {
        plugin,
        username,
        password,
        usernameSuffix,
      });

      const { data } = response;

      if (!data || !data.sessionId) {
        throw new Error('Invalid authentication response');
      }

      trackSignIn(plugin);

      data.tokenTTL =
        ICAT.authentication.validityTokenPercentage * data.lifeTimeMinutes;
      /** We are logged in now */
      dispatch({ type: LOGGED_IN, ...data });
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_ERROR, error: 'Authentication failed' });
    }
  };
}

export function switchAffilication(usernameSuffix) {
  return async (dispatch) => {
    dispatch(
      doSignIn(
        ICAT.authentication.sso.plugin,
        null,
        keycloak.idToken,
        usernameSuffix
      )
    );
  };
}

/**
 * This action will log out after the lifeTimeMinutes
 * @param {*} sessionId
 * @param {*} remainingTime
 */
export function setAutoLogOutTimer(sessionId, remainingRefreshTime) {
  return (dispatch) => {
    /** Trigger the refresher after the PERCENTAGE_TOKEN_VALIDATY_BEFORE_REFRESH of the duration of the token  */
    setTimeout(() => {
      if (!ICAT.authentication.autoRefresh) {
        dispatch(
          doLogOut(sessionId, {
            expired: true,
          })
        );
      } else {
        /** Refresh token **/
        axios
          .put(`${ICATPLUS.server}/session/${sessionId}`)
          .then(() => {
            dispatch({ type: SESSION_REFRESHED });
          })
          .catch((error) => {
            dispatch({ type: SESSION_REFRESHED, error });
          });
      }
    }, remainingRefreshTime);
  };
}

/**
 * This function handles the token. It will check the token status and will do log out if token is invalid or will set up the timer to do the autologout
 * @param {*} sessionId
 * @param {*} refreshTime
 */
export function handleToken(sessionId, refreshTime) {
  return (dispatch) => {
    if (!isValidSession(sessionId, refreshTime)) {
      if (sessionId) {
        /** There is a expired session */
        dispatch(doLogOut(sessionId, { expired: true }));
      }
    } else {
      dispatch(
        setAutoLogOutTimer(sessionId, getRemainingSessionTime(refreshTime))
      );
    }
  };
}
