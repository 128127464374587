import { intersectionWith } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import UI from '../../../config/ui';

function TagSelector(props) {
  const {
    availableTags,
    selectedTags,
    getTags,
    isFetching,
    onTagCreatedinWidget,
    onTagSelectedInWidget,
  } = props;

  const options = availableTags
    ? availableTags.map((tag) => ({
        value: tag._id,
        label: tag.name,
      }))
    : [];
  const value = selectedTags
    ? selectedTags.map((tag) => ({
        value: tag._id,
        label: tag.name,
      }))
    : [];

  /**
   * Callback function triggered when the input has changed.
   */
  const onChange = (option, action) => {
    if (option && action) {
      if (action.action === 'create-option') {
        const newTag = {
          color: UI.logbook.DEFAULT_TAG_COLOR,
          description: null,
          name: option[option.length - 1].label,
        };

        onTagCreatedinWidget(newTag);
      } else if (
        action.action === 'select-option' ||
        action.action === 'remove-value'
      ) {
        onTagSelectedInWidget(
          intersectionWith(
            availableTags,
            option,
            (availableTag, optionItem) => availableTag.name === optionItem.label
          )
        );
      }
    }
  };

  /**
   * Get the custom styles used for the creatableSelect component
   */
  const getCustomStyles = () => {
    return {
      dropdownIndicator: (provided) => ({ ...provided, padding: 1 }),
      clearIndicator: (provided) => ({ ...provided, padding: 1 }),
      control: (provided) => ({ ...provided, minHeight: 25 }),
      valueContainer: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      }),
    };
  };
  return (
    <CreatableSelect
      formatCreateLabel={(inputValue) => `Create "${inputValue.toLowerCase()}"`}
      isMulti
      isLoading={isFetching}
      menuPlacement="top"
      noOptionsMessage={() => 'No existing tag'}
      onChange={onChange}
      onMenuOpen={getTags}
      options={options}
      styles={getCustomStyles()}
      value={value}
    />
  );
}

TagSelector.propTypes = {
  /** list of available tags */
  availableTags: PropTypes.array.isRequired,
  /** callback function triggered when a tag is created in the select element */
  onTagCreatedinWidget: PropTypes.func,
  /** Callback funnction triggered when a tag is selected in the select element */
  onTagSelectedInWidget: PropTypes.func,
  /* list of selected tags */
  selectedTags: PropTypes.array,
};

export default TagSelector;
