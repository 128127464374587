import {
  SET_PAGE,
  SET_CATEGORY_TYPE,
  UNSET_CATEGORY_TYPE,
  SET_LOGBOOK_CONTEXT,
  SET_AUTOMATIC_COLLAPSING,
  SET_AUTOMATIC_REFRESH,
  SET_IS_SORTING_LATESTS_EVENTS_FIRST,
  SHOW_USER_LOGS_BEAMLINE_LOGBOOK,
} from '../constants/actionTypes';

/**
 * Set the logbook context ie the context in which the logbook is used. Here the context corresponds to :
 * - 'proposal': indicating that the logbook is associated to a proposal
 * - 'dataCollection': indicating that the logbook is associated to a dataCollection
 * - 'dataset': indicating that the logbook is associated to a dataset
 * @param {*} context the context object for which the logbook is run.
 */
export function setLogbookContextAction(context) {
  return {
    type: SET_LOGBOOK_CONTEXT,
    context,
  };
}

/**
 * Set a type, category to be shown in the logbook page
 * @param {*} type object with the different filters status
 */
export function setCategoryTypes(categoryType) {
  return {
    type: SET_CATEGORY_TYPE,
    categoryType,
  };
}

/**
 * Unset a type, category to be shown in the logbook page
 * @param {*} type object with the different filters status
 */
export function unsetCategoryTypes(categoryType) {
  return {
    type: UNSET_CATEGORY_TYPE,
    categoryType,
  };
}

/**
 * Set the automatic collapsing events
 * @param {*} automaticCollapsing automaticCollapsing value
 */
export function setAutomaticCollapsing(automaticCollapsing) {
  return {
    type: SET_AUTOMATIC_COLLAPSING,
    automaticCollapsing,
  };
}

/**
 * Set the automatic refresh of events
 * @param {*} automaticRefresh automaticRefresh value
 */
export function setAutomaticRefresh(automaticRefresh) {
  return {
    type: SET_AUTOMATIC_REFRESH,
    automaticRefresh,
  };
}

/**
 * Set the sorting order
 * @param {*} isSortingLatestEventsFirst isSortingLatestEventsFirst value
 */
export function setIsSortingLatestEventsFirst(isSortingLatestEventsFirst) {
  return {
    type: SET_IS_SORTING_LATESTS_EVENTS_FIRST,
    isSortingLatestEventsFirst,
  };
}

/**
 * Displays or hides the user's logs (linked to investigations in the logbook of the beamlines)
 * @param {*} isVisible
 */
export function setBeamlineLogbookShowUserLogs(isVisible) {
  return {
    type: SHOW_USER_LOGS_BEAMLINE_LOGBOOK,
    isVisible,
  };
}

/**
 * Set a type, category to be shown in the logbook page
 * @param {*} type object with the different filters status
 */
export function setPageSize(pageSize) {
  return {
    type: SET_PAGE,
    pageSize,
  };
}
