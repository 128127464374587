import React from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../Spinner';
import StatusDisplay from './StatusDisplay';
import StatusTooltipOverlay from './StatusTooltipOverlay';
import styles from './StatusButton.module.css';

function StatusButton(props) {
  const {
    status,
    onClick,
    isProcessing,
    variant = 'primary',
    disabled = false,
  } = props;

  if (disabled) {
    return (
      <Button variant={variant} disabled={true} size="sm">
        <StatusDisplay status={status} />
      </Button>
    );
  }

  return (
    <StatusTooltipOverlay status={status} placement="right">
      {/* <span> is needed to receive the ref and trigger of StatusTooltipOverlay */}
      <span>
        <Button
          variant={variant}
          size="sm"
          onClick={onClick}
          disabled={isProcessing}
        >
          {isProcessing ? (
            <Spinner className={styles.spinner} />
          ) : (
            <StatusDisplay status={status} action />
          )}
        </Button>
      </span>
    </StatusTooltipOverlay>
  );
}

export default StatusButton;
