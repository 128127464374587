import React from 'react';

import DatasetWidgetFactory from '../../Dataset/Widgets/DatasetWidgetFactory';
import InlineDatasetTable from '../InlineDatasetTable';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { getDatasetParameterValueByName } from '../../../helpers';
import {
  DATASET_HEADER_WITH,
  TIME_HEADER_WITH,
} from '../../Dataset/Widgets/BioSaxs/helper';

const getHeaderRowSpanded = (text, width) => {
  return (
    <th
      rowSpan="2"
      style={{
        //fontSize: '1.4em',
        width: width ? width : 50,
        verticalAlign: 'bottom',
        textAlign: 'center',
      }}
    >
      {text}
    </th>
  );
};

const SampleChangerHeader = () => {
  return (
    <thead className="text-white bg-secondary">
      <tr>
        <th
          rowSpan="2"
          style={{
            width: TIME_HEADER_WITH,
            borderRight: '1px solid gray',
            borderBottom: '1px solid white',
            backgroundColor: 'white',
          }}
        ></th>

        {getHeaderRowSpanded('Sample Changer', DATASET_HEADER_WITH)}
        {getHeaderRowSpanded('Run')}
        <th colSpan="2">Frames</th>
        <th colSpan="3">Guinier</th>
        <th colSpan="3">BIFT</th>
        <th colSpan="2">Porod</th>
        {getHeaderRowSpanded('Scattering')}
        {getHeaderRowSpanded('Kratky')}
        {getHeaderRowSpanded('Density')}
        {getHeaderRowSpanded('Guinier')}
        {getHeaderRowSpanded('', 100)}
      </tr>
      <tr>
        <th>Avg/Total</th>
        <th>Time</th>
        <th>Rg</th>
        <th style={{ width: '60px' }}>Points</th>
        <th>IO</th>
        <th>Rg</th>
        <th>Total</th>
        <th>
          D<sub>max</sub>
        </th>
        <th>Volume</th>
        <th>MM vol. est.</th>
      </tr>
    </thead>
  );
};

const HPLCHeader = () => {
  return (
    <thead className="text-light bg-secondary">
      <tr>
        <th
          style={{
            width: TIME_HEADER_WITH,
            borderRight: '1px solid gray',
            borderBottom: '1px solid white',
            backgroundColor: 'white',
          }}
        ></th>
        <th style={{ width: DATASET_HEADER_WITH }}>HPLC</th>
        <th>Frames</th>
        <th>Wavelength</th>
        <th>Transmission</th>
        <th>Flow Rate</th>
        <th>Mode</th>
        <th>Current</th>
        <th>Chromatogram</th>
        <th style={{ width: '100px' }}></th>
      </tr>
    </thead>
  );
};

const AligmentHeader = () => {
  return (
    <thead className="text-light bg-danger">
      <tr>
        <th
          style={{
            width: TIME_HEADER_WITH,
            borderRight: '1px solid gray',
            borderBottom: '1px solid white',
            backgroundColor: 'white',
          }}
        ></th>
        <th style={{ width: DATASET_HEADER_WITH }}>Calibration</th>
        <th>Mode</th>
        <th>Current</th>

        <th style={{ width: '100px' }}></th>
      </tr>
    </thead>
  );
};

const ExperimentGroup = (props) => {
  const { group, sessionId } = props;

  let header = <SampleChangerHeader></SampleChangerHeader>;
  if (group[0].experimentType === 'HPLC') {
    header = <HPLCHeader></HPLCHeader>;
  }
  if (group[0].experimentType === '') {
    header = <AligmentHeader></AligmentHeader>;
  }

  return (
    <Table borderless size="sm" style={{ fontSize: '1em' }}>
      {header}
      <tbody striped="columns">
        {group.map((dataset) => (
          <DatasetWidgetFactory dataset={dataset} sessionId={sessionId} />
        ))}
      </tbody>
    </Table>
  );
};
function BioSaxsInlineDatasetTable(props) {
  const { datasets } = props;
  const sessionId = useSelector((state) => state.user.sessionId);

  datasets.forEach((ds) => {
    ds.experimentType = getDatasetParameterValueByName(
      ds,
      'SAXS_experiment_type'
    );
    /** Temporary Hack to get the datasets for alignment */
    const definition = getDatasetParameterValueByName(ds, 'definition');
    if (definition !== 'SAXS') {
      ds.parameters.push({
        name: 'definition',
        value: 'SAXS',
      });
    }
  });

  /** Reduce by groups depending of the experiment type */
  const reduced = datasets.reduce((acc, value) => {
    // compare the current value with the last item in the collected array
    if (
      acc.length &&
      acc[acc.length - 1][0].experimentType === value.experimentType
    ) {
      // append the current value to it if it is matching
      acc[acc.length - 1].push(value);
    } else {
      // append the new value at the end of the collected array
      acc.push([value]);
    }

    return acc;
  }, []);
  return (
    <InlineDatasetTable {...props}>
      {reduced.map((group) => {
        return (
          <ExperimentGroup
            sessionId={sessionId}
            group={group}
          ></ExperimentGroup>
        );
      })}
    </InlineDatasetTable>
  );
}

export default BioSaxsInlineDatasetTable;
