import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { STATUS, STATUS_DEFS } from '../../constants/parcelStatuses';
import DangerousGoodsModal from './DangerousGoodsModal';
import InputModal from './InputModal';
import StatusButton from './StatusButton';
import styles from './ParcelStatusButtons.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function ParcelStatusButtons(props) {
  const { parcel, updateStatus, showSafetyActions } = props;
  const { status: currentStatus } = parcel;

  const allowedActions = showSafetyActions
    ? STATUS_DEFS[currentStatus].allowedActions(parcel)
    : STATUS_DEFS[currentStatus]
        .allowedActions(parcel)
        .filter((status) => !STATUS_DEFS[status].action.safety);

  const editableEmptyParcel =
    parcel.items.length === 0 && STATUS_DEFS[currentStatus].editable;

  const [nextStatus, setNextStatus] = useState();
  const [isProcessing, setProcessing] = useState(false);

  async function onClick(status) {
    if (STATUS_DEFS[status].action.requiresInput) {
      return setNextStatus(status);
    }

    setProcessing(true);
    await updateStatus(status);
    setProcessing(false);
  }

  const modalProps = {
    isProcessing,
    onConfirm: async (values) => {
      setProcessing(true);
      await updateStatus(nextStatus, values);
      setProcessing(false);
      setNextStatus(undefined);
    },
    onCloseModal: () => setNextStatus(undefined),
  };

  return (
    <div className={styles.wrapper}>
      {nextStatus === STATUS.READY && <DangerousGoodsModal {...modalProps} />}
      {nextStatus === STATUS.APPROVED && (
        <InputModal
          title={STATUS_DEFS[nextStatus].action.desc}
          variant="success"
          required={false}
          {...modalProps}
        />
      )}
      {nextStatus === STATUS.INPUT && (
        <InputModal
          title={STATUS_DEFS[nextStatus].action.desc}
          variant="warning"
          {...modalProps}
        />
      )}
      {nextStatus === STATUS.REFUSED && (
        <InputModal
          title={STATUS_DEFS[nextStatus].action.desc}
          variant="danger"
          {...modalProps}
        />
      )}
      {allowedActions.length > 0 && (
        <div className={styles.buttonGroup}>
          <div className={styles.label}>Actions:</div>
          {allowedActions.map((status) => (
            <div className={styles.button} key={status}>
              <StatusButton
                onClick={() => onClick(status)}
                status={status}
                isProcessing={isProcessing}
              />
            </div>
          ))}
        </div>
      )}
      {editableEmptyParcel && (
        <Alert variant="light">
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 10 }} />
          An empty parcel cannot be set as {STATUS_DEFS[STATUS.READY].label}.
        </Alert>
      )}
    </div>
  );
}

export default ParcelStatusButtons;
