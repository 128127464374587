import React from 'react';
import { Card } from 'react-bootstrap';

export function AbstractWidget(props) {
  const { descriptions } = props;

  function getAbstract(descriptions) {
    if (descriptions) {
      const abstractObject = descriptions.find((item) => {
        return item.descriptionType.toLowerCase() === 'abstract';
      });

      if (abstractObject) {
        return abstractObject.description;
      }
    }
    return undefined;
  }

  const abstract = getAbstract(descriptions);

  return (
    <>
      <Card style={{ marginBottom: 12, borderStyle: 'none' }}>
        <Card.Body style={{ padding: 0 }}>
          <Card.Text>{abstract}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
