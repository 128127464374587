import React, { useState } from 'react';
import Pager from '../Pager';
import DatasetWidgetFactory from '../Dataset/Widgets/DatasetWidgetFactory';
import { useSelector } from 'react-redux';
import { Navbar, Container, Nav, Form, Button } from 'react-bootstrap';

function GenericDatasetTable(props) {
  const { pageOptions, onHandleTableChange, datasets, onSearch } = props;
  const { page, sizePerPage, totalSize } = pageOptions;
  const [search, setSearch] = useState('');

  const sessionId = useSelector((state) => state.user.sessionId);
  return (
    <>
      <Navbar>
        <Container fluid>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <Pager
                eventCount={totalSize}
                activePage={page}
                pageSize={sizePerPage}
                onClick={onHandleTableChange}
              ></Pager>
            </Nav>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="outline-success"
                onClick={() => {
                  onSearch(search);
                }}
              >
                Search
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {datasets.map((ds) => (
        <DatasetWidgetFactory dataset={ds} sessionId={sessionId} />
      ))}
      <Pager
        eventCount={totalSize}
        activePage={page}
        pageSize={sizePerPage}
        onClick={onHandleTableChange}
      ></Pager>
    </>
  );
}

export default GenericDatasetTable;
