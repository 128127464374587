import React from 'react';
import InvestigationTable from '../../components/Investigation/InvestigationTable';

function ReleasedInvestigationsTable() {
  return (
    <InvestigationTable
      filter="released"
      withInvestigationStats
      withProposalLinks
    />
  );
}

export default ReleasedInvestigationsTable;
