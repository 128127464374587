import React from 'react';
import { Col, Container, Row, Tabs, Badge, Tab } from 'react-bootstrap';
import DatasetDetail from './DatasetDetail';
import { getDatasetParameterByName } from '../../helpers';
import { useHistory } from 'react-router';
import { useQuery } from '../../helpers/hooks';

/**
 * Returns a Badge with the type of a dataset or nothing if it is null
 * @param {*} dataset
 */
const getTabTitle = (dataset) => {
  const { type } = dataset;
  if (!type) return <></>;
  return (
    <>
      {getDatasetParameterByName(dataset, 'datasetName').toUpperCase()}{' '}
      <Badge bg={type.toLowerCase() === 'acquisition' ? 'info' : 'success'}>
        {type.toUpperCase()}
      </Badge>
    </>
  );
};

/**
 * Displays the information of a single dataset in a tabs separating the dataset and its processed datasets associated with
 * @param {*} props
 */
function DetailView(props) {
  const { dataset } = props;
  const history = useHistory();
  const query = useQuery();

  const datasetId = query.get('datasetId') || dataset.id;
  return (
    <Container fluid>
      <Row>
        <Col>
          <Tabs
            variant="pills"
            defaultActiveKey={datasetId}
            id={dataset.id}
            className="mb-3"
            justify
            onSelect={(datasetId) => {
              query.set('datasetId', datasetId);
              history.push({ search: query.toString() });
            }}
          >
            <Tab eventKey={dataset.id} title={getTabTitle(dataset)}>
              <DatasetDetail dataset={dataset}></DatasetDetail>
            </Tab>
            {dataset.outputDatasets &&
              dataset.outputDatasets.map((processed) => {
                return (
                  <Tab eventKey={processed.id} title={getTabTitle(processed)}>
                    <DatasetDetail dataset={processed}></DatasetDetail>
                  </Tab>
                );
              })}
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default DetailView;
