import React from 'react';
import UnitText from '../UnitText';
import { getDatasetParameterValueByName } from '../../../../helpers';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BiosaxsDatasetColumn(props) {
  const { dataset, showOutputDatasets } = props;
  const datasetName = getDatasetParameterValueByName(dataset, 'datasetName');

  return (
    <>
      <LinkContainer
        style={{ padding: 0 }}
        to={`/dataset/${dataset.id}?view=datasets`}
      >
        <Nav.Link>{datasetName} </Nav.Link>
      </LinkContainer>
      {dataset.sampleName}
      <br />
      <>
        {getDatasetParameterValueByName(dataset, 'SAXS_exposure_temperature')}
        <UnitText
          dataset={dataset}
          parameterName={'SAXS_exposure_temperature'}
        ></UnitText>
      </>
      <br />
      <>
        {getDatasetParameterValueByName(dataset, 'SAXS_concentration')}
        <UnitText
          dataset={dataset}
          parameterName={'SAXS_concentration'}
        ></UnitText>
      </>
      <div style={{ marginTop: 10 }}>
        {showOutputDatasets &&
          dataset.outputDatasets &&
          dataset.outputDatasets.map((ds) => {
            return (
              <LinkContainer
                style={{ padding: 0 }}
                className={'text-capitalize text-info'}
                to={`/dataset/${ds.id}?view=datasets`}
              >
                <Nav.Link>
                  <FontAwesomeIcon icon={faCheck} />
                  {getDatasetParameterValueByName(
                    ds,
                    'datasetName'
                  ).toLowerCase()}
                </Nav.Link>
              </LinkContainer>
            );
          })}
      </div>
    </>
  );
}

export default BiosaxsDatasetColumn;
