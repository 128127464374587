import { Resource } from 'rest-hooks';
import { store } from '../store';
import { getInvestigationUsersByInvestigationId } from '../api/icat-plus/catalogue';

export default class InvestigationUserResource extends Resource {
  name = undefined;
  fullName = '';
  role = '';
  investigationName = '';
  investigationId = undefined;
  email = '';
  id = undefined;

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'InvestigationUserResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId } = params;
    return getInvestigationUsersByInvestigationId(sessionId, investigationId);
  }

  static deleteShape() {
    return {
      ...super.deleteShape(),
      fetch: (params, body) => this.fetch('delete', this.listUrl(params), body),
    };
  }
}
