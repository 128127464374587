import { uniq } from 'lodash-es';
import {
  FETCH_INSTRUMENTSCIENTISTS_FULFILLED,
  FETCH_INSTRUMENTSCIENTISTS_PENDING,
  LOG_OUT,
} from '../constants/actionTypes';

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  instrumentNames: [],
};

const instrumentscientists = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTRUMENTSCIENTISTS_PENDING: {
      state = {
        ...state,
        data: [],
        fetched: false,
        fetching: true,
        instrumentNames: [],
      };
      break;
    }
    case FETCH_INSTRUMENTSCIENTISTS_FULFILLED: {
      state = {
        ...state,
        data: action.payload.data,
        fetched: true,
        fetching: false,
        instrumentNames: uniq(
          action.payload.data.map((e) => e.instrument.name)
        ).sort(),
      };
      break;
    }

    case LOG_OUT: {
      state = {
        ...state,
        data: [],
        fetched: false,
        fetching: false,
        instrumentNames: [],
      };
      break;
    }

    default:
      break;
  }
  return state;
};

export default instrumentscientists;
