import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Stack } from 'react-bootstrap';
import DatasetDownloadButton from '../../DatasetDownloadButton';

function Footer(props) {
  const { location, dataset } = props;

  return (
    <Stack direction="horizontal" gap={2}>
      <div>
        <FontAwesomeIcon icon={faFolderOpen} /> {location}
      </div>
      <div className="ms-auto">
        <DatasetDownloadButton id={dataset.id} dataset={dataset} />
      </div>
    </Stack>
  );
}

export default Footer;
