import Proptype from 'prop-types';
import React from 'react';
import TagLabel from './TagLabel';

/** React component which renders tags in line */
function TagListInLine(props) {
  const { tags, onDeleteTagClicked, showDeleteButton } = props;

  if (tags && tags instanceof Array && tags.length > 0) {
    return tags.map((tag) => (
      <TagLabel
        key={tag._id}
        onDeleteTagClicked={onDeleteTagClicked || null}
        showDeleteButton={showDeleteButton}
        tag={tag}
      />
    ));
  }
  return null;
}

TagListInLine.proptype = {
  /** Callback function triggered when the user clicks on the delete button of a tag. */
  onDeleteTagClicked: Proptype.func,
  /** Whether or not the delete button is shown for the tags. */
  showDeleteButton: Proptype.bool,
  /** all tag objects to be rendered */
  tags: Proptype.array,
};

export default TagListInLine;
