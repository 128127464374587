import React from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import UI from '../../config/ui';
import AnonymousSignIn from './AnonymousSignIn';
import { useSelector, useDispatch } from 'react-redux';
import { doSignIn } from '../../actions/login';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { ErrorUserMessage } from '../UserMessages/ErrorUserMessage';

function LoginForm(props) {
  const { plugin } = props;
  const user = useSelector((state) => state.user);

  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  return (
    <Card className="tab-panel-fix" style={{ padding: '20px 0 ' }}>
      <form
        className="container-fluid"
        onSubmit={handleSubmit((data) => {
          dispatch(doSignIn(plugin, data.username, data.password));
        })}
      >
        {UI.loginForm.header && (
          <p className="text-info" style={{ textAlign: 'left' }}>
            {UI.loginForm.header}
          </p>
        )}
        {user.error && <ErrorUserMessage text={user.error} />}
        {user.isSessionExpired && (
          <Alert variant="warning">Session expired</Alert>
        )}

        <Form.Group controlId="username">
          <Form.Label>{UI.loginForm.usernameLabel}</Form.Label>
          <Form.Control
            ref={register({ required: true })}
            type="text"
            name="username"
            autoFocus
            required
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            ref={register({ required: true })}
            type="password"
            name="password"
            required
          />
        </Form.Group>

        <Button
          style={{ marginTop: 5, width: '100%' }}
          type="submit"
          variant="primary"
        >
          <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: 10 }} />
          {user.isAuthenticating ? 'Signing in...' : 'Sign in'}
        </Button>
      </form>

      <AnonymousSignIn />
    </Card>
  );
}

export default LoginForm;
