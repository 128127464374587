import React from 'react';
import { Card } from 'react-bootstrap';

export function TitleWidget(props) {
  const { titles } = props;
  const title = titles && titles.length > 0 ? titles[0].title : '';
  return (
    <>
      <Card style={{ borderStyle: 'none' }}>
        <Card.Body style={{ padding: 0 }}>
          <Card.Title>
            <h3>{title}</h3>
          </Card.Title>
        </Card.Body>
      </Card>
    </>
  );
}
