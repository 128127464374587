import React from 'react';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
import UnitText from '../UnitText';
import {
  getDatasetParameterValueByName,
  getOutputDatasetByName,
  getOutputDatasetParameterValueByName,
  getOutputGalleryImageIdByKey,
} from '../../../../helpers';

import DatasetDownloadButton from '../../DatasetDownloadButton';
import { truncate } from './helper';
import BiosaxsDatasetColumn from './BiosaxsDatasetColumn';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import StartTimeDatasetPanel from '../StartTimeDatasetPanel';

function SampleChangerBioSaxsDatasetWidget(props) {
  const { dataset } = props;

  const subtract = getOutputDatasetByName(dataset, 'subtract');

  return (
    <>
      <tr>
        <td
          style={{
            borderRight: '1px solid gray',
            borderTop: '0px',
          }}
        >
          <StartTimeDatasetPanel dataset={dataset}></StartTimeDatasetPanel>
        </td>
        <td>
          <BiosaxsDatasetColumn dataset={dataset} showOutputDatasets={true} />
        </td>
        <td># {getDatasetParameterValueByName(dataset, 'SAXS_runNumber')} </td>
        <td>
          {getOutputDatasetParameterValueByName(
            dataset,
            'integrate',
            'SAXS_frames_averaged'
          )}
          / {getDatasetParameterValueByName(dataset, 'SAXS_numberFrames')}
        </td>
        <td>
          {getDatasetParameterValueByName(dataset, 'SAXS_timePerFrame')}
          <UnitText
            dataset={dataset}
            parameterName="SAXS_timePerFrame"
          ></UnitText>
        </td>
        {[
          'SAXS_guinier_rg',
          'SAXS_guinier_points',
          'SAXS_guinier_i0',
          'SAXS_rg',
          'SAXS_total',
          'SAXS_d_max',
        ].map((parameter) => {
          return (
            <td>
              {getOutputDatasetParameterValueByName(
                dataset,
                'subtract',
                parameter
              )}
              <UnitText dataset={subtract} parameterName={parameter}></UnitText>
            </td>
          );
        })}
        <td>
          {truncate(
            getOutputDatasetParameterValueByName(
              dataset,
              'subtract',
              'SAXS_porod_volume'
            )
          )}
          <UnitText
            dataset={subtract}
            parameterName="SAXS_porod_volume"
          ></UnitText>
        </td>
        <td style={{ width: '80px' }}>
          {getOutputDatasetParameterValueByName(
            dataset,
            'subtract',
            'SAXS_porod_MM_volume_estimation'
          )}

          <UnitText
            dataset={subtract}
            parameterName="SAXS_porod_MM_volume_estimation"
          ></UnitText>
        </td>
        <td style={{ width: '180px' }}>
          {!getOutputGalleryImageIdByKey(dataset, 'subtract', 'scattering') && (
            <GalleryDatasetThumbnail
              imageId={getOutputGalleryImageIdByKey(
                dataset,
                'integrate',
                'cattering'
              )}
            ></GalleryDatasetThumbnail>
          )}

          <GalleryDatasetThumbnail
            imageId={getOutputGalleryImageIdByKey(
              dataset,
              'subtract',
              'scattering'
            )}
          ></GalleryDatasetThumbnail>
        </td>
        {['kratky', 'density', 'guinier'].map((key) => {
          return (
            <td style={{ width: '180px' }}>
              {' '}
              <GalleryDatasetThumbnail
                imageId={getOutputGalleryImageIdByKey(dataset, 'subtract', key)}
              ></GalleryDatasetThumbnail>
            </td>
          );
        })}
        <td>
          <DatasetDownloadButton
            id={dataset.id}
            dataset={dataset}
          ></DatasetDownloadButton>
        </td>
      </tr>
      <tr style={{ backgroundColor: '#F5F5F5' }}>
        <td
          style={{
            borderRight: '0px solid gray',
            borderTop: '0px',
            backgroundColor: 'white',
          }}
        ></td>
        <td
          colSpan="100%"
          style={{
            textAlign: 'right',
          }}
        >
          <LinkContainer to={`/dataset/${dataset.id}?view=files`}>
            <Nav.Link>
              <FontAwesomeIcon icon={faFolder} style={{ marginRight: 10 }} />
              {dataset.location}
            </Nav.Link>
          </LinkContainer>
        </td>
      </tr>
    </>
  );
}

export default SampleChangerBioSaxsDatasetWidget;
