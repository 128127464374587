import React from 'react';
import { Card } from 'react-bootstrap';
import GalleryDatasetThumbnail from './GalleryDatasetThumbnail';
import { RESOURCES_GALLERY_FILE_PATHS_PARAMETER } from '../../helpers';

function getParameter(dataset) {
  /** Search parameter */
  return dataset.parameters.find(
    (p) => p.name === RESOURCES_GALLERY_FILE_PATHS_PARAMETER
  );
}
/**
 * Returns the name of the file in the gallery by index
 * @param {*} dataset
 * @param {*} index
 */
function getGalleryFileNameByIndex(parameter, index) {
  if (!parameter) return;

  const filePaths = parameter.value.split(',');

  if (filePaths.length < index) return;

  if (filePaths[index]) {
    const splitted = filePaths[index].split('/');
    return splitted[splitted.length - 1];
  }
}
/**
 * Card wrapper for GalleryDatasetThumbnail component that shows the component in a card with the name of the file in the header
 * @param {*} props
 */
export default function GalleryDatasetThumbnailCard(props) {
  const { dataset, index } = props;

  const resourcesGalleryFilePathsParameter = getParameter(dataset);
  if (!resourcesGalleryFilePathsParameter) return <></>;

  const fileName = getGalleryFileNameByIndex(
    resourcesGalleryFilePathsParameter,
    index
  );

  if (!fileName) return <></>;

  return (
    <Card>
      <Card.Header>{fileName}</Card.Header>
      <Card.Body>
        <GalleryDatasetThumbnail dataset={dataset} index={index} />
      </Card.Body>
    </Card>
  );
}
