import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

function ItemCondensedView(props) {
  const { name, type, sample, description, comments } = props;

  return (
    <ListGroup className="condensed-list-group">
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Name: </span>
        {name}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Type: </span>
        {type}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }} className={!sample && 'gray-out'}>
          Sample:{' '}
        </span>
        {sample ? sample : ''}
      </ListGroupItem>
      <ListGroupItem>
        <span
          style={{ fontWeight: 'bold' }}
          className={!description && 'gray-out'}
        >
          Description:{' '}
        </span>
        {description ? description : ''}
      </ListGroupItem>
      <ListGroupItem>
        <span
          style={{ fontWeight: 'bold' }}
          className={!comments && 'gray-out'}
        >
          Comments:{' '}
        </span>
        {comments ? comments : ''}
      </ListGroupItem>
    </ListGroup>
  );
}

export default ItemCondensedView;
