// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DOIBadge_badge__7mTrw {\n  display: inline-flex;\n  padding-left: 5px;\n  overflow: hidden;\n  background-color: #000;\n  border-radius: 5px;\n  line-height: 1.6;\n  color: #fff !important;\n  text-decoration: none !important;\n}\n\n.DOIBadge_doi__Oc-pY {\n  background-color: #09c;\n  margin-left: 5px;\n  padding: 0 5px;\n}\n\n.DOIBadge_badge__7mTrw:hover > .DOIBadge_doi__Oc-pY {\n  background-color: #006a8d;\n}\n", ""]);
// Exports
exports.locals = {
	"badge": "DOIBadge_badge__7mTrw",
	"doi": "DOIBadge_doi__Oc-pY"
};
module.exports = exports;
