import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';

export default class DoiResource extends Resource {
  doi = undefined;

  pk() {
    return this.doi?.toString();
  }

  static get key() {
    return 'DoiResource';
  }

  static listUrl(params) {
    const { doi } = params;
    return `${ICATPLUS.server}/doi/${doi}/json-datacite`;
  }

  static url(params) {
    const { doi } = params;
    return `${ICATPLUS.server}/doi/${doi}/json-datacite`;
  }

  // override the fetch method to handle errors when doi not recognized in icat
  static fetch(method, url, body) {
    return this.fetchResponse(method, url, body)
      .then((response) => {
        if (
          !response.headers.get('content-type')?.includes('json') ||
          response.status === 204
        )
          return response.text();
        return response.json().catch((error) => {
          error.status = 400;
          throw error;
        });
      })
      .catch((error) => {
        if (error.status === 500) {
          return { doi: -1, error: true };
        }
        throw error;
      });
  }
}
