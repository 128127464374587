import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  getFilesByDatasetId,
  getDownloadURLByDatafileId,
} from '../../api/icat-plus/catalogue';
import { stringifyBytesSize } from '../../helpers';
import ResponsiveTable from '../Table/ResponsiveTable';
import Loader from '../Loader';
import { trackDownload, trackH5Viewer } from '../../helpers/trackData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import {
  isH5Viewer,
  isZeroBytesFile,
} from '../../containers/DatasetPage/helper';

function FileTable(props) {
  const { sessionId, dataset, selectDatafile, preview = true } = props;

  const [data, setData] = useState([]);
  const [files, setFiles] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [sizePerPage, setSizePerPage] = useState(20);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function getData() {
      setFetching(true);
      const res = await axios.get(
        `${getFilesByDatasetId(
          sessionId,
          dataset.id
        )}?limit=${sizePerPage}&skip=${skip}&search=${search}`
      );
      const dataFilesList = res.data.map((row) => row.Datafile);
      setData(res.data);
      setFiles(dataFilesList);
      setFetching(false);
    }

    getData();
  }, [dataset.id, search, sessionId, sizePerPage, skip]);

  const totalSize = data && data.length > 0 ? data[0].meta?.page?.total : 0;

  const displayViewerButton = (_, dataFile) => {
    if (isH5Viewer(dataFile) && !isZeroBytesFile(dataFile)) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-right`}>Click to open H5 Viewer</Tooltip>
          }
        >
          <Button
            size="sm"
            variant="light"
            onClick={() => {
              trackH5Viewer(`${dataFile.id} - ${dataFile.name}`);
              if (selectDatafile) selectDatafile(dataFile);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </OverlayTrigger>
      );
    }
  };

  const download = (e, id) => {
    trackDownload('DataFile', id);
  };

  const downloadFormatter = (_, dataFile) => {
    if (!isZeroBytesFile(dataFile)) {
      return (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-right`}>Click to download the file</Tooltip>
          }
        >
          <a
            href={getDownloadURLByDatafileId(sessionId, dataFile.id)}
            onClick={(e) => download(e, dataFile.id)}
          >
            <Button size="sm" variant="light">
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </a>
        </OverlayTrigger>
      );
    }
  };

  const locationFormatter = (_, dataFile) => {
    return dataFile.name;
  };

  function handleTableChange(page, sizePerPage) {
    setPage(parseInt(page || 1));
    setSizePerPage(parseInt(sizePerPage || 20));
    setSkip(sizePerPage * (page - 1));
  }

  function onSearch(s) {
    setSearch(s);
    setPage(1);
    setSkip(sizePerPage * (page - 1));
  }

  if (fetching) {
    return <Loader message="Loading files..." spacedOut />;
  }
  return (
    <ResponsiveTable
      remote={true}
      data={files}
      columns={[
        { dataField: 'id', text: '', hidden: true },
        {
          dataField: 'download',
          text: '',
          formatter: downloadFormatter,
          headerStyle: { width: '60px' },
        },
        {
          dataField: 'Preview',
          text: 'Preview',
          hidden: !preview,
          formatter: displayViewerButton,
          headerStyle: { width: '70px' },
        },
        {
          dataField: 'name',
          text: 'Location',
          formatter: locationFormatter,
        },
        {
          dataField: 'fileSize',
          text: 'Size',
          formatter: stringifyBytesSize,
          headerStyle: { width: '10%' },
        },
      ]}
      pageOptions={{
        page: parseInt(page),
        sizePerPage,
        totalSize,
        showTotal: true,
        sizePerPageList: [
          { text: '10', value: 10 },
          { text: '20', value: 20 },
          { text: '30', value: 30 },
          { text: '50', value: 50 },
        ],
      }}
      defaultSearchText={search}
      handleTableChange={handleTableChange}
      onSearch={onSearch}
    />
  );
}

export default FileTable;
