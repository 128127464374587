import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { INVESTIGATION_DATE_FORMAT } from '../../../constants';
import 'react-day-picker/lib/style.css';
import { Form } from 'react-bootstrap';
import Moment from 'moment';

function DayPicker(props) {
  const { day, set, onDayChange, placeHolder } = props;
  return (
    <DayPickerInput
      format={INVESTIGATION_DATE_FORMAT}
      placeholder={placeHolder}
      value={Moment(day).format(INVESTIGATION_DATE_FORMAT)}
      onDayChange={(selectedDay, modifiers, dayPickerInput) => {
        set(dayPickerInput.getInput().value);
        onDayChange(dayPickerInput.getInput().value);
      }}
      dayPickerProps={{
        todayButton: 'Today',
      }}
    />
  );
}
function SampleTrackingStatsDateFilter(props) {
  const {
    start,
    setStart,
    end,
    setEnd,
    onStartDayChange,
    onEndDayChange,
  } = props;

  return (
    <Form className="d-flex">
      <Form.Group controlId="formInlineName">
        <Form.Label>Search parcels on sessions starting between: </Form.Label>{' '}
        <DayPicker
          day={start}
          set={setStart}
          onDayChange={onStartDayChange}
          placeHolder="Start Date"
        ></DayPicker>
      </Form.Group>{' '}
      <Form.Group style={{ marginLeft: 5 }} controlId="formInlineEmail">
        <Form.Label>and</Form.Label>{' '}
        <DayPicker
          day={end}
          set={setEnd}
          onDayChange={onEndDayChange}
          placeHolder="End Date"
        ></DayPicker>
      </Form.Group>{' '}
    </Form>
  );
}

export default SampleTrackingStatsDateFilter;
