import React from 'react';
import {
  Alert,
  Button,
  Col,
  DropdownButton,
  Form,
  Dropdown,
  Modal,
  Row,
} from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCache, useResource } from 'rest-hooks';
import AddressResource from '../../resources/address';
import AddressTextFieldsForm from '../Address/AddressTextFields';
import { addressFormatter, useReturnAddress } from '../Address/utils';
import TextFieldGroup from '../Form/TextFieldGroup';
import styles from './ParcelFormModal.module.css';
import RequiredStar from '../RequiredStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const FORM_LAYOUT = { labelCol: { sm: 3 }, fieldCol: { sm: 9 } };

const STORAGE_CONDITIONS = {
  RoomTemp: 'At room temperature',
  Fridge: 'In fridge (4°C)',
  Freezer: 'In freezer (-18°C)',
  UltraFreezer: 'In freezer (-80°C)',
  Other: 'Other',
};

function ParcelFormModal(props) {
  const {
    investigation,
    shipment,
    parcel,
    onSubmitAsync,
    onCloseModal,
  } = props;
  const [investigationId, investigationName] = parcel
    ? [parcel.investigationId, parcel.investigationName]
    : [investigation.id, investigation.name];
  const { _id: shipmentId } = shipment;
  const username = useSelector((state) => state.user.name);

  async function onSubmit(values) {
    const newValues = {
      ...values,
      returnAddress: values.noReturnAddress ? null : values.returnAddress,
    };
    await onSubmitAsync(newValues);
  }

  const addresses = useResource(AddressResource.listShape(), {});

  // Default addresses are returned in full with shipment so we get them straight from cache (as they may not be in the user's addresses)
  const shipmentShippingAddress = useCache(AddressResource.detailShape(), {
    _id: shipment.defaultShippingAddress,
  });
  const shipmentReturnAddress = useReturnAddress(shipment);

  const defaultValues = parcel
    ? { ...parcel, noReturnAddress: !parcel.returnAddress }
    : {
        shippingAddress: shipmentShippingAddress,
        returnAddress: shipmentReturnAddress,
        noReturnAddress: !shipmentReturnAddress,
        storageConditions: STORAGE_CONDITIONS.RoomTemp,
      };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    register,
    formState,
    watch,
    getValues,
    reset,
  } = methods;
  const { isSubmitting } = formState;

  const watchedNoReturnAddress = watch('noReturnAddress');
  const watchedStorageConditions = watch('storageConditions');

  const handleSelectSenderAddress = (e) => {
    const a = addresses[addresses.map((a) => a._id).indexOf(e)];
    reset({ ...getValues(), shippingAddress: a });
  };

  const handleSelectReturnAddress = (e) => {
    const a = addresses[addresses.map((a) => a._id).indexOf(e)];
    reset({
      ...getValues(),
      returnAddress: a,
      noReturnAddress: false,
    });
  };

  return (
    <Modal
      show
      backdrop="static"
      size="lg"
      aria-labelledby="parcel-modal-title"
      onHide={onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="parcel-modal-title">
          {parcel ? 'Edit' : 'Create'} parcel
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <FormProvider {...methods}>
            {parcel && <input name="_id" type="hidden" ref={register} />}
            {
              <input
                name="investigationId"
                defaultValue={investigationId}
                type="hidden"
                ref={register}
              />
            }
            {
              <input
                name="investigationName"
                defaultValue={investigationName}
                type="hidden"
                ref={register}
              />
            }
            {
              <input
                name="shipmentId"
                defaultValue={shipmentId}
                type="hidden"
                ref={register}
              />
            }

            <TextFieldGroup
              name="name"
              label="Name of the parcel"
              type="text"
              registerOptions={{ required: true }}
              layout={FORM_LAYOUT}
            />
            <TextFieldGroup
              style={{ marginTop: 10 }}
              name="description"
              type="textarea"
              layout={FORM_LAYOUT}
            />
            <Form.Group
              as={Row}
              style={{ marginTop: 10 }}
              controlId="storageConditions"
            >
              <Form.Label column {...FORM_LAYOUT.labelCol}>
                Storage conditions
                <RequiredStar />
              </Form.Label>
              <Col column {...FORM_LAYOUT.fieldCol}>
                <Form.Select name="storageConditions" ref={register}>
                  {Object.entries(STORAGE_CONDITIONS).map(([k, cond]) => (
                    <option key={k} value={cond}>
                      {cond}
                    </option>
                  ))}
                </Form.Select>
                {watchedStorageConditions === STORAGE_CONDITIONS.Other && (
                  <Alert variant="info" style={{ marginTop: 10 }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    Please specify in the comments
                  </Alert>
                )}
              </Col>
            </Form.Group>
            <TextFieldGroup
              style={{ marginTop: 10 }}
              name="comments"
              label="Comments"
              type="textarea"
              registerOptions={{
                required: watchedStorageConditions === STORAGE_CONDITIONS.Other,
              }}
              layout={FORM_LAYOUT}
            />

            <input
              name={'shippingAddress._id'}
              defaultValue={shipmentShippingAddress._id}
              type="hidden"
              ref={register}
            />
            <input
              name={'shippingAddress.createdBy'}
              defaultValue={username}
              type="hidden"
              ref={register}
            />
            <input
              name={'returnAddress.createdBy'}
              defaultValue={username}
              type="hidden"
              ref={register}
            />
            <input
              name={'shippingAddress.investigationName'}
              defaultValue={investigationName}
              type="hidden"
              ref={register}
            />
            <input
              name={'returnAddress.investigationName'}
              defaultValue={investigationName}
              type="hidden"
              ref={register}
            />
            <input
              name={'shippingAddress.investigationId'}
              defaultValue={investigationId}
              type="hidden"
              ref={register}
            />
            <input
              name={'returnAddress.investigationId'}
              defaultValue={investigationId}
              type="hidden"
              ref={register}
            />

            <Row>
              <Col {...FORM_LAYOUT.labelCol}>
                <Form.Group controlId="shippingAddress">
                  <Form.Label>Address of sender</Form.Label>
                  <DropdownButton
                    title="Choose from my addresses"
                    id="shippingAddressAutoFill"
                    className={styles.dropdown}
                    size="sm"
                    onSelect={handleSelectSenderAddress}
                  >
                    {addresses.map((a) => (
                      <Dropdown.Item key={a._id} eventKey={a._id}>
                        {addressFormatter(a)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </Col>
              <Col {...FORM_LAYOUT.fieldCol}>
                <AddressTextFieldsForm addressObjName={'shippingAddress'} />
                {getValues('shippingAddress._id') ===
                  shipmentShippingAddress._id && (
                  <Alert>
                    This was filled according to your shipping configuration
                  </Alert>
                )}
              </Col>
            </Row>

            <Row>
              <Col {...FORM_LAYOUT.labelCol}>
                <Form.Group controlId="returnAddress">
                  <Form.Label>Return address</Form.Label>
                  <p className={styles.info}>
                    Where to return the parcel at the end of the investigation.
                  </p>
                  <DropdownButton
                    title="Choose from my addresses"
                    id="returnAddressAutoFill"
                    className={styles.dropdown}
                    size="sm"
                    onSelect={handleSelectReturnAddress}
                  >
                    {addresses.map((a) => (
                      <Dropdown.Item key={a._id} eventKey={a._id}>
                        {addressFormatter(a)}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
              </Col>
              <Col {...FORM_LAYOUT.fieldCol}>
                <Form.Check
                  className={styles.returnCheckbox}
                  name="noReturnAddress"
                  type="checkbox"
                  ref={register}
                  label="No return address (the parcel will be discarded)"
                />

                {!watchedNoReturnAddress && (
                  <AddressTextFieldsForm addressObjName={'returnAddress'} />
                )}
                {((!shipmentReturnAddress && watchedNoReturnAddress) ||
                  (shipmentReturnAddress &&
                    !watchedNoReturnAddress &&
                    getValues('returnAddress._id') ===
                      shipmentReturnAddress._id)) && (
                  <Alert>
                    This was filled according to your shipping configuration
                  </Alert>
                )}
              </Col>
            </Row>
          </FormProvider>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="link" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          {`${parcel ? 'Sav' : 'Creat'}${isSubmitting ? 'ing...' : 'e'}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ParcelFormModal;
