import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class AddressResource extends Resource {
  _id = undefined;
  investigationName = '';
  status = '';
  name = '';
  surname = '';
  companyName = '';
  address = '';
  city = '';
  postalCode = null;
  email = '';
  phoneNumber = null;
  country = '';

  pk() {
    return this._id?.toString();
  }

  static get key() {
    return 'AddressResource';
  }

  static url(params) {
    const { sessionId } = store.getState().user;
    const { investigationId } = params;
    return `${ICATPLUS.server}/tracking/${sessionId}/investigation/id/${investigationId}/address`;
  }

  static listUrl() {
    const { sessionId } = store.getState().user;
    return `${ICATPLUS.server}/tracking/${sessionId}/address`;
  }

  static createShape() {
    return {
      ...super.createShape(),
      getFetchKey: (params) => `POST ${this.url(params)}`,
      fetch: (address) => this.fetch('post', this.url(address), address),
    };
  }

  static updateShape() {
    return {
      ...super.updateShape(),
      fetch: (address) => this.fetch('put', this.url(address), address),
    };
  }

  static deleteShape() {
    return {
      ...super.deleteShape(),
      fetch: (address) => this.fetch('delete', this.url(address), address),
    };
  }
}
