import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';

export default class InstrumentsResource extends Resource {
  Instrument = undefined;

  pk() {
    return this.Instrument.id?.toString();
  }

  static get key() {
    return 'InstrumentsResource';
  }

  static listUrl() {
    return `${ICATPLUS.server}/catalogue/instruments`;
  }

  static url(params) {
    return this.listUrl(params);
  }
}
