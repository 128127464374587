import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useFetcher } from 'rest-hooks';
import { isEditable } from '../../helpers/statusUtils';
import ParcelResource from '../../resources/parcel';
import DownloadLabelButton from './DownloadLabelButton';
import styles from './ParcelHeader.module.css';
import { Link } from 'react-router-dom';
import { startDateFormatter } from '../Investigation/utils';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ParcelHeader(props) {
  const { parcel, investigation, setAlert } = props;
  const { shipmentId, investigationId, _id } = parcel;
  const history = useHistory();

  const investigationSummary = investigation
    ? `${investigation.name} - ${startDateFormatter(
        investigation.startDate
      )} - ${investigation.visitId.toUpperCase()}`
    : parcel.investigationName;

  const deleteParcel = useFetcher(ParcelResource.deleteShape());

  return (
    <div className={styles.parcelHeader}>
      <div>
        <h4>
          <b>{parcel.name}</b>
        </h4>
        <h6 className={styles.investigation}>
          <Link to={`/investigation/${investigationId}/shipping`}>
            {investigationSummary}
          </Link>
        </h6>
      </div>
      <div>
        <DownloadLabelButton className={styles.parcelAction} parcel={parcel} />
        <Button
          className={styles.parcelAction}
          variant="danger"
          size="sm"
          onClick={async () => {
            try {
              await deleteParcel(
                { shipmentId, parcelId: _id, investigationId },
                { _id }
              );
              history.push(`/investigation/${investigationId}/shipping`);
            } catch (error) {
              setAlert({
                type: 'danger',
                message: `An error occurred. ${error.message}`,
              });
              console.error(error.response);
            }
          }}
          disabled={!isEditable(parcel)}
        >
          <FontAwesomeIcon icon={faTrash} />
          <span style={{ margin: 10 }}>Delete</span>
        </Button>
      </div>
    </div>
  );
}

export default ParcelHeader;
