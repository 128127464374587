import {
  faBook,
  faPlayCircle,
  faSignal,
  faCalendarMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import UI from '../../config/ui';
import { INVESTIGATION_DATE_FORMAT } from '../../constants';
import styles from './InvestigationDateFilter.module.css';

const getStatisticsHrefByInstrument = (instrumentName) => {
  try {
    if (instrumentName) {
      return `/manager/stats/data?instrumentName=${instrumentName.replace(
        '-',
        ''
      )}&aggregation=definition`;
    }
  } catch (e) {
    console.log(e);
  }
};

const getLogbookHrefByInstrument = (instrumentName) => {
  return `/beamline/${instrumentName}/events`;
};
function InvestigationDateFilter(props) {
  const {
    rootClassName,
    startValue,
    onStartDayChange,
    onStartClear,
    startPlaceHolder,
    endValue,
    onEndDayChange,
    onEndClear,
    endPlaceHolder,
    instrumentName,
    showStatisticsMenu,
    withJupyter,
    showLogbookMenu,
  } = props;

  return (
    <>
      <div className={rootClassName}>
        <DayPickerInput
          inputProps={{ className: styles.dateFilterInput }}
          value={startValue}
          placeholder={startPlaceHolder ? startPlaceHolder : 'Filter by date'}
          format={INVESTIGATION_DATE_FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onStartDayChange}
          dayPickerProps={{
            todayButton: 'Today',
          }}
        />
        <Button
          disabled={!startValue}
          onClick={onStartClear}
          variant="link"
          className={styles.clearButton}
        >
          <FontAwesomeIcon icon={faCalendarMinus} />
        </Button>

        <DayPickerInput
          inputProps={{ className: styles.dateFilterInput }}
          value={endValue}
          placeholder={endPlaceHolder ? endPlaceHolder : 'Filter by date'}
          format={INVESTIGATION_DATE_FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={onEndDayChange}
          dayPickerProps={{
            todayButton: 'Today',
          }}
        />
        <Button
          disabled={!endValue}
          onClick={onEndClear}
          variant="link"
          className={styles.clearButton}
        >
          <FontAwesomeIcon icon={faCalendarMinus} />
        </Button>
        {showStatisticsMenu && (
          <Button
            href={getStatisticsHrefByInstrument(instrumentName)}
            variant="outline-primary"
          >
            <FontAwesomeIcon icon={faSignal} style={{ marginRight: 5 }} />
            Data Statistics
          </Button>
        )}
        {withJupyter && (
          <Button
            style={{ width: 230, textAlign: 'left', margin: 5 }}
            variant="outline-primary"
            href={UI.analysis.jupyter.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faPlayCircle} style={{ marginRight: 5 }} />
            <span style={{ marginLeft: 2 }}> Live SESAEM Machine Status</span>
          </Button>
        )}
        {showLogbookMenu && (
          <Button
            style={{ margin: 5 }}
            href={getLogbookHrefByInstrument(instrumentName)}
            variant="outline-primary"
          >
            <FontAwesomeIcon icon={faBook} style={{ marginRight: 5 }} />
            {`${instrumentName.toUpperCase()} Logbook`}
          </Button>
        )}
      </div>
    </>
  );
}

export default InvestigationDateFilter;
