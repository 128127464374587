import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import TextFieldGroup from '../Form/TextFieldGroup';

function InputModal(props) {
  const {
    onConfirm,
    onCloseModal,
    variant,
    title,
    isProcessing,
    required = true,
  } = props;
  const [submitError, setSubmitError] = useState();

  const methods = useForm();
  const { handleSubmit } = methods;

  function onSubmit(values) {
    try {
      onConfirm(values);
    } catch (error) {
      setSubmitError(`There was an error while updating the parcel: ${error}`);
    }
  }

  return (
    <Modal show aria-labelledby="parcel-modal-title" onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title id="parcel-modal-title">{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {submitError && <Alert variant="danger">{submitError}</Alert>}
        <FormProvider {...methods}>
          <TextFieldGroup
            registerOptions={{ required }}
            name="comments"
            label="Justification"
            type="textarea"
          />
        </FormProvider>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant={variant}
          disabled={isProcessing}
          onClick={handleSubmit(onSubmit)}
        >
          {isProcessing ? 'Processing...' : 'Confirm'}
        </Button>
        <Button onClick={onCloseModal}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InputModal;
