import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Stack } from 'react-bootstrap';
import UI from '../../config/ui';

function LoginInformation() {
  const getFeedbackURL = () => {
    return `mailto:${UI.feedback.email}`;
  };

  const accountCreationLink = UI.loginForm.accountCreationLink;
  const notes = UI.loginForm.note.notes;
  return (
    <Stack gap={3}>
      <div>
        Don't have an account yet?
        <a target="_blank" rel="noopener noreferrer" href={accountCreationLink}>
          {' '}
          Register now
        </a>
      </div>
      <div>
        <h5>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />
          <a href={getFeedbackURL()}>I need further assistance</a>
        </h5>
      </div>
      {UI.loginForm.note.enabled && (
        <Alert variant="secondary">
          <Alert.Heading>{UI.loginForm.note.title}</Alert.Heading>

          {notes.map(({ text }, index) => (
            <div key={`note-${index}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
              {index < notes.length - 1 && <hr />}
            </div>
          ))}
        </Alert>
      )}
    </Stack>
  );
}

export default LoginInformation;
