import React, { useEffect, useState } from 'react';
import PlotWidget from '../../../../containers/Stats/PlotWidget';
import Loader from '../../../Loader';
import { parseCSV } from './helpers';

function HeatmapBeamlinePlot(props) {
  const { response, metric } = props;
  const { innerWidth: width } = window;
  const [loading, setLoading] = useState(true);
  const [plot, setPlot] = useState([]);

  useEffect(() => {
    if (response && response[0] && response[0].data) {
      const { data, dates, instruments } = parseCSV(response[0].data, metric);
      const annotations = [];
      for (let i = 0; i < dates.length; i++) {
        for (let j = 0; j < instruments.length; j++) {
          const currentValue = data[i][j];
          const result = {
            xref: 'x1',
            yref: 'y1',
            x: instruments[j],
            y: dates[i],
            text: currentValue || '',
            font: {
              family: 'Arial',
              size: 8,
            },
            showarrow: false,
          };
          annotations.push(result);
        }
      }

      setPlot([
        {
          z: data,
          y: dates,
          x: instruments,
          annotations,
          type: 'heatmap',
          hoverongaps: false,
          showscale: false,
          colorscale: [
            [0, '#BBDEFB'],
            [1, '#1A237E'],
          ],
        },
      ]);
    }
    setLoading(false);
  }, [metric, response]);

  if (!plot || !plot[0]) return null;

  if (loading) {
    return <Loader message="Rendering plots..."></Loader>;
  }
  return (
    <PlotWidget
      data={plot}
      layout={{
        annotations: plot[0].annotations,
        width: width - 0.1 * width,
        height: plot[0].y.length * 30 > 300 ? plot[0].y.length * 30 : 300,
        xaxis: {
          ticks: '',
          side: 'top',
        },
      }}
      responsive={true}
    ></PlotWidget>
  );
}

export default HeatmapBeamlinePlot;
