import { INVESTIGATION_DATE_FORMAT } from '../constants';
import moment from 'moment';
import { replaceOrAppendBreadCrumb } from '../actions/breadcrumbs';

export function setInvestigationBreadCrumbs(investigation, breadcrumbsList) {
  const investigationBreadCrumb = 'INVESTIGATION';

  function getDateFormat(date) {
    return date ? moment(date).format(INVESTIGATION_DATE_FORMAT) : '';
  }

  function getInvestigationBreadCrumb(investigation) {
    return {
      badges: [investigation.name, investigation.instrument.name],
      name: `${getDateFormat(investigation.startDate)}-${getDateFormat(
        investigation.endDate
      )} ${investigation.title}`,
      type: investigationBreadCrumb,
    };
  }

  function isLastItemInvestigation(items) {
    const nbItems = items.length;
    return (
      nbItems > 0 &&
      items[nbItems - 1].type &&
      items[nbItems - 1].type === investigationBreadCrumb
    );
  }

  function getLevelInBreadCrumb(breadcrumbsList) {
    const items =
      breadcrumbsList && breadcrumbsList.items ? breadcrumbsList.items : [];
    const level = isLastItemInvestigation(items)
      ? items.length - 1
      : items.length;
    return level;
  }

  const level = getLevelInBreadCrumb(breadcrumbsList);

  return replaceOrAppendBreadCrumb(
    getInvestigationBreadCrumb(investigation),
    level
  );
}
