import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import GalleryDatasetThumbnailCard from '../../components/Dataset/GalleryDatasetThumbnailCard';
import ParameterTableWidget from '../../components/Instrument/ParameterTableWidget';
import DatasetDownloadButton from '../../components/Dataset/DatasetDownloadButton';
import FileTable from '../../components/File/FileTable';
import { store } from '../../store';
/**
 * This component displays the details of a dataset including metadata and the gallery
 * @param {*} props
 * @returns
 */
function DatasetDetail(props) {
  const { dataset } = props;
  const { sessionId } = store.getState().user;
  return (
    <Card>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <Card>
                <Card.Header>
                  <Card.Title>General information</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ParameterTableWidget
                    parameters={[
                      {
                        name: 'Dataset',
                        value: dataset.name,
                      },
                      {
                        name: 'Sample',
                        value: dataset.sampleName,
                      },
                      {
                        name: 'Beamline',
                        value:
                          dataset.investigation.investigationInstruments[0]
                            .instrument.fullName,
                      },
                      {
                        name: 'Description',
                        value:
                          dataset.investigation.investigationInstruments[0]
                            .instrument.description,
                      },

                      {
                        name: 'Creation time',
                        value: dataset.startDate,
                      },
                    ]}
                  ></ParameterTableWidget>
                </Card.Body>
                <Card.Footer>
                  <DatasetDownloadButton
                    id={dataset.id}
                    dataset={dataset}
                  ></DatasetDownloadButton>
                </Card.Footer>
              </Card>{' '}
            </Col>
            {[0, 1, 2, 3, 4, 5, 6].map((index) => (
              <Col xs={12} md={2}>
                <GalleryDatasetThumbnailCard
                  dataset={dataset}
                  index={index}
                ></GalleryDatasetThumbnailCard>
              </Col>
            ))}
          </Row>
        </Container>
        <br />
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <Card>
                <Card.Header>
                  <Card.Title>Scientific metadata</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ParameterTableWidget
                    parameters={dataset.parameters
                      .filter(
                        (p) =>
                          p.name.indexOf('__') === -1 &&
                          p.name.indexOf('Resources') === -1 &&
                          p.name.indexOf('output_datasets') === -1
                      )
                      .sort((a, b) => a.name.localeCompare(b.name))}
                  ></ParameterTableWidget>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card>
                <Card.Header>
                  <Card.Title>{dataset.location}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <FileTable
                    dataset={dataset}
                    sessionId={sessionId}
                    preview={false}
                  ></FileTable>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default DatasetDetail;
