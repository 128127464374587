const TECHNIQUES = [
  {
    name: 'Ptychography',
    shortname: 'PTYCHO',
    description:
      'Ptychography is a technique invented by Walter Hoppe that aims to solve the diffraction-pattern phase problem by interfering adjacent Bragg reflections coherently and thereby determine their relative phase.',
    color: '#97e0fe',
  },
  {
    name: 'Tomography',
    shortname: 'TOMO',
    description:
      'Tomography is a technique for displaying a representation of a cross section through solid object using X-rays.',
    color: '#97feab',
  },
  {
    name: 'Fluorescence',
    shortname: 'FLUO',
    description:
      'Fluorescence is the emission of light by a substance that has absorbed light or other electromagnetic radiation. It is a form of luminescence. In most cases, the emitted light has a longer wavelength, and therefore lower energy, than the absorbed radiation.',
    color: '#fed597',
  },
  { name: 'KMAP', shortname: 'KMAP', description: '', color: '#b697fe' },
  {
    name: 'Scanning X-ray Microscope',
    shortname: 'SXM',
    description:
      'The Scanning X-ray microscope can be operated in an energy range from 2-9 keV, thus giving access to the K-edges of Phosphorus to Copper, and to the L- and M-edges of some heavier elements for micro-fluorescence and micro-XANES measurements.',
    color: '#b697fe',
  },
  {
    name: 'Crystallography',
    shortname: 'MX',
    description: '',
    color: '#fe97a3',
  },
  { name: 'BIOSAXS', shortname: 'SAXS', description: '', color: '#fed597' },
  {
    name: 'Microbeam Radiation Therapy',
    shortname: 'MRT',
    description: '',
    color: '#97fe9d',
  },
  {
    name: 'Publication',
    shortname: 'PUB',
    description: 'Public Document',
    color: '#007bff',
  },
];

export default TECHNIQUES;
