// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InvestigationTable_wrapper__cZ1EQ {\n  position: relative;\n}\n\ntable {\n  table-layout: auto !important;\n}\n\n@media (min-width: 50em) {\n  .InvestigationTable_filter__2DvZH {\n    display: flex;\n    align-items: center;\n    position: absolute;\n    left: 0;\n    top: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "InvestigationTable_wrapper__cZ1EQ",
	"filter": "InvestigationTable_filter__2DvZH"
};
module.exports = exports;
