import React, { useState } from 'react';
import { useFetcher } from 'rest-hooks';
import { Alert, Container, Row, Col, Card, Button } from 'react-bootstrap';
import UserComboBoxList from './UserComboBoxList';
import LoadingBoundary from '../LoadingBoundary';
import InvestigationUserResource from '../../resources/investigationUser';
import Loader from '../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';

export default function InvestigationSharingPanel(props) {
  const { investigationId, userAddedHandler } = props;

  const [loading, setLoading] = useState();
  const [submitError, setSubmitError] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const createInvestigationUser = useFetcher(
    InvestigationUserResource.createShape()
  );

  async function handleSubmit() {
    try {
      setLoading('Adding user to the investigation');
      setSubmitError(undefined);
      await createInvestigationUser(
        { investigationId, selectedUsers },
        selectedUsers[0]
      );
    } catch {
      setSubmitError(
        `${selectedUsers[0].fullName} could not be added to the investigation`
      );
    }
    setLoading();
    userAddedHandler();
    setSelectedUsers([]);
  }

  return (
    <Card border="success">
      <Card.Header>
        <Card.Title className="text-success">
          <FontAwesomeIcon icon={faShare} style={{ marginRight: 10 }} /> Sharing
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {loading && <Loader message={loading}></Loader>}
        {!loading && (
          <Container fluid style={{ margin: '20px' }}>
            <Row>
              <p>
                Sharing allows non-proposal users to{' '}
                <strong>access to the data</strong> and
                <strong> read/write the electronic logbook</strong>.
                <strong> Only ICAT users can be added</strong>. When a user is
                granted to access to an investigation then it will appear under
                his "My data" tab.
              </p>
              <Col xs={12} md={6}>
                <LoadingBoundary message="Loading users..." inPanel>
                  <UserComboBoxList
                    investigationId={investigationId}
                    onUserSelected={setSelectedUsers}
                  />
                </LoadingBoundary>
              </Col>
              <Col xs={12} md={2}>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={selectedUsers.length === 0}
                >
                  Invite
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                {submitError && <Alert variant="danger">{submitError}</Alert>}
              </Col>
            </Row>
          </Container>
        )}
      </Card.Body>
    </Card>
  );
}
