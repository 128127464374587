import React from 'react';
import {
  ANNOTATION,
  BROADCAST,
  EVENT_CATEGORY_COMMANDLINE,
  EVENT_CATEGORY_COMMENT,
  EVENT_CATEGORY_DEBUG,
  EVENT_CATEGORY_ERROR,
  EVENT_CATEGORY_INFO,
  NOTIFICATION,
  PLAINTEXT_CONTENT_FORMAT,
  HTML_CONTENT_FORMAT,
} from '../../../constants/eventTypes';
import {
  convertImagesToThumbnails,
  getText,
  getOriginalEvent,
} from '../../../helpers/eventHelpers';
import PropTypes from 'prop-types';
import LazyLoadedText from './LazyLoadedText';
import { Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';

/** React component which renders the box containing the text of the event. For annotations, the box renders a single text.
 * For notifications, the box could render 2 texts: the original text and the last comment if it exists
 * */
function EventTextBox(props) {
  const { event, search } = props;
  const htmlText = getText(event.content, 'html');

  let text = convertImagesToThumbnails(
    htmlText || getText(event.content, 'plainText')
  );

  if (!text) {
    return (
      <div style={{ fontStyle: 'italic', color: '#888' }}>
        There is no content
      </div>
    );
  }

  text = text.replace(/<img/g, "<img onClick='makeFullScreen(this)'  ");

  if (event.type === ANNOTATION) {
    return (
      <div>
        <LazyLoadedText text={text} search={search} />
      </div>
    );
  }

  if (event.type === BROADCAST) {
    return (
      <Stack direction="horizontal" gap={3}>
        <FontAwesomeIcon icon={faBullhorn} />
        <LazyLoadedText text={text} search={search} />
      </Stack>
    );
  }

  if (event.type === NOTIFICATION) {
    if (!event.previousVersionEvent) {
      return (
        <div className={getTextColor(event.category)}>
          <pre className="whitePre">
            <LazyLoadedText text={text} search={search} />
          </pre>
        </div>
      );
    }

    // event has a previous version
    const originalEvent = getOriginalEvent(event);
    const originalText =
      getText(originalEvent.content, HTML_CONTENT_FORMAT) ||
      getText(originalEvent.content, PLAINTEXT_CONTENT_FORMAT);
    return (
      <>
        <div className={getTextColor(event.category)}>
          <pre
            className={
              event.category.toLowerCase() === EVENT_CATEGORY_COMMANDLINE
                ? undefined
                : 'whitePre'
            }
          >
            <LazyLoadedText text={originalText} search={search} />
          </pre>
        </div>
        <div className={getTextColor(EVENT_CATEGORY_COMMENT)}>
          <LazyLoadedText text={text} search={search} />
        </div>
      </>
    );
  }
}

/**
 * get bootstrap css class defining font color by event category
 * @param {string} category an event category
 * @returns {string} bootstrap css class name
 */
function getTextColor(category) {
  if (category.toLowerCase() === EVENT_CATEGORY_COMMENT) {
    return 'text-primary';
  }

  if (category.toLowerCase() === EVENT_CATEGORY_ERROR) {
    return 'text-danger';
  }

  if (category.toLowerCase() === EVENT_CATEGORY_INFO) {
    return 'text-info';
  }

  if (category.toLowerCase() === EVENT_CATEGORY_DEBUG) {
    return 'text-muted';
  }
  return 'text-success';
}

EventTextBox.propTypes = {
  /** Event from which to extract the texts */
  event: PropTypes.object,
};

export default EventTextBox;
