import React from 'react';
import {
  DATASET_COUNT,
  ACQUISITION_DATASET_VOLUME,
  PROCESSED_DATASET_VOLUME,
  VOLUME,
  ACQUISITION_DATASET_COUNT,
  PROCESSED_DATASET_COUNT,
} from '../../constants/parameterTypes';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stringifyBytesSize } from '../../helpers';

const Statistics = (props) => {
  const { count, volume, text } = props;
  return (
    <>
      {text && <>{text} </>}
      {count}
      <small className={'text-muted'} style={{ marginLeft: '5px' }}>
        {volume !== undefined && volume !== 0 && stringifyBytesSize(volume)}
      </small>
    </>
  );
};

/**
 * If statistics are present then it displays the number of datasets with the volume
 * Besides it will show a popup on mouve over with the acquisition and processed statistics
 * @param {*} props
 */
function DatasetVolumeStatisticsColumn(props) {
  const { parameters } = props;

  if (parameters[DATASET_COUNT] === undefined) {
    return '';
  }

  if (
    !parameters[PROCESSED_DATASET_VOLUME] ||
    !parameters[ACQUISITION_DATASET_VOLUME]
  ) {
    return (
      <Statistics
        count={parameters[DATASET_COUNT]}
        volume={parameters[VOLUME]}
      ></Statistics>
    );
  }

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover>
          <Popover.Header as="h3">
            <FontAwesomeIcon icon={faChartBar} style={{ marginRight: '5px' }} />
            Dataset Statistics
          </Popover.Header>
          <Popover.Body>
            <Statistics
              text={'Raw : '}
              count={parameters[ACQUISITION_DATASET_COUNT]}
              volume={parameters[ACQUISITION_DATASET_VOLUME]}
            ></Statistics>
            <br />
            <Statistics
              text={'Processed : '}
              count={parameters[PROCESSED_DATASET_COUNT]}
              volume={parameters[PROCESSED_DATASET_VOLUME]}
            ></Statistics>
          </Popover.Body>
        </Popover>
      }
    >
      <div style={{ cursor: 'pointer' }} className={'text-right text-info'}>
        <Statistics
          count={parameters[DATASET_COUNT]}
          volume={parameters[VOLUME]}
        ></Statistics>
      </div>
    </OverlayTrigger>
  );
}

export default DatasetVolumeStatisticsColumn;
