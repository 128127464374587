import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LoginInformation from './LoginInformation';
import LoginTabs from './LoginTabs';

export default function LoginPanel() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <LoginTabs />
        </Col>
      </Row>
      <Row>
        <Col>
          <LoginInformation />
        </Col>
      </Row>
    </Container>
  );
}
