import React from 'react';
import InvestigationTable from '../../components/Investigation/InvestigationTable';
import UI from '../../config/ui';

function MyInvestigationsTable() {
  return (
    <InvestigationTable
      filter="participant"
      withInvestigationStats
      withProposalLinks
      withUserPortalLink={UI.userPortal.isLinkEnabled}
      withJupyter={UI.analysis.jupyter.isLinkEnabled}
    />
  );
}

export default MyInvestigationsTable;
