import React from 'react';
import { useResource } from 'rest-hooks';
import ParcelResource from '../../resources/parcel';
import ParcelTable from './ParcelTable';
import ParcelColumns from '../Shipping/ParcelColumns';
import { useSelector } from 'react-redux';

function MyParcelsSummary() {
  const parcels = useResource(ParcelResource.listShape(), {});
  const user = useSelector((state) => state.user);
  return (
    <ParcelTable
      columns={ParcelColumns(!user.isAdministrator)}
      parcels={parcels}
    />
  );
}

export default MyParcelsSummary;
