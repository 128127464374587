import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { STATUS_DEFS } from '../../constants/parcelStatuses';
import { beamlineFormatter } from '../Investigation/utils';
import { itemsFormatter } from '../../helpers';

function ParcelCondensedView(props) {
  const { parcel } = props;
  const { name, status, items, investigationName } = parcel;

  return (
    <ListGroup className="condensed-list-group">
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Name: </span>
        {name}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Proposal: </span>
        {investigationName}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Beamline: </span>
        {beamlineFormatter(parcel.investigation)}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Status: </span>
        {STATUS_DEFS[status].label}
      </ListGroupItem>
      <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>Items: </span>
        {itemsFormatter(items).join(', ')}
      </ListGroupItem>
    </ListGroup>
  );
}

export default ParcelCondensedView;
