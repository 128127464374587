import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Card,
  Row,
} from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import FieldAlert from '../Form/FieldAlert';
import TextFieldGroup from '../Form/TextFieldGroup';
import styles from './AddressFormModal.module.css';
import { useResource } from 'rest-hooks';
import InvestigationResource from '../../resources/investigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';

function AddressFormModal(props) {
  const { investigation, address, onSubmitAsync, onCloseModal } = props;
  const myInvestigations = useResource(InvestigationResource.listShape(), {
    filter: 'participant',
  });

  const methods = useForm({ defaultValues: address });
  const { handleSubmit, errors, register, formState } = methods;

  const { isSubmitting } = formState;
  const [submitError, setSubmitError] = useState();

  async function handleFormSubmit(values) {
    try {
      setSubmitError(undefined);
      await onSubmitAsync(values);
    } catch (error) {
      setSubmitError(
        `An error occurred while ${
          address ? 'saving' : 'creating'
        } the address: ${error}`
      );
    }
  }

  return (
    <Modal
      show
      backdrop="static"
      size="lg"
      aria-labelledby="address-modal-title"
      onHide={onCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="address-modal-title">
          {address ? 'Edit' : 'Create'} address
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {submitError && <Alert variant="danger">{submitError}</Alert>}

        <Form>
          <FormProvider {...methods}>
            {address && <input name="_id" type="hidden" ref={register} />}

            {investigation ? (
              <input
                name="investigationId"
                type="hidden"
                defaultValue={investigation.id}
                ref={register}
              />
            ) : (
              <Form.Group controlId="formInvestigation">
                <div className={styles.row}>
                  <Form.Label className={styles.investigationLabel}>
                    Investigation
                  </Form.Label>
                  <div>
                    <Form.Select
                      name="investigationId"
                      placeholder="Select an investigation"
                      ref={register({ required: true })}
                    >
                      {myInvestigations &&
                        myInvestigations.map((investigation) => (
                          <option
                            key={investigation.id}
                            value={investigation.id}
                          >
                            {investigation.name}
                          </option>
                        ))}
                    </Form.Select>
                  </div>
                  {errors.investigationId && (
                    <FieldAlert
                      fieldLabel="Investigation"
                      error={errors.investigationId}
                    />
                  )}
                </div>
              </Form.Group>
            )}

            <Card style={{ marginTop: 10 }}>
              <Card.Header>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} />{' '}
                Contact Information
              </Card.Header>
              <Card.Body>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="name"
                        label="First name"
                        type="text"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="surname"
                        label="Last name"
                        type="text"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="email"
                        type="email"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{
                          required: true,
                          pattern: /\+?\d+/,
                          maxLength: 20,
                        }}
                        name="phoneNumber"
                        label="Phone number"
                        type="tel"
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Card.Header>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: 10 }}
                />{' '}
                Address
              </Card.Header>
              <Card.Body>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={6}>
                      <TextFieldGroup
                        name="companyName"
                        label="Institution"
                        type="text"
                      />
                    </Col>
                  </Row>
                </Container>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={9}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="address"
                        type="textarea"
                      />
                    </Col>
                  </Row>
                </Container>
                <Container fluid>
                  <Row>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{
                          required: true,
                          pattern: /\d+/,
                          maxLength: 7,
                        }}
                        name="postalCode"
                        label="Postcode"
                        type="text"
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="city"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <TextFieldGroup
                        registerOptions={{ required: true }}
                        name="country"
                        type="text"
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </FormProvider>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="link" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={isSubmitting}
          onClick={handleSubmit(handleFormSubmit)}
        >
          {`${address ? 'Sav' : 'Creat'}${isSubmitting ? 'ing...' : 'e'}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressFormModal;
