// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Adapted from https://tobiasahlin.com/spinkit/ */\n\n.Spinner_skChaseSpinner__2Oivm {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  animation: Spinner_sk-chase__3IZ0e 2.5s infinite linear both;\n}\n\n.Spinner_skChaseDotContainer__1Uhz8 {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  animation: Spinner_sk-chase-dot-container__2wO3B 2s infinite ease-in-out both;\n}\n\n.Spinner_skChaseDot__mH0f- {\n  width: 25%;\n  height: 25%;\n  background-color: #fff;\n  border-radius: 100%;\n  animation: Spinner_sk-chase-dot__2l7dr 2s infinite ease-in-out both;\n}\n\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(1) {\n  animation-delay: -1.1s;\n}\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(2) {\n  animation-delay: -1s;\n}\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(3) {\n  animation-delay: -0.9s;\n}\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(4) {\n  animation-delay: -0.8s;\n}\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(5) {\n  animation-delay: -0.7s;\n}\n.Spinner_skChaseDotContainer__1Uhz8:nth-child(6) {\n  animation-delay: -0.6s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(1) {\n  animation-delay: -1.1s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(2) {\n  animation-delay: -1s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(3) {\n  animation-delay: -0.9s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(4) {\n  animation-delay: -0.8s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(5) {\n  animation-delay: -0.7s;\n}\n.Spinner_skChaseDot__mH0f-:nth-child(6) {\n  animation-delay: -0.6s;\n}\n\n@keyframes Spinner_sk-chase__3IZ0e {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes Spinner_sk-chase-dot-container__2wO3B {\n  80%,\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes Spinner_sk-chase-dot__2l7dr {\n  50% {\n    transform: scale(0.4);\n  }\n  100%,\n  0% {\n    transform: scale(1);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"skChaseSpinner": "Spinner_skChaseSpinner__2Oivm",
	"sk-chase": "Spinner_sk-chase__3IZ0e",
	"skChaseDotContainer": "Spinner_skChaseDotContainer__1Uhz8",
	"sk-chase-dot-container": "Spinner_sk-chase-dot-container__2wO3B",
	"skChaseDot": "Spinner_skChaseDot__mH0f-",
	"sk-chase-dot": "Spinner_sk-chase-dot__2l7dr"
};
module.exports = exports;
