import React from 'react';
import Spinner from './Spinner';
import styles from './Loader.module.css';

function Loader(props) {
  const { message, inPanel, spacedOut } = props;

  return (
    <div
      className={styles.loader}
      data-in-panel={inPanel || undefined}
      data-spaced-out={spacedOut || undefined}
    >
      <div className={styles.inner}>
        <Spinner className={styles.spinner} dotColor="#333" />
        <p className={styles.text}>{message || 'Loading...'}</p>
      </div>
    </div>
  );
}

export default Loader;
