import ICATPLUS from '../../config/icatPlus';
import { getURLParamsByDictionary } from '../../helpers/url';

/**
 * Get URL used to upload data such as a file to ICAT+.
 * @param {*} investigationId the investigationId
 * @param {*} instrumentName the instrumentName in case of beamline logbook
 */
export function uploadFile(sessionId, investigationId, instrumentName) {
  const params = getURLParamsByDictionary();
  investigationId
    ? params.set('investigationId', investigationId)
    : params.set('instrumentName', instrumentName);

  return `${
    ICATPLUS.server
  }/resource/${sessionId}/file/upload?${params.toString()}`;
}

/**
 * Get URL used to retrieve the file of an event which category is 'file'
 * @param {string} sessionId session identifier
 * @param {string} eventId event identifier where the file is stored
 * @return {String} URL to get the file
 */
export function getFileByEventId(sessionId, eventId) {
  return `${ICATPLUS.server}/resource/${sessionId}/file/download?resourceId=${eventId}`;
}
