import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import UI from '../../config/ui';
import { useQuery } from '../../helpers/hooks';
import { trackH5Viewer } from '../../helpers/trackData';

function H5Viewer(props) {
  const { datafile, iframeStyle, sessionId } = props;
  const query = useQuery();

  const h5viewerURL = query.get('h5viewerURL') || UI.h5Viewer.url;

  const getURLViewer = (h5viewerURL, sessionId, datafileId, datafileName) => {
    return `${h5viewerURL}?sessionId=${sessionId}&datafileId=${datafileId}&name=${datafileName}`;
  };
  const iframeURL = getURLViewer(
    h5viewerURL,
    sessionId,
    datafile.id,
    datafile.name
  );

  return (
    <>
      <iframe
        title="HDF5 Viewer"
        style={iframeStyle}
        src={`${iframeURL}&wide`}
      ></iframe>
      <Button
        target="_blank"
        href={iframeURL}
        onClick={() => trackH5Viewer(datafile.id)}
      >
        <FontAwesomeIcon icon={faShareAlt} style={{ marginRight: 5 }} />
        Open viewer
      </Button>
    </>
  );
}

export default H5Viewer;
