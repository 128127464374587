import Keycloak from 'keycloak-js';
import ICAT from './config/icat';

/**
 * It might happen that keycloak server is down then null is retturned
 */
const getKeyCloakClient = () => {
  try {
    const keycloak = new Keycloak(ICAT.authentication.sso.configuration);

    keycloak.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    });

    return keycloak;
  } catch {
    return null;
  }
};

const keycloak = ICAT.authentication.sso.enabled ? getKeyCloakClient() : null;

export default keycloak;
