import React from 'react';
import { useCache, useResource } from 'rest-hooks';
import AddressResource from '../../resources/address';
import ShipmentResource from '../../resources/shipment';
import { useReturnAddress } from '../Address/utils';
import ParcelsSection from './ParcelsSection';
import ShipmentSection from './ShipmentSection';
import LoadingBoundary from '../LoadingBoundary';

function ShippingPanel(props) {
  const { investigation } = props;

  const apiParams = { investigationId: investigation.id };
  const [shipment] = useResource(ShipmentResource.listShape(), apiParams);

  const defaultShippingAddress = useCache(AddressResource.detailShape(), {
    _id: shipment?.defaultShippingAddress,
  });
  const defaultReturnAddress = useReturnAddress(shipment);

  return (
    <>
      <ShipmentSection
        investigation={investigation}
        shipment={shipment}
        defaultShippingAddress={defaultShippingAddress}
        defaultReturnAddress={defaultReturnAddress}
      />
      {shipment && (
        <LoadingBoundary message="Loading parcels..." spacedOut>
          <ParcelsSection
            investigation={investigation}
            shipment={shipment}
            defaultShippingAddress={defaultShippingAddress}
            defaultReturnAddress={defaultReturnAddress}
          />
        </LoadingBoundary>
      )}
    </>
  );
}

export default ShippingPanel;
