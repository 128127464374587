import moment from 'moment';
import React from 'react';
import {
  Button,
  Col,
  Popover,
  Row,
  Alert,
  OverlayTrigger,
  Container,
} from 'react-bootstrap';
import {
  LATEST_EVENT_VERSION,
  MIDDLE_EVENT_VERSION,
  ORIGINAL_EVENT_VERSION,
} from '../../constants/eventTypes';
import { getPreviousVersionNumber } from '../../helpers/eventHelpers';
import EventVersionPanel from './EventVersionPanel';

/**
 * Render the popup which displays all event versions of the event
 * @param {*} event the event under investigation
 */
export default function EventVersions(event) {
  let e = event;

  /* build the different lines representing the previous versions */
  const lines = [];
  while (e.previousVersionEvent != null) {
    if (lines.length === 0) {
      lines.push(<EventVersionItem event={e} type="edition" mostRecent />);
    } else {
      lines.push(
        <EventVersionItem event={e} type="edition" mostRecent={false} />
      );
    }
    e = e.previousVersionEvent;
  }
  lines.push(<EventVersionItem event={e} type="creation" />);

  /* build the popover */
  return (
    <Popover
      id="popover-events"
      style={{ maxWidth: '500px', maxHeight: '700px', overflowY: 'auto' }}
    >
      <Popover.Header as="h3">
        This log has {getPreviousVersionNumber(event) + 1} versions.
      </Popover.Header>
      <Popover.Body>
        {lines.map((item, index) => (
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Popover
                id="popover-basic"
                style={{
                  maxWidth: '700px',
                  maxHeight: '500px',
                  overflowY: 'auto',
                }}
              >
                <Popover.Body style={{ padding: 0 }}>
                  <EventVersionPanel
                    event={item.props.event}
                    version={
                      index === 0
                        ? LATEST_EVENT_VERSION
                        : index === lines.length - 1
                        ? ORIGINAL_EVENT_VERSION
                        : MIDDLE_EVENT_VERSION
                    }
                  />
                </Popover.Body>
              </Popover>
            }
          >
            <span key={index}>{item} </span>
          </OverlayTrigger>
        ))}

        <hr style={{ marginBottom: 5 }} />

        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover
              id="popover-all-versions"
              style={{
                maxWidth: '700px',
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <Popover.Body>
                <CompleteEventHistory event={event} />
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">See all versions</Button>
        </OverlayTrigger>
      </Popover.Body>
    </Popover>
  );
}

/**
 * React component which represents on item of the event history
 * @param {*} props  the props passed to this component
 */
function EventVersionItem(props) {
  const { event, type, mostRecent } = props;
  return (
    <Alert variant="secondary" style={{ marginBottom: 5, cursor: 'pointer' }}>
      <b> {event.fullName} </b>
      {type === 'creation' ? 'created on ' : ''}
      {type === 'edition' ? 'edited on ' : ''}
      {moment(event.creationDate).format('MMMM DD HH:mm')}
      {mostRecent ? ' (most recent)' : ''}
      {event.machine ? (
        <span style={{ color: '#777', fontStyle: 'italic' }}>
          {' '}
          from {event.machine}{' '}
        </span>
      ) : (
        ''
      )}
    </Alert>
  );
}

/**
 * React component which displays the complete event history
 */
const CompleteEventHistory = (props) => {
  let { event } = props;
  const eventVersions = [];
  /* first push the current event which is the latest version of the event */
  eventVersions.push(
    <EventVersionPanel event={event} version={LATEST_EVENT_VERSION} />
  );
  /* then display the previous version of this event */
  while (event.previousVersionEvent != null) {
    event = event.previousVersionEvent;
    eventVersions.push(
      <EventVersionPanel
        event={event}
        version={
          event.previousVersionEvent === null
            ? ORIGINAL_EVENT_VERSION
            : MIDDLE_EVENT_VERSION
        }
      />
    );
  }

  return (
    <Container fluid style={{ padding: 0, margin: 0 }}>
      {eventVersions.map((item) => (
        <Row style={{ marginBottom: 10 }}>
          <Col xs={12}>{item}</Col>
        </Row>
      ))}
    </Container>
  );
};
