// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ItemTable_actions__2INND {\n  display: flex;\n  justify-content: space-around;\n}\n\n.ItemTable_actionHeader__1voXw {\n  width: 14rem;\n}\n\n@media (max-width: 50em) {\n  .ItemTable_actionHeader__1voXw {\n    width: 6rem;\n  }\n\n  .ItemTable_actionLabel__1tx4R {\n    display: none;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"actions": "ItemTable_actions__2INND",
	"actionHeader": "ItemTable_actionHeader__1voXw",
	"actionLabel": "ItemTable_actionLabel__1tx4R"
};
module.exports = exports;
