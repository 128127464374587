const GB = 1024 * 1024 * 1024;

const parse = (csv, metric, slice) => {
  const lines = csv.split('\n');
  const instruments = lines[0]
    .split('\t')
    .slice(slice.start, slice.end)
    .map((instrumentName_metric) =>
      instrumentName_metric.substring(0, instrumentName_metric.lastIndexOf('_'))
    );

  const dates = lines.slice(1).map((line) => line.split('\t')[0]);
  const data = lines
    .slice(1)
    .map((line) => line.split('\t'))
    .map((line) => line.slice(slice.start, slice.end));

  for (let i = 0; i < data.length; i++) {
    const line = data[i];
    for (let j = 0; j < line.length; j++) {
      let value = parseFloat(line[j]);
      if (metric.toLowerCase() === 'volume') {
        const valueInGB = parseFloat(value / GB);
        value =
          valueInGB > 0 && valueInGB < 1
            ? valueInGB.toFixed(
                1 - Math.floor(Math.log(valueInGB) / Math.log(10))
              )
            : valueInGB.toFixed(1);
      }
      line[j] = value > 0 ? value : null;
    }
  }
  return {
    data: JSON.parse(JSON.stringify(data)),
    dates: JSON.parse(JSON.stringify(dates)),
    instruments: JSON.parse(JSON.stringify(instruments)),
  };
};

export function parseCSV(csv, metric) {
  return parse(csv, metric, { start: 3 });
}

export function parseTotalCSV(csv, metric) {
  return parse(csv, metric, { start: 0, end: 2 });
}
