import React from 'react';
import { Card } from 'react-bootstrap';

function BasicPanel(props) {
  const { title, children, variant } = props;
  return (
    <Card style={{ marginTop: 10 }} variant={variant}>
      <Card.Header>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default BasicPanel;
