import axios from 'axios';
import ICATPLUS from '../../config/icatPlus';
import { getURLParamsByDictionary } from '../../helpers/url';

export function getFilesByDatasetId(sessionId, datasetIds) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/dataset/id/${datasetIds}/datafile`;
}

export function getUsers(sessionId) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/user`;
}

export function getUsersByInvestigationIds(sessionId, investigationIds) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/investigation/id/${investigationIds}/investigationusers`;
}

export function getDatasetsById(sessionId, datasetIds) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/dataset/id/${datasetIds}/dataset`;
}

export function getDatasetsByInvestigationId(sessionId) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/dataset`;
}

export function getDatasetsURL(sessionId) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/dataset`;
}

export function getDatasetStatus(sessionId, datasetIds) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/dataset/id/${datasetIds}/status`;
}

export function getDataCollections(sessionId) {
  return axios.get(`${ICATPLUS.server}/catalogue/${sessionId}/datacollection`);
}

export function getInvestigationUsersByInvestigationId(
  sessionId,
  investigationId
) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/investigation/id/${investigationId}/investigationusers`;
}

export function getInstrumentScientistsBySessionId(sessionId) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/instrumentscientist`;
}

export function createInstrumentScientists(sessionId) {
  return `${ICATPLUS.server}/catalogue/${sessionId}/instrumentscientist`;
}

export function getDatasetParameterURL(sessionId, parameterId) {
  const params = getURLParamsByDictionary({
    parameterId,
  });

  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/datasetParameter?${params.toString()}`;
}

export function getParameterTypeURL(name) {
  const params = getURLParamsByDictionary({
    name,
  });

  return `${ICATPLUS.server}/catalogue/parameters?${params.toString()}`;
}

export function getDatasets(
  sessionId,
  datasetIds,
  skip,
  limit,
  sortOrder,
  sortBy,
  search
) {
  const params = getURLParamsByDictionary({
    limit,
    sortBy,
    sortOrder,
    skip,
    search,
    datasetIds,
  });
  return `${getDatasetsURL(sessionId)}?${params.toString()}`;
}

export function getDatasetsByInvestigationIdURL(
  sessionId,
  investigationId,
  sampleId,
  skip,
  limit,
  sortOrder,
  sortBy,
  search,
  datasetType,
  nested
) {
  const params = getURLParamsByDictionary({
    limit,
    sortBy,
    sortOrder,
    skip,
    search,
    datasetType,
    nested,
    sampleId,
    investigationId,
  });
  return `${getDatasetsByInvestigationId(sessionId)}?${params.toString()}`;
}

export function getDownloadURLByDatasetId(sessionId, datasetIds) {
  const params = getURLParamsByDictionary({ datasetIds });
  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/data/download?${params.toString()}`;
}

export function getRestoreURLByDatasetId(sessionId, datasetId) {
  const params = getURLParamsByDictionary({ datasetId });
  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/dataset/restore?${params.toString()}`;
}

export function getDownloadURLByDatafileId(sessionId, datafileIds) {
  const params = getURLParamsByDictionary({ datafileIds });
  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/data/download?${params.toString()}`;
}

export function getSampleParameterURL(sessionId, investigationId, parameterId) {
  const params = getURLParamsByDictionary({
    investigationId,
  });

  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/sampleParameters/${parameterId}?${params.toString()}`;
}

export function getCreateSampleParameterURL(
  sessionId,
  investigationId,
  sampleId
) {
  const params = getURLParamsByDictionary({
    investigationId,
  });

  return `${
    ICATPLUS.server
  }/catalogue/${sessionId}/samples/${sampleId}/sampleParameters?${params.toString()}`;
}
