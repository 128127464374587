import React from 'react';
import { getParcelInstrumentByStatus } from './ParcelStatsUtils';
import PlotWidget from '../PlotWidget';

function PiePlotBeamlineByStatus(props) {
  const { status, parcels } = props;
  const statusPerInstrument = getParcelInstrumentByStatus(parcels, status);

  const labels = [...new Set(statusPerInstrument)];

  const values = labels
    .map((instrumentName) =>
      statusPerInstrument.filter((s) => s === instrumentName)
    )
    .map((s) => s.length);

  return (
    <PlotWidget
      data={[
        {
          type: 'pie',
          hole: 0.4,
          textposition: 'inside',
          labels,
          values,
          texttemplate: '%{label}: %{value}',
        },
      ]}
      layout={{
        showLegend: true,
        title: 'Parcels currently on beamlines',
      }}
    />
  );
}

export default PiePlotBeamlineByStatus;
