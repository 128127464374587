import React from 'react';
import { STATUS, STATUS_DEFS } from '../../../constants/parcelStatuses';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { getParcelCountByStatus } from './ParcelStatsUtils';
import ParameterTableWidget from '../../../components/Instrument/ParameterTableWidget';
import PlotWidget from '../PlotWidget';
import PiePlotBeamlineByStatus from './PiePlotBeamlineByStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas as icons } from '@fortawesome/free-solid-svg-icons';

function SummaryParcelStats(props) {
  const { beamlineTest, stats, parcels } = props;
  const {
    parcelsCount,
    beamlinesCount,
    samplesCount,
    toolsCount,
    othersCount,
    itemsCount,
    beamlines,
    parcelsPerBeamline,
    itemsPerBeamline,
  } = stats;

  return (
    <>
      <Alert variant="warning">
        <strong>Test parcels are filtered!</strong> A parcel is considered as
        test if the beamline associated is <strong>{beamlineTest}</strong>
      </Alert>

      <Container fluid style={{ margin: 20 }}>
        <Row>
          <Col xs={12} md={2}>
            <ParameterTableWidget
              striped
              parameters={[
                { name: 'Parcels', value: parcelsCount },
                { name: 'Beamlines', value: beamlinesCount },
              ]}
            ></ParameterTableWidget>
            <h4>Items</h4>
            <ParameterTableWidget
              striped
              parameters={[
                { name: 'Samples', value: samplesCount },
                { name: 'Tools', value: toolsCount },
                { name: 'Others', value: othersCount },
                { name: 'Total items', value: itemsCount },
              ]}
            ></ParameterTableWidget>
          </Col>
          <Col xs={12} md={2}>
            <ParameterTableWidget
              striped
              parameters={Object.keys(STATUS).map((status) => {
                return {
                  name: (
                    <span>
                      <FontAwesomeIcon
                        icon={icons[STATUS_DEFS[status].icon]}
                        style={{ marginRight: '10px' }}
                      />
                      {status}
                    </span>
                  ),
                  value: getParcelCountByStatus(parcels, status),
                };
              })}
            ></ParameterTableWidget>
          </Col>

          <Col xs={12} md={8}>
            <PiePlotBeamlineByStatus parcels={parcels} status="BEAMLINE" />
          </Col>
        </Row>
        <Row>
          <PlotWidget
            data={[
              {
                name: 'Parcels',
                type: 'bar',
                x: beamlines,
                y: parcelsPerBeamline,
              },
              {
                x: beamlines,
                y: itemsPerBeamline,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                yaxis: 'y2',
                name: 'Items',
              },
            ]}
            layout={{
              width: window.innerWidth - window.innerWidth * 0.2,
              title: 'Parcel and items/Beamlines',
              xaxis: { title: 'Beamlines' },
              yaxis: { title: 'Parcels' },
              showlegend: true,
              legend: {
                y: 80,
                orientation: 'h',
              },
              yaxis2: {
                title: 'Items',
                overlaying: 'y',
                side: 'right',
                showgrid: false,
              },
            }}
          />
        </Row>
      </Container>
    </>
  );
}

export default SummaryParcelStats;
