import {
  faEnvelope,
  faHome,
  faPhoneAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './AddressPanel.module.css';

function AddressPanel(props) {
  const { address, header, variant } = props;
  return (
    <Card border={variant}>
      {header && (
        <Card.Header>
          <Card.Title>{header}</Card.Title>
        </Card.Header>
      )}
      <Card.Body>
        {address ? (
          <>
            <div className={styles.row}>
              <FontAwesomeIcon className={styles.glyph} icon={faUser} />
              {address.name} {address.surname}
            </div>
            <div className={styles.row}>
              <FontAwesomeIcon className={styles.glyph} icon={faPhoneAlt} />
              {address.phoneNumber}
            </div>
            <div className={styles.row}>
              <FontAwesomeIcon className={styles.glyph} icon={faEnvelope} />
              {address.email}
            </div>
            <div className={styles.row}>
              <FontAwesomeIcon className={styles.glyph} icon={faHome} />
              <div>
                {address.companyName}
                {address.address && (
                  <>
                    <br />
                    {address.address}
                  </>
                )}
                {address.region && (
                  <>
                    <br />
                    {address.region}
                  </>
                )}
                {(address.postalCode || address.city) && (
                  <>
                    <br />
                    {address.postalCode} {address.city}
                  </>
                )}
                {address.country && (
                  <>
                    <br />
                    {address.country}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.row}>No address</div>
        )}
      </Card.Body>
    </Card>
  );
}

export default AddressPanel;
