import { SELECT_DATASETS, DESELECT_DATASETS } from '../constants/actionTypes';

export function selectDatasets(ids) {
  return {
    type: SELECT_DATASETS,
    payload: ids,
  };
}

export function deselectDatasets(ids) {
  return {
    type: DESELECT_DATASETS,
    payload: ids,
  };
}
