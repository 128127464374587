import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class UserResource extends Resource {
  name = undefined;
  fullName = '';
  email = '';
  id = '';

  pk() {
    return this.name?.toString();
  }

  static get key() {
    return 'UserResource';
  }

  static listUrl(params) {
    const { investigationId } = params;
    const { sessionId } = store.getState().user;
    if (investigationId) {
      return `${ICATPLUS.server}/catalogue/${sessionId}/investigation/${investigationId}/user`;
    }
    return `${ICATPLUS.server}/catalogue/${sessionId}/user`;
  }
}
