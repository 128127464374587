// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AddressFormModal_row__3Ldj- {\n  display: flex;\n  align-items: baseline;\n  justify-content: flex-start;\n}\n\n.AddressFormModal_investigationLabel__1ZNVk {\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "AddressFormModal_row__3Ldj-",
	"investigationLabel": "AddressFormModal_investigationLabel__1ZNVk"
};
module.exports = exports;
