import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useResource } from 'rest-hooks';
import ParcelDetails from '../components/Parcels/ParcelDetails';
import InvestigationResource from '../resources/investigation';
import LoadingBoundary from '../components/LoadingBoundary';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import ParcelResource from '../resources/parcel';
import { useDispatch } from 'react-redux';
import { trackParcel } from '../helpers/trackData';
import { usePageTracking } from '../helpers/hooks';

function ParcelPage() {
  usePageTracking();
  const { investigationId, parcelId } = useParams();
  const investigations = useResource(InvestigationResource.listShape(), {
    ids: investigationId,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;

  const parcels = useResource(ParcelResource.listShape(), {
    investigationId,
    _id: parcelId,
  });
  const parcel = parcels[0];

  const dispatch = useDispatch();
  useEffect(() => {
    if (investigation && parcel) {
      trackParcel(`${investigation.id}-${parcel.id}`);
      dispatch(
        setBreadCrumbs([
          {
            name: investigation.visitId.toUpperCase(),
            link: `/investigation/${investigation.id}/datasets`,
          },
          { badges: [investigation.name], name: investigation.summary },
          {
            name: 'Shipping',
            link: `/investigation/${investigation.id}/shipping`,
          },
          { name: parcel ? parcel.name : 'Not found' },
        ])
      );
    }
  }, [dispatch, investigation, parcel]);
  return (
    <div className="app__inner">
      <LoadingBoundary message="Loading parcel...">
        <ParcelDetails investigation={investigation} parcel={parcel} />
      </LoadingBoundary>
    </div>
  );
}

export default ParcelPage;
