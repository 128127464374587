import React from 'react';
import UI from '../config/ui';
import logo from '../images/ebs.gif';
import styles from './Footer.module.css';
import { Navbar, Container } from 'react-bootstrap';

function Footer() {
  return (
    <Navbar className={styles.footer} expand="lg">
      <Container>
        <Navbar.Brand
          href={UI.footer.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={styles.logo} src={logo} alt="ESRF" />
          <a
            href={UI.footer.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 'smaller' }}
          >
            {UI.footer.text}
          </a>
        </Navbar.Brand>
        <Navbar.Toggle />
        {UI.footer.certification.enabled && (
          <Navbar className="justify-content-end" style={{ padding: 0 }}>
            <a
              href={UI.footer.certification.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logo}
                src={UI.footer.certification.logo}
                alt={UI.footer.certification.alt}
              />
            </a>
          </Navbar>
        )}
      </Container>
    </Navbar>
  );
}

export default Footer;
