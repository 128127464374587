import PropTypes from 'prop-types';
import React from 'react';
import ImageZoom from 'react-medium-image-zoom';
import { getFileByEventId } from '../../api/icat-plus/resource';
import { store } from '../../store';

export default function GalleryDatasetThumbnail(props) {
  const { dataset, index } = props;

  const { sessionId } = store.getState().user;

  let { imageId } = props;

  if (!imageId && !dataset) {
    return <></>;
  }
  if (!imageId) {
    let gallery = dataset.parameters.filter(
      (o) => o.name === 'ResourcesGallery'
    );
    if (gallery != null) {
      if (gallery.length > 0) {
        gallery = gallery[0].value.split(' ');
        if (gallery.length > index) {
          imageId = gallery[index];
        }
      }
    }
  }
  if (imageId) {
    const url = getFileByEventId(sessionId, imageId);
    return (
      <ImageZoom
        image={{
          src: url,
          className: 'thumbnail',
          style: { width: '100%' },
        }}
      />
    );
  }
  return <></>;
}

GalleryDatasetThumbnail.propTypes = {
  index: PropTypes.number.isRequired,
  dataset: PropTypes.object.isRequired,
};
