import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import UI from '../../config/ui';

function DangerousGoodsModal(props) {
  const { onConfirm, onCloseModal, isProcessing } = props;
  const [submitError, setSubmitError] = useState();

  const { handleSubmit } = useForm();

  function onSubmit(values) {
    try {
      onConfirm(values);
    } catch (error) {
      setSubmitError(`There was an error while updating the parcel: ${error}`);
    }
  }

  return (
    <Modal show aria-labelledby="parcel-modal-title" onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title id="parcel-modal-title">
          Does the parcel contain dangerous goods ?
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {submitError && <Alert variant="danger">{submitError}</Alert>}
        <p style={{ fontWeight: 600 }}>
          Please ensure you reply correctly to this question after consultation
          with your lab safety referent and/or the transport company.
        </p>
        <p style={{ color: '#d43f3a', fontWeight: 600 }}>
          You will not be able to edit your parcel afterwards !
        </p>
        <Alert>{UI.dangerousGoodsHelp}</Alert>
      </Modal.Body>

      <Modal.Footer>
        {isProcessing ? (
          <Button variant="warning" disabled={true}>
            Processing...
          </Button>
        ) : (
          <>
            <Button
              variant="danger"
              onClick={() =>
                handleSubmit(onSubmit({ containsDangerousGoods: true }))
              }
            >
              Yes, this parcel contains <b>dangerous</b> goods
            </Button>
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(onSubmit({ containsDangerousGoods: false }))
              }
            >
              No
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DangerousGoodsModal;
