import { Resource } from 'rest-hooks';
import ICATPLUS from '../config/icatPlus';
import { store } from '../store';

export default class SampleResource extends Resource {
  id = undefined;
  name = '';

  pk() {
    return this.id?.toString();
  }

  static get key() {
    return 'SampleResource';
  }

  static listUrl(params) {
    const { sessionId } = store.getState().user;
    const { investigationId } = params;
    return `${ICATPLUS.server}/catalogue/${sessionId}/investigation/id/${investigationId}/sample`;
  }
}
