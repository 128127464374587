// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InvestigationDateFilter_clearButton__LAwi0 {\n  color: inherit;\n  padding-left: 6px;\n}\n\n.InvestigationDateFilter_clearButton__LAwi0:hover {\n  color: darkslategray;\n}\n\n.InvestigationDateFilter_dateFilterInput__27U2R {\n  padding: 7px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"clearButton": "InvestigationDateFilter_clearButton__LAwi0",
	"dateFilterInput": "InvestigationDateFilter_dateFilterInput__27U2R"
};
module.exports = exports;
