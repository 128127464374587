import React from 'react';
import {
  getDatasetParameterByName,
  getDatasetParameterByPrefixName,
} from '../../helpers';
import ParameterTableWidget from './ParameterTableWidget';

function InstrumentDetectorWidget(props) {
  const { dataset } = props;

  const getDetectorWidget = (index) => {
    const parameters = getDatasetParameterByPrefixName(
      dataset,
      `InstrumentDetector0${index}`
    );
    if (parameters) {
      if (parameters.length > 0) {
        return (
          <DetectorWidget
            detectorIndex={index}
            name={getDatasetParameterByName(
              dataset,
              `InstrumentDetector0${index}_name`
            )}
            positionersValue={getDatasetParameterByName(
              dataset,
              `InstrumentDetector0${index}Positioners_value`
            )}
            positionersName={getDatasetParameterByName(
              dataset,
              `InstrumentDetector0${index}Positioners_name`
            )}
            positionersRoisValue={getDatasetParameterByName(
              dataset,
              `InstrumentDetector0${index}PositionersRois_value`
            )}
            positionersRoisName={getDatasetParameterByName(
              dataset,
              `InstrumentDetector0${index}PositionersRois_name`
            )}
          />
        );
      }
    }
  };

  const detectors = [];
  for (let i = 1; i < 10; i++) {
    const detector = getDetectorWidget(i);
    if (detector) {
      detectors.push(detector);
    }
  }
  if (detectors.length === 0) {
    return null;
  }
  return (
    <div className="container-fluid">
      <span style={{ fontSize: 16, fontWeight: 'bold' }}>Detectors</span>
      <div className="row">
        {detectors.map((detector) => (
          <>{detector}</>
        ))}
      </div>
    </div>
  );
}

export default InstrumentDetectorWidget;

function DetectorWidget(props) {
  const { name, detectorIndex, positionersName, positionersValue } = props;
  return (
    <div className="col-sm-1">
      <ParameterTableWidget
        header={`#${detectorIndex}${name}`}
        names={positionersName}
        values={positionersValue}
      />
    </div>
  );
}
