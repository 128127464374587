import moment from 'moment';
import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOG_IN,
  LOG_OUT,
  SESSION_REFRESHED,
} from '../constants/actionTypes';
import ICAT from '../config/icat';

const initialState = {
  username: null,
  isSSO: false,
  sessionId: null,
  /** Time the token will be expired */
  expirationTime: null,
  /** Time the token will need refreshing */
  refreshTime: null,
  /** Number of minutes of validity of the ICAt token */
  lifeTimeMinutes: null,
  /** Number of minutes before the token will be refreshed or logged out */
  tokenTTL: null,
  fullName: null,
  name: null,
  isAnonymous: false,
  isAdministrator: false,
  isInstrumentScientist: false,
  isAuthenticating: false,
  isSessionExpired: false,
  usersByPrefix: [],
  error: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN: {
      state = {
        ...initialState,
        username: action.username,
        isSSO: action.username === null,
        isAuthenticating: true,
      };
      break;
    }
    case LOGGED_IN: {
      state = {
        ...initialState,
        username: state.username,
        isSSO: state.isSSO,
        sessionId: action.sessionId,
        lifeTimeMinutes: action.lifeTimeMinutes,
        tokenTTL: action.tokenTTL,
        expirationTime: moment()
          .add(action.lifeTimeMinutes, 'minutes')
          .format(),
        refreshTime: moment().add(action.tokenTTL, 'minutes').format(),
        name: action.name,
        fullName: action.fullName,
        isAnonymous:
          ICAT.authentication.anonymous.enabled &&
          state.username === ICAT.authentication.anonymous.username,
        isAdministrator: action.isAdministrator,
        isInstrumentScientist: action.isInstrumentScientist,
        usersByPrefix: action.usersByPrefix,
      };
      break;
    }
    case SESSION_REFRESHED: {
      state = {
        ...state,
        refreshTime: moment().add(state.tokenTTL, 'minutes').format(),
        expirationTime: moment().add(state.lifeTimeMinutes, 'minutes').format(),
      };
      break;
    }
    case LOG_OUT: {
      state = { ...initialState, isSessionExpired: action.expired };
      break;
    }
    case LOGIN_ERROR: {
      state = { ...initialState, error: action.error };
      break;
    }
    default:
      break;
  }
  return state;
};

export default user;
