import moment from 'moment';
import React, { useState } from 'react';
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Accordion,
  Form,
} from 'react-bootstrap';
import { useQuery } from '../../helpers/hooks';
import { useHistory } from 'react-router';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SettingParameters(props) {
  const {
    parameter,
    onChange,
    title,
    radioGroupName,
    radioButtons,
    disabled,
  } = props;
  const [parameterState, setparameterState] = useState(parameter);
  const onClick = (e) => {
    setparameterState(e.target.value);
    onChange(e.target.value);
  };
  return (
    <Container fluid>
      <Row>
        <h4>{title}</h4>
      </Row>
      <Row>
        <Container fluid>
          <Row>
            {radioButtons.map((radio) => (
              <Form.Check
                type="radio"
                disabled={disabled}
                name={radioGroupName}
                value={radio.value}
                checked={parameterState === radio.value}
                onClick={onClick}
                label={radio.text}
              />
            ))}
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

function CustomDates(props) {
  const { startDate, endDate, onStartDayChange, onEndDayChange } = props;
  return (
    <Container fluid>
      <Row>
        <h4>Custom Dates</h4>
      </Row>
      <Row>
        <Container fluid>
          <Row>
            <Col md={6} sm={6} xs={6}>
              Start Date:
            </Col>
            <Col md={6} sm={6} xs={6}>
              <DayPickerInput
                value={startDate}
                placeholder={startDate ? startDate : 'Choose a start date'}
                onDayChange={onStartDayChange}
                dayPickerProps={{
                  todayButton: 'Today',
                }}
              ></DayPickerInput>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col md={6} sm={6} xs={6}>
              End Date:
            </Col>
            <Col md={6} sm={6} xs={6}>
              <DayPickerInput
                value={endDate}
                placeholder={endDate ? endDate : 'Choose a end date'}
                onDayChange={onEndDayChange}
                dayPickerProps={{
                  todayButton: 'Today',
                }}
              ></DayPickerInput>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}
function DataStatisticsMenu(props) {
  const history = useHistory();
  const query = useQuery();

  const {
    startDate,
    endDate,
    metric,
    interval,
    aggregation,
    tab,
    plottype,
  } = props;

  const [startDateParameter, setStartDateParameter] = useState(startDate);
  const [endDateParameter, setEndDateParameter] = useState(endDate);
  const [metricParameter, setMetricParameter] = useState(metric);
  const [intervalParameter, setIntervalParameter] = useState(interval);
  const [aggregationParameter, setAggregationParameter] = useState(aggregation);
  const [plottypeParameter, setPlottypeParameter] = useState(plottype);

  const applySettings = () => {
    query.set('startDate', moment(startDateParameter).format('YYYY-MM-DD'));
    query.set('endDate', moment(endDateParameter).format('YYYY-MM-DD'));
    query.set('metric', metricParameter);
    query.set('aggregation', aggregationParameter);
    query.set('interval', intervalParameter);
    query.set('plottype', plottypeParameter);
    history.push({ search: query.toString() });
  };
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} /> Settings
        </Accordion.Header>
        <Accordion.Body>
          <Card>
            <Card.Body>
              <Container fluid>
                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <CustomDates
                      startDate={startDate}
                      endDate={endDate}
                      onStartDayChange={(date) => setStartDateParameter(date)}
                      onEndDayChange={(date) => setEndDateParameter(date)}
                    ></CustomDates>
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    {tab === 'beamlines' && (
                      <SettingParameters
                        parameter={aggregationParameter}
                        onChange={(aggregation) =>
                          setAggregationParameter(aggregation)
                        }
                        title="Group by"
                        radioGroupName="aggregationRadioButtons"
                        radioButtons={[
                          {
                            text: 'Technique (definition)',
                            value: 'definition',
                          },
                          { text: 'Instrument', value: 'instrumentName' },
                        ]}
                      ></SettingParameters>
                    )}
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    {tab === 'beamlines' && (
                      <SettingParameters
                        parameter={metricParameter}
                        onChange={(metric) => setMetricParameter(metric)}
                        title="Metric"
                        radioGroupName="metricRadioButtons"
                        radioButtons={[
                          { text: 'Volume', value: 'volume' },
                          { text: 'File Count', value: 'fileCount' },
                        ]}
                      ></SettingParameters>
                    )}
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    {tab === 'beamlines' && (
                      <SettingParameters
                        parameter={intervalParameter}
                        onChange={(interval) => setIntervalParameter(interval)}
                        title="Interval"
                        radioGroupName="intervalRadioButtons"
                        radioButtons={[
                          { text: '1 day', value: '1d' },
                          { text: '1 week', value: '7d' },
                          { text: '1 month', value: '30d' },
                          { text: '3 months', value: '3m' },
                        ]}
                      ></SettingParameters>
                    )}
                  </Col>
                  <Col xs={12} sm={6} md={2}>
                    {tab === 'beamlines' && (
                      <SettingParameters
                        parameter={plottypeParameter}
                        onChange={(plottype) => setPlottypeParameter(plottype)}
                        title="Plot"
                        radioGroupName="plotRadioButtons"
                        radioButtons={[
                          { text: 'Heatmap', value: 'heatmap' },
                          { text: 'Line', value: 'line' },
                          { text: 'Line (accumulative)', value: 'linetotal' },
                          { text: 'Bar', value: 'bar' },
                        ]}
                      ></SettingParameters>
                    )}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
            <Card.Footer>
              <div className="container-fluid">
                <div className="pull-left"></div>
                <div className="pull-right">
                  <Button variant="primary" onClick={applySettings}>
                    Apply
                  </Button>
                </div>
              </div>
            </Card.Footer>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default DataStatisticsMenu;
