import React, { useState } from 'react';
import { useFetcher, useResource } from 'rest-hooks';
import SampleResource from '../../resources/sample';
import ResponsiveTable from '../Table/ResponsiveTable';
import { getSamplePageURL } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListAlt,
  faPencilAlt,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  fileCountFormatter,
  getSampleParameterByName,
  getSampleParameterDescription,
  getSampleParameterValueDescription,
} from './utils';
import SampleParameterResource from '../../resources/sampleParameter';
import UI from '../../config/ui';
import EditSampleModal from './EditSampleModal';
import DatasetVolumeStatisticsColumn from './DatasetVolumeStatisticsColumn';
import { useSelector } from 'react-redux';

export function userPortalSampleLinkFormatter(sample, width) {
  const sampleURL = getSamplePageURL(sample);
  if (sampleURL) {
    return (
      <Button
        style={{ width, textAlign: 'left', paddingBottom: 1, paddingTop: 1 }}
        size="sm"
        variant="outline-info"
      >
        <a href={sampleURL} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faListAlt} />
          <span style={{ marginLeft: 10 }}>{sample.name}</span>
        </a>
      </Button>
    );
  }
}

export function isSampleEditable(sample) {
  return !getSampleParameterByName(sample, UI.sample.nonEditableParameterName);
}

export function parseParameters(sample) {
  const parameters = {};
  if (sample.parameters) {
    sample.parameters.forEach((p) => {
      parameters[p.name] = p.value;
    });
  }
  return parameters;
}

function getLgHeaderStyle(width, hidden) {
  return {
    xs: { hidden: true },
    sm: { hidden: true },
    md: { hidden: true },
    lg: { hidden, width, textAlign: 'center' },
  };
}

function SampleColumns({ investigationId, editSample, showSampleStats }) {
  return [
    { text: '', dataField: 'id', hidden: true },
    {
      text: 'Name',
      dataField: 'name',
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      formatter: (cell, sample) => {
        return getSampleParameterValueDescription(sample);
      },
      sort: true,
      responsiveHeaderStyle: {
        md: { hidden: false },
        lg: { hidden: false },
      },
    },
    {
      dataField: 'samplesheetForm',
      text: 'Sample sheet Form',
      sort: false,
      formatter: (_, sample) => {
        return userPortalSampleLinkFormatter(sample, 120);
      },
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { width: 120 },
        md: { width: 150, textAlign: 'center' },
        lg: { width: 150, textAlign: 'center' },
      },
    },
    {
      dataField: 'safety',
      text: 'Safety Mode',
      formatter: (cell, sample) => {
        if (sample.parameters) {
          const parameter = sample.parameters.find(
            (parameter) => parameter.name === 'SafetyLevel'
          );
          if (parameter) return parameter.value;
        }
      },
      sort: true,
      responsiveHeaderStyle: {
        xs: { width: 60, textAlign: 'center' },
        sm: { width: 100, textAlign: 'center' },
        md: { width: 120, textAlign: 'center' },
        lg: { width: 120, textAlign: 'center' },
      },
    },
    {
      text: 'Datasets',
      dataField: 'parameters',
      isDummyField: true,
      formatter: (_, sample) => (
        <DatasetVolumeStatisticsColumn
          parameters={parseParameters(sample)}
        ></DatasetVolumeStatisticsColumn>
      ),
      responsiveHeaderStyle: getLgHeaderStyle(130, !showSampleStats),
    },
    {
      text: 'Files',
      dataField: 'dataFileStats',
      isDummyField: true,
      formatter: (_, sample) => fileCountFormatter(parseParameters(sample)),
      responsiveHeaderStyle: getLgHeaderStyle(80, !showSampleStats),
    },
    {
      text: 'View datasets',
      dataField: 'actions',
      formatter: (_, sample) => (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip">View datasets linked to this sample</Tooltip>
          }
        >
          <Button
            style={{ width: 70, textAlign: 'left', margin: 5 }}
            size="sm"
            variant="primary"
            target="_blank"
            href={`/investigation/${investigationId}/datasets?sampleId=${sample.id}`}
          >
            <FontAwesomeIcon
              icon={faUpRightFromSquare}
              style={{ marginRight: 5 }}
            />
            <span>View</span>
          </Button>
        </OverlayTrigger>
      ),
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { width: 40, textAlign: 'center' },
        md: { width: 70, textAlign: 'center' },
        lg: { width: 70, textAlign: 'center' },
      },
    },
    {
      dataField: 'edit',
      text: '',
      formatter: (_, sample) => {
        if (isSampleEditable(sample)) {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="tooltip"> Edit the sample description</Tooltip>
              }
            >
              <Button
                style={{ width: 70, textAlign: 'left', margin: 5 }}
                size="sm"
                variant="primary"
                onClick={() => editSample(sample.id)}
              >
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  style={{ marginRight: 5 }}
                />
                <span style={{ marginLeft: 2 }}>Edit</span>
              </Button>
            </OverlayTrigger>
          );
        }
      },
      responsiveHeaderStyle: {
        xs: { hidden: true },
        sm: { width: 100, hidden: !UI.sample.editable, textAlign: 'center' },
        md: { width: 100, hidden: !UI.sample.editable, textAlign: 'center' },
        lg: { width: 100, hidden: !UI.sample.editable, textAlign: 'center' },
      },
    },
  ];
}

function SamplesTable(props) {
  const { investigationId, withSampleStats = false } = props;
  const samples = useResource(SampleResource.listShape(), { investigationId });
  const refetch = useFetcher(SampleResource.listShape());
  const refetchSamples = async () => {
    await refetch({ investigationId });
  };

  const user = useSelector((state) => state.user);
  const { isAdministrator } = user;

  const createSampleParameter = useFetcher(
    SampleParameterResource.createShape()
  );
  const editSampleParameter = useFetcher(SampleParameterResource.updateShape());
  const [editingSampleId, setEditingSampleId] = useState(undefined);

  const editedSample = samples.find((s) => s.id === editingSampleId);
  const sampleParameterDescription = getSampleParameterDescription(
    editedSample
  );
  const [description, setDescription] = useState(
    sampleParameterDescription?.value
  );

  function handleClose() {
    setEditingSampleId(undefined);
  }

  function handleShow(sampleId) {
    setEditingSampleId(sampleId);
  }

  async function handleSubmit() {
    const values = {
      investigationId,
      parameterId: sampleParameterDescription?.id,
      value: description,
      name: UI.sample.descriptionParameterName,
      sampleId: editingSampleId,
    };
    if (sampleParameterDescription) {
      await editSampleParameter(values);
    } else if (description) {
      await createSampleParameter(values);
    }
    await refetchSamples();
    handleClose();
  }

  return (
    <>
      <ResponsiveTable
        keyField="id"
        defaultSorted={[{ dataField: 'name', order: 'asc' }]}
        data={samples}
        columns={SampleColumns({
          investigationId,
          editSample: handleShow,
          showSampleStats: withSampleStats || isAdministrator,
        })}
        pageOptions={{
          sizePerPage: 25,
        }}
      />
      <EditSampleModal
        sampleId={editingSampleId}
        handleClose={handleClose}
        sample={editedSample}
        sampleParameterDescription={sampleParameterDescription}
        setDescription={setDescription}
        handleSubmit={handleSubmit}
      ></EditSampleModal>
    </>
  );
}

export default SamplesTable;
