import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { isSendable } from '../../helpers/statusUtils';
import ParcelResource from '../../resources/parcel';

function DownloadLabelButton(props) {
  const { parcel } = props;
  const { investigationId, _id: parcelId } = parcel;

  return (
    <Button
      variant="primary"
      href={ParcelResource.labelUrl({ investigationId, parcelId })}
      download
      size="sm"
      target="_blank"
      rel="noopener noreferrer"
      disabled={!isSendable(parcel)}
    >
      <FontAwesomeIcon icon={faDownload} />
      <span style={{ margin: 10 }}>Download Labels</span>
    </Button>
  );
}

export default DownloadLabelButton;
