import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getTagURL } from '../../../api/icat-plus/logbook';
import TagSelector from '../../../components/Logbook/Tag/TagSelector';

function TagsSelectorContainer(props) {
  const { tags, onChange, user, investigationId, instrumentName } = props;
  const [availableTags, setAvailableTags] = useState(tags);
  const [selectedTags, setSelectedTags] = useState(tags);
  const [fetching, setFetching] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState(null);

  /** Creates an investigation tag on the server
   * @param {object} newTag new tag created on the widget
   */
  const createTag = (newTag) => {
    if (newTag) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: getTagURL(user.sessionId, investigationId, instrumentName),
          data: { ...newTag, investigationId },
        })
          .then((data) => resolve(data.data))
          .catch(() => {
            reject();
            console.log('createTag promise was rejected');
          });
      });
    }
  };

  const onTagCreatedinWidget = (newTag) => {
    createTag(newTag).then((newlyCreatedTag) => {
      const newSelectedTags = [...selectedTags, newlyCreatedTag];
      setSelectedTags(newSelectedTags);
      onChange(selectedTags);
    });
  };

  useEffect(() => {
    onChange(selectedTags);
  }, [onChange, selectedTags]);

  /**
   * Get available tags
   */
  const getTags = () => {
    if (user.sessionId) {
      setFetching(true);
      setErrorMessage(null);

      axios({
        method: 'get',
        url: getTagURL(user.sessionId, investigationId, instrumentName),
      })
        .then((data) => {
          setAvailableTags(
            data.data.filter((tag) => tag.instrumentName || tag.investigationId)
          );
          setFetching(false);
        })
        .catch((error) => {
          console.log(
            `ERROR] An error occured while retrieving the tag list. The error is ${error}`
          );
          setErrorMessage('An error occured while retrieving the tag list.');
          setFetching(false);
        });
    } else {
      console.log('[ERROR] Missing props in AvailableTagsContainer');
      setAvailableTags([]);
      setFetching(false);
      setErrorMessage(null);
    }
  };

  return (
    <TagSelector
      availableTags={availableTags}
      selectedTags={selectedTags}
      onTagCreatedinWidget={onTagCreatedinWidget}
      onTagSelectedInWidget={setSelectedTags}
      getTags={getTags}
      isFetching={fetching}
    />
  );
}

TagsSelectorContainer.propTypes = {
  /* investigation identifier */
  investigationId: PropTypes.number,
  /* instrumentName */
  instrumentName: PropTypes.string,
  /* function triggered when the tag selection has changed. Currently selected tags are argument of this function */
  onChange: PropTypes.func,
  /* Tags initially selected */
  tags: PropTypes.array,
  /* user details (redux props) */
  user: PropTypes.object.isRequired,
};

export default TagsSelectorContainer;
