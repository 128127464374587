import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { createInstrumentScientists } from '../../api/icat-plus/catalogue';
import Loader from '../Loader';
import ResponsiveTable from '../Table/ResponsiveTable';
import { ErrorUserMessage } from '../UserMessages/ErrorUserMessage';

function InstrumentScientistTable(props) {
  const {
    user,
    fetchInstrumentScientistsBySessionId,
    instrumentScientists,
    isFetching,
    refetch,
  } = props;
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState();

  const handleRevokeClick = async (instrumentscientistsid) => {
    setFetching(true);

    axios
      .delete(createInstrumentScientists(user.sessionId), {
        data: {
          instrumentscientistsid: [instrumentscientistsid],
        },
      })
      .then(() => {
        setFetching(false);
        fetchInstrumentScientistsBySessionId(user.sessionId);
      })
      .catch((error) => {
        setFetching(false);
        setMessage({
          isError: true,
          text: error.message,
        });
      });
    await refetch();
  };

  const renderButtons = (_, row) => (
    <Button variant="danger" onClick={() => handleRevokeClick(row.id)}>
      Revoke
    </Button>
  );

  const getColumns = () => {
    return [
      {
        text: 'id',
        dataField: 'id',
        hidden: true,
      },
      {
        text: 'User',
        dataField: 'fullName',
        sort: true,
      },
      {
        text: 'Beamline',
        dataField: 'instrumentName',
        sort: true,
      },
      {
        dataField: 'buttons',
        text: '',
        formatExtraData: this,
        formatter: renderButtons,
      },
    ];
  };

  const data = instrumentScientists.sort(
    (a, b) => a.instrument.name > b.instrument.name
  );

  if (isFetching || fetching) {
    return <Loader message="Loading beamline managers..." inPanel />;
  }

  return (
    <>
      {message && message.text && <ErrorUserMessage text={message.text} />}
      <ResponsiveTable
        data={data.map((is) => ({
          id: is.id,
          instrumentName: is.instrument.name,
          fullName: is.user.fullName,
        }))}
        columns={getColumns()}
        pageOptions={{
          sizePerPage: 100,
        }}
      />
    </>
  );
}

export default InstrumentScientistTable;
