import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import { getDatasetParameterValueByName } from '../../helpers';
import ParameterTableWidget from '../Instrument/ParameterTableWidget';
import GalleryDatasetThumbnail from './GalleryDatasetThumbnail';

function MXDatasetSummary(props) {
  const { dataset } = props;

  const getParameters = () => {
    return [
      { name: 'Name', value: dataset.name },
      {
        name: 'Definition',
        value: getDatasetParameterValueByName(dataset, 'definition'),
      },
      {
        name: 'Start',
        value: moment(dataset.startDate).format('LTS'),
      },
      { name: 'Sample', value: dataset.sampleName },
      {
        name: 'Images',
        value: getDatasetParameterValueByName(dataset, 'MX_numberOfImages'),
      },
      {
        name: 'Transmission',
        value: `${getDatasetParameterValueByName(
          dataset,
          'MX_transmission'
        )} %`,
      },
      {
        name: 'Prefix',
        value: getDatasetParameterValueByName(dataset, 'MX_template'),
      },
    ];
  };

  const toExponential = (value) => {
    if (value) {
      try {
        return parseFloat(value).toExponential();
      } catch {}
    }
    return '';
  };

  const getTechniqueParameters = () => {
    return [
      {
        name: 'Resolution',
        value: `${getDatasetParameterValueByName(dataset, 'MX_resolution')} Å`,
      },
      {
        name: 'Wavelength',
        value: `${getDatasetParameterValueByName(
          dataset,
          'InstrumentMonochromator_wavelength'
        )} Å`,
      },
      {
        name: 'Exposure Time',
        value: `${getDatasetParameterValueByName(
          dataset,
          'MX_exposureTime'
        )} s`,
      },
      {
        name: 'Flux start',
        value: toExponential(
          getDatasetParameterValueByName(dataset, 'MX_flux')
        ),
      },
      {
        name: 'Flux end',
        value: toExponential(
          getDatasetParameterValueByName(dataset, 'MX_fluxEnd')
        ),
      },
      {
        name: 'X Beam',
        value: `${getDatasetParameterValueByName(dataset, 'MX_xBeam')} mm`,
      },
      {
        name: 'Y Beam',
        value: `${getDatasetParameterValueByName(dataset, 'MX_yBeam')} mm`,
      },
    ];
  };

  return (
    <Container fluid style={{ margin: 20 }}>
      <Row>
        <Col xs={12} md={2}>
          <ParameterTableWidget striped={false} parameters={getParameters()} />
        </Col>
        <Col xs={12} sm={12} md={2}>
          <ParameterTableWidget
            striped={false}
            parameters={getTechniqueParameters()}
          />
        </Col>

        {[0, 1, 2, 3, 4, 5, 6].map((index) => (
          <Col xs={12} sm={12} md={2} key={index}>
            <GalleryDatasetThumbnail
              dataset={dataset}
              index={index}
            ></GalleryDatasetThumbnail>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default MXDatasetSummary;
