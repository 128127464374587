import React from 'react';
import {
  OverlayTrigger,
  Popover,
  Button,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import moment from 'moment';
import { FILE_COUNT } from '../../constants/parameterTypes';
import { Link } from 'react-router-dom';
import {
  INVESTIGATION_DATE_FORMAT,
  INVESTIGATION_TIME_FORMAT,
} from '../../constants';
import DOIBadge from '../doi/DOIBadge';
import UI from '../../config/ui';
import {
  faArrowCircleRight,
  faCalendar,
  faListAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function startDateFormatter(investigation) {
  function getDateFormat(date, dateFormat, timeFormat, title) {
    return (
      <>
        <strong>{title}</strong> {moment(date).format(dateFormat)}
        <span style={{ marginLeft: '20px', color: 'grey', fontSize: '14px' }}>
          {moment(date).format(timeFormat)}
        </span>
      </>
    );
  }
  const calendarHoverFocus = (
    <Popover
      id="popover-trigger-hover-focus"
      title="Experimental session dates"
    >
      {getDateFormat(
        investigation.startDate,
        INVESTIGATION_DATE_FORMAT,
        INVESTIGATION_TIME_FORMAT,
        'Start date:'
      )}
      <br />
      {getDateFormat(
        investigation.endDate,
        INVESTIGATION_DATE_FORMAT,
        INVESTIGATION_TIME_FORMAT,
        'End date:'
      )}
    </Popover>
  );

  return investigation.startDate ? (
    <>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="bottom"
        overlay={calendarHoverFocus}
      >
        {dateFormatter(
          investigation.startDate,
          INVESTIGATION_DATE_FORMAT,
          true
        )}
      </OverlayTrigger>
    </>
  ) : (
    ''
  );
}

export function dateFormatter(date, format, hasIcon) {
  return date ? (
    <span>
      {hasIcon && (
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: '5px' }} />
      )}
      {moment(date).format(format)}
    </span>
  ) : (
    ''
  );
}

/**
 * This method checks if there is a pk to the user portal and a link then it will return a link to the user portal
 * @param {*} investigation
 * @param {*} link
 */
export function userPortalLinkFormatter(investigation, width) {
  if (investigation?.parameters?.[UI.userPortal.investigationParameterPkName]) {
    const link =
      UI.userPortal.link +
      investigation.parameters[UI.userPortal.investigationParameterPkName];
    return (
      <Button
        style={{ width, textAlign: 'left', paddingBottom: 1, paddingTop: 1 }}
        size="sm"
        variant="outline-info"
      >
        <a href={link} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faListAlt} />
          <span style={{ marginLeft: 10 }}>{investigation.name}</span>
        </a>
      </Button>
    );
  }
}

export function beamlineFormatter(investigation) {
  try {
    return (investigation?.instrument?.name || '').toUpperCase();
  } catch {
    return 'No instrument found';
  }
}

export function nameFormatter(investigation, showLink, section = 'datasets') {
  const { id, name } = investigation;

  if (!showLink) {
    return <span style={{ fontWeight: 'bold' }}>{name}</span>;
  }

  return (
    <Link to={`/investigation/${id}/${section}`}>
      <Button
        size="sm"
        style={{
          width: '100%',
          textAlign: 'left',
          paddingBottom: 1,
          paddingTop: 1,
        }}
        variant="primary"
      >
        <FontAwesomeIcon icon={faArrowCircleRight} />
        <span style={{ marginLeft: 10 }}>{name}</span>
      </Button>
    </Link>
  );
}

export function experimentFormatter(investigation, showLink) {
  const { summary, doi } = investigation;

  return (
    <Container style={{ textAlign: 'center' }}>
      <Row className="show-grid">
        <Col xs={12} md={12}>
          {nameFormatter(investigation, showLink)}
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12} md={12}>
          <span style={{ fontWeight: 'bold' }}>
            {beamlineFormatter(investigation)}
          </span>
        </Col>
      </Row>
      <Row className="show-grid">
        <Col xs={12}>
          <div style={{ color: 'gray', fontStyle: 'italic' }}>{summary}</div>
        </Col>
      </Row>
      <Row className="show-grid" style={{ fontSize: 10 }}>
        <Col xs={12}>
          <DOIBadge doi={doi} />
        </Col>
      </Row>
    </Container>
  );
}

export function fileCountFormatter(parameters) {
  const fileCount = parameters[FILE_COUNT];

  if (fileCount === undefined) {
    return '';
  }

  return (
    <span style={{ width: 50, textAlign: 'right', float: 'left' }}>
      {fileCount}
    </span>
  );
}

export function orcidFormatter(orcid, isCompactView) {
  return orcid ? (
    <a
      href={`https://orcid.org/${orcid}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginRight: '2px', marginLeft: '4px' }}
    >
      <img
        alt="ORCID logo"
        src="/images/orcid_16x16.png"
        width="16"
        height="16"
      />
      {isCompactView && orcid}
    </a>
  ) : (
    ''
  );
}
/**
 * If exists, returns the sample parameter with the given name, undefined otherwise
 * @param {*} sample
 * @param {*} name
 * @returns
 */
export function getSampleParameterByName(sample, name) {
  if (sample && sample.parameters) {
    return sample.parameters.find((parameter) => parameter.name === name);
  }
  return undefined;
}

/**
 * If exists, returns the sample parameter value with the given name, undefined otherwise
 * @param {*} sample
 * @param {*} name
 * @returns
 */
export function getSampleParameterValueByName(sample, name) {
  const parameter = getSampleParameterByName(sample, name);
  return parameter ? parameter.value : undefined;
}

/**
 * Returns the sample parameter description value
 * @param {*} sample
 * @returns
 */
export function getSampleParameterValueDescription(sample) {
  return getSampleParameterValueByName(
    sample,
    UI.sample.descriptionParameterName
  );
}

/**
 * Returns the sample parameter description
 * @param {*} sample
 * @returns
 */
export function getSampleParameterDescription(sample) {
  return getSampleParameterByName(sample, UI.sample.descriptionParameterName);
}

/**
 * Returns true if the investigation is released.
 * It means that has a DOI and a released date that is < currentTime
 * @param {*} investigation
 * @returns boolean
 */
export function isReleasedInvestigation(investigation) {
  if (investigation) {
    return (
      !!investigation.releaseDate &&
      investigation.doi &&
      new Date(investigation.releaseDate) < new Date()
    );
  }
  return false;
}
