import React from 'react';
import StartTimeDatasetPanel from '../StartTimeDatasetPanel';
import { Container, Row, Col, Table, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import GalleryDatasetThumbnail from '../../GalleryDatasetThumbnail';
import {
  getOutputGalleryImageIdByKey,
  getDatasetParameterValueByName,
} from '../../../../helpers';
import HTXRPDDatasetColumn from './HTXRPDDatasetColumn';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ParameterTableWidget from '../../../Instrument/ParameterTableWidget';

function HTXRPDDatasetWidget(props) {
  const { dataset } = props;
  return (
    <Table>
      <tr>
        <td
          style={{
            borderRight: '1px solid gray',
            borderTop: '0px',
          }}
        >
          <StartTimeDatasetPanel dataset={dataset}></StartTimeDatasetPanel>
        </td>
        <td>
          <Container fluid>
            <Row>
              <Col xs={4} md={2}>
                <HTXRPDDatasetColumn
                  dataset={dataset}
                  showOutputDatasets={true}
                ></HTXRPDDatasetColumn>
              </Col>
              <Col>
                <ParameterTableWidget
                  header="Parameters"
                  parameters={[
                    {
                      name: 'Energy',
                      value: getDatasetParameterValueByName(
                        dataset,
                        'HTXRPD_energy'
                      ),
                    },
                    {
                      name: 'Exposure time',
                      value: getDatasetParameterValueByName(
                        dataset,
                        'HTXRPD_exposureTime'
                      ),
                    },
                    {
                      name: 'Vibration rate',
                      value: getDatasetParameterValueByName(
                        dataset,
                        'HTXRPD_sampleVibration'
                      ),
                    },
                  ]}
                ></ParameterTableWidget>
              </Col>
              <Col>
                <ParameterTableWidget
                  header="Processing"
                  parameters={[]}
                ></ParameterTableWidget>
                <div style={{ marginTop: 10 }}>
                  {dataset.outputDatasets &&
                    dataset.outputDatasets.map((ds) => {
                      return (
                        <LinkContainer
                          style={{ padding: 0 }}
                          className={'text-capitalize text-info'}
                          to={`/dataset/${ds.id}?view=datasets`}
                        >
                          <Nav.Link>
                            <FontAwesomeIcon icon={faCheck} />
                            {getDatasetParameterValueByName(
                              ds,
                              'datasetName'
                            ).toLowerCase()}
                          </Nav.Link>
                        </LinkContainer>
                      );
                    })}
                </div>
              </Col>
              <Col xs={6} md={2}>
                <GalleryDatasetThumbnail
                  imageId={getOutputGalleryImageIdByKey(
                    dataset,
                    'integrate',
                    'integrate'
                  )}
                ></GalleryDatasetThumbnail>
              </Col>
              <Col xs={6} md={2}>
                <GalleryDatasetThumbnail
                  imageId={getOutputGalleryImageIdByKey(
                    dataset,
                    'integrate',
                    'ring_detection'
                  )}
                ></GalleryDatasetThumbnail>
              </Col>
            </Row>
          </Container>
        </td>
      </tr>
    </Table>
  );
}
export default HTXRPDDatasetWidget;
