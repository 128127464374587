import React, { useEffect, useRef } from 'react';
import { useResource } from 'rest-hooks';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadCrumbs } from '../actions/breadcrumbs';
import { useScrollToHash, usePageTracking } from '../helpers/hooks';
import {
  trackBeamlineLogBook,
  trackInvestigationLogBook,
} from '../helpers/trackData';
import LogbookPanel from '../components/Logbook/LogbookPanel';
import InvestigationResource from '../resources/investigation';
import { setInvestigationBreadCrumbs } from './investigation-breadcrumbs';
import TabContainerMenu from '../components/TabContainerMenu/TabContainerMenu';

const InvestigationLogbookPanel = (props) => {
  const { investigationId } = props;
  const dispatch = useDispatch();

  const investigations = useResource(InvestigationResource.listShape(), {
    ids: investigationId,
  });
  const investigation =
    investigations.length > 0 ? investigations[0] : undefined;
  const breadcrumbsList = useSelector((state) => state.breadcrumbsList);
  const currentBreadcrumbsList = useRef(); // work around stale breadcrumbsList reference
  currentBreadcrumbsList.current = breadcrumbsList;

  useEffect(() => {
    if (investigation) {
      trackInvestigationLogBook(
        `${investigation.name} ${investigation.instrument?.name} ${investigation.startDate}`
      );
      dispatch(
        setInvestigationBreadCrumbs(
          investigation,
          currentBreadcrumbsList.current
        )
      );
    }
  }, [dispatch, investigation]);

  return (
    <>
      <TabContainerMenu investigation={investigation} />
      <LogbookPanel {...props} investigation={investigation} />
    </>
  );
};

function LogbookPage() {
  usePageTracking();
  const dispatch = useDispatch();

  const { instrumentName, investigationId } = useParams();

  /** Breadcrumbs */
  const breadcrumbsList = useSelector((state) => state.breadcrumbsList);
  const currentBreadcrumbsList = useRef(); // work around stale breadcrumbsList reference
  currentBreadcrumbsList.current = breadcrumbsList;

  useScrollToHash({ milliseconds: 350, attempts: 50 });

  useEffect(() => {
    if (!investigationId && instrumentName) {
      trackBeamlineLogBook(instrumentName.toUpperCase());
      dispatch(
        setBreadCrumbs([{ name: `${instrumentName.toUpperCase()} Logbook` }])
      );
    }
    if (!investigationId && !instrumentName) {
      dispatch(setBreadCrumbs([{ name: `ESRF Logbook` }]));
    }
  }, [dispatch, currentBreadcrumbsList, instrumentName, investigationId]);

  return (
    <>
      {investigationId && (
        <InvestigationLogbookPanel
          investigationId={investigationId}
          instrumentName={instrumentName}
        ></InvestigationLogbookPanel>
      )}
      {!investigationId && (
        <LogbookPanel
          investigationId={investigationId}
          instrumentName={instrumentName}
        ></LogbookPanel>
      )}
    </>
  );
}

export default LogbookPage;
