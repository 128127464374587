// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ParameterTableWidget_row__1Qbhw[data-empty] > td {\n  color: #e7e5e5;\n  border: 0 solid white;\n  background-color: white;\n}\n\n.ParameterTableWidget_row__1Qbhw:not([data-empty]) > td:last-child {\n  font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"row": "ParameterTableWidget_row__1Qbhw"
};
module.exports = exports;
