import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import LoadingBoundary from '../LoadingBoundary';
import { DOIReferenceItem } from './DOIReferenceItem';

/** This component displays the related identifiers linked to a specific doi.  */
export function LinkedDOIWidget(props) {
  const { relatedIdentifiers } = props;

  const hasRelatedIdentifiers =
    relatedIdentifiers !== undefined && relatedIdentifiers.length > 0;
  return (
    <Card style={{ marginBottom: 12 }}>
      <Card.Header>Related DOIs</Card.Header>
      <Card.Body>
        {hasRelatedIdentifiers ? (
          <ListGroup variant="flush">
            {relatedIdentifiers.map((relatedIdentifier, id) => (
              <ListGroup.Item key={`relatedIdentifier-${id}`}>
                {relatedIdentifier.relatedIdentifierType === 'DOI' ? (
                  <LoadingBoundary message="Loading related DOI..." spacedOut>
                    <DOIReferenceItem
                      doi={relatedIdentifier.relatedIdentifier}
                      canCopyToClipboard={false}
                    />
                  </LoadingBoundary>
                ) : (
                  <p>{relatedIdentifier.relatedIdentifier}</p>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <Card.Text>No related DOIs were found.</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
}
